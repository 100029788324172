.dropDownForChangeCompanyContDark {
  position: relative;
  display: inline-block;
}

.dropDownForChangeCompanyContDark .dropdown-toggle {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.dropDownForChangeCompanyContDark>button p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 120px;
  /* 171.429% */
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
    /* Начальное положение над элементом */
  }

  100% {
    opacity: 1;
    transform: translateY(0);
    /* Конечное положение элемента */
  }
}

.dropDownForChangeCompanyContDark>div {
  display: flex;
  position: fixed;
  /* Фиксированное положение для центрирования */
  top: 8%;
  /* Центрирование по вертикали */
  transform: translate(-50%, -50%);
  /* Коррекция положения по оси X и Y */
  width: 363px;
  max-height: 525px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: var(--Field-sizes-Field-border-width, 0px) var(--Field-sizes-Field-border-width, 0px) var(--Border-radius-M, 4px) var(--Border-radius-M, 4px);
  background: #1B1E25;
  box-shadow: 0px 0px 59px 0px rgba(0, 0, 0, 0.06);
  z-index: 14;
  opacity: 0;
  /* Начальная прозрачность */
  animation: slideDown 0.3s ease forwards;
  /* Запуск анимации */
}

.dropDownForChangeCompanyContDark>div>div {
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}


.dropDownForChangeCompanyContDark>div>button {
  display: none;
}

@media only screen and (max-width: 769px) {

  .dropDownForChangeCompanyContDark>div>button {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    background: var(--Primary-Primary-500, #3B79F6);
  }

  .dropDownForChangeCompanyContDark>div>button>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .dropDownForChangeCompanyContDark>div>button>div>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px;
    justify-content: center;
    align-items: center;
  }

  .dropDownForChangeCompanyContDark>div>button>div>div>svg {
    width: 21.5px;
    height: 21.5px;
    flex-shrink: 0;
  }

  .dropDownForChangeCompanyContDark>div>button>div>p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
  }

}

.dropDownForChangeCompanyContDark>div>div>label {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 12px;
  border: var(--Overlay-border-width, 1px) solid rgba(255, 255, 255, 0.10);
  background: #21262E;

}



.dropDownForChangeCompanyContDark>div>div>label>input {
  outline: none;
  border: none;
  width: 100%;
  color: #A8A8A8;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background: #21262E;
  /* 171.429% */
}

.dropDownForChangeCompanyContDark>div>div>label>input::placeholder {
  color: #A8A8A8;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}


.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.thisChecked {
  background: #242831 !important;
}


.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.thisChecked>li:nth-child(2)>div {
  border-radius: var(--Border-radius-L, 8px);
  background: #181B22;
}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  height: 50dvh;
  overflow: auto;
}


.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu::-webkit-scrollbar-thumb {
  background: #21262E;
}

/* Handle on hover */
.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu::-webkit-scrollbar-thumb:hover {
  background: white;
}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>.thisChecked {
  background: #242831 !important;
}


.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>.thisChecked>li:nth-child(2)>div {
  border-radius: var(--Border-radius-L, 8px);
  background: #181b22;
}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  height: 50dvh;
  overflow: auto;
}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont {
  display: flex;
  width: 100%;
  align-items: center;
}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-top: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
  border-bottom: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
  cursor: pointer;
  width: 100%;
}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label:hover {
  background: #242831;
}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label:hover>li:nth-child(2)>div {
  background-color: #12151A;
  /* Use without !important if possible */
}


.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label>li:nth-child(1) {
  width: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label>li:nth-child(1) .Frame625952 {
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
}




.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label>li:nth-child(1) .Frame625952>.myCheckBoxContChecked {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label>li:nth-child(1) .Frame625952>.myCheckBoxCont>div>svg>path {
  stroke: white !important;
}


.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label>li:nth-child(1) .Frame625952>.myCheckBoxContChecked>div {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  border-radius: var(--Border-radius-M, 4px);
  border: var(--Overlay-border-width, 1px) solid var(--Semantic-colors-Primary, #006AF5);
  display: flex;
  justify-content: center;
  align-items: center;
}


.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label>li:nth-child(1) .Frame625952>p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: white;
  text-overflow: ellipsis;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  /* 121.429% */
}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label>li:nth-child(1) .EditDelCont {
  display: flex;
  align-items: center;
  gap: 12px;
}


.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label>li:nth-child(1) .EditDelCont>div {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: var(--Border-radius-L, 8px);
  background: #F7F8FA;
  cursor: pointer;
}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label>li:nth-child(1) .EditDelCont>div>svg {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}


.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label>li:nth-child(2) {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
}


.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label>li:nth-child(2)>div {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: var(--Border-radius-L, 8px);
  background: #242831;
}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label>li:nth-child(2)>div>div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label>li:nth-child(2)>div>div>p:first-child {
  color: #929292;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  /* 121.429% */
}



.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>label>li:nth-child(2)>div>div>p:last-child {
  color: white;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  /* 85.714% */
}


.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>div:last-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  border-top: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
  border-bottom: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>.EditDelCont {
  background: #242831;
}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>div:last-child>div:first-child {
  display: flex;
  padding: 16px 16px 7px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>div:last-child>div:last-child {
  display: flex;
  padding: 7px 16px 16px 16px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

}

.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>div:last-child>div:last-child>div {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #333846;
  cursor: pointer;
}


.dropDownForChangeCompanyContDark .dropDownForChangeCompanydropdown-menu>.companiListCont>div:last-child>div:first-child>div {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #333846;
  cursor: pointer;
}




@media only screen and (max-width: 600px) {
  .dropDownForChangeCompanyContDark>div {
    position: fixed;
    /* Обеспечивает фиксированное положение на экране */
    top: 10%;
    /* Центрирование по вертикали */
    left: 10%;
    /* Центрирование по горизонтали */
    transform: translate(-50%, -50%);
    /* Смещение на 50% для точного центрирования */
    width: 100%;
    /* Чтобы элемент занимал всю ширину на мобильном экране */
    max-width: 363px;
    /* Максимальная ширина для управления размером */
    max-height: 525px;
    /* Ограничение по высоте */
  }
}



@media only screen and (max-width: 411px) {
  .dropDownForChangeCompanyContDark>div {
    width: 85%;
  }
}