@keyframes spinner-0tkp9a {
    to {
       transform: rotate(.5turn);
    }
 }


 @keyframes spinner-c7wet2 {
    100% {
       transform: rotate(1turn);
    }
 }