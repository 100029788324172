

.allcont{
    display: flex;
    width: 100% !important;
    height: 100dvh !important;
    background: #ECF0FF;
    
  
}
.LeftMenuContDekStop{
    display: flex;
}

.outletAndTopMenu{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100dvh;
    min-width: 320px;
    overflow: hidden;

}


@media only screen and (max-width: 1024px) {
    .LeftMenuContDekStop{
        display: none !important;
    }
    
   
  }