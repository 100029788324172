.ModalForEditChatImageContDark .modal-ModalForEditChatImage {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 10;
    /* Sit on top */
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content */
.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent {
    display: inline-flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    border-radius: 12px;
    background: #171B22;
    margin: auto;
}

.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(1)>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(1)>div p {
    color: white;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
}

.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(1)>div div {
    display: flex;
    padding: 6px 9px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}


.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(1)>div div span {
    width: 24px;
    height: 24px;
}

.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(2) {
    display: flex;
    width: 372px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(2)>div {
    display: flex;
    padding: 0px var(--Overlay-border-width, 1px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

/* .ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(2)>div img {
    width: 175px;
    height: 175px;
    border-radius: 182px;

} */

.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(2)>div .label2 {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background:#21262E;
    cursor: pointer;
}

.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(2)>div input {
    display: none;
}

.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(2)>div .label2 p {
    color: var(--semantic-colors-primary-text, #005FDB);
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(3)>button:nth-child(1) {
    display: flex;
    height: 48px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 4.067px;
    flex: 1 0 0;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    color: #858C99;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(3)>button:nth-child(2) {
    display: flex;
    height: 48px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 16px;
    background: var(--Primary-Primary-500, #3B79F6);
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(3)>button:nth-child(2)>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 6.25px 3.25px 5.936px 3.25px;
    justify-content: center;
    align-items: center;
}

.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(3)>button:nth-child(2)>div svg {
    width: 17.5px;
    height: 11.814px;
    flex-shrink: 0;
}

.ModalForEditChatImageContDark .modal-ModalForEditChatImagecontent>div:nth-child(3)>button:nth-child(2):disabled{
    opacity: 0.8;
    cursor: no-drop;
}