.settingsCont {
    display: flex;
    width: 100%;
    height: 100dvh;
    padding: 24px 32px 24px 16px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    background: #ECF0FF;
}

.settingsCont>form {
    display: flex;
    padding: 16px 0px 24px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
    align-self: stretch;
    border-radius: 12px;
    background: #FFF;
    overflow: auto;
}
@media (max-height: 819px) {
    .settingsCont>form  {
      padding-bottom: 150px;
    }
  }

.settingsCont>form>div {
    display: flex;
    padding: 0px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.settingsCont>form>div>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
    align-self: stretch;
}

.settingsCont>form>div>div>p {
    color: #353535;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 100% */
}

.settingsCont>form>div>div>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
    align-self: stretch;
    width: 100%;
}

.settingsCont>form>div>div>div:nth-child(2)>label {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
}

.settingsCont>form>div>div>div:nth-child(2)>label>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

.settingsCont>form>div>div>div:nth-child(2)>label>input {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    overflow: hidden;
    color: #353535;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
    outline: none;
    /* 157.143% */
}

.settingsCont>form>div>div>div:nth-child(2)>label>input::placeholder {
    overflow: hidden;
    color: #353535;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}

.settingsCont>form>div>div>div:nth-child(2)>div {
    display: flex;
    align-items: flex-start;
    gap: 17px;
    align-self: stretch;
}

.settingsCont>form>div>div>div:nth-child(2)>div>label {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    flex: 1 0 0;
}

.settingsCont>form>div>div>div:nth-child(2)>div>label>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

.settingsCont>form>div>div>div:nth-child(2)>div>label>input {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    overflow: hidden;
    color: #353535;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    width: 100%;
    outline: none;
    /* 157.143% */
}

.settingsCont>form>div>div>div:nth-child(2)>div>label>input::placeholder {
    color: #353535;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}

.settingsCont>form>div>div>div:nth-child(3) {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    padding-left: 4px;
}

.settingsCont>form>div>div>div:nth-child(3)>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #353535;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}

.settingsCont>form>div>div>div:nth-child(4)>button {
    display: flex;
    height: 48px;
    padding: 16px;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: #3B79F6;

}

.settingsCont>form>div>div>div:nth-child(4)>button>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px;
    justify-content: center;
    align-items: center;
}

.settingsCont>form>div>div>div:nth-child(4)>button>div>svg {
    width: 21.5px;
    height: 21.5px;
    flex-shrink: 0;
}
.settingsCont>form>div>div>div:nth-child(4)>button>div>svg > path{
    stroke: white;
}

.settingsCont>form>div>div>div:nth-child(4)>button>p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

@media only screen and (max-width: 1024px) {
    .settingsCont{
        padding: 24px 16px !important;
    }
    .settingsCont>form>div>div>div:nth-child(2)>div{
        flex-direction: column;
    }
    .settingsCont>form>div>div>div:nth-child(2)>div>label,.settingsCont>form>div>div>div:nth-child(2)>div>label > input{
        width: 100%;
    }
  }