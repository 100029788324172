@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');
.creatCommentBTNLight {
    display: flex;
    height: 34px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 24px ;
    border: 1px solid #F7F8FA ;
    color: #858C99;
    background-color: #FFFFFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 166.667% */
    transition: 0.2s;
}

.creatCommentBTNLight:hover{
    border-radius: 24px;
    border: 1px solid rgba(59, 121, 246, 0.15);
    background: #F7F8FA;


}