    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

    /* The Modal (background) */
    .addingParticipantsLightCont {
        display: none;
        /* Hidden by default */
        position: fixed;
        /* Stay in place */
        z-index: 14;
        /* Sit on top */
        left: 0;
        top: 0;
        width: 100%;
        /* Full width */
        height: 100%;
        /* Full height */
        overflow: auto;
        /* Enable scroll if needed */
        background-color: rgb(0, 0, 0);
        /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4);
        /* Black w/ opacity */
    }

    /* Modal Content */
    .addingParticipantsLightCont .ddingParticipants-content {
        position: relative;
        display: flex;
        width: 429px;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        gap: 16px;
        border-radius: 12px;
        background: #FFF;
        margin: auto;
        overflow: auto;
        
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s
    }

    .addingParticipantsLightCont .ddingParticipants-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }



    .addingParticipantsLightCont .ddingParticipants-header .textAndClose {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
    }

    .addingParticipantsLightCont .textAndClose svg {
        width: 14px;
        height: 14px;
        flex-shrink: 0;
    }

    .addingParticipantsLightCont .closeIconCont {
        display: flex;
        width: 24px;
        height: 24px;
        padding: 5px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .addingParticipantsLightCont .ddingParticipants-header .textAndClose >p {
        margin: 0 !important;
        color: black !important; 
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Inter";
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 20px !important;
        /* 125% */
    }

    .addingParticipantsLightCont .ddingParticipantsSearchPerformerCont {
        display: flex;
        height: 40px;
        padding: 0px 12px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--Gray-100, #DFE1E6);
        background: var(--White-White, #FFF);
        cursor: pointer;
    }

    .addingParticipantsLightCont input {
        color: var(--Gray-300, #A1A7B3);
        font-feature-settings: 'clig' off, 'liga' off;

        /* Semibold 600/12 */
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 166.667% */
        border: none;
        outline: none;
    }


    .addingParticipantsLightCont input::placeholder {
        color: var(--Gray-300, #A1A7B3);
        font-feature-settings: 'clig' off, 'liga' off;

        /* Semibold 600/12 */
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 166.667% */
        border: none;
        outline: none;
    }

    .addingParticipantsLightCont .ddingParticipantsSearchPerformerCont>div {
        display: flex;
        align-items: center;
        gap: 8px;
    }


    .addingParticipantsLightCont .ddingParticipants--body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        /* Изменил значение на flex-start */
        align-items: flex-end;
        gap: 4px;
        align-self: stretch;
        overflow-y: auto;
        min-height: 200px;
        max-height: 400px;
    }

    .addingParticipantsLightCont .ddingParticipants--body>* {
        flex-shrink: 0;
        /* Предотвращает сжатие элементов внутри flex-контейнера */
    }

    .addingParticipantsLightCont .ddingParticipants--body::-webkit-scrollbar {
        width: 5px;

    }

    /* Track */
    .addingParticipantsLightCont .ddingParticipants--body::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    .addingParticipantsLightCont .ddingParticipants--body::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 12px;

    }

    /* Handle on hover */
    .addingParticipantsLightCont .ddingParticipants--body::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .addingParticipantsLightCont .ddingParticipantsPerformersList {
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid #F7F8FA;
        background: #FFF;
        box-shadow: 0px 1px 7px 0px rgba(106, 113, 128, 0.04);
        cursor: pointer;
        transition: 0.3s;
    }

    .addingParticipantsLightCont .ddingParticipantsPerformersList:hover {
        background-color: #F0F1F5;
    }

    .addingParticipantsLightCont .inputContChecked {
        display: flex;
        padding: 4px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 4px;
        background: #3B79F6;
        width: 25px;
        height: 25px;
    }

    .addingParticipantsLightCont .inputContChecked svg {
        display: flex;
        width: 16px;
        height: 16px;
        padding: 4.167px 2.168px 3.957px 2.165px;
        justify-content: center;
        align-items: center;
    }

    .addingParticipantsLightCont .inputContNoChecked {
        display: flex;
        padding: 4px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.10);

        width: 25px;
        height: 25px;
        justify-content: flex-end;
        align-items: center;
    }

    .addingParticipantsLightCont .ddingParticipantsPerformersList>p {
        margin: 0 !important;
    }

    .addingParticipantsLightCont .perfomer-name {
        color: black !important;
        font-feature-settings: 'clig' off, 'liga' off !important;
        font-family: "Inter";
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: 20px !important;
        /* 166.667% */
    }

    .addingParticipantsLightCont .perfomer-nameDogs {
        color: #A1A7B3;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 166.667% */
    }

    .addingParticipantsLightCont .ddingParticipants--footer {
        display: flex;
        align-items: flex-start;
        gap: 12px;

    }

    .addingParticipantsLightCont .Skip {
        display: flex;
        height: 50px;
        padding: 16px;
        align-items: center;
        gap: 4.067px;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        color: #858C99;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Semibold 600/12 */
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 166.667% */
    }

    .addingParticipantsLightCont .addPerformer {
        display: flex;
        height: 50px;
        padding: 16px;
        align-items: center;
        gap: 4.067px;
        border-radius: 16px;
        background: #3B79F6;
        color: white;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Semibold 600/12 */
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 166.667% */
    }

    @media only screen and (max-width: 600px) {
        .ddingParticipants-content {
            margin: auto 4% !important;
        }
    }