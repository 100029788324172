.modalForInstructionsContLight {
    max-width: 776px !important;
}

.modalForInstructionsContLight .chakra-modal__body {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    max-width: 776px;

}


.modalForInstructionsContLight .chakra-modal__body .modalForInstructionsHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    width: 100%;
}

.modalForInstructionsContLight .chakra-modal__body .modalForInstructionsHeader>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}

.modalForInstructionsContLight .chakra-modal__body .modalForInstructionsHeader>p {
    align-self: stretch;
    color: #585858;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.modalForInstructionsContLight .chakra-modal__body .modalForInstructionsHeader>div>p {
    color: #353535;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
}


.modalForInstructionsContLight .chakra-modal__body>.androidCont {
    display: flex;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    background: #F8FBFF;
}

.modalForInstructionsContLight .chakra-modal__body>.androidCont>div:first-child {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.modalForInstructionsContLight .chakra-modal__body>.androidCont>div:first-child>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.modalForInstructionsContLight .chakra-modal__body>.androidCont>div:first-child>div>div {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.modalForInstructionsContLight .chakra-modal__body>.androidCont>div:first-child>div>div>p {
    color: var(--grey-8, #25272B);

    /* bold/16 */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}

.modalForInstructionsContLight .chakra-modal__body>.androidCont>div:first-child>div>p {
    align-self: stretch;
    color: var(--grey-6, #4B4D57);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    word-break: break-word;
    /* 157.143% */
}


.modalForInstructionsContLight .chakra-modal__body>.androidCont>div:last-child {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

@media only screen and (max-width: 792px) {
    .modalForInstructionsContLight .chakra-modal__body>.androidCont>div:last-child {
        flex-direction: column;
    }

    .modalForInstructionsContLight {
        max-width: 401px !important;
    }

    .modalForInstructionsContLight .chakra-modal__body {
        max-width: 401px !important;

    }

    .modalForInstructionsContLight .chakra-modal__body>.androidCont>div:last-child>div:nth-child(1),
    .modalForInstructionsContLight .chakra-modal__body>.androidCont>div:last-child>div:nth-child(2),
    .modalForInstructionsContLight .chakra-modal__body>.androidCont>div:last-child>div:nth-child(3),
    .modalForInstructionsContLight .chakra-modal__body>.iphoneCont>div:last-child>div:nth-child(1),
    .modalForInstructionsContLight .chakra-modal__body>.iphoneCont>div:last-child>div:nth-child(2),
    .modalForInstructionsContLight .chakra-modal__body>.iphoneCont>div:last-child>div:nth-child(3) {
        width: 100%;
    }
}

.modalForInstructionsContLight .chakra-modal__body>.androidCont>div:last-child>div:nth-child(1) {
    display: flex;
    padding: 16px 20.667px 0px 20px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 7.059px;
    background: #EEF5FF;
}

.modalForInstructionsContLight .chakra-modal__body>.androidCont>div:last-child>div:nth-child(2) {
    display: flex;
    padding: 16px 20.335px 0px 20.332px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 7.059px;
    background: #EEF5FF;
}

.modalForInstructionsContLight .chakra-modal__body>.androidCont>div:last-child>div:nth-child(3) {
    display: flex;
    padding: 16px 20.335px 0px 20.332px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 7.059px;
    background: #EEF5FF;
}

.closeModal {
    cursor: pointer;
}



/* iphone=========================================== */

.modalForInstructionsContLight .chakra-modal__body>.iphoneCont {
    display: flex;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    background: #F8FBFF;
}

.modalForInstructionsContLight .chakra-modal__body>.iphoneCont>div:first-child {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.modalForInstructionsContLight .chakra-modal__body>.iphoneCont>div:first-child>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.modalForInstructionsContLight .chakra-modal__body>.iphoneCont>div:first-child>div>div {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.modalForInstructionsContLight .chakra-modal__body>.iphoneCont>div:first-child>div>div>p {
    color: var(--grey-8, #25272B);

    /* bold/16 */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}

.modalForInstructionsContLight .chakra-modal__body>.iphoneCont>div:first-child>div>p {
    align-self: stretch;
    color: var(--grey-6, #4B4D57);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    word-break: break-word;
    /* 157.143% */
}


.modalForInstructionsContLight .chakra-modal__body>.iphoneCont>div:last-child {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

@media only screen and (max-width: 792px) {
    .modalForInstructionsContLight .chakra-modal__body>.iphoneCont>div:last-child {
        flex-direction: column;
    }
}

.modalForInstructionsContLight .chakra-modal__body>.iphoneCont>div:last-child>div:nth-child(1) {
    display: flex;
    padding: 16px 20.667px 0px 20px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 7.059px;
    background: #EEF5FF;
}

.modalForInstructionsContLight .chakra-modal__body>.iphoneCont>div:last-child>div:nth-child(2) {
    display: flex;
    padding: 16px 20.335px 0px 20.332px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 7.059px;
    background: #EEF5FF;
}

.modalForInstructionsContLight .chakra-modal__body>.iphoneCont>div:last-child>div:nth-child(3) {
    display: flex;
    padding: 16px 20.335px 0px 20.332px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 7.059px;
    background: #EEF5FF;
}


.modalForInstructionsContLight .chakra-modal__body> .footerModalInstroction {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.modalForInstructionsContLight .chakra-modal__body> .footerModalInstroction>button {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    background: #3B79F6;
}

.modalForInstructionsContLight .chakra-modal__body> .footerModalInstroction>button>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.modalForInstructionsContLight .chakra-modal__body> .footerModalInstroction>button>div>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 2px;
    justify-content: center;
    align-items: center;
}

.modalForInstructionsContLight .chakra-modal__body> .footerModalInstroction>button>div>div>svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.modalForInstructionsContLight .chakra-modal__body> .footerModalInstroction>button>div>p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}