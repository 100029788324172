@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

.messageItemLightCont {
    display: flex;
    padding: 16px 32px 16px 16px;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    background: #FFF;
    margin: 16px 0;
   
    

}

.messageItemLightCont:hover {
    background: #F7F8FA;
    cursor: pointer;
}

.messageItemLightCont p {
    margin: 0 !important;
    color: #767676 !important;

}

.messageItemLightCont .messageInfoCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
}

.messageItemLightCont .frame19 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.messageItemLightCont .frame19 .name {
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}

.messageItemLightCont .frame19 .clock {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 200% */
}

.messageItemLightCont .frame41 {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.messageItemLightCont .frame41 a{
    color: #3B79F6;
}
.messageItemLightCont .messageText {
    color: #6A7180;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    flex: 1 0 0;

}

.openModalForAddSubCommentAndSmileContRelative {
    position: relative;

   

}


@media only screen and (max-width: 1024px) {
    .messageItemLightCont {
        display: flex !important;
        align-items: flex-start !important;
        align-content: flex-start !important;
        gap: 12px !important;
        align-self: stretch !important;
        flex-wrap: wrap !important;
    }

    .messageItemLightCont .messageText {
        flex: 1 0 0 !important;
        color: #6A7180;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
    }
}