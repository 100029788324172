
  
  .dropdownEmojiDark{
    position: relative;
    display: flex;
   
   
  }
  
  .dropdownEmojiDark .dropdownEmojiCont-content {
    display: none;
    position: absolute;
    background-color: #21262E;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 9 !important;
    top: -450px;
    left: 0;
    border-radius: 10px;
    background-color: #21262E;
  }

  .dropdownEmojiDark .dropdownEmojiCont-content .EmojiPickerReact .epr-header .epr-header-overlay{
    background-color: #21262E;
  }


  .dropdownEmojiDark .dropdownEmojiCont-content .EmojiPickerReact .epr-search-container input.epr-search{
    background-color: #21262E;
    border: 1px solid white !important;
  }
  .dropdownEmojiDark .dropdownEmojiCont-content aside.EmojiPickerReact.epr-main:has(input:placeholder-shown) .epr-category-nav{
    background-color: #21262E;
  }


  .dropdownEmojiDark .dropdownEmojiCont-content .EmojiPickerReact .epr-body{
    background-color: #21262E;
  }

  /* width */
  .dropdownEmojiDark .dropdownEmojiCont-content .EmojiPickerReact .epr-body::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .dropdownEmojiDark .dropdownEmojiCont-content .EmojiPickerReact .epr-body::-webkit-scrollbar-track {
    background: #21262E;
   
  }
  
  /* Handle */
  .dropdownEmojiDark .dropdownEmojiCont-content .EmojiPickerReact .epr-body::-webkit-scrollbar-thumb {
    background: #888;
   
  }
  
  /* Handle on hover */
  .dropdownEmojiDark .dropdownEmojiCont-content .EmojiPickerReact .epr-body::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .dropdownEmojiDark .dropdownEmojiCont-content  .EmojiPickerReact li.epr-emoji-category>.epr-emoji-category-label{
    background-color: #21262E;
  }

  .dropdownEmojiDark .dropdownEmojiCont-content  .EmojiPickerReact .Flex.FlexRow{
    background-color: #21262E;
  }

  
  .dropdownEmojiDark .dropdownEmojiCont-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  

  .dropdownEmojiDark .dropdownEmojiCont-content .epr-header{
    background-color: #21262E;
  }




  .dropdownEmojiDark .dropdownEmojiCont-content aside.EmojiPickerReact.epr-main{
    border-color: #21262E;
    background-color: #21262E;
  }


  .dropdownEmojiDark .dropdownEmojiCont-content .EmojiPickerReact button.epr-emoji .epr-emoji-img:hover{
    background-color: #858C99;
  }
  .dropdownEmojiDark .dropdownEmojiCont-content:hover {background-color: #f1f1f1}
  

