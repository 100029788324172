.appContDark {
    display: flex;
    width: 100%;
    height: 100dvh;
    padding: 24px 32px 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 20px;
    flex-shrink: 0;
    background: linear-gradient(0deg, #181B22 0%, #181B22 100%), #ECF0FF;
}

@media only screen and (max-width: 600px) {
    .appContDark {
        display: flex;
        width: 100%;
        padding: 24px 16px;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 16px;
        margin: auto;
        background: linear-gradient(0deg, #181B22 0%, #181B22 100%), #ECF0FF;
    }
}