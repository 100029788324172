.uploadFileLight {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.uploadFileLight>div {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.uploadFileLight>div>div:nth-child(1) {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
}

.uploadFileLight>div>div:nth-child(1)>label {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.uploadFileLight>div>div:nth-child(1)>label>p {
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  background: var(--shades-contrast-5, rgba(25, 59, 103, 0.05));
  color: var(--semantic-colors-primary-text, #005FDB);
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.uploadFileLight>div>div:nth-child(2) {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  flex-wrap: wrap;
  overflow: auto;
  height: 200px;
}

/* width */
.uploadFileLight>div>div:nth-child(2)::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.uploadFileLight>div>div:nth-child(2)::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.uploadFileLight>div>div:nth-child(2)::-webkit-scrollbar-thumb {
  background: #C9C9C9;
}

/* Handle on hover */
.uploadFileLight>div>div:nth-child(2)::-webkit-scrollbar-thumb:hover {
  background: #C9C9C9;
  cursor: pointer;
}






.uploadFileLight>div>div:nth-child(2)>div>div>img,
.uploadFileLight>div>div:nth-child(2)>div>div>video {
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  border-radius: var(--Border-radius-M, 4px);
  object-fit: cover;
  /* Сохраняет пропорции, заполняя элемент */
}

.uploadFileLight>div>div:nth-child(2)>div {
  display: flex;
  width: 169.375px;
  height: 140px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}




.uploadFileLight>div>div:nth-child(2)>div>img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: var(--Border-radius-L, 8px);
  filter: brightness(80%)
}


.uploadFileLight>div>div:nth-child(2)>div>svg {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 250px;
  height: 162px;
  border-radius: var(--Border-radius-L, 8px);
  filter: brightness(80%)
}



.uploadFileLight>div>div:nth-child(2)>div>video {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: var(--Border-radius-L, 8px);
  filter: brightness(80%)
}

.uploadFileLight>div>div:nth-child(2)>div>div {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 12px 13.375px 12px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 44px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  height: 100%;
  flex-direction: column-reverse;
  border-radius: 12px !important;

}

.uploadFileLight>div>div:nth-child(2)>div>div:hover {
  cursor: pointer;
  opacity: 0.9;
}

.uploadFileLight>div>div:nth-child(2)>div>div>div:first-child {
  display: flex;
  width: 144px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.uploadFileLight>div>div:nth-child(2)>div>div>div:first-child>p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: #FFF;
  text-overflow: ellipsis;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 161.104%;
  /* 22.554px */
}

.uploadFileLight>div>div:nth-child(2)>div>div>div:first-child>div {
  display: flex;
  align-items: center;
  gap: var(--Field-sizes-Field---Label-gap, 6px);
  align-self: stretch;
}

.uploadFileLight>div>div:nth-child(2)>div>div>div:first-child>div>p {
  color: #FFF;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  /* 121.429% */
}

.uploadFileLight>div>div:nth-child(2)>div>div>div:last-child {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;

}

.uploadFileLight>div>div:nth-child(2)>div>div>div:last-child>div {
  display: flex;
  align-items: center;
  gap: 8px;
}


.uploadFileLight>div>div:nth-child(2)>div>div>div:last-child>div>div:first-child {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: var(--Border-radius-L, 8px);
  background: #F7F8FA;
  cursor: pointer;
}

.uploadFileLight>div>div:nth-child(2)>div>div>div:last-child>div>div:first-child>svg {
  width: 24px;
  height: 24px;
}


.uploadFileLight>div>div:nth-child(2)>div>div>div:last-child>div>div:last-child {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: var(--Border-radius-L, 8px);
  background: #F7F8FA;
  cursor: pointer;
}

.uploadFileLight>div>div:nth-child(2)>div>div>div:last-child>div>div:last-child>svg {
  width: 24px;
  height: 24px;

}






/* myModalUploadSlider================================================== */


/* The Modal (background) */



.myModalSliderUpload {
  display: none;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, .9);
}

.myModalSliderUpload>.modal-contentSliderUpload {
  position: relative;
  margin: auto;
  padding: 0;
  width: 100%;
  height: 100%;
  animation: animatetop 0.4s;
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}



.myModalSliderUpload>.modal-contentSliderUpload>.modal-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 10px 16px;
  color: white;
}

.myModalSliderUpload>.modal-contentSliderUpload>.modal-header>div {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 50px;
}




.myModalSliderUpload>.modal-contentSliderUpload>.modal-header>div>svg:hover {

  cursor: pointer;

}

.myModalSliderUpload>.modal-contentSliderUpload>.modal-header>div>svg>path {
  stroke: white;
}

.myModalSliderUpload>.modal-contentSliderUpload>.modal-body {
  padding: 2px 16px;
}



/* Стили для слайдера */
.myModalSliderUpload>.modal-contentSliderUpload>.modal-body .slider {
  position: relative;
  text-align: center;
}

.myModalSliderUpload>.modal-contentSliderUpload>.modal-body .slider>img {
  width: 100%;
  /* Изображение не будет шире контейнера */
  max-height: 90vh;
  /* Ограничиваем высоту изображения, чтобы оно не выходило за пределы экрана */
  object-fit: contain;
  /* Сохраняем пропорции изображения */
}


.myModalSliderUpload>.modal-contentSliderUpload>.modal-body .slider>video {
  width: 100%;
  /* Изображение не будет шире контейнера */
  max-height: 90vh;
  /* Ограничиваем высоту изображения, чтобы оно не выходило за пределы экрана */
  object-fit: contain;
  /* Сохраняем пропорции изображения */
}

.myModalSliderUpload>.modal-contentSliderUpload>.modal-body .slide-item {
  width: 100%;
  /* Изображение не будет шире контейнера */
  max-height: 90vh;
  /* Ограничиваем высоту изображения, чтобы оно не выходило за пределы экрана */
  object-fit: contain;
  /* Сохраняем пропорции изображения */
}


.myModalSliderUpload>.modal-contentSliderUpload>.modal-body .slider>.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  user-select: none;
}

.myModalSliderUpload>.modal-contentSliderUpload>.modal-body .slider>.prev {
  left: 0;
}

.myModalSliderUpload>.modal-contentSliderUpload>.modal-body .slider>.next {
  right: 0;
}

.myModalSliderUpload>.modal-contentSliderUpload>.modal-body .slider>.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}