.CommentAllContLight {
  display: flex;
  height: 247px;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  background-color: white;

}

.CommentAllContLight>div {
  display: flex;
  width: 100%;
  height: 247px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

}

.CommentAllContLight>div>div {
  display: flex;
  height: 350px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
  align-self: stretch;

}

.CommentAllContLight>div>div>div:first-child {
  display: flex;
  height: 137px;
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  flex-shrink: 0;
  align-self: stretch;

}

.CommentAllContLight>div>div>div:first-child>div {
  display: flex;
  padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Field-sizes-Field---Label-gap, 6px);
  flex: 1 0 0;
  align-self: stretch;
}

.CommentAllContLight>div>div>div:first-child>div>p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
  text-overflow: ellipsis;
  /* Field label */
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  /* 121.429% */
}

.CommentAllContLight>div>div>div:first-child>div>div {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  z-index: 1;


}

.CommentAllContLight>div>div>div:first-child>div>div>div:first-child {
  max-height: 150px;
  overflow: auto;
  width: 100%;
}

/* width */
.CommentAllContLight>div>div>div:first-child>div>div>div:first-child::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.CommentAllContLight>div>div>div:first-child>div>div>div:first-child::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.CommentAllContLight>div>div>div:first-child>div>div>div:first-child::-webkit-scrollbar-thumb {
  background: #C9C9C9;
}

/* Handle on hover */
.CommentAllContLight>div>div>div:first-child>div>div>div:first-child::-webkit-scrollbar-thumb:hover {
  background: #C9C9C9;
  cursor: pointer;
}



.CommentAllContLight>div>div>div:first-child>div>div>div>textarea {
  width: 100%;
  outline: none;
  height: auto;
  overflow: hidden;
  /* Убираем скролл */
  resize: none;
  /* Убираем возможность изменения размера */
  min-height: 20px;

}

.CommentAllContLight .commentOptions {
  display: flex;
  width: 100%;
  height: 24px;
  justify-content: flex-end;
  gap: 12px;
}

.CommentAllContLight .commentOptions>label {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: #FFF;
}

.CommentAllContLight .commentOptions svg {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.CommentAllContLight .commentOptions>div {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: #FFF;
}


.CommentAllContLight .commentScrollDiv {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 200px;
  overflow: auto;
  justify-content: space-between;
  padding: 20px 0;

}




/* width */
.CommentAllContLight .commentScrollDiv::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.CommentAllContLight .commentScrollDiv::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.CommentAllContLight .commentScrollDiv::-webkit-scrollbar-thumb {
  background: #C9C9C9;
}

/* Handle on hover */
.CommentAllContLight .commentScrollDiv::-webkit-scrollbar-thumb:hover {
  background: #C9C9C9;
  cursor: pointer;
}





.CommentAllContLight .CommentListCont {
  display: flex;
  flex-direction: column;
}

.CommentAllContLight .localFilesRender {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.CommentAllContLight .CommentItem {
  display: flex;
  width: 100%;
  align-items: flex-start;

}

.CommentAllContLight .CommentItem>div {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}

.CommentAllContLight .CommentItem>div>img {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 32px;

}

.CommentAllContLight .CommentItem>div>.firstDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;

}


.CommentAllContLight .CommentItem>div>.firstDiv>div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}


.CommentAllContLight .CommentItem>div>.firstDiv>div>div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.CommentAllContLight .CommentItem>div>.firstDiv>div>div:first-child>p:first-child {
  color: #353535;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 166.667% */
}


.CommentAllContLight .CommentItem>div>.firstDiv>div>div:first-child>.commentItemTime {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Gray-400, #858C99);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: "Inter";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 200% */
}

.CommentAllContLight .CommentItem>div>.firstDiv>div>div:last-child {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
}

.CommentAllContLight .CommentItem>div>.firstDiv>div>div:last-child>div {
  width: 100%;
  /* Устанавливает ширину контейнера на 100% */
  max-width: 600px;
  /* Задайте максимальную ширину контейнера */
  overflow-wrap: break-word;
  /* Позволяет переносить длинные слова */
  word-wrap: break-word;
  /* Поддержка старых браузеров */
}

.CommentAllContLight .CommentItem>div>.firstDiv>div>div:last-child>div p {
  flex: 1 0 0;
  color: #767676;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 166.667% */

}





.CommentAllContLight .lastDiv {
  display: flex;
  width: 54px;
  height: 54px;
  padding: 0px 16px;
  align-items: center;
  gap: var(--Field-sizes-Field---Label-gap, 6px);
  border-right: var(--Overlay-border-width, 1px) solid rgba(136, 136, 136, 0.10);
  justify-content: center;
  align-items: center;
  align-content: center;

}

.CommentAllContLight .lastDiv:hover {

  cursor: pointer;
}

.CommentAllContLight .lastDiv>svg {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.CommentAllContLight .fileCommentFiles {
  display: flex;
  height: 65px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}


/* file render cont start */

.CommentAllContLight .FilRenderCreat {
  display: flex;
  align-items: center;
  gap: 13px;

}

.CommentAllContLight .FilRenderCreat>div:first-child {
  display: flex;
  width: 169.375px;
  height: 140px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

}

.CommentAllContLight .FilRenderCreat>div:first-child img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: var(--Border-radius-L, 8px);
  filter: brightness(80%);
}

.CommentAllContLight .FilRenderCreat>div:first-child>div {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 12px 13.375px 12px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 44px;
  flex: 1 0;
  align-self: stretch;
  width: 100%;
  height: 100%;
  flex-direction: column-reverse;
  border-radius: 12px !important;
}

.CommentAllContLight .FilRenderCreat>div:first-child>div>div:first-child {
  display: flex;
  width: 144px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0;
}

.CommentAllContLight .FilRenderCreat>div:first-child>div>div:first-child>p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: #FFF;
  text-overflow: ellipsis;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 161.104%;
}


.CommentAllContLight .FilRenderCreat>div:first-child>div>div:first-child>div {
  display: flex;
  align-items: center;
  gap: var(--Field-sizes-Field---Label-gap, 6px);
  align-self: stretch;
}

.CommentAllContLight .FilRenderCreat>div:first-child>div>div:first-child>div>p {
  color: #FFF;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}

.CommentAllContLight .FilRenderCreat>div:first-child>div>div:last-child {
  display: flex;
  align-items: center;
  gap: 8px;
}

.CommentAllContLight .FilRenderCreat>div:first-child>div>div:last-child>div:first-child {
  display: flex;
  align-items: center;
  gap: 8px;
}

.CommentAllContLight .FilRenderCreat>div:first-child>div>div:last-child>div:first-child>div:first-child {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: var(--Border-radius-L, 8px);
  background: #F7F8FA;
  cursor: pointer;
}

.CommentAllContLight .FilRenderCreat>div:first-child>div>div:last-child>div:first-child>div:last-child {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: var(--Border-radius-L, 8px);
  background: #F7F8FA;
  cursor: pointer;
}






/* file render cont end */

.CommentAllContLight .editIamgeCont {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: white;

}

.popoverCommentCreatLight .chakra-popover__body {
  display: flex;
  flex-direction: column;
  gap: 10;
}

.popoverCommentCreatLight .chakra-popover__body>div {
  display: flex;
  padding: 5px 4px;
  align-items: flex-start;
  gap: 8px;
  align-items: center;
  align-content: center;
}

.popoverCommentCreatLight .chakra-popover__body>div>p {
  color: #353535;
  /* Field label */
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  /* 121.429% */
}


/* Стили для модального окна */
.myModalSliderComment {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, .9);
}

.modal-contentSliderComment {
  position: relative;
  margin: auto;
  padding: 0;
  width: 100%;
  height: 100%;
  animation: animatetop 0.4s;
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}



.modal-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 10px 16px;
  color: white;
}

.modal-header>div {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 50px;
}




.modal-header>div>svg:hover {

  cursor: pointer;

}

.modal-header>div>svg>path {
  stroke: white;
}

.modal-body {
  padding: 2px 16px;
}



/* Стили для слайдера */
.slider {
  position: relative;
  text-align: center;
}

.slider>img {
  width: 100%;
  /* Изображение не будет шире контейнера */
  max-height: 90vh;
  /* Ограничиваем высоту изображения, чтобы оно не выходило за пределы экрана */
  object-fit: contain;
  /* Сохраняем пропорции изображения */
}


.slider>video {
  width: 100%;
  /* Изображение не будет шире контейнера */
  max-height: 90vh;
  /* Ограничиваем высоту изображения, чтобы оно не выходило за пределы экрана */
  object-fit: contain;
  /* Сохраняем пропорции изображения */
}

.slide-item {
  width: 100%;
  /* Изображение не будет шире контейнера */
  max-height: 90vh;
  /* Ограничиваем высоту изображения, чтобы оно не выходило за пределы экрана */
  object-fit: contain;
  /* Сохраняем пропорции изображения */
}


.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  user-select: none;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@media only screen and (max-width: 600px) {
  .CommentAllContLight .commentScrollDiv {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    max-height: 100px;
    /* Ограничивает высоту контейнера */
    overflow-y: scroll !important;
    /* Включает вертикальный скролл */
    justify-content: space-between;
    padding: 20px 0;
  }

  .CommentAllContLight .CommentItem>div>.firstDiv>div>div:last-child>div {
    width: 100%;
    /* Устанавливает ширину контейнера на 100% */
    max-width: 300px;
    /* Задайте максимальную ширину контейнера */
    overflow-wrap: break-word;
    /* Позволяет переносить длинные слова */
    word-wrap: break-word;
    /* Поддержка старых браузеров */
  }
}


@media only screen and (max-width: 520px) {

  .CommentAllContLight .CommentItem>div>.firstDiv>div>div:last-child>div {
    width: 100%;
    /* Устанавливает ширину контейнера на 100% */
    max-width: 200px;
    /* Задайте максимальную ширину контейнера */
    overflow-wrap: break-word;
    /* Позволяет переносить длинные слова */
    word-wrap: break-word;
    /* Поддержка старых браузеров */
  }
}



@media only screen and (max-width: 400px) {

  .CommentAllContLight .CommentItem>div>.firstDiv>div>div:last-child>div {
    width: 100%;
    /* Устанавливает ширину контейнера на 100% */
    max-width: 150px;
    /* Задайте максимальную ширину контейнера */
    overflow-wrap: break-word;
    /* Позволяет переносить длинные слова */
    word-wrap: break-word;
    /* Поддержка старых браузеров */
  }
}


@media only screen and (max-width: 364px) {

  .CommentAllContLight .CommentItem>div>.firstDiv>div>div:last-child>div {
    width: 100%;
    /* Устанавливает ширину контейнера на 100% */
    max-width: 80px;
    /* Задайте максимальную ширину контейнера */
    overflow-wrap: break-word;
    /* Позволяет переносить длинные слова */
    word-wrap: break-word;
    /* Поддержка старых браузеров */
  }
}

@media only screen and (max-width: 337px) {

  .CommentAllContLight .CommentItem>div>.firstDiv>div>div:last-child>div {
    width: 100%;
    /* Устанавливает ширину контейнера на 100% */
    max-width: 40px;
    /* Задайте максимальную ширину контейнера */
    overflow-wrap: break-word;
    /* Позволяет переносить длинные слова */
    word-wrap: break-word;
    /* Поддержка старых браузеров */
  }
}