.qrCodeContOuth {

    padding: 0 !important;
}

.qrCodeContOuth {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 12px;
    background: #FFF;
}

.qrCodeContOuth>div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.qrCodeContOuth>div:first-child>h1 {
    align-self: stretch;
    color: #1E1E1E;
    text-align: center;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.qrCodeContOuth>div:first-child>p {
    align-self: stretch;
    color: #565656;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.qrCodeContOuth>div:last-child {
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    background: #F2F4F8;

    background: url("../../../img/bgQr.png") lightgray 50% / cover no-repeat;


}

.qrCodeContOuth>div:last-child>div {
    background-color: white;
    padding: 20px;
    border-radius: 12px;
}