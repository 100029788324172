.dropdownEditTextLight {
    position: relative !important;
    width: 24px !important;
    height: 24px !important;
    padding: 5px 6px 4.001px 7px !important;
    justify-content: center !important;
    align-items: center !important;
}

.dropdownEditTextLight > .dropdownEditTex-content {
    display: none;
    min-width: 287px !important;
    padding: 12px 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    top: 50%;
    left: 50%;

    transform: translate(-2%, -111%);
    border-radius: 12px;
    border: 1px solid #F7F8FA;
    background: #FFF;
    box-shadow: 0px 1px 7px 0px rgba(106, 113, 128, 0.04);

}




.dropdownEditTextLight .editTextType {
    display: flex;
    padding: 4px 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.dropdownEditTextLight .editTextType>p {
    margin: 0 !important;
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 100%;

}

.dropdownEditTextLight .editTextType>div {
    display: flex;

    align-items: center;
    gap: 4px;
}

.dropdownEditTextLight .editTextType>div>p {
    margin: 0 !important;
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    width: 33px;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #F7F8FA;
}


@media only screen and (max-width: 600px) {
    .dropdownEditTextLight .dropdownEditTex-content{
        transform: translate(8%, -60%);
    }
  }



  @media only screen and (max-width: 340px) {
    .dropdownEditTextLight .dropdownEditTex-content{
        transform: translate(-20%, -60%);
        width: 100px !important;
    }
  }


  .dropdownEditTextLight:hover .dropdownEditTex-content {
        display: block;
  }
  