.videoTutDiv {
  display: flex;
  height: 48px;
  padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

@media only screen and (max-width: 1410px) {

  .videoTutDiv {
    display: none !important;
  }
}

.videoTutDiv>div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.videoTutDiv>div p {
  color: var(--Primary-Primary-500, #3B79F6);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  /* 121.429% */
}

.modalForTutorial {
  margin: 0 !important;
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 10;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  -webkit-animation-name: fadeIn;
  /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s
}

/* Modal Content */
.modalForTutorial .modal-content {
  width: 100% !important;

  bottom: 0;


  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s
}

/* The Close Button */
.modalForTutorial .close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modalForTutorial .close:hover,
.modalForTutorial .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.8;
}

.modalForTutorial .modal-header {
  padding: 2px 16px;
  color: white;
  width: 100%;
  display: flex;
  justify-content: flex-end;

}

.modalForTutorial .modal-header span {
  width: 3% !important;
  background-color: #3B79F6;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.modalForTutorial .modal-body {
  padding: 2px 16px;
  width: 100%;
  height:80dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}



.modalForTutorial .modal-footer {
  
  background-color: #3B79F6;
  color: white;
}



/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0
  }

  to {
    bottom: 0;
    opacity: 1
  }
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0
  }

  to {
    bottom: 0;
    opacity: 1
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes fadeIn {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@media only screen and (max-width: 600px) {
  .modalForTutorial .modal-header {
    justify-content: flex-end;
  }

  .modalForTutorial .modal-header span {
    padding: 1px 20px;
  } 
}