.startDateModalLightCont>div {
    display: flex;
    padding: 0px 4px;
    align-items: center;
    gap: 12px;
}

.startDateModalLightCont>div>div {
    display: flex;
    width: var(--Field-sizes-Field-height, 36px);
    height: var(--Field-sizes-Field-height, 36px);
    padding: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 90px;
    background: #EEF5FF;
}

.startDateModalLightCont>div>input {
    color: #353535;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    background-color: #F8FBFF;
    outline: none;
    border: none;
    width: 132px;
}

.StartDateAndEndDateContentModalLight input{
    width: 100%;
}



  