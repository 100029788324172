@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

.chatItemPrivateLight {
    display: flex;
    padding: 16px 16px 16px 32px;
    justify-content: center;
    align-items: flex-end;
    gap: 12px;
    align-self: stretch;
    border-top: 1px solid rgba(0, 0, 0, 0.10);
    border-right: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    position: relative;
    cursor: pointer;
    width: 100%;



}

.chatItemPrivateLight:hover {
    text-decoration: none;
    background: linear-gradient(0deg, #F7F8FA 0%, #F7F8FA 100%), #F2F7FF;
}

.chatItemPrivateLight .imgCon {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 32px;
    background: #86C995;
}

.chatItemPrivateLight .frame19 {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
}

.chatItemPrivateLight .frame19 p {
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    word-break: break-word;
    /* 166.667% */
}

.chatItemPrivateLight .frame205 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.chatItemPrivateLight .frame205 .pinChat {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.chatItemPrivateLight .frame205 .pinChat>div {
    display: flex;
    width: 12px;
    height: 12px;
    padding: 0.625px 1.508px 0.763px 1.161px;
    justify-content: center;
    align-items: center;
}

.chatItemPrivateLight .frame205 .pinChat>div svg {
    width: 9.331px;
    height: 10.612px;
    flex-shrink: 0;
}

.chatItemPrivateLight .frame205 p {
    margin: 0;
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}

.chatItemPrivateLight .hicon {
    display: flex;
    width: 12px;
    height: 12px;
    padding: 0.625px 1.125px;
    justify-content: center;
    align-items: center;
}

.chatItemPrivateLight .messageTime {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    position: absolute;
    right: 16px;
    top: 12px;
    overflow: hidden;
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}

.activelight {
    background-color: #F7F8FA;
    text-decoration: none;
}

.CountMessage {
    display: flex;
    height: 20px;
    padding: 5px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #5E7DE8;
    color: var(--White-White, #FFF);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Extra Bold 800/8 */
    font-family: "Inter";
    font-size: 8px;
    font-style: normal;
    font-weight: 800;
    line-height: 12px;
    /* 150% */
}