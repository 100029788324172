.DateEditForTable {
  display: flex;
  padding: 0px 4px;
  align-items: center;
  gap: 12px;
}

.DateEditForTable>div {
  display: flex;
  padding: 0px 4px;
  align-items: center;
  gap: 12px;
}

@media only screen and (max-width: 1655px) {
  .DateEditForTable>div {
    display: flex;
    padding: 0px 4px;
    align-items: center;
    gap: 12px;
    width: 100%;
  }
}

.DateEditForTable>div input {
  color: #353535;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  min-width: 100px;
  width: 100%;
  outline: none;
}

.DateEditForTable .dateChange input {
  color: #353535;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  border: none;
  outline: none;
  cursor: none;
}

.DateEditForTable>div>div {
  display: flex;
  width: var(--Field-sizes-Field-height, 36px);
  height: var(--Field-sizes-Field-height, 36px);
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background: #EEF5FF;
}


.DateEditForTable .changeDateInput {
  width: 100%;

  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.chageDateContentLight {
  background-color: white;
}


.chageDateContentLight>.chakra-modal__body .changeDateInput {

  width: 100%;
}