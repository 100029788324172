.employeesContLight {
    display: flex;
    width: 100%;
    height:calc(100dvh - 124px);
    padding: 24px 32px 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
    background: #ECF0FF;

}



.employeesContLight>div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.employeesContLight>div:first-child>.searchEmplotDiv {
    display: flex;
    width: 330px;
    height: 48px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    border: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;
}

.employeesContLight>div:first-child>.searchEmplotDiv>input {
    background-color: white;
    width: 100%;
    color: #A8A8A8;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border: none;
    outline: none;
    /* 171.429% */
}

.employeesContLight>div:first-child>.searchEmplotDiv>input::placeholder {
    background-color: white;
    width: 100%;
    color: #A8A8A8;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
}

.employeesContLight>div:first-child>button {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 12px;
    background: var(--Primary-Primary-500, #3B79F6);
}

.employeesContLight>div:first-child>button>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.employeesContLight>div:first-child>button>div>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px;
    justify-content: center;
    align-items: center;
}

.employeesContLight>div:first-child>button>div>div>svg {
    width: 21.5px;
    height: 21.5px;
    flex-shrink: 0;
}

.employeesContLight>div:first-child>button>div>p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.employeesContLight>div:nth-child(2) {
    width: 100%;
    border-radius: 12px;

}


.employeesContLight>div:nth-child(2)>div:nth-child(2) {
    display: flex;
    padding-bottom: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 12px;
    background: #FFF;
    height:auto;
    max-height: calc(100dvh - 226px);
    overflow: auto;
}

.employeesContLight>div:nth-child(2)>div:nth-child(2)>table thead th {
    position: sticky;
    top: 0px;
    z-index: 5;
    background-color: white;
    color: #929292;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 121.429% */
    outline: 0.7px solid rgba(136, 136, 136, 0.10);
    border: 1px solid rgba(136, 136, 136, 0.10);
    padding: 0;
    height: 54px;
    padding: 0px 16px;
    text-align: start;
}

.employeesContLight>div:nth-child(2)>div:nth-child(2)>table tbody td {
    padding: 0;
    height: 54px;
    padding: 0px 16px;
    text-align: start;
    outline: 0.7px solid rgba(136, 136, 136, 0.10);

    flex: 1 0 0;
    color: #353535;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
}

.employeesContLight>div:nth-child(2)>div:nth-child(2)>table thead th>label {
    border: 1px solid #DADFE4;
    border-radius: 3px;
    background-color: #DADFE4;
}

.employeesContLight>div:nth-child(2)>div:nth-child(2)>table tbody td>label {
    border: 1px solid #DADFE4;
    border-radius: 3px;
    background-color: #DADFE4;
}

.employeesContLight>div:nth-child(2)>div:nth-child(2)>table thead .employOtherOptions {

    width: 50%;


}

.employeesContLight>div:nth-child(2)>div:nth-child(2)>table thead .employOtherOptions>button:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;
    margin-left: 97%;

}

.employeesContLight>div:nth-child(2)>div:nth-child(2)>table thead .THIMG {
    text-align: center;
}

.employeesContLight>div:nth-child(2)>div:nth-child(2)>table tbody .THIMGTD {
    text-align: center;
}

.employeesContLight>div:nth-child(2)>div:nth-child(2)>table {
    border-radius: 12px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px;

}

.deletrTD {
    display: flex;
    padding: 8px 0px;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: var(--Border-radius-M, 4px);
}

.employeesContLight>div:nth-child(2)>div:nth-child(2)>table tbody td>.divDeleteBTN,
.employeesContLight>div:nth-child(2)>div:nth-child(2)>table tbody td>.editEmploy {
    display: flex;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: var(--Border-radius-L, 8px);
    background: #F7F8FA;
}

.deleteAllEmployDark {
    display: flex;
    padding: 8px 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    border: none !important;
   
    background-color: #1B1E25 !important;

    /* shadow */
    box-shadow: 0px 1px 7px 0px rgba(106, 113, 128, 0.04);
}

.deleteAllEmployDark>.chakra-popover__body {
    display: flex;
    padding: 4px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    background-color: #1B1E25;
}

.deleteAllEmployDark>.chakra-popover__body>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    width: 100%;
}

.deleteAllEmployDark>.chakra-popover__body>div>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 3px 2.5px 3px 3.5px;
    justify-content: center;
    align-items: center;

}

.deleteAllEmployDark>.chakra-popover__body>div>div>svg {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
}

.deleteAllEmployDark>.chakra-popover__body>div>div>svg path{
    stroke: #DD5E98;
}

.deleteAllEmployDark>.chakra-popover__body>div>p {
    color: white;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.forMobileLight {
    display: none;
}

@media only screen and (max-width: 790px) {
    .employeesContLight>div:nth-child(2)>div:nth-child(2)>table {
        display: none;
    }

    .forMobileLight {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 12px;
        background: #FFF;
        height: 60dvh;
        overflow: auto;
    }


    .forMobileLight::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    .forMobileLight::-webkit-scrollbar-track {
        background: #ECF0FF;
    }

    /* Handle */
    .forMobileLight::-webkit-scrollbar-thumb {
        background: #aeacac;
    }

    /* Handle on hover */
    .forMobileLight::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .forMobileLight>div {
        display: flex;
        padding-top: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        align-self: stretch;
        border-radius: 12px 12px var(--Field-sizes-Field-border-width, 0px) var(--Field-sizes-Field-border-width, 0px);
        border-bottom: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
        background: #FFF;
    }

    .forMobileLight>div>div:nth-child(1) {
        display: flex;
        padding: 0px 16px 16px 16px;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border-bottom: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    }

    .forMobileLight>div>div:nth-child(1)>div:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex: 1 0 0;
        align-self: stretch;
    }

    .forMobileLight>div>div:nth-child(1)>div:first-child>div {
        display: flex;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);
        flex: 1 0 0;
    }

    .forMobileLight>div>div:nth-child(1)>div:first-child>div p {
        flex: 1 0 0;
        color: #353535;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        /* 121.429% */
    }

    .forMobileLight>div>div:nth-child(1)>div:last-child {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .forMobileLight>div>div:nth-child(1)>div:last-child>div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        display: flex;
        padding: 4px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        border-radius: var(--Border-radius-L, 8px);
        background: #F7F8FA;
    }

    .forMobileLight>div>div:nth-child(2) {
        display: flex;
        padding: 0px 16px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border-bottom: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
        background: #F8FBFF;
    }

    .forMobileLight>div>div:nth-child(2)>div:first-child {
        display: flex;
        width: 128px;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);
        align-self: stretch;
        border-right: var(--Overlay-border-width, 1px) solid rgba(136, 136, 136, 0.10);
    }

    .forMobileLight>div>div:nth-child(2)>div:first-child>p {
        flex: 1 0 0;
        color: #929292;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        /* 121.429% */
    }

    .forMobileLight>div>div:nth-child(2)>div:last-child {
        display: flex;
        padding: 12px 0px;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);
        flex: 1 0 0;
    }

    .forMobileLight>div>div:nth-child(2)>div:last-child>p {
        flex: 1 0 0;
        color: #353535;
        /* Field label */
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        word-break: break-word;
        /* 121.429% */
    }


    /* 3 */
    .forMobileLight>div>div:nth-child(3) {
        display: flex;
        padding: 0px 16px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border-bottom: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
        background: #F8FBFF;
    }

    .forMobileLight>div>div:nth-child(3)>div:first-child {
        display: flex;
        width: 128px;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);
        align-self: stretch;
        border-right: var(--Overlay-border-width, 1px) solid rgba(136, 136, 136, 0.10);
    }

    .forMobileLight>div>div:nth-child(3)>div:first-child>p {
        flex: 1 0 0;
        color: #929292;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        /* 121.429% */
    }

    .forMobileLight>div>div:nth-child(3)>div:last-child {
        display: flex;
        padding: 12px 0px;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);
        flex: 1 0 0;
    }

    .forMobileLight>div>div:nth-child(3)>div:last-child>p {
        flex: 1 0 0;
        color: #353535;

        /* Field label */
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        word-break: break-word;
        /* 121.429% */
    }

    /* 4 */
    .forMobileLight>div>div:nth-child(4) {
        display: flex;
        padding: 0px 16px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border-bottom: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
        background: #F8FBFF;
    }

    .forMobileLight>div>div:nth-child(4)>div:first-child {
        display: flex;
        width: 128px;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);
        align-self: stretch;
        border-right: var(--Overlay-border-width, 1px) solid rgba(136, 136, 136, 0.10);
    }

    .forMobileLight>div>div:nth-child(4)>div:first-child>p {
        flex: 1 0 0;
        color: #929292;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        /* 121.429% */
    }

    .forMobileLight>div>div:nth-child(4)>div:last-child {
        display: flex;
        padding: 12px 0px;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);
        flex: 1 0 0;
    }

    .forMobileLight>div>div:nth-child(4)>div:last-child>p {
        flex: 1 0 0;
        color: #353535;

        /* Field label */
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        /* 121.429% */
        word-break: break-word;
    }


}

@media only screen and (max-width: 600px) {
    .employeesContLight>div:first-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
    }

    .employeesContLight>div:first-child>button {
        width: 100%;
    }

    .employeesContLight>div:first-child>.searchEmplotDiv {
        width: 100%;
    }

    .employeesContLight>div:first-child>.searchEmplotDiv>input {
        width: 100%;
    }
}

.noEmployPageLight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: 50dvh;
}


.noEmployPageLight>div:first-child {
    width: 226.727px;
    height: 255px;
    background: url("../../../../img/noEmployImgLight.png") 50% / cover no-repeat;
}

.noEmployPageLight>div:last-child>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.noEmployPageLight>div:last-child>div>p:first-child {
    color: #353535;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 100% */
}

.noEmployPageLight>div:last-child>div>p:last-child {
    align-self: stretch;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 121.429% */
}