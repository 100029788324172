@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

.searchChatContainerLight {
    display: flex;
    height: 55px;
    padding: 16px;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    align-self: stretch;
    border-right: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
}

.searchChatContainerLight input {
    width: 100%;
    border: none;
    outline: none;
    color: #A8A8A8;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */

}

.searchChatContainerLight input::placeholder {
    color: #A8A8A8;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
}

.searchChatContainerLight > div{
    display: flex;
    width: 24px;
    height: 24px;
    padding: 0.25px 0.25px 1.25px 1.25px;
    justify-content: center;
    align-items: center;
}

.searchChatContainerLight svg {
    width: 17px !important;
    height: 30px !important;
}