@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.navBarLeftDeskopContDark {
    display: flex;
    width: 305px;
    height: 100dvh;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    border-right: 1px solid rgba(255, 255, 255, 0.10);
    background: #12151A;
    transition: 0.5s;
    overflow: hidden;
    overflow-y: auto;

}


/* width */
.navBarLeftDeskopContDark::-webkit-scrollbar {
    width: 2px;
}

/* Track */
.navBarLeftDeskopContDark::-webkit-scrollbar-track {
    background: #12151A;
}

/* Handle */
.navBarLeftDeskopContDark::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.navBarLeftDeskopContDark::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.navBarLeftDeskopContDark .navBarLeftDeskopFirstChild {
    width: 100%;
}

.navBarLeftDeskopContDark .navBarLeftDeskopFirstChild {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.navBarLeftDeskopContDark .toggleAndLogoCont {
    display: flex;
    height: 72px;
    padding: 20px 16px 20px 32px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom:1px solid rgba(255, 255, 255, 0.10) ;
}


.navBarLeftDeskopContDark .logoAndSaitNameCont {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.navBarLeftDeskopContDark .logoAndSaitNameCont>div {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 1px 2px 0.143px 3px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}


.navBarLeftDeskopContDark .toggleAndLogoCont>div:last-child {
    display: flex;
    padding: 7.2px;
    justify-content: center;
    align-items: center;
    gap: 7.2px;
    border-radius: 72px;
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
    box-shadow: 0px 1.44px 3.6px 0px rgba(38, 51, 77, 0.03);
    cursor: pointer;
}

.navBarLeftDeskopContDark .toggleAndLogoCont>div:last-child svg {
    width: 21.6px;
    height: 21.6px;
}

.navBarLeftDeskopContDark .toggleAndLogoCont>div:last-child svg {
    stroke: white;
}

.navBarLeftDeskopContDark .logoAndSaitNameCont>p {
    color: white;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}


.navBarLeftDeskopContDark .nameAndNavLinkItems {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.navBarLeftDeskopContDark .myInfoAndOptionIcon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

}

.navBarLeftDeskopContDark .Component38 {
    display: flex;
    padding: 12px 16px 12px 32px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #ECF0FF;
    overflow: hidden;
}

.navBarLeftDeskopContDark .Component38>div:first-child {
    display: flex;
    align-items: center;
    gap: 12px;
}



.navBarLeftDeskopContDark .Component38>div:first-child p {
    color: white;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    /* 171.429% */
}


.navBarLeftDeskopContDark .Component38>div:last-child {
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--Border-radius-L, 8px);
    background: #171B22;
    cursor: pointer;
}

.navBarLeftDeskopContDark .Component38>div:last-child div {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 1px;
    justify-content: center;
    align-items: center;
}

.navBarLeftDeskopContDark .Component38>div:last-child div svg {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
}


.navBarLeftDeskopContDark .Component38>div:last-child div svg:hover {
    animation: rotate 2s linear infinite;
    /* Adjust duration and timing function as needed */

}



@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.navBarLeftDeskopContDark .leftMenuLinkItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.navBarLeftDeskopContDark .leftMenuLinkItem>div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}


.navBarLeftDeskopContDark .leftMenuLinkItem .Frame54 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}


.navBarLeftDeskopContDark .leftMenuLinkItem .Frame54:hover {
    background: #5E7DE8;
}


.navBarLeftDeskopContDark .leftMenuLinkItem .Frame54:hover .frame4 p {
    color: white;
}


.navBarLeftDeskopContDark .leftMenuLinkItem .Frame54:hover .frame4 svg path {
    stroke: white;
}


.navBarLeftDeskopContDark .leftMenuLinkItem .active {
    background: #5E7DE8;
}

.navBarLeftDeskopContDark .leftMenuLinkItem .active .badgeChatMessage{
    background-color: white !important;
    
    
}

.navBarLeftDeskopContDark .leftMenuLinkItem .active .badgeChatMessage > p{
    color: #5E7DE8 !important;
}

.navBarLeftDeskopContDark .leftMenuLinkItem .active .frame4 p {
    color: white;
}


.navBarLeftDeskopContDark .leftMenuLinkItem .active .frame4 svg path {
    stroke: white;
}

.navBarLeftDeskopContDark .leftMenuLinkItem .Frame15 {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}


.navBarLeftDeskopContDark .leftMenuLinkItem .Frame14 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}


.navBarLeftDeskopContDark .leftMenuLinkItem .Frame14>div {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

}



.navBarLeftDeskopContDark .leftMenuLinkItem .comp32 {
    display: flex;
    padding: 12px 24px 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 9px;
}


.navBarLeftDeskopContDark .leftMenuLinkItem .frame4 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}


.navBarLeftDeskopContDark .leftMenuLinkItem .frame4 div {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 3px 1.801px 3px 2px;
    justify-content: center;
    align-items: center;
}

.navBarLeftDeskopContDark .leftMenuLinkItem .frame4 div>svg {
    width: 16.199px;
    height: 14px;
    flex-shrink: 0;

}


.navBarLeftDeskopContDark .leftMenuLinkItem .frame4 div>svg path {
    stroke: white;
}

.navBarLeftDeskopContDark .leftMenuLinkItem .frame4 p {

    color: white;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    /* 171.429% */
}


.navBarLeftDeskopContDark .navBarLeftDeskopLasttChild {
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}




.navBarLeftDeskopContDark .addEmploysAndToggleThemeAndReclamCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
}

.navBarLeftDeskopContDark .forSlide {

    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    height: 250px;
    width: 100%;
    border-radius: 12px;
    background: #21262E;
}

.navBarLeftDeskopContDark .addEmploysAndToggleThemeAndReclamCont>div:first-child {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

}


.navBarLeftDeskopContDark .addEmloyLeftBtnCont {
    display: flex;
    width: 273px;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 12px;
    background: #3B79F6;
}



.navBarLeftDeskopContDark .addEmloyLeftBtnCont>div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.navBarLeftDeskopContDark .addEmloyLeftBtnCont>div:first-child>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px;
    justify-content: center;
    align-items: center;
}

.navBarLeftDeskopContDark .addEmloyLeftBtnCont>div:first-child p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}


.navBarLeftDeskopContDark .addEmploysAndToggleThemeAndReclamCont .toggleBTNForTheme {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.navBarLeftDeskopContDark .addEmploysAndToggleThemeAndReclamCont .component38 {
    display: flex;
    width: 273px;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #ECF0FF;
}


.navBarLeftDeskopContDark .addEmploysAndToggleThemeAndReclamCont .component38 .frame4 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.navBarLeftDeskopContDark .addEmploysAndToggleThemeAndReclamCont .component38 .frame4 p {
    color: white;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}


.navBarLeftDeskopContDark .addEmploysAndToggleThemeAndReclamCont .component38 .frame4 svg {
    width: 20px;
    height: 20px;

}

.navBarLeftDeskopContDark .addEmploysAndToggleThemeAndReclamCont .component38 .frame4 svg path {

    stroke: white;
}



.navBarLeftDeskopContDark .navBarLeftDeskopLasttChild>div:last-child {
    display: flex;
    padding: 0px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}