.myTableDivDark {
    width: 100%;
    table-layout: fixed;
    border-radius: 12px;
    overflow-y: auto; /* Оставляем прокрутку */
    border-collapse: collapse;
}

/* Для экранов до 1682px */
@media only screen and (max-width: 1682px) {
    .myTableDivDark {
        height: calc(100vh - 168px); /* Высота с учетом отступов */
    }
}

/* Для экранов от 1683px и выше */
@media only screen and (min-width: 1683px) {
    .myTableDivDark {
        height: 860px; /* Фиксированная высота */
        max-height: 82vh; /* Гибкость, чтобы не было выше экрана */
    }
}




.myTableDivDark .draggable-table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;

    border-radius: 12px;

    position: relative;

}

.myTableDivDark .draggable-table th > p{
    color: #929292;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px; /* 121.429% */
}

.myTableDivDark .draggable-table th>div {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.myTableDivDark .draggable-table th > p > svg{
    position: absolute;
    top: 43px;
    left: 0;
}
.myTableDivDark .draggable-table th > p > svg path{
    stroke: #3B79F6;
}

.myTableDivDark .draggable-table th,
.myTableDivDark .draggable-table td {
    border-bottom: 1px solid rgba(136, 136, 136, 0.10);
    text-align: center;
    padding: 12px;
    font-family: "Inter", sans-serif;
    background-color: #323A49;
}

.myTableDivDark .draggable-table td {
    color: white;
    font-size: 12px;
    font-weight: 500;



}

.myTableDivDark .draggable-table th:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: #323A49;
    z-index: 10;
    width: 150px !important;
    z-index: 100;


}


.myTableDivDark .draggable-table td:nth-child(1) {

    background-color: white;



}

.myTableDivDark .draggable-table th:nth-child(24),
.myTableDivDark .draggable-table th:nth-child(23),
.myTableDivDark .draggable-table th:nth-child(22) {
    width: 150px !important;
}

.myTableDivDark .draggable-table th:last-child {
    width: 150px !important;
}

.myTableDivDark .draggable-table td:nth-child(1)>div {
    display: flex;
    justify-content: space-between;
    gap: 5px;


}

.myTableDivDark .draggable-table td:nth-child(1)>div>img {
    /* height: auto;
    border-radius: 2px;
    object-fit: cover; */
}

.myTableDivDark .draggable-table td:nth-child(1)>div>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
}

.myTableDivDark .draggable-table td:nth-child(1)>div>div>div {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 5px;
}

.myTableDivDark .draggable-table td:nth-child(1)>div>div>div>a {
    color: #3B79F6;
}

.myTableDivDark .draggable-table td:nth-child(1)>div>div>div>svg {
    color: #3B79F6;
}

.myTableDivDark .draggable-table td:nth-child(1)>div>div>p:first-child {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-align: justify;
    line-height: 1.2;
    word-break: break-word;
    color: white;
}

.myTableDivDark .draggable-table td:nth-child(1)>div>div>p:last-child {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-align: justify;
    line-height: 1.2;
    text-align: start;
    word-break: break-word;
}

.myTableDivDark .draggable-table td:nth-child(1)>div>div>p:last-child>span {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-align: justify;
    line-height: 1.2;
    text-align: start;
    color: white;
}



.myTableDivDark .draggable-table th {
    width: 100px;
    color: #929292;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
    white-space: normal;
    word-wrap: break-word;
    position: relative;
}


/* .myTableDivDark .draggable-table th:nth-child(4)>p,
.myTableDivDark .draggable-table th:nth-child(5)>p,
.myTableDivDark .draggable-table th:nth-child(6)>p,
.myTableDivDark .draggable-table th:nth-child(7)>p,
.myTableDivDark .draggable-table th:nth-child(8)>p,
.myTableDivDark .draggable-table th:nth-child(9)>p,
.myTableDivDark .draggable-table th:nth-child(10)>p,
.myTableDivDark .draggable-table th:nth-child(11)>p,
.myTableDivDark .draggable-table th:nth-child(12)>p,
.myTableDivDark .draggable-table th:nth-child(13)>p,
.myTableDivDark .draggable-table th:nth-child(14)>p,
.myTableDivDark .draggable-table th:nth-child(15)>p,
.myTableDivDark .draggable-table th:nth-child(16)>p,
.myTableDivDark .draggable-table th:nth-child(17)>p,
.myTableDivDark .draggable-table th:nth-child(18)>p {
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
    text-align: center;

} */

.myTableDivDark .draggable-table th:nth-child(12) {

    border-left: 1px solid #3B79F6;

}

.myTableDivDark .draggable-table td:nth-child(12) {
    border-left: 1px solid #3B79F6;

}


.myTableDivDark .draggable-table tr {
    background-color: white;
    width: 50px;
}



@media only screen and (max-width: 600px) {

    .myTableDivDark .draggable-table th,
    .myTableDivDark .draggable-table td {
        font-size: 8px;
    }
}


.spinner {
    width: 20px;
    height: 20px;
    margin: auto;
    border-radius: 55%;
    border: 2px solid #3b79f6;
    animation: spinner-bulqg1 0.8s infinite linear alternate, spinner-oaa3wk 1.6s infinite linear;
    z-index: 15;
}

.myTableDivDark .draggable-table .commentCont {
    border: 1px solid #f0f0f0;
    color: rgb(85, 85, 85);
   
}


.myTableDivDark .draggable-table .commentCont p:first-child {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-align: justify;
    line-height: 1.2;
    text-align: center;
    min-height: 15px;
    max-height: 15px;
    color: white;

}


.myTableDivDark .draggable-table .commentCont p:last-child {
    color: white;
}




@keyframes spinner-d3wgkg {
    to {
        transform: rotate(1turn);
    }
}

.myTableDivDark .draggable-table .totalDiv {
    border-radius: 10px;
    box-sizing: border-box;
    font-weight: 900;
    padding: 10px;
}






.myTableDivDark .draggable-table tr.sticky {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #323A49;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
}


/*
  STICKY COLUMN
  Avoid undesirable overlapping shadows by creating a faux shadow on the ::after psudo-element instead of using the css box-shadow property.
  */

.myTableDivDark .draggable-table td.sticky,
.myTableDivDark .draggable-table td.sticky {
    position: sticky;
    left: 0;
    background: var(--stickyBackground);
    background: #323A49;
}

.myTableDivDark .draggable-table td.sticky::after,
.myTableDivDark .draggable-table td.sticky::after {
    content: "";
    position: absolute;
    right: -6px;
    top: 0;
    bottom: -1px;
    width: 5px;
    border-left: 1px solid rgba(136, 136, 136, 0.10);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0) 100%);
}

.myTableDivDark .draggable-table td.sticky::before,
.myTableDivDark .draggable-table td.sticky::before {
    content: "";
    position: absolute;
    left: -6px;
    top: 0;
    bottom: -1px;
    width: 5px;
    border-right: 1px solid rgba(136, 136, 136, 0.10);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.08) 100%);
    
}


.myTableDivDark .draggable-table td .GRADES_NONE {
    background-color: rgba(211, 211, 211, 1);
    /* Цвет для ожидаемых поставок */
    padding: 10px;
    border-radius: 10px;
    
}

.myTableDivDark .draggable-table td .GRADES_NOSALES {
    background-color: rgba(253, 229, 236, 1);
    /* Цвет для нет продаж */
    padding: 10px;
    border-radius: 10px;
   
}

.myTableDivDark .draggable-table td .GRADES_GREEN {
    background-color: rgba(144, 238, 144, 1);
    /* Цвет для «хороший» */
    padding: 10px;
    border-radius: 10px;
    
}

.myTableDivDark .draggable-table td .GRADES_YELLOW {
    background-color: rgba(255, 255, 224, 1);
    /* Цвет для «средний» */
    padding: 10px;
    border-radius: 10px;
    color: black;
}

.myTableDivDark .draggable-table td .GRADES_RED {
    background-color: rgba(240, 128, 128, 1);
    /* Цвет для «плохой» */
    padding: 10px;
    border-radius: 10px;
}

.myTableDivDark .draggable-table td .GRADES_CRITICAL {
    background-color: rgba(139, 0, 0, 1);
    /* Цвет для критического состояния */
    padding: 10px;
    border-radius: 10px;
    color: white;
    outline: none;
    
}

/* Опционально, добавление тени к тексту для улучшения читаемости */

.myTableDivDark .draggable-table .commentCont >div{
   
    width: 100mm;
    min-width:100px;
    border-radius: 4px;
    overflow: hidden;
    border: none;
    outline: none;
}



.myPopover{
    border: none !important;
}

@media only screen and (max-width: 600px) {
    .myTableDivDark {
        height: 70dvh !important;
    }

    .myTableDivDark .draggable-table th>p>svg{
        top: 32px;
    }
  }

