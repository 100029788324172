.video-container {
    display: flex;
    width: 100%;
    margin-bottom: 100px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
   
}

.video-container video {
    border-radius: 10px;
    margin: 10px 0;
    padding: 5px;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: black;
    height: 100vh;
}

.copyBtnCont {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-around;
    padding: 16px;
    border-radius: 12px;
    background: #3B79F6;
    color: var(--Base-Colors-White, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 100%;
    /* 166.667% */

}

.myId input {
    display: flex;
    width: 240px;
    height: 40px;
    padding: 0px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid var(--Gray-100, #DFE1E6);
    background: var(--Base-Colors-White, #FFF);
    color: var(--Gray-300, #A1A7B3);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/12 */
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}


.callBTN {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    padding: 16px;
    border-radius: 12px;
    background: #3B79F6;
    color: var(--Base-Colors-White, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 100%;
    margin-top: 20px;
}

.is_calling {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    border-radius: 12px;
    background: #0dda4a;
    color: var(--Base-Colors-White, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 100%;
    margin-top: 20px;
}

.endCall{
    display: flex;
    padding: 16px;
   align-items: center;
   align-items: center;
   justify-content: space-between;
    border-radius: 12px;
    background: red;
    color: var(--Base-Colors-White, #FFF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 100%;
    margin-top: 20px;
}

.name_h1_chat {
    width: 100% !important;
    text-align: center;
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
    margin-top: 100px;

}