.taskMenegerContDark{
    display: flex;
    width: 100%;
    height: 90dvh;
    padding: 24px 32px 24px 16px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background: #181B22;
    position: relative;
  
}

@media only screen and (max-width: 1410px) {
    .taskMenegerContDark{
        padding: 24px 16px;
    }
  }
  

  @media only screen and (max-width: 600px) {
    .taskMenegerContDark {
        height: 82dvh !important;
    }
}

  