.wbCont {
    width: 100%;
    padding: 24px 32px 24px 16px;
    height: 100dvh;
    background: #ECF0FF;
    box-sizing: border-box;
}


.wbCont > nav {
  display: flex;
  justify-content: flex-start;
}

.wbCont > nav > ul>li> .active {
  color: #1677FF;
}

.wbCont > nav  > ul {
  list-style: none;
  display: flex;
  gap: 20px;
  padding: 0;
  margin: 0;
}

.wbCont > nav  > ul > li {
  display: inline;
}

.wbCont > nav > ul>li> a {
  display: flex;
padding: 9px 16px;
justify-content: center;
align-items: center;
gap: 10px;
flex: 1 0 0;
border-radius: 12px;
background: #F3F8FF;
color: #353535;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}

.wbCont > nav > ul>li> .active {
  display: flex;
  padding: 9px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 12px;
background: linear-gradient(0deg, #5E7DE8 0%, #5E7DE8 100%), #FFF;
color: #FFF;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}
.wbCont {
    display: flex;
    flex-direction: column;
  gap: 10px;
}

.wbCont .inputSearchMarketplace {
    display: flex;
    align-items: center;
    gap: 24px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    justify-content: space-between;
    align-content: center;
    flex-direction: row;
    width: 40%;
    padding: 10px;
}

.wbCont .inputSearchMarketplace>input {
    width: 100%;
    outline: none;
    color: #A8A8A8;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
}

.wbCont .inputSearchMarketplace>span {
    color: #3B79F6;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.wbCont .inputSearchMarketplace>input::placeholder {
    width: 100%;
    outline: none;
    color: #A8A8A8;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
}

.wbCont .inputSearchMarketplace>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 0.25px 0.25px 1.25px 1.25px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.wbCont  .not-found-container {
    text-align: center;
    padding: 50px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin:  auto;
  }
  

  
  .wbCont  .not-found-container h2 {
    color: #333;
    font-size: 22px;
    margin-top: 15px;
  }
  
  .wbCont  .not-found-container p {
    color: #777;
    font-size: 16px;
  }
  
  .wbCont  .not-found-container .retry-button {
    margin-top: 15px;
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .wbCont  .not-found-container  .retry-button:hover {
    background: #0056b3;
  }
  