@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

.rightClickModalForMessageItemDarkCont {
    display: flex;
    width: 100%;
    padding: 16px 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    overflow: hidden;
    border-radius: 12px;
     background: #21262E;
}

/* animation */
@-moz-keyframes growDown {
    0% {
        transform: scaleY(0);
    }

    80% {
        transform: scaleY(1.1);
    }

    100% {
        transform: scaleY(1);
    }
}

@-webkit-keyframes growDown {
    0% {
        transform: scaleY(0);
    }

    80% {
        transform: scaleY(1.1);
    }

    100% {
        transform: scaleY(1);
    }
}

@-o-keyframes growDown {
    0% {
        transform: scaleY(0);
    }

    80% {
        transform: scaleY(1.1);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes growDown {
    0% {
        transform: scaleY(0);
    }

    80% {
        transform: scaleY(1.1);
    }

    100% {
        transform: scaleY(1);
    }
}


/*  */

.rightClickModalForMessageItemDarkCont li {
    display: flex;
    padding: 4px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    transition: 0.3s;
}

.rightClickModalForMessageItemDarkCont li:hover {
    display: flex;
    padding: 4px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background: #3F4754;
    cursor: pointer;
}

.rightClickModalForMessageItemDarkCont li>div {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 12px;
}

.rightClickModalForMessageItemDarkCont li p {
    margin: 0;
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}

.rightClickModalForMessageItemDarkCont .iconContRightClickModal {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px 2.25px 2.22px 2.25px;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.rightClickModalForMessageItemDarkCont .iconContRightClickModal svg {
    width: 19.5px;
    height: 20.53px;
    flex-shrink: 0;
}

.rightClickModalForMessageItemDarkCont .iconContRightClickModal svg path {
    fill: #FFFFFF;
}

.openOtherOptions {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.openOtherOptions svg {
    width: 3.2px;
    height: 3.2px;
    flex-shrink: 0;
}

.RightClickdarkPopoover{
    background-color: #21262E !important;
    color: #21262E !important;
    border-color: #21262E !important;
    border-radius: 12px !important;
    border: 1px solid rgba(0, 0, 0, 0.10) !important;

    box-shadow: 0px 1px 7px 0px rgba(106, 113, 128, 0.04) !important; 

}