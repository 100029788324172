.aouthCont>div:first-child>.sliderCont {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    position: relative;
    background-color: #F7F8FA;
    border-radius: 12px;
    overflow: hidden;
    transition: 1s;
}

.aouthCont>div:first-child>.sliderCont>.pagitation {
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    position: absolute;
    bottom: 50px;
    left: 50px;
    width: 90%;

}

.aouthCont>div:first-child>.sliderCont>.pagitation>div {
    height: 6px;
    flex: 1 0 0;
    border-radius: 24px;
    background: linear-gradient(0deg, #656665 0%, #656665 100%), #656665;
    cursor: pointer;
}


.aouthCont>div:first-child>.sliderCont>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.aouthCont>div:first-child>.sliderCont>img.active {
    opacity: 1;
}

.aouthCont>div:first-child>.sliderCont>img.active {
    opacity: 1;
}


.aouthCont>div:first-child>.sliderCont .link {
    position: absolute;
    bottom: 100px;
    left: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    align-self: stretch;
    border-radius: 20px;

}

.aouthCont>div:first-child>.sliderCont .link>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 20px;
}


.aouthCont>div:first-child>.sliderCont .link>div>p:first-child {
    width: 90%;
    color: #FFF;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-wrap: wrap;
    /* 133.333% */
}


.aouthCont>div:first-child>.sliderCont .link>div>p:last-child {
    width: 90%;
    color: #FFF;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    opacity: 0.9;
    text-wrap: wrap;
}

.textContForMobile {
    display: none;
}

@media only screen and (max-width: 1053px) {
    .sliderCont {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        background: linear-gradient(0deg, #F7F8FA 0%, #F7F8FA 100%), #FFF;
        flex-direction: column-reverse;
        position: relative;
        border-radius: 12px;

    }

    .textContForMobile {
        display: flex;
        width: 100%;
        padding: 26px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        border-radius: 12px;
        background: #FFF;
        margin-top: 16px;
        height: 282px;
        margin: auto;
    }

    .textContForMobile>div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;
        align-self: stretch;
        border-radius: 20px;
        backdrop-filter: blur(9px);
    }

    .textContForMobile>div>div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 20px;
    }

    .textContForMobile>div>div>p:first-child {
        align-self: stretch;
        color: #1E1E1E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        /* 155.556% */
    }

    .textContForMobile>div>div>p:last-child {
        align-self: stretch;
        color: #767676;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
        /* 155.556% */
    }

    .sliderCont>.link {
        display: none;
    }


    .sliderCont>img {
        display: flex;
        border-radius: 2%;
        transition: 1s;
        display: none;
        transition: opacity 1s ease-in-out;
    }

    .sliderCont>img.active {
        display: flex;
        opacity: 1;

    }




    .sliderCont .pagitation {
        position: absolute;
        bottom: 23px;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        /* Устанавливается ширина контента */
        display: flex;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        width: 85%;
    }


}

.pagitation>div {
    height: 6px;
    flex: 1 0 0;
    border-radius: 24px;
    background: #656665;
    cursor: pointer;
}