.socWidgetsCont {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    align-content: center;
    gap: 15px;
    width: 100%;
    padding: 10px 10px;
}

.yandex-login-btn, 
.google-login-btn, 
.mailRU-login-btn,.telegram-button{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
    white-space: nowrap;
    width: 100%;
}




/* Стили для разных кнопок */
.yandex-login-btn {
    background-color: #ffcc00;
    color: black;
}

.google-login-btn {
    background-color: #ffffff;
    color: #000;
    border: 1px solid #ddd;
}

.mailRU-login-btn {
    background-color: #168de2;
    color: white;
}

.yandex-login-btn:hover {
    background-color: #ffdb4d;
}

.google-login-btn:hover {
    background-color: #f8f8f8;
}

.mailRU-login-btn:hover {
    background-color: #1a75cf;
}

.telegram-button{
    background-color:#54A9EB;
}

.yandex-login-btn:active,
.google-login-btn:active,
.mailRU-login-btn:active {
    transform: scale(0.96);
}

.yandex-login-btn img,
.google-login-btn img,
.mailRU-login-btn img {
    width: 24px;
    height: 24px;
}

.yandex-login-btn span,
.google-login-btn span,
.mailRU-login-btn span {
    font-size: clamp(14px, 1.2vw, 16px); /* Минимум 14px, адаптивно от ширины экрана, максимум 16px */
    font-weight: bold;
}


@media only screen and (max-width: 346px) {
    .yandex-login-btn span,
    .google-login-btn span,
    .mailRU-login-btn span {
        font-size: clamp(12px, 1.2vw, 14px); /* Минимум 14px, адаптивно от ширины экрана, максимум 16px */
        font-weight: bold;
    }
  }
  

