@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto:wght@100;400&display=swap');

.devPageContDark {
    display: flex;
    width: 100%;
    height: 93vh;
    min-width: 360px;
    align-items: flex-start;
    flex-wrap: wrap;
    border-radius: var(--16, 16px);
    background: #12151A;

    /* Shadows/Base/1 Level */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.devPageContDark p {
    margin: 0;
    padding: 0;
    color: black;
}

.devPageContDark .devPageCont1 {
    display: flex;
    min-width: 360px;
    padding: var(--96, 96px) 0px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    background: #12151A;
    transition: 1s;
}

.devPageContDark .devPageContCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--32, 32px);
}

.devPageContDark .devPageCont1 img {
    width: 50px;
    height: 50px;
}

.devPageContDark .devPageCont1 .settingImgCont {
    width: 100px;
    /* Set your desired image dimensions */
    height: 100px;
    background: url('./settingsICon.png') center/cover;
    /* Replace 'your-image.png' with your image URL */
    animation: rotate 4s linear infinite;
    /* Adjust the duration and animation name as needed */
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.devPageContDark .devPageCont2 {
    display: flex;
    min-width: 360px;
    padding: var(--24, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--40, 40px);
    flex: 1 0 0;
    align-self: stretch;
    background: #21262E;
}

.devPageContDark .devPageCont2 .myimg {
    display: flex;
    padding: var(--40, 40px);
    align-items: flex-end;
    gap: var(--24, 24px);
    flex: 1 0 0;
    align-self: stretch;
    border-radius: var(--12, 12px);
    background: var(--image-backgorund-7, url("./backImage.jpg"), lightgray 50% / cover no-repeat);
}


.devPageContDark .Heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--12, 12px);
}

.devPageContDark .Heading .errorP {
    color: var(--primary-primary-500, #578EFF);

    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/12 */
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}

.devPageContDark .Heading .noPageP {
    color: var(--base-colors-gray-900, #FFF);

    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/36 */
    font-family: "Inter";
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    /* 166.667% */
}

.devPageContDark .Heading .infoP {
    color: var(--base-colors-gray-400, #808080);

    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/12 */
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}


.devPageContDark .Content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--16, 16px);
    align-self: stretch;
}

.devPageContDark .Content .Links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--8, 8px);
}

.devPageContDark .Content .Links p {
    color: var(--primary-primary-500, #578EFF);

    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/16 */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}


.devPageContDark .Content .Links svg {
    width: var(--20, 20px);
    height: var(--20, 20px);
}

.devPageContDark .Content .Links svg path {
    fill: #578EFF;
}

.devPageContDark .Links2 {
    display: flex;
    min-width: 420px;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: var(--24, 24px);
    align-self: stretch;
    flex-wrap: wrap;
}

.devPageContDark .Links2 p {
    color: var(--primary-primary-500, #578EFF);

    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/16 */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}


@media only screen and (max-width: 1183px) {
    .devPageContDark .noPageP {
        font-size: 30px !important;
        text-wrap: wrap !important;
    }

    .devPageContDark .Heading .infoP {
        font-size: 10px;
    }
}


@media only screen and (max-width: 1005px) {
    .devPageContDark .Heading .infoP {
        font-size: 10px;
        text-wrap: wrap !important;
    }

    .devPageContDark .Links2 {
        justify-content: center !important;
        align-self: center !important;
    }

    .devPageContDark .devPageCont1 {
        height: 50vh !important;
    }

    .devPageContDark .devPageCont2 {
        height: 40vh !important;
    }

    .devPageContDark .devPageCont1 {
        padding: 0;
        padding: 20px 0;
    }

}