/* The Modal (background) */
.editStatusMobile .modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 6;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.editStatusMobile .modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 323px;

}

.editStatusMobile .showStatus div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: var(--gray-blue-grey-blue-60, #6B7A99);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  width: 100px;
  /* 166.667% */
}

.editStatusMobile .modal-content .dropdown-contentChangeStatus>div {
  color: var(--gray-blue-grey-blue-60, #6B7A99);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 166.667% */
}

/* The Close Button */
.editStatusMobile .close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.editStatusMobile .close:hover,
.editStatusMobile .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* @media only screen and (max-width: 600px) {
  .editStatusMobile .showStatus div {
    width: 120px;
}
} */


