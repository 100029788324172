.ChtatLeftSideContDark {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100dvh !important;
    background-color: #12151A;
}

.ChtatLeftSideContDark > div {
   
    height: 100%;
}

.ChtatLeftSideContDark .listChatItem {
   
    overflow: auto;
    margin: 0; /* убедитесь, что отступы сброшены */
    padding: 0; /* убедитесь, что поля сброшены */
    box-sizing: border-box;
    height: 80vh;
    background-color: #12151A;
}

.ChtatLeftSideContDark .listChatItem a:last-child {

    border-bottom: 1px solid rgba(0, 0, 0, 0.10);


}

