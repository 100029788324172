@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

/* The Modal (background) */
.creatChatModalContLight {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 14;
    /* Sit on top */

    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    transition: 1s;
}




.creatChatModalContLight .creatChatModal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    display: flex;
    width: 429px;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

.creatChatModalContLight .creatChatModal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.creatChatModalContLight .creatChatModal-header>p {
    margin: 0 !important;
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 125% */
}


.creatChatModalContLight .creatChatModal-header>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 5px;
    justify-content: center;
    align-items: center;
}


.creatChatModalContLight .creatChatModal-header>div svg {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    cursor: pointer;
}



.creatChatModalContLight .writeNameCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.creatChatModalContLight .writeNameCont>input {
    display: flex;
    height: 40px;
    padding: 0px 16px;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--Gray-100, #DFE1E6);
    background: var(--White-White, #FFF);
    color: var(--Gray-300, #A1A7B3);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/12 */
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
    outline: none;
}

.creatChatModalContLight .writeNameCont>input::placeholder {
    color: var(--Gray-300, #A1A7B3);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/12 */
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}

.creatChatModalContLight .writeNameCont p {
    margin: 0;
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}


.creatChatModalContLight .selectTypeCont {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.creatChatModalContLight .selectTypeCont input {
    width: 21px;
    height: 21px;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.10);
}

.creatChatModalContLight .Conversation {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    cursor: pointer;
}

.Conversation div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}

.creatChatModalContLight .Conversation div .ConversationName {
    align-self: stretch;
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
    margin: 0 !important;
}


.creatChatModalContLight .Conversation div .AnyoneCanWrite {
    align-self: stretch;
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}


/* canal */
.creatChatModalContLight .canal {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    cursor: pointer;
}

.creatChatModalContLight .canal div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}

.creatChatModalContLight .canal div>.EditorsWrite {
    align-self: stretch;
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}

.creatChatModalContLight .canal div>.nameCanal {
    align-self: stretch;
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
    margin: 0 !important;
}


.creatChatModalContLight .MakeItClosedCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.creatChatModalContLight .MakeItClosedCont:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
}

.creatChatModalContLight .MakeItClosedCont:last-child div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
}

.creatChatModalContLight .MakeItClosedCont:last-child div p {
    margin: 0 !important;
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}

.creatChatModalContLight .MakeItClosedCont .iconCont {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px 2.25px;
    justify-content: center;
    align-items: center;
}

.creatChatModalContLight .MakeItClosedCont:last-child .iconCont svg {
    width: 19.5px;
    height: 21.5px;
    flex-shrink: 0;
}

.creatChatModalContLight .Make-it-closed-pp {
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    margin: 0 !important;
}

.creatChatModalContLight .MakeItClosedCont div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
}

.creatChatModalContLight .notificationCreatChat {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.creatChatModalContLight .notificationCreatChat>p {
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}

.creatChatModalContLight .creatChatBtnFooterCont {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.creatChatModalContLight .creatChatBtnFooterCont>button {
    display: flex;
    height: 50px;
    padding: 16px;
    align-items: center;
    gap: 4.067px;
    border-radius: 16px;
    background: #3B79F6;
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/12 */
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}


@media only screen and (max-width: 600px) {
    .creatChatModalContLight .creatChatModalContLight .creatChatModal-content {
        margin: auto 4%;
    }

    .creatChatModalContLight .selectTypeCont {
        flex-direction: column;
        width: 100%;
    }

    .creatChatModalContLight .selectTypeCont>.Conversation {
        width: 100%;
    }

    .creatChatModalContLight .selectTypeCont p {
        margin: 0 !important;
    }

    .creatChatModalContLight .canal {
        width: 100%;
    }
}


.creatChatModalContLight .publicSweetch {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.creatChatModalContLight .publicSweetch div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.creatChatModalContLight .publicSweetch div:first-child>p {
    margin: 0 !important;
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}


.creatChatModalContLight .publicSweetch div:last-child {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.creatChatModalContLight .publicIconCont {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4px 0.25px 3.5px 0.25px;
    justify-content: center;
    align-items: center;
}

.creatChatModalContLight .publicIconCont svg {
    width: 23.5px;
    height: 16.5px;
    flex-shrink: 0;
}

.creatChatModalContLight .publicSweetch div:last-child>p {
    flex: 1 0 0;
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}

.creatChatModalContLight .custom-loaderCreatChat {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%, #3B79F6);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
    animation: s3 1s infinite linear;
    margin: auto;
    display: flex;
    justify-content: center;

}

@keyframes s3 {
    to {
        transform: rotate(1turn)
    }
}

.uploadChatImageCreat {
    display: none;
}

.uploadChatImageCreatCont {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.uploadChatImageCreatCont>label:nth-child(1) {
    display: flex;
    width: var(--Field-sizes-Field-height, 36px);
    height: var(--Field-sizes-Field-height, 36px);
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.uploadChatImageCreatCont>label:nth-child(2) {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: var(--shades-contrast-5, rgba(25, 59, 103, 0.05));
    color: var(--semantic-colors-primary-text, #005FDB);
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}

.uploadChatImageCreatCont>div {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: var(--shades-contrast-5, rgba(25, 59, 103, 0.05));
    cursor: pointer;
}

.uploadChatImageCreatCont>div p {
    color: var(--semantic-colors-primary-text, #005FDB);
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}