.chatBodyDarkCont{
    background-color: #12151A !important;
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    height: 100%;
    width: 100%;
    overflow: hidden;
  

  
 
  
}


.chatBodyDarkCont > div{
  display: flex;
  flex-direction: column;
  flex-direction: column-reverse;
  padding: 25px 12px;
  padding-top: 100px;
  margin-top: auto;
  margin-bottom: 36px;
  overflow: auto;
  flex: 1;
  background-color: #12151A !important;

}







/* width */
.chatBodyDarkCont > div::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .chatBodyDarkCont > div::-webkit-scrollbar-track {
    background: #12151A;
  }
  
  /* Handle */
  .chatBodyDarkCont > div::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: #AAA;
  }
  
  /* Handle on hover */
  .chatBodyDarkCont > div::-webkit-scrollbar-thumb:hover {
    background: #555;
  }




  .chatBodyDarkCont .datemessageCont{
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  .chatBodyDarkCont .datemessageCont div{
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 24px;
    opacity: var(--Overlay-border-width, 1);
    background: #21262E;
  }
  
  .chatBodyDarkCont .datemessageCont p{
    margin: 0 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    opacity: var(--Overlay-border-width, 1);
  }