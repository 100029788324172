.openModalForSubComANDSmileLightCont {
    position: absolute;
    display: none;
    width: 327px;
    height: 40px;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #F7F8FA;
    background: #FFF;
    box-shadow: 0px 1px 7px 0px rgba(106, 113, 128, 0.04);
    z-index: 1;
    

}

.openModalForSubComANDSmileLightCont>div {
    display: flex;
    align-items: center;
    gap: 9px;
}

.openModalForSubComANDSmileLightCont>div>div {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 6px;
    justify-content: center;
    align-items: center;

}


.openModalForSubComANDSmileLightCont>div>div:hover {
    background: #F7F8FA;
    border-radius: 24px;

}




