.conversationsLightCont{
    position: absolute;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
   
}

