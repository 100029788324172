.tradeDarktCont {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 326px !important;
    background-color: #12151A;
}

.tradeDarktCont .tradeHeader {
    display: flex;
    padding: 9px 16px;
    width: 326px;
    max-height: 50px;
    flex-direction: column;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: var(--Field-sizes-Field-border-width, 0px);
    border-top: var(--Field-sizes-Field-border-width, 0px) solid rgba(0, 0, 0, 0.10);
    border-right: var(--Field-sizes-Field-border-width, 0px) solid rgba(0, 0, 0, 0.10);
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    border-left: var(--Field-sizes-Field-border-width, 0px) solid rgba(0, 0, 0, 0.10);
}

.tradeDarktCont .tradeOtherChild{
    position: relative;
    padding: 25px 12px;
    margin-top: auto;
    margin-bottom: 35px;
    max-height: 936px;
    overflow: auto;
    background-color: #12151A;
}



.tradeDarktCont .tradeHeader>div {
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: var(--Field-sizes-Field-border-width, 0px);
}

.tradeDarktCont .tradeHeader>div >div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: var(--Field-sizes-Field-border-width, 0px);
}

.tradeDarktCont .tradeHeader>div>div p:first-child{
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
}

.tradeDarktCont .tradeHeader>div>div p {
    margin: 0 !important;
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 133.333% */
}

.tradeDarktCont .teadeCloseIconCont {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.tradeDarktCont .teadeCloseIconCont svg {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
}


.tradeDarktCont   .suppliedEmoticonsDarkCont {
    display: flex !important;
    align-items: flex-start !important;
    align-content: flex-start !important;
    gap: 12px  !important;
    align-self: stretch !important;
    flex-wrap: wrap !important;
}

.tradeDarktCont  .creatCommentBTNDark{
    display: none !important;
}


.tradeDarktCont  .subCommentBtn {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 24px;
    background: #21262E;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
color: #767676;
font-feature-settings: 'clig' off, 'liga' off;
text-overflow: ellipsis;
font-family: "Inter";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
    /* 166.667% */
    margin: auto;
    margin-top: 16px;
    margin-bottom: 30px;
   
}
