.conteinerToggelBTNContDark {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
}

.modalToggleCont {
    width: 100%;

}

.conteinerToggelBTNContDark>.modalheader {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.conteinerToggelBTNContDark>.modalheader>h1 {
    color: white;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
}


.conteinerToggelBTNContDark>.modalheader>p {
    align-self: stretch;
    color: #767676;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.conteinerToggelBTNContDark>.modalToggelBTNCont {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 16px;
}

.conteinerToggelBTNContDark>.modalToggelBTNCont>button {
    display: flex;
    height: 48px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 12px;
    background: #373E4A;
    color: #FFF;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */

}

.conteinerToggelBTNContDark>.modalToggelBTNCont>.active {
    background: linear-gradient(0deg, #5E7DE8 0%, #5E7DE8 100%), #FFF;
    color: #FFF;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
}
.conteinerToggelBTNContDark >.modalToggleCont > .qrCodeContOuth>div:last-child{
   background: url("../../../../img/bgQrDark.png") #181B22 50% / cover no-repeat;
}

.conteinerToggelBTNContDark >.modalToggleCont  .qr-scanner-wrapper {
    background-color: #21262E ;
}

.conteinerToggelBTNContDark>.modalForInstructions {
    display: flex;
    height: 48px;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.conteinerToggelBTNContDark>.modalForInstructions>div {
    display: flex;
    align-items: center;
    gap: 8px;
}

.conteinerToggelBTNContDark>.modalForInstructions>div>svg {
    width: 24px;
    height: 24px;
}

.conteinerToggelBTNContDark>.modalForInstructions>div>p {
    color: #3B79F6;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

@media only screen and (max-width: 600px) {
    .conteinerToggelBTNContDark>.modalToggelBTNCont {
        flex-direction: column;
    }

    .conteinerToggelBTNContDark>.modalToggelBTNCont>button {
        width: 100%;
    }

}