.editStatusBySelectContLight {
    display: flex;
    padding: 3px 16px;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;


}

.editStatusBySelectContLight button {
    display: flex;
    width: 100%;
    height: 36px;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
   
}

.editStatusBySelectContLight button>.Frame625852 {
    display: flex;
    align-items: center;
    gap: 4px;
}

.editStatusBySelectContLight button>.Frame625852>.Frame625851 {
    display: flex;
    align-items: center;
    gap: 4px;
}

.editStatusBySelectContLight button>.Frame625852>.Frame625851>p {
    color: #353535;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}


.editStatusBySelectContLight .select-items {
    display: flex;
    width: 100%;
    max-height: 180px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0px 0px 12px 12px;
    background: var(--Components-Overlay-background, #FFF);
    overflow: auto;
   
}


.editStatusBySelectContLight .select-items>.Component56 {
    display: flex;
    height: 36px;
    padding: 0px 10px;
    align-items: center;
    align-self: stretch;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    overflow: hidden;
    cursor: pointer;
}

.select-items>.Component56:nth-child(1) p{
    color: #FFEC8B;
}

.select-items>.Component56:nth-child(2) p{
    color: #56D4FC;
}

.select-items>.Component56:nth-child(3) p{
    color: #4DFF30;
}
.select-items>.Component56:nth-child(4) p{
    color: #FB50FF;
}

.select-items>.Component56:nth-child(5) p{
    color:#5364FF;
}
.select-items>.Component56:nth-child(6) p{
    color:#FF50A0;
}

.Frame625852 {
    display: flex;
    align-items: center;
    gap: 4px;
}

.Frame625851 {
    display: flex;
    align-items: center;
    gap: 4px;
}


