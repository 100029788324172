.replyMessageContDark {
    display: flex;
    padding: 16px 32px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
   
}

.replyMessageContDark:hover{
    background: #E7F0FF;
    cursor: pointer;
}

.replyMessageContDark>div:first-child {
    display: flex;
    align-items: flex-end;
    gap: 9px;
    align-self: stretch;
}


.replyMessageContDark>div:first-child>span {
    width: 2px;
    align-self: stretch;
    border-radius: var(--Border-radius-M, 4px);
    background: #4254FF;
}


.replyMessageContDark>div:first-child>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}


.replyMessageContDark>div:first-child>div p:first-child {
    align-self: stretch;
    color: var(--Primary-Primary-500, #3B79F6);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}



.replyMessageContDark>div:first-child>div p:last-child {
    align-self: stretch;
    color: #767676;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}



.replyMessageContDark>div:last-child {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.replyMessageContDark>div:last-child>img {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;

}


.replyMessageContDark>div:last-child>.messageInfoCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}

.replyMessageContDark>div:last-child>.messageInfoCont .frame19 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}


.replyMessageContDark>div:last-child>.messageInfoCont .frame19 .name {
    color: #353535;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}


.replyMessageContDark>div:last-child>.messageInfoCont .frame19 .clock {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 200% */
}

.replyMessageContDark>div:last-child>.messageInfoCont .frame41 {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}


.replyMessageContDark>div:last-child>.messageInfoCont .frame41 p{
    color: #767676;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}