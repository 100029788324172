.checkedCompanyCont {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  align-content: center !important;
  padding: 25px;
  cursor: pointer;
}

.checkedCompanyCont p {
  margin: 0 !important;
  text-align: center !important;
  color: var(--gray-500, #6a7180);
  width: 150px;
  /* Bold 700/12 */
  font-family: "Inter";
  font-size: 12px;

  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
text-align: start;
word-wrap: break-word !important;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1;
flex: 1 0 0;
}

.handleEvents {
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-shrink: 0;
}

.checkedCompanyCont svg {
  width: 20px;
  height: 20px;
  flex-shrink: 0;

}

.css-1kfu8nn {
  width: 350px !important;
  height: 266px !important;
  flex-shrink: 0;
  overflow: auto;
}

.handleEvents svg {
  margin: 0 10%;
}


.acionsEditDel svg{
  width: 13px;
height: 13px;
flex-shrink: 0;
}