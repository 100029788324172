/* The Modal (background) */
.addNewUserModalDarkCont {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 14;
    /* Sit on top */
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    justify-content: center;
    align-items: center;
}

/* Modal Content */
.addNewUserModalDarkCont .addNewUserModalLigth-content {
    position: relative;
    display: flex;
    width: 520px;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    border-radius: 12px;
    background: #171B22;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

.addNewUserModalDarkCont .addNewUserModal-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.addNewUserModalDarkCont .addNewUserModal-header>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.addNewUserModalDarkCont .addNewUserModal-header>div>p {
    color: white;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
}

.addNewUserModalDarkCont .addNewUserModal-header>p {
    color: #FFF !important;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.addNewUserModalDarkCont .addNewUserModal-header>div>div {
    display: flex;
    padding: 6px 9px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.addNewUserModalDarkCont .addNewUserModal-header>div>div svg {
    width: 24px;
    height: 24px;
    stroke: white;
    fill: white;
}

.addNewUserModalDarkCont .addNewUserModal-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.addNewUserModalDarkCont .addNewUserModal-body>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.addNewUserModalDarkCont .addNewUserModal-body>div>div {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;

}


.addNewUserModalDarkCont .addNewUserModal-body>div>div label {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: #767676;
    text-overflow: ellipsis;

    /* Field label */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

.addNewUserModalDarkCont .addNewUserModal-body>div>div select {
    height: 44px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Overlay-border-width, 1px) solid rgba(255, 255, 255, 0.10);
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
    overflow: hidden;
    color: #FFF;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */

}

.addNewUserModalDarkCont .addNewUserModal-body>div>div select option {
    display: flex;
    height: 36px;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    overflow: hidden;
    color: white;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
    background-color: #21262E;
    border-radius: 10px;
    padding: 25px !important;
}



.addNewUserModalDarkCont .addNewUserModal-body>div>div .PhoneInputInput {
    height: 44px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Overlay-border-width, 1px) solid rgba(255, 255, 255, 0.10);
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%);
    width: 440px !important;
    overflow: hidden;
    color: #929292;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
}


.addNewUserModalDarkCont .addNewUserModal-body>div>div .PhoneInputInput::placeholder {
    height: 44px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Overlay-border-width, 1px) solid rgba(255, 255, 255, 0.10);
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%);
    width: 440px !important;
    overflow: hidden;
    color: #929292;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
}

.addNewUserModalDarkCont .addNewUserModal-footer {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.addNewUserModalDarkCont .closeaddNewUserModal {
    display: flex;
    height: 48px;
    padding: 16px;
    align-items: center;
    gap: 4.067px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    color: #858C99;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.addNewUserModalDarkCont .addDddNewUserModal {
    display: flex;
    height: 48px;
    padding: 16px;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: var(--Primary-Primary-500, #3B79F6);
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    cursor: pointer;
}

.addNewUserModalDarkCont .addDddNewUserModal>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px;
    justify-content: center;
    align-items: center;
}

.addNewUserModalDarkCont .addDddNewUserModal>div svg {
    width: 21.5px;
    height: 21.5px;
    flex-shrink: 0;
}


.addNewUserModalDarkCont .addNewUserModal-footer>.addEmloyLeftBtnCont {
    display: flex;
    height: 48px;
    padding: 16px;
    width: auto !important;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: var(--Primary-Primary-500, #3B79F6);
}


.addNewUserModalDarkCont .addEmloyLeftBtnCont>div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.addNewUserModalDarkCont .addEmloyLeftBtnCont>div:first-child>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px;
    justify-content: center;
    align-items: center;
}

.addNewUserModalDarkCont .addEmloyLeftBtnCont>div:first-child p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}


@media only screen and (max-width: 600px) {
    .addNewUserModalDarkCont .addNewUserModalLigth-content{
        width: 100%;
        margin: 4%;
    }
    .PhoneInput{
        width: 100%;
    }
    .addNewUserModalDarkCont .addNewUserModal-body>div>div .PhoneInputInput{
        width: 100% !important;
    }
  }