.modalForChatFileLight {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 20;
    /* Sit on top */
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.8);
    /* Black w/ opacity */
}

/* Modal Content */
.modalForChatFile-content {
    position: relative;
    margin: auto;
    padding: 0;
    width: 100%;
    height: 100%;

    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

/* The Close Button */
.close {
    color: white;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modalForChatFile-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 10px 16px;
    color: white;

}

.modalForChatFile-header>h2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
}

.modalForChatFile-body {
    padding: 2px 16px;
    display: flex;
    justify-content: center;
    position: relative;

}


.modalForChatFile-body>img {
    width: 100%;
    max-height: 90vh;
    object-fit: contain;
    border-radius: var(--Field-sizes-Field-border-width, 5px);
}

.modalForChatFile-body>video {
    height: 482px;
    flex-shrink: 0;
    border-radius: var(--Field-sizes-Field-border-width, 5px);


}

@media only screen and (max-width: 823px) {
    .modalForChatFile-body>img {
        height: 400px;
    }

    .modalForChatFile-body>video {
        height: 400px;
    }
}

@media only screen and (max-width: 759px) {
    .modalForChatFile-body>img {
        height: 300px !important;
    }

    .modalForChatFile-body>video {
        height: 300px !important;
    }
}

@media only screen and (max-width: 550px) {
    .modalForChatFile-body>img {
        height: 240px !important;
    }

    .modalForChatFile-body>video {
        height: 240px !important;
    }
}

.fileFlexCont {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

}



.fileCont {
    display: flex;
    width: 376.87px;
    height: auto;
    justify-content: center;
    align-items: center;
    margin: 5px;
}

.fileCont>div {
    width: 376.87px;
    height: auto;
    flex-shrink: 0;
    position: relative;
}


.fileCont>div>img {
    width: 376.87px;
    height: 180px;
    flex-shrink: 0;
    border-radius: var(--Field-sizes-Field-border-width, 5px);

}

.fileCont>div>video {
    width: 376.87px;
    height: 180px;
    flex-shrink: 0;
    border-radius: var(--Field-sizes-Field-border-width, 5px);

}

.fileCont>div>img:hover {
    opacity: 0.6;
}

.fileCont>div>button {
    align-items: center;
    background: var(--black-50, #00000080);
    border-radius: 100px;
    display: flex;
    flex-shrink: 0;
    height: 40px;
    justify-content: center;
    padding: 6px;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40px;
    transform: translate(-50%, -50%);
}

.btnCont {
    display: inline-flex;
    align-items: flex-start;
    gap: 8px;
    margin-left: 40%;
}

.btnCont>div {
    display: flex;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--Border-radius-M, 4px);

}



.btnCont>div:nth-child(1)>div:nth-child(1) {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4px 5.5px 4px 6.5px;
    justify-content: center;
    align-items: center;
}

.btnCont>div:nth-child(2) svg path {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    fill: white;
    stroke: white;
    color: white;
}

.btnCont>div:nth-child(1) svg path {
    width: 12px;
    height: 16px;
    flex-shrink: 0;
    fill: white;
    stroke: white;
    color: white;

}

.btnCont>div:nth-child(1) svg{
    fill: red;
    stroke: red;
    color: red;
}

.btnCont>div:nth-child(2)>div:nth-child(1) {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4px 5.5px 4px 6.5px;
    justify-content: center;
    align-items: center;
}

.fileFlexCont .otherFileCont {
    display: flex;
    width: 280px;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--Border-radius-L, 8px);
    border: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;
}

.fileFlexCont .otherFileCont>div:first-child {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
}

.fileFlexCont .otherFileCont>div:last-child {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #3B79F6;
}

.fileFlexCont .otherFileCont>div:first-child>div {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
}

.fileFlexCont .otherFileCont>div:first-child>div>div:first-child {
    display: flex;
    width: 40px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: var(--Border-radius-M, 4px);
    background: #F7F8FA;
}

.fileFlexCont .otherFileCont>div:first-child>div>div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.fileFlexCont .otherFileCont>div:first-child>div>div:last-child>p:first-child {
    color: var(--Gray-500, #6A7180) !important;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-wrap: wrap;
    width: 172px;
    display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1;
flex: 1 0 0;
overflow: hidden;
text-overflow: ellipsis;
    /* 166.667% */
}

.fileFlexCont .otherFileCont>div:first-child>div>div:last-child>p:last-child {
    color: #A1A7B3;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}