.glavimg {
    display: flex;
    justify-content: center;
    align-items: center; /* Исправлено на align-items */
    height: calc(100dvh - 144px);
    width: 100%;
    overflow: auto;
}

.glavimg img {
    width: 50%;
    height: 50%; /* Вы можете настроить высоту по своему усмотрению */

    object-fit: contain;
    margin: auto !important;
}
