.replaceContDark {
    display: flex;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin-bottom: 70px;
    background-color: #12151A;
}

.replaceContDark>div:first-child {
    display: flex;
    align-items: flex-end;
    gap: 9px;
    flex: 1 0 0;
}

.replaceContDark>div:first-child>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.replaceContDark>div:first-child span {
    width: 2px;
    align-self: stretch;
    border-radius: var(--Border-radius-M, 4px);
    background: #4254FF;
}


.replaceContDark>div:first-child p:nth-child(1) {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
}


.replaceContDark>div:first-child p:nth-child(2) {
    color: #A0A0A0;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
}


.replaceContDark>div:first-child>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.replaceContDark>div:last-child {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 4.167px 4.168px 4.167px 4.165px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.replaceContDark>div:last-child svg path{
    stroke: white;
    width: 11.667px;
height: 11.667px;
flex-shrink: 0;
}