.modalForInstructionsContDark {
    max-width: 776px !important;
}

.modalForInstructionsContDark .chakra-modal__body {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    max-width: 776px;
    background: linear-gradient(0deg, #171B22 0%, #171B22 100%), #FFF;
}


.modalForInstructionsContDark .chakra-modal__body .modalForInstructionsHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    width: 100%;
}

.modalForInstructionsContDark .chakra-modal__body .modalForInstructionsHeader>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}

.modalForInstructionsContDark .chakra-modal__body .modalForInstructionsHeader>p {
    align-self: stretch;
    color:#767676;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.modalForInstructionsContDark .chakra-modal__body .modalForInstructionsHeader>div>p {
    color:white;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
}

.modalForInstructionsContDark .chakra-modal__body .modalForInstructionsHeader>div >div >svg > path{
   stroke: white;
   fill: white;
}

.modalForInstructionsContDark .chakra-modal__body>.androidCont {
    display: flex;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    background: linear-gradient(0deg, #292F39 0%, #292F39 100%), #F8FBFF;

}

.modalForInstructionsContDark .chakra-modal__body>.androidCont>div:first-child {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.modalForInstructionsContDark .chakra-modal__body>.androidCont>div:first-child>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.modalForInstructionsContDark .chakra-modal__body>.androidCont>div:first-child>div>div {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.modalForInstructionsContDark .chakra-modal__body>.androidCont>div:first-child>div>div>p {
    color:white;
    /* bold/16 */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}

.modalForInstructionsContDark .chakra-modal__body>.androidCont>div:first-child>div>p {
    align-self: stretch;
    color:#767676;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    word-break: break-word;
    /* 157.143% */
}


.modalForInstructionsContDark .chakra-modal__body>.androidCont>div:last-child {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

@media only screen and (max-width: 792px) {
    .modalForInstructionsContDark .chakra-modal__body>.androidCont>div:last-child {
        flex-direction: column;
    }

    .modalForInstructionsContDark {
        max-width: 401px !important;
    }

    .modalForInstructionsContDark .chakra-modal__body {
        max-width: 401px !important;

    }

    .modalForInstructionsContDark .chakra-modal__body>.androidCont>div:last-child>div:nth-child(1),
    .modalForInstructionsContDark .chakra-modal__body>.androidCont>div:last-child>div:nth-child(2),
    .modalForInstructionsContDark .chakra-modal__body>.androidCont>div:last-child>div:nth-child(3),
    .modalForInstructionsContDark .chakra-modal__body>.iphoneCont>div:last-child>div:nth-child(1),
    .modalForInstructionsContDark .chakra-modal__body>.iphoneCont>div:last-child>div:nth-child(2),
    .modalForInstructionsContDark .chakra-modal__body>.iphoneCont>div:last-child>div:nth-child(3) {
        width: 100%;
    }
}

.modalForInstructionsContDark .chakra-modal__body>.androidCont>div:last-child>div:nth-child(1) {
    display: flex;
    padding: 16px 20.667px 0px 20px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 7.059px;
    background: #373E4A;

}

.modalForInstructionsContDark .chakra-modal__body>.androidCont>div:last-child>div:nth-child(2) {
    display: flex;
    padding: 16px 20.335px 0px 20.332px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 7.059px;
    background: #373E4A;
}

.modalForInstructionsContDark .chakra-modal__body>.androidCont>div:last-child>div:nth-child(3) {
    display: flex;
    padding: 16px 20.335px 0px 20.332px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 7.059px;
    background: #373E4A;
}

.closeModal {
    cursor: pointer;
}



/* iphone=========================================== */

.modalForInstructionsContDark .chakra-modal__body>.iphoneCont {
    display: flex;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    background: linear-gradient(0deg, #292F39 0%, #292F39 100%), #F8FBFF;

}

.modalForInstructionsContDark .chakra-modal__body>.iphoneCont>div:first-child {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.modalForInstructionsContDark .chakra-modal__body>.iphoneCont>div:first-child>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.modalForInstructionsContDark .chakra-modal__body>.iphoneCont>div:first-child>div>div {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.modalForInstructionsContDark .chakra-modal__body>.iphoneCont>div:first-child>div>div>p {
    color:white;

    /* bold/16 */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}

.modalForInstructionsContDark .chakra-modal__body>.iphoneCont>div:first-child>div>p {
    align-self: stretch;
    color:#767676;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    word-break: break-word;
    /* 157.143% */
}


.modalForInstructionsContDark .chakra-modal__body>.iphoneCont>div:last-child {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

@media only screen and (max-width: 792px) {
    .modalForInstructionsContDark .chakra-modal__body>.iphoneCont>div:last-child {
        flex-direction: column;
    }
}

.modalForInstructionsContDark .chakra-modal__body>.iphoneCont>div:last-child>div:nth-child(1) {
    display: flex;
    padding: 16px 20.667px 0px 20px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 7.059px;
    background: linear-gradient(0deg, #373E4A 0%, #373E4A 100%), #EEF5FF;

}

.modalForInstructionsContDark .chakra-modal__body>.iphoneCont>div:last-child>div:nth-child(2) {
    display: flex;
    padding: 16px 20.335px 0px 20.332px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 7.059px;
    background: #EEF5FF;
    background: linear-gradient(0deg, #373E4A 0%, #373E4A 100%), #EEF5FF;
}

.modalForInstructionsContDark .chakra-modal__body>.iphoneCont>div:last-child>div:nth-child(3) {
    display: flex;
    padding: 16px 20.335px 0px 20.332px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    border-radius: 7.059px;
    background: #EEF5FF;
    background: linear-gradient(0deg, #373E4A 0%, #373E4A 100%), #EEF5FF;
}


.modalForInstructionsContDark .chakra-modal__body> .footerModalInstroction {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.modalForInstructionsContDark .chakra-modal__body> .footerModalInstroction>button {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    background: #3B79F6;
}

.modalForInstructionsContDark .chakra-modal__body> .footerModalInstroction>button>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.modalForInstructionsContDark .chakra-modal__body> .footerModalInstroction>button>div>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 2px;
    justify-content: center;
    align-items: center;
}

.modalForInstructionsContDark .chakra-modal__body> .footerModalInstroction>button>div>div>svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.modalForInstructionsContDark .chakra-modal__body> .footerModalInstroction>button>div>p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}