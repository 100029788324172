.changeChatNameInfoContLight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.changeChatNameInfoContLight>div {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 4px;
}


.changeChatNameInfoContLight>div p:nth-child(1) {
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    /* 166.667% */
}


.changeChatNameInfoContLight>div p:nth-child(2) {
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}


.changeChatNameInfoContLight>div p:nth-child(3) {
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    /* 166.667% */
}