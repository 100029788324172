@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

.creatCommentBTNDark {
    display: flex;
    height: 34px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 24px;
    border: 1px solid rgba(59, 121, 246, 0.15);

    color: #858C99;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    transition: 0.2s;
}

.creatCommentBTNDark:hover {
    background: #21262E;
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

}