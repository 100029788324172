.conten-instruct {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    position: relative;
}

 .ant-modal-close-x {
    color: black !important; /* Replace with your custom color */
  }
  

.conten-instruct .mobileDrowerBtnForInstrcution {
    position: fixed;
    top: 53px;
    left: 0;
    display: flex;
    padding: 8px 6px;
    justify-content: center;
    align-items: center;
    gap: 8.4px;
    border-radius: 0px 84px 84px 0px;
    background: #5E7DE8;
    box-shadow: 0px 1.68px 4.2px 0px rgba(38, 51, 77, 0.03);
    cursor: pointer;
}

/* Header */
.conten-instruct .header {
    background: #f8fbff;
    padding: 16px;
    border-bottom: 1px solid #ddd;
}

.conten-instruct .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.conten-instruct .header-title {
    color: #353535;
    font-size: 20px;
    font-weight: bold;
}

.conten-instruct .header-img {
    width: 42px;
    height: 36px;
    object-fit: fill;
}

.conten-instruct .header-subtext {
    color: #585858;
    font-size: 16px;
    margin-top: 10px;
}

/* Main Wrapper */
.conten-instruct .main-wrapper {
    display: flex;
    margin: 16px;
    background-color: #F8FBFF;
    align-items: flex-start;
    gap: 16px;
}

/* Sidebar для обоих вариантов: внутри .conten-instruct и в модальном окне */
.conten-instruct .sidebar,
.mobileDrower>.sidebar {
    display: flex;
    width: 286px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex-shrink: 0;
    padding: 16px;
    background: #f8fbff;
    border-radius: 12px;
}

/* Стили для ul сайдбара в обоих вариантах */
.conten-instruct .sidebar ul,
.mobileDrower>.sidebar ul {
    list-style: none;
    display: flex;
    width: 238px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex-shrink: 0;
    border-radius: 12px;
    margin: 0 !important;
    padding: 0;
}

/* Стили для li сайдбара в обоих вариантах */
.conten-instruct .sidebar li,
.mobileDrower>.sidebar li {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    gap: 16px;
    align-self: stretch;
    border-radius: 12px;
    background: #FFF;
    margin-bottom: 10px;
    cursor: pointer;
    color: #585858;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.conten-instruct .sidebar li:hover,
.mobileDrower>.sidebar li:hover {
    opacity: 0.9;
    transition: 0.5s;
}

/* Активный элемент в списке сайдбара для обоих вариантов */
.conten-instruct .sidebar ul>.active,
.mobileDrower>.sidebar ul>.active {
    border-radius: 12px;
    background: #5E7DE8;
    color: #FFF;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

/* Nested menu в сайдбаре для обоих вариантов */
.conten-instruct .nested-menu,
.mobileDrower>.sidebar .nested-menu {
    list-style: none;
    padding-left: 20px;
}

/* Content */
.conten-instruct .content {
    flex: 1;
    margin-left: 16px;
    padding: 16px;
    background: #ffffff;
    border-radius: 12px;
    margin-top: 15px;
    overflow: auto;
    height: calc(100dvh);
}

/* Секции */
.conten-instruct .section {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.conten-instruct .section h2 {
    color: #353535;
    font-size: 24px;
    font-weight: bold;

}

.conten-instruct .section>.task-step span {
    align-self: stretch;
    color: var(--grey-6, #4B4D57);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
}

.conten-instruct .section>.task-step .ant-image {
    display: flex !important;
}

.conten-instruct .section p {
    align-self: stretch;
    color: var(--grey-6, #4B4D57);
    /* regular/14 */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
}

/* Ряды изображений */

.conten-instruct .task-images-row,
.conten-instruct .employee-images,
.conten-instruct .chat-images {
    display: flex;
    gap: 15px;
    margin-bottom: 16px;
    justify-content: space-between;
    padding: 10px;


}


.conten-instruct .images-row {
    display: flex;
    gap: 15px;
    margin-bottom: 16px;
    justify-content: space-between;
    padding: 10px;

}

.conten-instruct .images-row>img {
    background-color: #292F39;
    padding: 5px;
    border-radius: 8px;
    background: #EEF5FF;
}

.conten-instruct .ozon-images {
    display: flex;

    gap: 15px;
    margin-bottom: 16px;
    justify-content: space-between;
    padding: 10px;

}

.conten-instruct .ozon-images>img {
    padding: 10px;

}




.conten-instruct .full-width-image {
    width: 100%;
    border-radius: 8px;
    object-fit: fill;
    margin-bottom: 16px;
    padding: 10px;

}

.conten-instruct .tall-image {
    height: auto;

}

.conten-instruct .tall-image>img {
    padding: 10px;

}

/* Input и кнопки */
.conten-instruct .input {
    width: 100%;
    padding: 17px 13px;
    border: none;
    border-radius: 8px;
    background: #f8fbff;
    color: #4B4D57;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
}

.conten-instruct .input:disabled{
    width: 100%;
    padding: 17px 13px;
    border: none;
    border-radius: 8px;
    background: #f8fbff;
    color: #4B4D57;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    opacity: 1;
}

.conten-instruct .task-button,
.conten-instruct .chat-button {
    width: 100%;
    padding: 17px 13px;
    margin-bottom: 12px;
    border: none;
    border-radius: 8px;
    background: #f8fbff;
    font-size: 14px;
    color: #4b4d57;
    cursor: pointer;
}

.conten-instruct .task-button:hover,
.conten-instruct .chat-button:hover {
    background: #e0e7f5;
}

/* Responsive */
@media (max-width: 768px) {
    .conten-instruct .main-wrapper {
        flex-direction: column;
    }

    .conten-instruct .sidebar,
    .mobileDrower>.sidebar {
        width: 100%;
        margin-bottom: 16px;
    }

    .conten-instruct .content {
        margin-left: 0;
    }
}

/* Стиль для модального окна */
.conten-instruct .instruction-modal-fullscreen .ant-modal {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    top: 0 !important;
    /* Можно дополнительно убрать тень, если хотите */
    /* box-shadow: none !important; */
}