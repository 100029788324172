@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

.searchChatContainerDark {
    display: flex;
    height: 55px;
    padding: 16px;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    align-self: stretch;
    border-right: 1px solid rgba(0, 0, 0, 0.10);
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
}

.searchChatContainerDark input {
    width: 100%;
    border: none;
    outline: none;
    color: var(--Gray-300, #A1A7B3);
    font-feature-settings: 'clig' off, 'liga' off;
    
    /* Semibold 600/12 */
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
}

.searchChatContainerDark input::placeholder {
    color: var(--Gray-300, #A1A7B3);
    font-feature-settings: 'clig' off, 'liga' off;
    
    /* Semibold 600/12 */
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
}

.searchChatContainerDark svg {
    width: 17px !important;
    height: 30px !important;
}