.startDateModalDarkCont>div {
    display: flex;
    padding: 0px 4px;
    align-items: center;
    gap: 12px;
}

.startDateModalDarkCont>div>div {
    display: flex;
    width: var(--Field-sizes-Field-height, 36px);
    height: var(--Field-sizes-Field-height, 36px);
    padding: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 90px;
    background: #21262E;
}

.startDateModalDarkCont>div>div >svg path{
    stroke: white;
    fill: white;
}

.startDateModalDarkCont>div>input {
    color: white;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    background-color: #272C34;
    outline: none;
    border: none;
    width: 132px;
}
.StartDateAndEndDateContentModalDark {
background-color:#21262E !important;
}

.StartDateAndEndDateContentModalDark > .chakra-modal__header{
    color: white;
}
.StartDateAndEndDateContentModalDark input{
     color-scheme: dark;
    width: 100%;
    color: white;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    background-color:#21262E
    
}