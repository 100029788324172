.navbarTopLightCont .navbarContForDekstop {
    display: flex;
    width: 100%;
    padding: 12px 32px 12px 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    background: linear-gradient(0deg, #F8FBFF 0%, #F8FBFF 100%), #FFF;
    height: 72px;
}

.navbarTopLightCont .marketplaceTopMenuCont {
    display: flex;
    height: 48px;
    align-items: center;
    gap: 16px;
}

.navbarTopLightCont .marketplaceTopMenuCont .Frame226 {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}


.navbarTopLightCont .marketplaceTopMenuCont .Frame226 .menu {
    display: flex;
    align-items: center;
    gap: 24px;
}

.navbarTopLightCont .marketplaceTopMenuCont .Frame226 .menu a {
    color: #6A7180;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/12 */
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}

.navbarTopLightCont .searchTopMenu {
    display: flex;
    width: 346px;
    height: 48px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
}


.navbarTopLightCont .searchTopMenu input {
    color: #A8A8A8;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    outline: none;
    border: none;
}


.navbarTopLightCont .rightOtherComponent {
    display: flex;
    align-items: center;
    gap: 16px;
}

.navbarTopLightCont .rightOtherComponent .Frame229 {
    display: flex;
    align-items: center;
    gap: 24px;
}


.navbarTopLightCont .rightOtherComponent .Frame229 .Frame49 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}


.navbarTopLightCont .rightOtherComponent .Frame229 .Frame49 .dropContTop {
    display: flex;
    align-items: flex-start;
    gap: 4px;
}


.navbarTopLightCont .rightOtherComponent .Frame229 .Frame49 .addCompanyBTNTopNav {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    background: var(--Primary-Primary-500, #3B79F6);
}

.navbarTopLightCont .rightOtherComponent .Frame229 .Frame49 .addCompanyBTNTopNav>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.navbarTopLightCont .rightOtherComponent .Frame229 .Frame49 .minimizeSearch {
    display: none;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #EEF5FF;
}

.navbarTopLightCont .minimizeSearchCont {
    display: flex;
    align-items: center;
    gap: 12px;
}

.navbarTopLightCont .logoForMobile {
    display: none;
}

.navbarTopLightCont .rightOtherComponent .Frame229 .Frame49 .minimizeSearch>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 0.25px 0.25px 1.25px 1.25px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.navbarTopLightCont .rightOtherComponent .Frame229 .Frame49 .minimizeSearch>div>svg {
    width: 22.5px;
    height: 22.5px;
    flex-shrink: 0;
}


.navbarTopLightCont .rightOtherComponent .Frame229 .Frame49 .addCompanyBTNTopNav>div .svgContBTN {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px;
    justify-content: center;
    align-items: center;
}

.navbarTopLightCont .rightOtherComponent .Frame229 .Frame49 .addCompanyBTNTopNav>div .svgContBTN svg {
    width: 21.5px;
    height: 21.5px;
    flex-shrink: 0;
}

.navbarTopLightCont .rightOtherComponent .Frame229 .Frame49>.addCompanyBTNTopNav p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}


.navbarTopLightCont .rightOtherComponent .Frame229 .Frame49 .questionMark {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #EEF5FF;
}


.navbarTopLightCont .dropContTop {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 4px;
}

.navbarTopLightCont .rightLogoForPLanshet {
    display: none;
}


@media only screen and (max-width: 1320px) {
    .navbarTopLightCont .searchTopMenu {
        display: none !important;
    }

    .navbarTopLightCont .minimizeSearch {
        display: flex !important;
    }
}

@media only screen and (max-width: 1340px) {
    .rightOtherComponent .Frame229 .Frame49>.addCompanyBTNTopNav p {
        font-size: 12px !important;
    }
}

.navbarTopLightCont .logoAndSaitNameCont {
    display: flex;
    align-items: center;
    gap: 8px;
}

.navbarTopLightCont .PlanshetCont {
    display: none;
}

/* planshet ====================================================================================== */
@media only screen and (max-width: 1024px) {
    .navbarTopLightCont .navbarContForDekstop {
        display: none !important;
    }

    .navbarTopLightCont .PlanshetCont {
        display: flex;
    }

    .navbarTopLightCont .PlanshetCont {
        display: flex;
        height: 72px;
        padding: 0px 16px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-bottom: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
        background: #F8FBFF;
    }

    .navbarTopLightCont .PlanshetContLogoCont {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .navbarTopLightCont .PlanshetContLogoCont>div {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .navbarTopLightCont .iconContLogo {
        display: flex;
        width: 32px;
        height: 32px;
        padding: 1px 2px 0.143px 3px;
        justify-content: center;
        align-items: center;
        background: url("logo.png") -7.714px -2.571px / 171.429% 150% no-repeat;
    }

    .navbarTopLightCont .PlanshetContLogoCont>div>p {
        color: #353535;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        /* 150% */
    }


    /* right item planshet  */
    .navbarTopLightCont .rightItemPlanshet {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .navbarTopLightCont .minmizeSearchPlanshet {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: #EEF5FF;
    }

    .navbarTopLightCont .minmizeSearchPlanshet svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
    }


    .navbarTopLightCont .dropContTopPlanshet {
        display: flex;
        align-items: flex-start;
        gap: 4px;
    }

    .navbarTopLightCont .addCompanyBTNTopNavPlanshet {
        display: flex;
        padding: 12px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        border-radius: 12px;
        background: var(--Primary-Primary-500, #3B79F6);
    }

    .navbarTopLightCont .addCompanyBTNTopNavPlanshet>div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .navbarTopLightCont .addCompanyBTNTopNavPlanshet>div p {
        color: #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 171.429% */
    }

    .navbarTopLightCont .questionMarkPlanshet {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: #EEF5FF;
    }
}

/* mobile============================================================ */
.navbarTopLightCont .NavBarTopMobileCont {
    display: none;
}



/* search minimize  */

.navbarTopLightCont .toggleSreachCont {
    display: flex;
    padding: 12px 32px 12px 16px;
    align-items: center;
    gap: 16px;
    border-bottom: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    border-left: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: linear-gradient(0deg, #F8FBFF 0%, #F8FBFF 100%), #FFF;
}

.navbarTopLightCont .toggleSreachCont>label {
    display: flex;
    height: 48px;
    width: auto;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
}

.navbarTopLightCont .toggleSreachCont>label input {

    width: 100%;
    border: none;
    outline: none;
    color: #353535;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.navbarTopLightCont .toggleSreachCont>button {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #EEF5FF;
}

.navbarTopLightCont .toggleSreachCont>button>div {
    display: flex;
    padding: 6px 9px;
    justify-content: center;
    align-items: center;
    gap: 4px;
}


.navbarTopLightCont .toggleSreachCont>button>svg {
    width: 24px;
    height: 24px;
}

@media only screen and (max-width: 768px) {

    .PlanshetCont,
    .PlanshetContLogoCont {
        display: none !important;
    }


    .NavBarTopMobileCont {
        display: flex !important;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;



    }



    .navbarTopLightCont .NavBarTopMobileCont>div {
        display: flex;
        width: 100%;
        height: 72px;
        padding: 0px 16px;
        justify-content: space-between;
        align-items: center;
        border-bottom: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
        background: #F8FBFF;

        transition: 1s;
    }

    .navbarTopLightCont .logoAndSearch {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .navbarTopLightCont .logoAndSearch .imgContContMobile {
        width: 27px;
        height: 30.857px;
        flex-shrink: 0;
        background: url("logo.png") -7.714px -2.571px / 171.429% 150% no-repeat;
    }

    .navbarTopLightCont .searchContMinimizeMobile {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: #EEF5FF;
    }

    .navbarTopLightCont .searchContMinimizeMobile svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
    }

    .navbarTopLightCont .drowerCompanyContMobile {
        display: flex;
        align-items: flex-start;
        gap: 4px;
    }

    .navbarTopLightCont .notifyAndQueryCont {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .navbarTopLightCont .questionMark {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: #EEF5FF;
    }

    .navbarTopLightCont .questionMark svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
    }
}


@media only screen and (max-width: 368px) {

    .navbarTopLightCont   .NavBarTopMobileCont {
        display: flex !important;
        height: 72px !important;
        justify-content: space-between !important;
        align-items: center !important;
        align-self: stretch !important;
        width: 100%;
    }

    .navbarTopLightCont     .NavBarTopMobileCont>div {
        display: flex !important;
        height: 72px !important;
        padding: 12px 16px !important;
        justify-content: space-between !important;
        align-items: center !important;
        align-self: stretch !important;
        width: 100% !important;
        background: #F8FBFF !important;
    }

    .navbarTopLightCont .logoAndSearch {
        display: flex;
        align-items: center;
        gap: 9px;
    }

    .navbarTopLightCont .logoAndSearch .imgContContMobile {
        width: 27px;
        height: 30.857px;
        flex-shrink: 0;
    }

    .navbarTopLightCont .searchContMinimizeMobile {
        display: flex;
        width: 36px;
        height: 36px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 11.25px;
        border-radius: 112.5px;
        background: #EEF5FF;
    }

    .navbarTopLightCont .searchContMinimizeMobile>svg {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
    }

    .navbarTopLightCont .questionMark {
        display: flex;
        width: 36px;
        height: 36px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 11.25px;
        border-radius: 112.5px;
        background: #EEF5FF;
    }

    .navbarTopLightCont .questionMark>svg {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
    }

    .navbarTopLightCont .notifyAndQueryCont {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }

    .dropdownAllNotifyCont .notifyBtnNav {
        display: flex;
        width: 36px !important;
        height: 36px !important;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 11.25px;
        border-radius: 112.5px;
        background: #EEF5FF;
    }

    .dropdownAllNotifyCont .notifyBtnNav>svg {
        display: flex;
        width: 18px !important;
        height: 18px !important;
        padding: 1.8px 1.662px 0.9px 1.8px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }
}