.dropdownAllNotifyCont {
  position: relative;
  display: inline-block;
}

.dropdownAllNotifyCont .dropdown-menuNotify {

  position: absolute;
  background-color: #f9f9f9;
  width: 584px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 7;
  left: -518px;
  top: 80px;
  border-radius: 24px;
  overflow: hidden;
  height: 70vh;
  margin: auto;
  padding: 0 10%;

}

/* width */
.notifyDrowerLight >.chakra-modal__body::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.notifyDrowerLight >.chakra-modal__body::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.notifyDrowerLight >.chakra-modal__body::-webkit-scrollbar-thumb {
  background:#C9C9C9;
}

/* Handle on hover */
.notifyDrowerLight >.chakra-modal__body::-webkit-scrollbar-thumb:hover {
  background: #555;
}



.notifyDrowerLight .chakra-modal__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 0 !important;
}

.notifyDrowerLight .chakra-modal__header>.Headertitle {
  display: flex;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #FFF;
}

.notifyDrowerLight .chakra-modal__header>.Headertitle>div:first-child {
  display: flex;
  align-items: center;
  gap: 8px;
}


.notifyDrowerLight .chakra-modal__header>.Headertitle>div:first-child>p {
  color: #1E293B;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 30px */
}


.notifyDrowerLight .chakra-modal__header>.Headertitle>div:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
}

.notifyDrowerLight .chakra-modal__header>.Headertitle>div:last-child>div:first-child {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.notifyDrowerLight .chakra-modal__header>.Headertitle>div:last-child>div:first-child>p {
  color: var(--Primary-Primary-500, #3B79F6);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 14px */
  cursor: pointer;
}

.notifyDrowerLight .chakra-modal__header>.Headertitle>div:last-child>div:last-child {
  display: flex;
  width: 32px;
  height: 32px;
  padding: var(--Field-sizes-Field---Label-gap, 6px) 9px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.notifyDrowerLight .chakra-modal__header>.Headertitle>div:last-child>div:last-child>svg {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.notifyDrowerLight .mode-Tabs{
  display: flex;
  padding: 0px 24px;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: var(--Overlay-border-width, 1px) solid rgba(26, 56, 96, 0.10);
background: #FFF;
}

.notifyDrowerLight .mode-Tabs >div{
  display: flex;
  padding-right: 4px;
  align-items: flex-start;
  flex: 1 0 0;
  border-bottom: var(--Overlay-border-width, 1px) solid #F2F4F7;
}

.notifyDrowerLight .mode-Tabs >div >div:first-child{
  display: flex;
height: 39px;
padding: 0px 4px;
flex-direction: column;
align-items: flex-start;
}

.notifyDrowerLight .mode-Tabs >div >div:first-child >div{
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  cursor: pointer;
}

.notifyDrowerLight .mode-Tabs >div >div:first-child >div >p{
  color: #1E293B;

  /* Text sm/Semibold */
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}


.notifyDrowerLight .mode-Tabs >div >div:first-child >div >div{
  display: flex;
  padding: 2px 8px;
  align-items: center;
  border-radius: 10px;
background: #F2F4F7;
mix-blend-mode: multiply;
}


.notifyDrowerLight .mode-Tabs >div >div:first-child >div >div >p{
  color: #334155;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.notifyDrowerLight .mode-Tabs >div >div:first-child >span{
  height: 3px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: var(--Border-radius-M, 4px) var(--Border-radius-M, 4px) var(--Field-sizes-Field-border-width, 0px) var(--Field-sizes-Field-border-width, 0px);
background: var(--Primary-Primary-500, #3B79F6);
}

.notifyDrowerLight .mode-Tabs >div >div:last-child{
  display: flex;
  height: 39px;
  padding: 0px 4px;
  flex-direction: column;
  align-items: flex-start;

}

.notifyDrowerLight .mode-Tabs >div >div:last-child > div{
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.notifyDrowerLight .mode-Tabs >div >div:last-child > div >p{
  color: #64748B;

/* Text sm/Semibold */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
}
.notifyDrowerLight .mode-Tabs >div >div:last-child > div >div{
  display: flex;
padding: 2px 8px;
align-items: center;
border-radius: 10px;
background: #F2F4F7;
mix-blend-mode: multiply;
}

.notifyDrowerLight .mode-Tabs >div >div:last-child > div >div >p{
  color: #334155;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.notifyDrowerLight .mode-Tabs >div >div:last-child >span{
  height: 3px;
flex-shrink: 0;
align-self: stretch;
border-radius: var(--Border-radius-M, 4px) var(--Border-radius-M, 4px) var(--Field-sizes-Field-border-width, 0px) var(--Field-sizes-Field-border-width, 0px);
background: var(--Primary-Primary-500, #3B79F6);
}

 .dropdownAllNotifyCont .notifyBtnNav {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: linear-gradient(0deg, #EEF5FF 0%, #EEF5FF 100%), #E8ECFE;
  cursor: pointer;
}

.badge {
  display: flex;
  height: 20px;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: -8px;
  top: -8px;
  border-radius: 18px;
  background: #5E7DE8;
}

.badge p {
  color: var(--White-White, #FFF);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter";
  font-size: 8px;
  font-style: normal;
  font-weight: 800;
  line-height: 12px;

}

.loadNotify{
  top: 5%; /* Оставляем как есть, чтобы элемент был немного ниже от верхней границы */
  left: 50%; /* Выравниваем по центру горизонтально */
  position: absolute;
  width:50px;
  height:50px;
  border-radius:50%;
  background:conic-gradient(#0000 10%,#3B79F6);
  -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation:s3 1s infinite linear;
}

@keyframes s3 {to{transform: rotate(1turn)}}