.aouthCont {
  display: flex;
  align-items: center;
  align-self: stretch;
  width: 100%;
  height: 100dvh;
}


.aouthCont>div:first-child {
  display: flex;
  padding: 24px 20px 24px 45px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  background: #F7F8FA;
  width: 100%;
}




.aouthCont>div:first-child>.outNav {
  display: flex;
  width: 100%;
  height: 72px;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  background: #FFF;
}




.aouthCont>div:first-child>div>div:first-child {
  display: flex;
  align-items: center;
  gap: 8px;
}

.aouthCont>div:first-child>div>div:first-child>img {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 1px 2px 0.143px 3px;
  justify-content: center;
  align-items: center;
}

.aouthCont>div:first-child>div>div:first-child>p {
  color: #353535;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.aouthCont>div:first-child>div>ul {
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 32px;
}


.aouthCont>div:first-child>div>ul>li {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.aouthCont>div:first-child>div>ul>li>p {
  color: #5A5A5A;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
}


.aouthCont>div:last-child {
  display: flex;
  width: 30%;
  height: 812px;
  padding: 20px 45px 20px 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  background: #FFF;
}


.aouthCont>div:last-child>div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.aouthCont>div:last-child>div:nth-child(1)>div:first-child {
  display: flex;
  width: 130px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.aouthCont>div:last-child>div:nth-child(2)>div .ScannerAndCallBTN{
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-direction: unset;
}

.aouthCont>div:last-child>div:nth-child(2) > p{
  align-self: stretch;
  color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.aouthCont>div:last-child>div:nth-child(2) > p > a{
  align-self: stretch;
  color: #3B79F6;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  text-decoration: underline;
}

.aouthCont>div:last-child>div:nth-child(2)>div .ScannerAndCallBTN>.pinBTN {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 12px;
  background: #3B79F6;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
}

.aouthCont>div:last-child>div:nth-child(2)>div .ScannerAndCallBTN>.scannerBTN {
  display: flex;
  width: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background: #F7F8FA;
}

.aouthCont>div:last-child>div:nth-child(2)>div .ScannerAndCallBTN>.scannerBTN>svg {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.aouthCont>div:last-child>div:nth-child(1)>div:first-child>img {

  width: 89px;
  height: 107.147px;
}

.aouthCont>div:last-child>div:nth-child(1)>div:last-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.aouthCont>div:last-child>div:nth-child(1)>div:last-child>p:first-child {
  align-self: stretch;
  color: #1E1E1E;
  text-align: center;
  font-family: "Inter";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.aouthCont>div:last-child>div:nth-child(1)>div:last-child>p:last-child {
  align-self: stretch;
  color: #565656;
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.aouthCont>div:last-child>div:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.aouthCont>div:last-child>div:nth-child(2)>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.aouthCont>div:last-child>div:nth-child(2)>div>div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.aouthCont>div:last-child>div:nth-child(2)>div>div>p {
  align-self: stretch;
  color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.aouthCont>div:last-child>div:nth-child(2)>div>div>div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}



.aouthCont>div:last-child>div:nth-child(2)>div>div>div>div {
  display: flex;
  padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Field-sizes-Field---Label-gap, 6px);
  align-self: stretch;
}

.aouthCont>div:last-child>div:nth-child(2)>div>div>div>div>p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
  text-overflow: ellipsis;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  /* 121.429% */
}

.aouthCont>div:last-child>div:nth-child(2)>div>div>div>div>div {
  width: 100%;
}

.aouthCont>div:last-child>div:nth-child(2)>div>div>div>div>div>.PhoneInputInput {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  overflow: hidden;
  color: #929292;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 137.5% */
}

.aouthCont>div:last-child>div:nth-child(2)>div>button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Primary-Primary-500, #3B79F6);
}

.aouthCont>div:last-child>div:nth-child(2)>div>button>svg {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 2.25px 2.271px 2.271px 2.25px;
  justify-content: center;
  align-items: center;
}

.aouthCont>div:last-child>div:nth-child(2)>div>button>p {
  color: #FFF;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
}


.aouthCont>div:last-child>div:nth-child(3) {
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  background: #F2F4F8;
}

.aouthCont>div:last-child>div:nth-child(3)>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.aouthCont>div:last-child>div:nth-child(3)>div>div>input {
  display: flex;
  width: 49px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #FFF;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  color: var(--Primary-Primary-500, #3B79F6) !important;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  /* 121.429% */
}

.css-39xwre:focus,
.css-39xwre:focus {
  border-color: white !important;
  box-shadow: none !important;
}

.aouthCont>div:last-child>div:nth-child(3)>div>p {
  align-self: stretch;
  color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  /* 121.429% */
}

.aouthContMobile {
  display: none;
}

@media only screen and (max-width: 1273px) {
  .aouthCont>div:first-child>div>ul>li>p {
    font-size: 10px;
  }

}


@media only screen and (max-width: 1053px) {
  .aouthCont {
    display: none;
  }

  .outhForMobile>div:nth-child(2) > p{
    align-self: stretch;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  
  .outhForMobile>div:nth-child(2) > p > a{
    align-self: stretch;
    color: #3B79F6;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    text-decoration: underline;
  }
  .aouthContMobile {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  .aouthContMobile>nav {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: #F7F8FA;
    width: 100%;
    height: 76px;
  }

  .aouthContMobile>nav>div:first-child {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .aouthContMobile>nav>div:first-child>div {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 1px 2px 0.143px 3px;
    justify-content: center;
    align-items: center;
  }

  .aouthContMobile>nav>div:first-child>p {
    color: #353535;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
  }

  .aouthContMobile>nav>div:last-child {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 12px;
    background: #FFF;
    cursor: pointer;
  }

  .aouthContMobile>nav>div:last-child>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4.25px;
    justify-content: center;
    align-items: center;
  }

  .aouthContMobile>nav>div:last-child>div>svg {
    width: 15.5px;
    height: 15.5px;
    flex-shrink: 0;
  }

  .mobileOutListMenu {
    display: flex;
    width: 100%;
    padding: 16px;
    align-items: flex-start;
    gap: 10px;
    position: absolute;
    top: 76px;
    background: #FFF;
    box-shadow: 0px 13px 12px 0px rgba(0, 0, 0, 0.04);
    overflow: hidden;
    height: 0;
    z-index: 10;
    transition: 0.2s;
  }

  .mobileOutListMenu>ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
  }

  .mobileOutListMenu>ul>li {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #5A5A5A;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
    cursor: pointer;
  }

  .scrolForMobileOuth {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    height: calc(100dvh - 76px);
    overflow: auto;
    background: #F2F4F8;

  }

  /* width */
  .scrolForMobileOuth::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  .scrolForMobileOuth::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .scrolForMobileOuth::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  .scrolForMobileOuth::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .outhForMobile {
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    background-color: white;
  }

  .outhForMobile>div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }

  .outhForMobile>div:nth-child(1)>div:nth-child(1) {
    display: flex;
    width: 68.122px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6.288px;
  }

  .outhForMobile>div:nth-child(1)>div:nth-child(1)>img {
    width: 46.638px;
    height: 56.147px;
  }

  .outhForMobile>div:nth-child(1)>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }

  .outhForMobile>div:nth-child(1)>div:nth-child(2)>p:first-child {
    align-self: stretch;
    color: #1E1E1E;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .outhForMobile>div:nth-child(1)>div:nth-child(2)>p:last-child {
    align-self: stretch;
    color: #565656;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .outhForMobile>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
  }

  .outhForMobile>div:nth-child(2)>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }

  .outhForMobile>div:nth-child(2)>div>div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  .outhForMobile>div:nth-child(2)>div>.ScannerAndCallBTN {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }

  .outhForMobile>div:nth-child(2)>div>.ScannerAndCallBTN>.pinBTN {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 12px;
    background: #3B79F6;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
  }

  .outhForMobile>div:nth-child(2)>div>.ScannerAndCallBTN>.scannerBTN {
    display: flex;
    width: 48px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    background: #F7F8FA;
  }

  .outhForMobile>div:nth-child(2)>div>.ScannerAndCallBTN>.scannerBTN>svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  .outhForMobile>div:nth-child(2)>div>div:first-child>p {
    align-self: stretch;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    text-align: center;
  }

  .outhForMobile>div:nth-child(2)>div>div:first-child>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    align-self: stretch;
  }

  .outhForMobile>div:nth-child(2)>div>div:first-child>div>div {
    width: 100%;
  }

  .outhForMobile>div:nth-child(2)>div>div:first-child>div>div .verifyInput {
    width: 100% !important;

  }

  .outhForMobile>div:nth-child(2)>div>div:first-child>div .verifyInput>input {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    margin-top: 10px;
  }

  .outhForMobile>div:nth-child(2)>div>div:first-child>div>div>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 121.429% */
  }

  .outhForMobile>div:nth-child(2)>div>div:first-child>div .verifyInput>input {
    border-radius: 12px !important;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    width: 100% !important;
  }

  .outhForMobile>div:nth-child(2)>div>button {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 12px;
    background: var(--Primary-Primary-500, #3B79F6);
  }

  .outhForMobile>div:nth-child(2)>div>button>p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
  }

  .outhForMobile>div:nth-child(2)>div>button>svg {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 2.25px 2.271px 2.271px 2.25px;
    justify-content: center;
    align-items: center;
  }

  .outhForMobile>div:nth-child(3) {
    display: flex;
    width: 100%;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 12px;
    background: #F2F4F8;
  }

  .outhForMobile>div:nth-child(3)>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }

  .outhForMobile>div:nth-child(3)>div>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }


  .outhForMobile>div:nth-child(3)>div>div>input {
    display: flex;
    width: 49px;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #FFF;
    color: var(--Primary-Primary-500, #3B79F6);
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 121.429% */
  }

  .outhForMobile>div:nth-child(3)>div>p {
    align-self: stretch;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 121.429% */
  }


}