
.dropdownNotifySendMsgLigthDark {
    display: flex;
    position: relative;
    display: inline-block;
  
  }
  
.dropdownNotifySendMsgLigthDark .dropdown-contentNotify {
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: absolute;
    background-color: #12151A;
    max-width: 25rem !important;
    max-height: 20vh;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    right: 80%;
    bottom: 10%;
    z-index: 150 !important;
    border-radius: 10px;
  
  }

/* width */
.dropdownNotifySendMsgLigthDark .dropdown-contentNotify::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .dropdownNotifySendMsgLigthDark .dropdown-contentNotify::-webkit-scrollbar-track {
    background: #12151A;
  }
  
  /* Handle */
  .dropdownNotifySendMsgLigthDark .dropdown-contentNotify::-webkit-scrollbar-thumb {
    background: #3B79F6;
  }
  
  /* Handle on hover */
  .dropdownNotifySendMsgLigthDark .dropdown-contentNotify::-webkit-scrollbar-thumb:hover {
    background: #3B79F6;
  }

  
.dropdownNotifySendMsgLigthDark .dropdown-contentNotify > div{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: start;
    padding: 5px;
    width: 100%;
  }
  
.dropdownNotifySendMsgLigthDark .dropdown-contentNotify > div p{
    margin: 0 !important;
  }
  
.dropdownNotifySendMsgLigthDark .dropdown-contentNotify > div:hover{
    background-color: #21262E;
    cursor: pointer;
  }
  
  
.dropdownNotifySendMsgLigthDark .dropdown-contentNotify p {
    color: #6A717F !important;
    padding: 12px 16px;
    text-decoration: none;
    font-weight: 600;
    display: block;
   
  }
  
.dropdownNotifySendMsgLigthDark .dropdown a:hover {
    background-color: #ddd;
  }


  @media only screen and (max-width: 427px) {
    .dropdownNotifySendMsgLigthDark .dropdown-contentNotify p{
      font-size: 10px;
    }
  }
  
  
  @media only screen and (max-width: 341px) {
    .dropdownNotifySendMsgLigthDark .dropdown-contentNotify p{
      font-size: 10px;
    }
    .dropdownNotifySendMsgLigthDark .dropdown-contentNotify{
      right: 50%; /* Сместите элемент на 50% */
      transform: translateX(7%); /* Перемещает элемент на половину его ширины */
     
    }
  }
  

  @media only screen and (max-width: 451px) {
    .dropdownNotifySendMsgLigthDark .dropdown-contentNotify{
      right: -93% !important;
    }
  }
  