.editPriorityBySelectContDark {
    display: flex;
    padding: 3px 16px;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
}

.editPriorityBySelectContDark button {
    display: flex;
    width:auto;
    width: 100%;
    height: 36px;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #333846;
   
}
.editPriorityBySelectContDark button> svg path {
    fill: white;
    color: white;
}

.editPriorityBySelectContDark button>.Frame625852 {
    display: flex;
    align-items: center;
    gap: 4px;
}

.editPriorityBySelectContDark button>.Frame625852>.Frame625851 {
    display: flex;
    align-items: center;
    gap: 4px;
}

.editPriorityBySelectContDark button>.Frame625852>.Frame625851>p {
    color: white;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}



.editPriorityBySelectContDark .select-itemsPriority {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background:#21262E;
    overflow: auto;
   
}


.editPriorityBySelectContDark .select-itemsPriority>.Component56 {
    display: flex;
    height: 36px;
    padding: 0px 10px;
    align-items: center;
    align-self: stretch;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #21262E;
    overflow: hidden;
    cursor: pointer;
}

.select-itemsPriority>.Component56:nth-child(1) p{
    color: red;
}

.select-itemsPriority>.Component56:nth-child(2) p{
    color: #56D4FC;
}

.select-itemsPriority>.Component56:nth-child(3) p{
    color: #4DFF30;
}
.select-itemsPriority>.Component56:nth-child(4) p{
    color: #FB50FF;
}

.select-itemsPriority>.Component56:nth-child(5) p{
    color:#5364FF;
}
.select-itemsPriority>.Component56:nth-child(6) p{
    color:#FF50A0;
}

.Frame625852 {
    display: flex;
    align-items: center;
    gap: 4px;
}

.Frame625851 {
    display: flex;
    align-items: center;
    gap: 4px;
}

.Frame625851 p {
    
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}
@media only screen and (max-width: 1440px) {
    .editPriorityBySelectContDark .select-itemsPriority {
        width: 100% !important; 
    }
  }