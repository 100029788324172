.ChtatLeftSideContLight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100dvh !important;
   
}

.ChtatLeftSideContLight > div {
   
    height: 100%;
}

.ChtatLeftSideContLight .listChatItem {
   
    overflow: auto;
    margin: 0; /* убедитесь, что отступы сброшены */
    padding: 0; /* убедитесь, что поля сброшены */
    box-sizing: border-box;
    height: 80vh;
}

.ChtatLeftSideContLight .listChatItem a:last-child {

    border-bottom: 1px solid rgba(0, 0, 0, 0.10);


}

