.otherNavItemsDarkCont {
    display: none;
    padding: 23px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    background: #12151A;
    height: calc(100dvh - 197px) !important;
    overflow: auto;
}

.otherNavItemsDarkCont>div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}


.otherNavItemsDarkCont>div:first-child .row1,
.otherNavItemsDarkCont>div:first-child .row2,
.otherNavItemsDarkCont>div:first-child .row3 {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 4px;
    align-self: stretch;
    flex-wrap: wrap;
}

.otherNavItemsDarkCont>div:first-child .row1>a,
.otherNavItemsDarkCont>div:first-child .row2>a,
.otherNavItemsDarkCont>div:first-child .row3>a {
    display: flex;
    height: 103px;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 12px;
    background: #21262E;
    position: relative;
    overflow: hidden;

}


.otherNavItemsDarkCont>div:first-child .row1>a>p,
.otherNavItemsDarkCont>div:first-child .row2>a>p,
.otherNavItemsDarkCont>div:first-child .row3>a>p {
    align-self: stretch;
    color: white;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 200% */
}


.otherNavItemsDarkContMobile .empty {
    background: #12151A !important;
}



.iconContOtherMenuTask {
    background: lightgray url("./iconCanot/task.png") no-repeat -15px -0.079px / 164.706% 100.22% !important;
    width: 51px;
    height: 72px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -9px;
}

.iconContOtherMenuMessanger {

    background: url("./iconCanot/messanger.png") 50% / cover no-repeat;
    width: 78px;
    height: 97px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -34px;
}

.settingImgContSettings {
    background: url("./iconCanot/settings.png") 50% / cover no-repeat;
    width: 91px;
    height: 91px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -28px;
}


.iconContOtherMenuEmploys {
    background: url("./iconCanot/employs.png") -7.718px -4.478px / 106.628% 127.698% no-repeat;
    width: 103px;
    height: 83px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
}


.iconContOtherMenuApi {
    background: url("./iconCanot//api.png") -10.602px -11.314px / 122.043% 119.524% no-repeat;
    width: 87px;
    height: 99px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -36px;
}

.iconContOtherMenuOzon {
    background: url("./iconCanot/ozon.png") -3.455px -9.787px / 102.677% 121.799% no-repeat;
    width: 123px;
    height: 107px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -44px;
}


.iconContOtherMenuWB {
    background: url("./iconCanot/wb.png") -3.486px -8.929px / 103.352% 123.139% no-repeat;
    width: 104px;
    height: 89px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
}

.iconContOtherMenuYandex {
    background: url("./iconCanot/yandex.png") 0px -9.386px / 100% 122.422% no-repeat;
    width: 109px;
    height: 91px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -28px;
}
@media screen and (max-width: 1024px) and (min-width: 768px) {

    .otherNavItemsDarkCont {
        display: flex;
    }



}

/* otherNavItemsLightContMobile  for mobile class */

.otherNavItemsDarkContMobile {
    display: none;
}

@media only screen and (max-width: 768px) {
    .otherNavItemsDarkContMobile {
        display: flex;
        padding: 23px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
        align-self: stretch;
        background: #12151A;
        height: calc(100dvh - 197px) !important;
        overflow: auto;
    }

    .otherNavItemsDarkContMobile>div:first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
    }


    .otherNavItemsDarkContMobile>div:first-child .row1,
    .otherNavItemsDarkContMobile>div:first-child .row2,
    .otherNavItemsDarkContMobile>div:first-child .row3 {
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        gap: 4px;
        align-self: stretch;
        flex-wrap: wrap;
    }

    .otherNavItemsDarkContMobile>div:first-child .row1>a,
    .otherNavItemsDarkContMobile>div:first-child .row2>a,
    .otherNavItemsDarkContMobile>div:first-child .row3>a {
        display: flex;
        height: 103px;
        padding: 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
        border-radius: 12px;
        background: #21262E;
        position: relative;
        overflow: hidden;

    }


    .otherNavItemsDarkContMobile>div:first-child .row1>a>p,
    .otherNavItemsDarkContMobile>div:first-child .row2>a>p,
    .otherNavItemsDarkContMobile>div:first-child .row3>a>p {
        align-self: stretch;
        color: white;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 200% */
    }

}
/*  */



/* height */
@media screen and (max-height: 426px) {
    .otherNavItemsDarkCont {
        margin-bottom: 50px;
    }
.otherNavItemsDarkContMobile{
    margin-bottom: 50px;
}


}


@media only screen and (max-width: 384px) {
    .otherNavItemsDarkContMobile>div:first-child .row1>a>p,
    .otherNavItemsDarkContMobile>div:first-child .row2>a>p,
    .otherNavItemsDarkContMobile>div:first-child .row3>a>p {
            font-size: 10px;
    }
}