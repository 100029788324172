.aouthContAdmin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30;
    height: 100dvh;
}

.aouthContAdmin >div > img{
    width: 89px;
height: 107.147px;
}

.aouthContAdmin h1{
    color: #1E1E1E;
    text-align: center;
    font-family: "Inter";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.aouthContAdmin .enterPhone{
    text-align: center;
}
.aouthContAdmin > .inputCont{
    display: flex;
    width: 469px;
    padding: 20px 45px 20px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 10px;
}