@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');

.CreatChatButtonContainerLight {
    display: flex;
    height: 48px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    background: var(--Primary-500, #3B79F6);
    box-shadow: 0px 0.508px 1.525px 0px rgba(59, 130, 246, 0.40);
    width: 100%;
}


.CreatChatButtonContainerLight p {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    margin: 0 !important;
}


.CreatChatButtonContainerLight svg {
    width: 24px;
    height: 24px;
}