.addnewadminCont{
    width: 25%;
 
  

}

.addnewadminCont button{
    background-color:#3B79F6;
    padding: 2%;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

}


/* mobile meida===================================================== */
@media only screen and (max-width: 727px) {
    .addnewadminCont{
        width: 95%;
       text-align: center;
        margin: auto;
    }
    
    .addnewadminCont button{
        background-color: #5E82DF;
        padding: 10px;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    
    }
    
    .addnewadminCont button span{
        font-size: 3.5vw !important;
    }
    
    
}