

.myTableDiv {
    width: 100%;
    table-layout: fixed;
    border-radius: 12px;
    overflow-y: auto; /* Оставляем прокрутку */
    border-collapse: collapse;
}

/* Для экранов до 1682px */
@media only screen and (max-width: 1682px) {
    .myTableDiv {
        height: calc(100vh - 212px); /* Высота с учетом отступов */
    }
}

/* Для экранов от 1683px и выше */
@media only screen and (min-width: 1683px) {
    .myTableDiv {
        height: 860px; /* Фиксированная высота */
        max-height: 82vh; /* Гибкость, чтобы не было выше экрана */
    }
}

.myTableDiv .draggable-table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    border-radius: 12px;
    position: relative;

}

.myTableDiv .draggable-table th>div {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}



.myTableDiv .draggable-table th>p>svg {
    position: absolute;
    top: 43px;
    left: 0;
}

.myTableDiv .draggable-table th>p>svg path {
    stroke: #3B79F6;
}

.myTableDiv .draggable-table th,
.myTableDiv .draggable-table td {
    border-bottom: 1px solid rgba(136, 136, 136, 0.15);
    text-align: center;
    padding: 12px;
    font-family: "Inter", sans-serif;

}


.myTableDiv .draggable-table td {
    color: #353535;
    font-size: 12px;
    font-weight: 500;



}

.myTableDiv .draggable-table th:nth-child(1) {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 10;
    width: 150px !important;
    z-index: 100;
}


.myTableDiv .draggable-table th > p{
    color: #929292;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px; /* 121.429% */
}


.myTableDiv .draggable-table td:nth-child(1) {

    background-color: white;



}

.myTableDiv .draggable-table th:nth-child(24),
.myTableDiv .draggable-table th:nth-child(23),
.myTableDiv .draggable-table th:nth-child(22) {
    width: 150px !important;
}

.myTableDiv .draggable-table th:last-child {
    width: 150px !important;
}

.myTableDiv .draggable-table td:nth-child(1)>div {
    display: flex;
    justify-content: space-between;
    gap: 5px;



}



.myTableDiv .draggable-table td:nth-child(1)>div>img {
    /* height: auto;
    border-radius: 2px;
    object-fit: cover; */
}

.myTableDiv .draggable-table td:nth-child(1)>div>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
}

.myTableDiv .draggable-table td:nth-child(1)>div>div>div {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 5px;
}

.myTableDiv .draggable-table td:nth-child(1)>div>div>div>a {
    color: #3B79F6;
}

.myTableDiv .draggable-table td:nth-child(1)>div>div>div>svg {
    color: #3B79F6;
}

.myTableDiv .draggable-table td:nth-child(1)>div>div>p:first-child {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-align: justify;
    line-height: 1.2;
    word-break: break-word;
}

.myTableDiv .draggable-table td:nth-child(1)>div>div>p:last-child {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-align: justify;
    line-height: 1.2;
    text-align: start;
    word-break: break-word;
}

.myTableDiv .draggable-table td:nth-child(1)>div>div>p:last-child>span {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-align: justify;
    line-height: 1.2;
    text-align: start;
}



.myTableDiv .draggable-table th {
    width: 100px;
    color: #929292;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
    white-space: normal;
    word-wrap: break-word;
    position: relative;
}



.myTableDiv .draggable-table th:nth-child(4)>p,
.myTableDiv .draggable-table th:nth-child(5)>p,
.myTableDiv .draggable-table th:nth-child(6)>p,
.myTableDiv .draggable-table th:nth-child(7)>p,
.myTableDiv .draggable-table th:nth-child(8)>p,
.myTableDiv .draggable-table th:nth-child(9)>p,
.myTableDiv .draggable-table th:nth-child(10)>p,
.myTableDiv .draggable-table th:nth-child(11)>p,
.myTableDiv .draggable-table th:nth-child(12)>p,
.myTableDiv .draggable-table th:nth-child(13)>p,
.myTableDiv .draggable-table th:nth-child(14)>p,
.myTableDiv .draggable-table th:nth-child(15)>p,
.myTableDiv .draggable-table th:nth-child(16)>p,
.myTableDiv .draggable-table th:nth-child(17)>p,
.myTableDiv .draggable-table th:nth-child(18)>p {
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
    /* writing-mode: vertical-rl; */
    text-align: center;
    touch-action: none !important;

}

.myTableDiv .draggable-table th:nth-child(12) {

    border-left: 1px solid #3B79F6;

}

.myTableDiv .draggable-table td:nth-child(12) {
    border-left: 1px solid #3B79F6;

}


.myTableDiv .draggable-table tr {
    background-color: white;
    width: 50px;
}



@media only screen and (max-width: 600px) {

    .myTableDiv .draggable-table th,
    .myTableDiv .draggable-table td {
        font-size: 8px;
    }

}


.spinner {
    width: 20px;
    height: 20px;
    margin: auto;
    border-radius: 55%;
    border: 2px solid #3b79f6;
    animation: spinner-bulqg1 0.8s infinite linear alternate, spinner-oaa3wk 1.6s infinite linear;
    z-index: 15;
}

.myTableDiv .draggable-table .commentCont {
    border: 1px solid #f0f0f0;
    color: rgb(85, 85, 85);

}


.myTableDiv .draggable-table .commentCont p:first-child {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-align: justify;
    line-height: 1.2;
    text-align: center;
    min-height: 15px;
    max-height: 15px;

}



@keyframes spinner-d3wgkg {
    to {
        transform: rotate(1turn);
    }
}

.myTableDiv .draggable-table .totalDiv {
    padding: 10px;
    border-radius: 10px;
}





.myTableDiv .draggable-table tr.sticky {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    touch-action: none !important;
}


/*
  STICKY COLUMN
  Avoid undesirable overlapping shadows by creating a faux shadow on the ::after psudo-element instead of using the css box-shadow property.
  */

.myTableDiv .draggable-table td.sticky,
.myTableDiv .draggable-table td.sticky {
    position: sticky;
    left: 0;
    background: var(--stickyBackground);
    background-color: white;
    touch-action: none !important;
}

.myTableDiv .draggable-table td.sticky::after,
.myTableDiv .draggable-table td.sticky::after {
    content: "";
    position: absolute;
    right: -6px;
    top: 0;
    bottom: -1px;
    width: 5px;
    border-left: 1px solid var(--borderColor);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0) 100%);
}

.myTableDiv .draggable-table td.sticky::before,
.myTableDiv .draggable-table td.sticky::before {
    content: "";
    position: absolute;
    left: -6px;
    top: 0;
    bottom: -1px;
    width: 5px;
    border-right: 1px solid var(--borderColor);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.08) 100%);
}


.myTableDiv .draggable-table td .GRADES_NONE {
    background-color: rgba(211, 211, 211, 1);
    /* Цвет для ожидаемых поставок */
    padding: 10px;
    border-radius: 10px;
}

.myTableDiv .draggable-table td .GRADES_NOSALES {
    background-color: rgba(253, 229, 236, 1);
    /* Цвет для нет продаж */
    padding: 10px;
    border-radius: 10px;
}

.myTableDiv .draggable-table td .GRADES_GREEN {
    background-color: rgba(144, 238, 144, 1);
    /* Цвет для «хороший» */
    padding: 10px;
    border-radius: 10px;
}

.myTableDiv .draggable-table td .GRADES_YELLOW {
    background-color: rgba(255, 255, 224, 1);
    /* Цвет для «средний» */
    padding: 10px;
    border-radius: 10px;
}

.myTableDiv .draggable-table td .GRADES_RED {
    background-color: rgba(240, 128, 128, 1);
    /* Цвет для «плохой» */
    padding: 10px;
    border-radius: 10px;
}

.myTableDiv .draggable-table td .GRADES_CRITICAL {
    background-color: rgba(139, 0, 0, 1);
    /* Цвет для критического состояния */
    padding: 10px;
    border-radius: 10px;
    color: white;
}

/* Опционально, добавление тени к тексту для улучшения читаемости */

@media only screen and (max-width: 600px) {
    .myTableDiv {
        height: 70dvh !important;
    }
    .myTableDiv .draggable-table th>p>svg{
        top: 32px;
    }
}