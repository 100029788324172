.whoReadThisMessageModalContLight .whoReadThisMessageContScroll{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 12px;
    align-self: stretch;
}

.whoReadThisMessageModalContLight .whoReadThisMessageContScroll >div{
   display: flex;
padding: 12px;
justify-content: space-between;
align-items: center;
align-self: stretch;
}

.whoReadThisMessageModalContLight .whoReadThisMessageContScroll >div >div{
    display: flex;
align-items: center;
gap: 8px;
}