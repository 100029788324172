.modalEditCompany>form {
    display: flex;
    padding: 16px 0px 24px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
    align-self: stretch;
    width: 100%;
}

.modalEditCompany>form>div:first-child {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.modalEditCompany>form>div:last-child {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.modalEditCompany .bridge {
    border-radius: var(--Border-radius-L, 8px);
    background: #5E7DE8;
    display: flex;
    padding: 5px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: inline;
    color: var(--White-White, #FFF);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Inter";
    font-size: 8px;
    font-style: normal;
    font-weight: 800;
    line-height: 12px;
    margin-left: 10px;
    /* 150% */
}
.modalEditCompany .css-1eziwv{
    
    display: flex;
    align-items: center;
    align-content: center;
}