.EditemploysDark {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 10;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    -webkit-animation-name: fadeIn;
    /* Fade in the background */
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s
  }
  
  .EditemploysDark>.EditemploysModal-content {
    display: flex;
    width: 520px;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    border-radius: 12px;
    background: linear-gradient(0deg, #171B22 0%, #171B22 100%), #FFF;
    margin: auto;
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s
  }
  
  .EditemploysDark>.EditemploysModal-content>div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  }
  
  .EditemploysDark>.EditemploysModal-content>div:first-child>p {
    width: 330px;
    color: #585858;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word; /* Обеспечивает перенос длинных слов */
    overflow-wrap: break-word; /* Современный аналог word-wrap */
  }
  
  .EditemploysDark>.EditemploysModal-content>div:first-child>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  
  .EditemploysDark>.EditemploysModal-content>div:first-child>div>div {
    display: flex;
    padding: var(--Field-sizes-Field---Label-gap, 6px) 9px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    transition: 0.3s;
  }

  .EditemploysDark>.EditemploysModal-content>div:first-child>div>div > svg path{
    fill: white;
  }
  
  .EditemploysDark>.EditemploysModal-content>div:first-child>div>div:hover {
    background-color: #21262E;
    border-radius: 10px;
  
  }
  
  .EditemploysDark>.EditemploysModal-content>div:first-child>div>p {
    color:white;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
  }
  
  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0
    }
  
    to {
      top: 0;
      opacity: 1
    }
  }
  
  .EditemploysDark>.EditemploysModal-content>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
  
  .EditemploysDark>.EditemploysModal-content>div:nth-child(2)>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  }
  
  .EditemploysDark>.EditemploysModal-content>div:nth-child(2)>div>label {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    /* Field label */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
  }
  
  
  .EditemploysDark>.EditemploysModal-content>div:nth-child(2)>div>label>input,
  .EditemploysDark>.EditemploysModal-content>div:nth-child(2)>div>label>select {
    height: 44px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #32353C;
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
    overflow: hidden;
    color: #929292;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    padding: 10px;

  }
  .EditemploysDark>.EditemploysModal-content>div:nth-child(2)>div>label>select{
    cursor: pointer;
  }

  .EditemploysDark>.EditemploysModal-content>div:nth-child(2)>div>label>select >option{
    background: #21262E;
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }
  
  .EditemploysDark>.EditemploysModal-content>div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    flex-direction: row-reverse;
  }
  
  .EditemploysDark>.EditemploysModal-content>div:nth-child(3)>button:first-child {
    display: flex;
    height: 48px;
    padding: 16px;
    align-items: center;
    gap: 4.067px;
    border-radius: 16px;
    background: var(--Primary-Primary-500, #3B79F6);
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
    /* 142.857% */
  }
  
  .EditemploysDark>.EditemploysModal-content>div:nth-child(3)>button:first-child>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 6.25px 3.25px 5.936px 3.25px;
    justify-content: center;
    align-items: center;
  }
  
  .EditemploysDark>.EditemploysModal-content>div:nth-child(3)>button:last-child {
    display: flex;
    height: 48px;
    padding: 16px;
    align-items: center;
    gap: 4.067px;
    border-radius: 16px;
    border: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    color: #858C99;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
  }
  
  @media only screen and (max-width: 600px) {
    .EditemploysDark>.EditemploysModal-content{
      width: 85%;
    }
  }
  
  @media only screen and (max-width: 374px) {
    .EditemploysDark>.EditemploysModal-content>div:first-child>p{
      width: 250px;
    }
  }
  
  @media only screen and (max-width: 1134px) {
    .employeesContLight>div:nth-child(2)>div:nth-child(2)>table tbody td{
      font-size: 11px;
    }
    .employeesContLight>div:nth-child(2)>div:nth-child(2)>table thead th{
      font-size: 11px !important;
    }
  }