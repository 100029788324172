.CommentAllContDark {
    display: flex;
    height: 247px;
    padding: 0px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    align-self: stretch;
    background-color: #21262E;

}

.CommentAllContDark>div {
    display: flex;
    width: 100%;
    height: 247px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

}

.CommentAllContDark>div>div {
    display: flex;
    height: 350px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex-shrink: 0;
    align-self: stretch;

}

.CommentAllContDark>div>div>div:first-child {
    display: flex;
    height: 137px;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
    flex-shrink: 0;
    align-self: stretch;

}

.CommentAllContDark>div>div>div:first-child>div {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    flex: 1 0 0;
    align-self: stretch;
}

.CommentAllContDark>div>div>div:first-child>div>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: #767676;
    text-overflow: ellipsis;
    /* Field label */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

.CommentAllContDark>div>div>div:first-child>div>div {
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(255, 255, 255, 0.10);
    background: #21262E;
    z-index: 1;

}

.CommentAllContDark>div>div>div:first-child>div>div>div:first-child {
    max-height: 150px;
    overflow: auto;
    width: 100%;
}

/* width */
.CommentAllContDark>div>div>div:first-child>div>div>div:first-child::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.CommentAllContDark>div>div>div:first-child>div>div>div:first-child::-webkit-scrollbar-track {
    background: #21262E;
}

/* Handle */
.CommentAllContDark>div>div>div:first-child>div>div>div:first-child::-webkit-scrollbar-thumb {
    background: #C9C9C9;
}

/* Handle on hover */
.CommentAllContDark>div>div>div:first-child>div>div>div:first-child::-webkit-scrollbar-thumb:hover {
    background: #C9C9C9;
    cursor: pointer;
}



.CommentAllContDark>div>div>div:first-child>div>div>div>textarea {
    width: 100%;
    outline: none;
    height: auto;
    overflow: hidden;
    /* Убираем скролл */
    resize: none;
    /* Убираем возможность изменения размера */
    background: #21262E;
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}

.CommentAllContDark .commentOptions {
    display: flex;
    width: 100%;
    height: 24px;
    justify-content: flex-end;
    gap: 12px;
}

.CommentAllContDark .commentOptions>label {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: #21262E;
}

.CommentAllContDark .commentOptions svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.CommentAllContDark .commentOptions>div {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: #21262E;
}


.CommentAllContDark .commentScrollDiv {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    height: 200px;
    overflow: auto;
    justify-content: space-between;
    padding: 20px 0;

}


/* width */
.CommentAllContDark .commentScrollDiv::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.CommentAllContDark .commentScrollDiv::-webkit-scrollbar-track {
    background: #21262E;
}

/* Handle */
.CommentAllContDark .commentScrollDiv::-webkit-scrollbar-thumb {
    background: #C9C9C9;
}

/* Handle on hover */
.CommentAllContDark .commentScrollDiv::-webkit-scrollbar-thumb:hover {
    background: #C9C9C9;
    cursor: pointer;
}





.CommentAllContDark .CommentListCont {
    display: flex;
    flex-direction: column;
}

.CommentAllContDark .localFilesRender {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.CommentAllContDark .CommentItem {
    display: flex;
    width: 100%;
    align-items: flex-start;

}

.CommentAllContDark .CommentItem>div {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
}

.CommentAllContDark .CommentItem>div>img {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 32px;

}

.CommentAllContDark .CommentItem>div>.firstDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;

}


.CommentAllContDark .CommentItem>div>.firstDiv>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}


.CommentAllContDark .CommentItem>div>.firstDiv>div>div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.CommentAllContDark .CommentItem>div>.firstDiv>div>div:first-child>p:first-child {
    color: white;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}


.CommentAllContDark .CommentItem>div>.firstDiv>div>div:first-child>.commentItemTime {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #858C99;
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 200% */
}

.CommentAllContDark .CommentItem>div>.firstDiv>div>div:last-child {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
}

.CommentAllContDark .CommentItem>div>.firstDiv>div>div:last-child>div p {
    flex: 1 0 0;
    color: #767676;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 600px;
    text-wrap: wrap;
    /* 166.667% */
}



.CommentAllContDark .lastDiv {
    display: flex;
    width: 54px;
    height: 54px;
    padding: 0px 16px;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    border-right: var(--Overlay-border-width, 1px) solid rgba(136, 136, 136, 0.10);
    justify-content: center;
    align-items: center;
    align-content: center;
}
.CommentAllContDark .lastDiv:hover{
    cursor: pointer;
}
.CommentAllContDark .lastDiv>svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.CommentAllContDark .fileCommentFiles {
    display: flex;
    height: 65px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}




.CommentAllContDark .FilRenderCreat {
    display: flex;
    align-items: center;
    gap: 13px;
  
  }
  
  .CommentAllContDark .FilRenderCreat>div:first-child {
    display: flex;
    width: 169.375px;
    height: 140px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  
  }
  
  .CommentAllContDark .FilRenderCreat>div:first-child img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: var(--Border-radius-L, 8px);
    filter: brightness(80%);
  }
  
  .CommentAllContDark .FilRenderCreat>div:first-child>div {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    padding: 12px 13.375px 12px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 44px;
    flex: 1 0;
    align-self: stretch;
    width: 100%;
    height: 100%;
    flex-direction: column-reverse;
    border-radius: 12px !important;
  }
  
  .CommentAllContDark .FilRenderCreat>div:first-child>div>div:first-child {
    display: flex;
    width: 144px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0;
  }
  
  .CommentAllContDark .FilRenderCreat>div:first-child>div>div:first-child>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: #FFF;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 161.104%;
  }
  
  
  .CommentAllContDark .FilRenderCreat>div:first-child>div>div:first-child>div {
    display: flex;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
  }
  
  .CommentAllContDark .FilRenderCreat>div:first-child>div>div:first-child>div>p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }
  
  .CommentAllContDark .FilRenderCreat>div:first-child>div>div:last-child {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .CommentAllContDark .FilRenderCreat>div:first-child>div>div:last-child>div:first-child {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .CommentAllContDark .FilRenderCreat>div:first-child>div>div:last-child>div:first-child>div:first-child {
    display: flex;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: var(--Border-radius-L, 8px);
    background: #F7F8FA;
    cursor: pointer;
  }
  
  .CommentAllContDark .FilRenderCreat>div:first-child>div>div:last-child>div:first-child>div:last-child {
    display: flex;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: var(--Border-radius-L, 8px);
    background: #F7F8FA;
    cursor: pointer;
  }
  


.CommentAllContDark .editIamgeCont {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: #21262E;

}

.popoverCommentDark {
    padding: 0 !important;
    border: none !important;
}

.popoverCommentDark .chakra-popover__body {
    display: flex;
    flex-direction: column;
    gap: 10;
    background-color: #272C34;
}

.popoverCommentDark .chakra-popover__body>div {
    display: flex;
padding: 5px 4px;
align-items: flex-start;
gap: 8px;
align-items: center;
align-content: center;
}


.popoverCommentDark .chakra-popover__body >div > p{
    color: white;
    /* Field label */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px; /* 121.429% */
    }





      @media only screen and (max-width: 600px) {
        .CommentAllContDark .commentScrollDiv {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
            max-height: 100px; /* Ограничивает высоту контейнера */
            overflow-y: scroll !important; /* Включает вертикальный скролл */
            justify-content: space-between;
            padding: 20px 0;
        }
    
        .CommentAllContDark .CommentItem > div > .firstDiv > div > div:last-child > div {
          width: 100%; /* Устанавливает ширину контейнера на 100% */
          max-width: 300px; /* Задайте максимальную ширину контейнера */
          overflow-wrap: break-word; /* Позволяет переносить длинные слова */
          word-wrap: break-word; /* Поддержка старых браузеров */
        }
    }
    
    
    @media only screen and (max-width: 520px) {
    
      .CommentAllContDark .CommentItem > div > .firstDiv > div > div:last-child > div {
        width: 100%; /* Устанавливает ширину контейнера на 100% */
        max-width: 200px; /* Задайте максимальную ширину контейнера */
        overflow-wrap: break-word; /* Позволяет переносить длинные слова */
        word-wrap: break-word; /* Поддержка старых браузеров */
      }
    }
    
    
    
    @media only screen and (max-width: 400px) {
    
      .CommentAllContDark .CommentItem > div > .firstDiv > div > div:last-child > div {
        width: 100%; /* Устанавливает ширину контейнера на 100% */
        max-width: 150px; /* Задайте максимальную ширину контейнера */
        overflow-wrap: break-word; /* Позволяет переносить длинные слова */
        word-wrap: break-word; /* Поддержка старых браузеров */
      }
    }
    
    
    @media only screen and (max-width: 364px) {
    
      .CommentAllContDark .CommentItem > div > .firstDiv > div > div:last-child > div {
        width: 100%; /* Устанавливает ширину контейнера на 100% */
        max-width: 80px; /* Задайте максимальную ширину контейнера */
        overflow-wrap: break-word; /* Позволяет переносить длинные слова */
        word-wrap: break-word; /* Поддержка старых браузеров */
      }
    }


    @media only screen and (max-width: 337px) {

        .CommentAllContDark .CommentItem > div > .firstDiv > div > div:last-child > div {
          width: 100%; /* Устанавливает ширину контейнера на 100% */
          max-width: 40px; /* Задайте максимальную ширину контейнера */
          overflow-wrap: break-word; /* Позволяет переносить длинные слова */
          word-wrap: break-word; /* Поддержка старых браузеров */
        }
      }