.MycompanyContApi {
    display: flex;
    width: 97%;
    margin:1% auto;
    padding: 16px 0px 24px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
    border-radius: 12px;
    background: #FFF;
}

.MycompanyContApi .tabs-header{
    display: flex;
    padding: 0px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}

.MycompanyContApi .tabs-header >div{
    display: flex;
align-items: flex-start;
gap: 24px;

}

.MycompanyContApi .tabs-header >div > button{
    display: flex;
padding-top: 10px;
flex-direction: column;
align-items: flex-start;
gap: 12px;

}

.MycompanyContApi .tabs-header >div > button > p{
    color: var(--Black-Black-48, rgba(17, 17, 17, 0.48));
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}


.MycompanyContApi .tabs-header >div > .tab-button.active > span{
    height: 2px;
align-self: stretch;
background: #3B79F6;
}

.MycompanyContApi .tabs-header >div > .tab-button.active >p {
    color: #3B79F6;
}

  


.MycompanyContApi>div {
    width: 100%;
    padding: 24px 16px;
}

.MycompanyContApi .wildberiscont,
.MycompanyContApi .ozoncont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
    align-self: stretch;
}

.MycompanyContApi .wildberiscont>div,
.MycompanyContApi .ozoncont>div {
    width: 100% !important;
}

.MycompanyContApi .wildberiscont .changeApiContChild1,
.MycompanyContApi .ozoncont .changeApiContChild1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
    align-self: stretch;
}

.MycompanyContApi .wildberiscont .changeApiContChild1>p,
.MycompanyContApi .ozoncont .changeApiContChild1>p {
    color: #353535;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 100% */
}

.MycompanyContApi .wildberiscont .changeApiContChild1>.forBorder,
.MycompanyContApi .ozoncont .changeApiContChild1>.forBorder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
    align-self: stretch;
}
.MycompanyContApi .wildberiscont .changeApiContChild1>.forBorder > p,
.MycompanyContApi .ozoncont .changeApiContChild1>.forBorder > p{
    display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1;
align-self: stretch;
overflow: hidden;
color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
text-overflow: ellipsis;

/* Field label */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 17px; /* 121.429% */
}
.MycompanyContApi .wildberiscont .changeApiContChild1>.forBorder>label,
.MycompanyContApi .ozoncont .changeApiContChild1>.forBorder>label {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
}

.MycompanyContApi .wildberiscont .changeApiContChild1>.forBorder>label>p,
.MycompanyContApi .ozoncont .changeApiContChild1>.forBorder>label>p {
    display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1;
align-self: stretch;
overflow: hidden;
color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
text-overflow: ellipsis;

/* Field label */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 17px; /* 121.429% */
}


.MycompanyContApi .wildberiscont .changeApiContChild1>.forBorder>label>input,
.MycompanyContApi .ozoncont .changeApiContChild1>.forBorder>label>input {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    width: 100%;
    overflow: hidden;
    color: #353535;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
}

.MycompanyContApi .wildberiscont .changeApiContChild1>.forBorder>label>input:disabled,
.MycompanyContApi .ozoncont .changeApiContChild1>.forBorder>label>input:disabled{
    cursor: not-allowed;
    opacity: 0.6;
}

.MycompanyContApi .wildberiscont .changeApiContChild1>.forBorder>button,
.MycompanyContApi .ozoncont .changeApiContChild1>.forBorder>button {
    display: flex;
    height: 48px;
    padding: 16px;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: #3B79F6;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.MycompanyContApi .wildberiscont .changeApiContChild1>.forBorder>button:disabled,
.MycompanyContApi .ozoncont .changeApiContChild1>.forBorder>button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.MycompanyContApi .wildberiscont .changeApiContChild1>.forBorder>button>div,
.MycompanyContApi .ozoncont .changeApiContChild1>.forBorder>button>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.199px 2.323px 1.82px 1.199px;
    justify-content: center;
    align-items: center;
}
