.notifyCont {
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border: var(--Field-sizes-Field-border-width, 1px) solid var(--shades-contrast-10, rgba(26, 56, 96, 0.10));
    background: #F8FBFF;
}

.notifyCont>div:first-child {
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.notifyCont> .perFinfo{
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.notifyCont> .perFinfo>div:nth-child(2) {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    flex: 1 0 0;
}





.notifyCont>div:first-child>div:nth-child(2) {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    flex: 1 0 0;
}


.notifyCont> .perFinfo>div:nth-child(2)>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    cursor: pointer;
    /* 121.429% */
}

.notifyCont>div:first-child>div:nth-child(2)>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    cursor: pointer;
    /* 121.429% */
}


.notifyCont>.perFinfo>div:nth-child(2)>input {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    overflow: hidden;
    color: #353535;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

}

.notifyCont>div:first-child>div:nth-child(2)>input {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    overflow: hidden;
    color: #353535;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

}


.notifyCont>.perFinfo>div:nth-child(3) {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    flex: 1 0 0;
}
.notifyCont>div:first-child>div:nth-child(3) {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    flex: 1 0 0;
}

.notifyCont>.perFinfo>div:nth-child(3)>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    cursor: pointer;
    /* 121.429% */
}

.notifyCont>div:first-child>div:nth-child(3)>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    cursor: pointer;
    /* 121.429% */
}
/* ============ */

.notifyCont>.perFinfo>div:nth-child(3)>div {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;

}

.notifyCont>.perFinfo>div:nth-child(3)>div>div {
    display: flex;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
}

.notifyCont>.perFinfo>div:nth-child(3)>div>div>input {
    background-color: white;
    overflow: hidden;
    color: #353535;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}

/* ------ */
.notifyCont>div:first-child>div:nth-child(3)>div {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;

}

.notifyCont>div:first-child>div:nth-child(3)>div>div {
    display: flex;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
}

.notifyCont>div:first-child>div:nth-child(3)>div>div>input {
    background-color: white;
    overflow: hidden;
    color: #353535;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}

.notifyCont>div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.notifyCont>div:last-child>div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}


.notifyCont>div:last-child>div:first-child>div {
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.notifyCont>div:last-child>div:first-child>div>div {
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.notifyCont>div:last-child>div:first-child>div>div>div {
    display: flex;
    width: 223px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
}

.notifyCont>div:last-child>div:first-child>div>div>p {
    align-self: stretch;
    color: #353535;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125.303%;
    /* 20.048px */
}

.notifyCont>div:last-child>div:first-child>p {
    align-self: stretch;
    color: #353535;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 161.104%;
    /* 25.777px */
}


.notifyCont>div:last-child>div:first-child>a {
    align-self: stretch;
    color: #353535;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 161.104%;
    /* 25.777px */
}

.notifyCont>div:last-child>div:first-child>p>a {
    align-self: stretch;
    color: #3B79F6;
    font-family: "Inter";
    font-size: 16px;
    font-style: bold;
    font-weight: 800;
    line-height: 161.104%;
    text-decoration: underline;

    /* 25.777px */
}

.notifyCont>div:last-child>div:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}


.notifyCont>div:last-child>div:last-child>button:first-child {
    display: flex;
    width: 102px;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: var(--Border-radius-M, 4px);
    background: rgba(25, 59, 103, 0.05);
}

.notifyCont>div:last-child>div:last-child>button:first-child>p {
    color: var(--Primary-Primary-500, #3B79F6);
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.notifyCont>div:last-child>div:last-child>button:last-child {
    display: flex;
    width: 32px;
    height: 32px;
    padding: var(--Field-sizes-Field---Label-gap, 6px) 9px;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.notifyCont>div:last-child>div:last-child>button:last-child>svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}




.notifyCont>div:last-child>div:last-child>.confirmBtnNotify {
    width: 100%;
    display: flex;
    gap: 20px;
}

.notifyCont>div:last-child>div:last-child>.confirmBtnNotify>.confirm {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: rgba(34, 197, 94, 0.05);
    color: #22C55E;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.notifyCont>div:last-child>div:last-child>.confirmBtnNotify>.cancel {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: rgba(235, 87, 87, 0.05);
    color: #EB5757;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}


.notifyCont>div:last-child>div:last-child>.confirmBtnNotify>.canceled {
    display: flex;
    height: 33px;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: #FFF;

}

.notifyCont>div:last-child>div:last-child>.confirmBtnNotify>.canceled>p {
    color: #EB5757;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.notifyCont>div:last-child>div:last-child>.confirmBtnNotify>.confirmed {
    display: flex;
    height: 33px;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: #FFF;

}

.notifyCont>div:last-child>div:last-child>.confirmBtnNotify>.confirmed>p {
    color: #22C55E;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.notifyCont>div:last-child>div:last-child>.confirmBtnNotify>.noReadBtn {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: rgba(25, 59, 103, 0.05);

}

.notifyCont>div:last-child>div:last-child>.confirmBtnNotify>.noReadBtn>p {
    color: var(--Primary-Primary-500, #3B79F6);
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.notifyDrowerLight>.chakra-modal__body {
    padding: 0;
}


.notifyDrowerLight>.chakra-modal__body .dropdown-menuNotifyCont>div:first-child {
    border-top: none;

}


@media only screen and (max-width: 600px) {
    .notifyCont>div:first-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
    }

    .notifyCont>.perFinfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
    }
    .notifyDrowerLight .chakra-modal__header>.Headertitle {
        display: flex;
        padding: 24px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        background: #FFF;
    }


    .notifyCont>div:first-child>div:nth-child(2) {
        width: 100% !important;
    }

    .notifyCont>div:first-child>div:nth-child(3) {
        width: 100%;
    }

    .notifyCont>div:last-child>div:last-child>.confirmBtnNotify {
        display: flex;
        flex-wrap: wrap;
    }



    .notifyCont>.perFinfo>div:nth-child(2) {
        width: 100% !important;
    }

    .notifyCont>.perFinfo>div:nth-child(3) {
        width: 100%;
    }

    .notifyCont>.perFinfo>div:last-child>.confirmBtnNotify {
        display: flex;
        flex-wrap: wrap;
    }
}