.main-containerSucsses {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* Изменено с absolute на fixed */
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%); /* Центрируем контейнер */
    z-index: 10;
  }
  
  .check-containerSucsses {
    width: 100px;
    height: 100px;
    position: relative;
  }
  
  .check-backgroundSucsses {
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #5de593, #41d67c);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    transform: scale(0.8);
    opacity: 0;
    animation: animateContainer 0.75s ease-out forwards 0.5s;
  }
  
  .check-backgroundSucsses svg {
    width: 60%;
    height: auto;
    stroke-dasharray: 80;
    stroke-dashoffset: 80;
    animation: animateCheck 0.5s ease-out forwards 1s;
  }
  
  /* Анимация контейнера */
  @keyframes animateContainer {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    50% {
      opacity: 1;
      transform: scale(1.1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  /* Анимация галочки */
  @keyframes animateCheck {
    from {
      stroke-dashoffset: 80;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  