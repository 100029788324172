.ToggleAllMyProjectContDark {
    display: flex;
    padding: 12px 16px;
    justify-content: space-between !important;
    align-items: center !important;
    align-items: center !important;
  
    border-radius: 12px;
    background: #21262E;

    margin: 0 16px
}

.ToggleAllMyProjectContDark>div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.ToggleAllMyProjectContDark>div:first-child>p {
    color: white;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.ToggleAllMyProjectContDark>div:last-child {
    display: flex;
    width: 32px;
    height: 19.451px;
    justify-content: center;
    align-items: center;
}