.modalPerfomerDark {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 10;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */

}

/* Modal Content */
.modalPerfomerDark .modal-contentPerfomer {
  position: relative;
  background-color: #171B22;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 16px;
  width: 429px;
  border-radius: 12px;

  margin: auto;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;

}

@media only screen and (max-width: 600px) {
  .modalPerfomerDark .modal-contentPerfomer{
    width: 100%;
  }
}



.modalPerfomerDark .modal-contentPerfomer>.modal-headerPerfomer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.modalPerfomerDark .modal-contentPerfomer>.modal-headerPerfomer>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.modalPerfomerDark .modal-contentPerfomer>.modal-headerPerfomer>div p {
  color: white !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 100% */
}

.modalPerfomerDark .modal-contentPerfomer>.modal-headerPerfomer>div>div {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modalPerfomerDark .modal-contentPerfomer>.modal-headerPerfomer>div>div>svg {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}

.modalPerfomerDark .modal-contentPerfomer>.modal-headerPerfomer>div>div>svg > path{
  fill: white;
  stroke: white;
}
.modalPerfomerDark .modal-contentPerfomer>.modal-headerPerfomer>label {
  display: flex;
  height: 48px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  border: var(--Overlay-border-width, 1px) solid rgba(255, 255, 255, 0.10);
  background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
}

.modalPerfomerDark .modal-contentPerfomer>.modal-headerPerfomer>label>input {
  border: none;
  outline: none;
  background-color: #21262E;
  color: white;
}

.modalPerfomerDark .modal-contentPerfomer>.modal-headerPerfomer>label>input::placeholder{
  color: white;
}

.modalPerfomerDark .modal-contentPerfomer>.modal-bodyPerfomer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.modalPerfomerDark .modal-contentPerfomer>.modal-bodyPerfomer>.executorCont {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 4px;
  align-self: stretch;
  overflow-y: auto;
  min-height: 200px;
  max-height: 400px;
  width: 100%;
}

.modalPerfomerDark .modal-contentPerfomer>.modal-bodyPerfomer>.executorCont>.executor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 4px;
  align-self: stretch;
}

.modalPerfomerDark .modal-contentPerfomer>.modal-bodyPerfomer>.executorCont>.executor .chekedDivFalse {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  border: 0px solid var(--Components-Field-border, rgba(28, 48, 74, 0.52));
  background: var(--Components-Checkbox-background, rgba(28, 55, 90, 0.16));
}

.modalPerfomerDark .modal-contentPerfomer>.modal-bodyPerfomer>.executorCont>.executor .chekedDivTrue {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #3B79F6;
}

.modalPerfomerDark .modal-contentPerfomer>.modal-bodyPerfomer>.executorCont>.executor .chekedDivTrue svg {
  width: 10.667px;
  height: 7.944px;
  flex-shrink: 0;
  fill: var(--semantic-colors-primary-contrast, #FFF);
}

.modalPerfomerDark .modal-contentPerfomer>.modal-bodyPerfomer>.executorCont>.executor>div {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--Border-radius-L, 8px);
  border: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
  background: #21262E;
  width: 100%;
  cursor: pointer;
}

.modalPerfomerDark .modal-contentPerfomer>.modal-bodyPerfomer>.executorCont>.executor>div .perfName {
  color: white;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
}

.modalPerfomerDark .modal-contentPerfomer>.modal-bodyPerfomer>.executorCont>.executor>div .perfTel {
  color: white;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
}

.modalPerfomerDark .modal-contentPerfomer>.modal-footerPerfomer {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.modalPerfomerDark .modal-contentPerfomer>.modal-footerPerfomer>button:first-child {
  display: flex;
  height: 48px;
  padding: 16px;
  align-items: center;
  gap: 4.067px;
  border-radius: 16px;
  border: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
  color: #858C99;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.modalPerfomerDark .modal-contentPerfomer>.modal-footerPerfomer>button:last-child {
  display: flex;
  height: 48px;
  padding: 16px;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  background: #3B79F6;
  color: #FFF;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

.modalPerfomerDark .modal-contentPerfomer>.modal-footerPerfomer>button:last-child>div:first-child {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 1.25px;
  justify-content: center;
  align-items: center;
}

.modalPerfomerDark .modal-contentPerfomer>.modal-footerPerfomer>button:last-child>div:first-child>svg {
  width: 21.5px;
  height: 21.5px;
  flex-shrink: 0;
}

.modalPerfomerDark .modal-contentPerfomer>.modal-footerPerfomer>button:last-child>div:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.modalPerfomerDark .modal-contentPerfomer>.modal-footerPerfomer>button:last-child>div:last-child>p {
  color: #FFF;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
}

.selectPerformerDark .perfomerOpenModal {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  background: #3B79F6;
}


.selectPerformerDark .perfomerOpenModal>div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.selectPerformerDark .perfomerOpenModal>div>div {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 1.25px;
  justify-content: center;
  align-items: center;
}

.selectPerformerDark .perfomerOpenModal>div>p {
  color: #FFF;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
}






.selectPerformerDark {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  background: #21262E;
  width: 100%;
}

.selectPerformerDark>div {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  height: 100%;
}

.selectPerformerDark>div >p{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
text-overflow: ellipsis;
display: none;
/* Field label */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 17px; /* 121.429% */
}


@media only screen and (max-width: 608px) {
  .selectPerformerDark>div >p{
    display: flex;
    color: #767676;
  }
  .forDekstop{
    display: none;
  }
  .selectPerformerDark{
    flex-direction: column;
  }
.ChangedirectorEdit{
  border-bottom: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
  width: 100%;
}
.selectPerformerDark>div>div >p{
  display: none !important;
}
}

.selectPerformerDark>div>div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-direction: column;
}

.selectPerformerDark>div>div>div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}


.selectPerformerDark>div>div >p{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: #76766B;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;

}

.selectPerformerDark>div>div>div>.perfName {
  color: white;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  /* 121.429% */
}


.selectPerformerDark>div>div>div>.perfTel {
  color: #353535;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  /* 121.429% */
}

.selectPerformerDark>div>div>div>div {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.selectPerformerDark>div>div>div>div>div {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: var(--Border-radius-L, 8px);
  background: #171B22;
}

.selectPerformerDark>div>div>div>div>p {
  color: #DD5E98;

  /* Field label */
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  /* 121.429% */
}