/* Общие стили для узлов */
.react-flow .react-flow__node {
  height: 150px; /* Высота узла */
  width: 150px; /* Ширина узла */
  justify-content: center; /* Центрирование содержимого по горизонтали */
  align-items: center; /* Центрирование содержимого по вертикали */
  display: flex; /* Flexbox для расположения элементов внутри узла */
  border: 2px solid #3B79F6; /* Синий контур узла */
  border-radius: 10px; /* Закругление углов */
  background-color: #fff; /* Цвет фона узла */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Легкая тень для глубины */
  transition: transform 0.3s; /* Анимация при наведении */
}

/* Эффект при наведении на узел */
.react-flow .react-flow__node:hover {
  transform: scale(1.05); /* Увеличение узла при наведении */
}

/* Стили для ручек соединения */
.react-flow .react-flow__handle {
  border-radius: 100%; /* Круглая форма ручек */
  background-color: #3B79F6; /* Синий цвет ручек */
  width: 10px; /* Ширина ручки */
  height: 10px; /* Высота ручки */
  border: 2px solid white; /* Белая рамка вокруг ручки */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Легкая тень для глубины */
}

/* Позиционирование верхней и нижней ручек */
.react-flow .react-flow__handle-top {
  top: -10px; /* Смещение вверх */
}

.react-flow .react-flow__handle-bottom {
  bottom: -10px; /* Смещение вниз */
}

/* Стили для ребер соединения */
.react-flow .react-flow__edge path,
.react-flow__connectionline path {
  stroke: #3B79F6; /* Синий цвет ребер */
  stroke-width: 2; /* Ширина линии */
  transition: stroke-dasharray 0.3s; /* Анимация для соединений */
}

/* Стили для контейнера */
.wrapper {
  flex-grow: 1; /* Заполнение доступного пространства */
  height: calc(100dvh - 124px);
 
}

/* Стили для пользовательских узлов */
.custom-node {
  background-color: #ffefef; /* Светло-красный фон */
  border: 1px solid #ff6f61; /* Красная рамка */
  border-radius: 10px; /* Закругление углов */
  padding: 10px; /* Отступы внутри узла */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Тень для глубины */
  transition: background-color 0.3s; /* Анимация при наведении */
}

/* Эффект при наведении на пользовательский узел */
.custom-node:hover {
  background-color: #ffcccb; /* Изменение цвета фона при наведении */
}
