.notifyContDark {
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border: var(--Field-sizes-Field-border-width, 1px) solid var(--shades-contrast-10, rgba(26, 56, 96, 0.10));
    background: #1B1E25;
}

.notifyContDark>div:first-child {
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}


.notifyContDark> .perFinfo{
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}


.notifyContDark> .perFinfo>div:nth-child(2) {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    flex: 1 0 0;
}


.notifyContDark>div:first-child>div:nth-child(2) {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    flex: 1 0 0;
}



.notifyContDark> .perFinfo>div:nth-child(2)>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: #767676;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    cursor: pointer;
}
.notifyContDark>div:first-child>div:nth-child(2)>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: #767676;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
    cursor: pointer;
}


.notifyContDark>.perFinfo>div:nth-child(2)>input {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Overlay-border-width, 1px) solid rgba(255, 255, 255, 0.10);
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
    overflow: hidden;
    color: white !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
}


.notifyContDark>.perFinfo>div:nth-child(2)>input::placeholder{
    color: white;
}


.notifyContDark>div:first-child>div:nth-child(2)>input {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Overlay-border-width, 1px) solid rgba(255, 255, 255, 0.10);
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
    overflow: hidden;
    color: #FFF;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
}


.notifyContDark>.perFinfo>div:nth-child(3) {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    flex: 1 0 0;
}
.notifyContDark>div:first-child>div:nth-child(3) {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    flex: 1 0 0;
}

.notifyContDark>.perFinfo>div:nth-child(3)>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: #767676;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    cursor: pointer;
    /* 121.429% */
}

.notifyContDark>div:first-child>div:nth-child(3)>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: #767676;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    cursor: pointer;
    /* 121.429% */
}

.notifyContDark>.perFinfo>div:nth-child(3)>div {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Overlay-border-width, 1px) solid rgba(255, 255, 255, 0.10);
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
}

.notifyContDark>.perFinfo>div:nth-child(3)>div>div {
    display: flex;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
}

.notifyContDark>.perFinfo>div:nth-child(3)>div>div>input {
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
    overflow: hidden;
color: #929292;
text-overflow: ellipsis;
white-space: nowrap;
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 157.143% */
}

.notifyContDark>.perFinfo>div:nth-child(3)>div>div>svg path{
    fill: white;
}

.notifyContDark>div:first-child>div:nth-child(3)>div {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Overlay-border-width, 1px) solid rgba(255, 255, 255, 0.10);
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;


}

.notifyContDark>div:first-child>div:nth-child(3)>div>div {
    display: flex;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
}

.notifyContDark>div:first-child>div:nth-child(3)>div>div > svg > path{
    fill: white;
}

.notifyContDark>div:first-child>div:nth-child(3)>div>div>input {
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
    overflow: hidden;
color: #929292;
text-overflow: ellipsis;
white-space: nowrap;
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 157.143% */
}

.notifyContDark>div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.notifyContDark>div:last-child>div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}


.notifyContDark>div:last-child>div:first-child>div {
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.notifyContDark>div:last-child>div:first-child>div>div {
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.notifyContDark>div:last-child>div:first-child>div>div>div {
    display: flex;
    width: 223px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
}

.notifyContDark>div:last-child>div:first-child>div>div>p {
    align-self: stretch;
    color: white;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125.303%;
    /* 20.048px */
}

.notifyContDark>div:last-child>div:first-child>p {
    align-self: stretch;
    color: rgba(255, 255, 255, 0.94);
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 161.104%; /* 25.777px */
}


.notifyContDark>div:last-child>div:first-child>a {
    align-self: stretch;
    color: #353535;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 161.104%;
    /* 25.777px */
}

.notifyContDark>div:last-child>div:first-child>p > a{
    align-self: stretch;
    color: #3B79F6;
    font-family: "Inter";
    font-size: 16px;
    font-style: bold;
    font-weight: 800;
    line-height: 161.104%;
    text-decoration: underline;
    
    /* 25.777px */
}
.notifyContDark>div:last-child>div:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}


.notifyContDark>div:last-child>div:last-child>button:first-child {
    display: flex;
    width: 102px;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: var(--Border-radius-M, 4px);
    background: #21262E;
}

.notifyContDark>div:last-child>div:last-child>button:first-child>p {
    color: var(--Primary-Primary-500, #3B79F6);
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.notifyContDark>div:last-child>div:last-child>button:last-child {
    display: flex;
    width: 32px;
    height: 32px;
    padding: var(--Field-sizes-Field---Label-gap, 6px) 9px;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.notifyContDark>div:last-child>div:last-child>button:last-child>svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.notifyContDark>div:last-child>div:last-child>button:last-child>svg > path{
    fill: white;
}




.notifyContDark>div:last-child>div:last-child>.confirmBtnNotify {
    width: 100%;
    display: flex;
    gap: 20px;
}

.notifyContDark>div:last-child>div:last-child>.confirmBtnNotify>.confirm {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: rgba(34, 197, 94, 0.05);
    color: #22C55E;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.notifyContDark>div:last-child>div:last-child>.confirmBtnNotify>.cancel {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: rgba(235, 87, 87, 0.05);
    color: #EB5757;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}


.notifyContDark>div:last-child>div:last-child>.confirmBtnNotify>.canceled {
    display: flex;
    height: 33px;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
background: #FFF;

}

.notifyContDark>div:last-child>div:last-child>.confirmBtnNotify>.canceled >p{
    color: #EB5757;
text-align: center;
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.notifyContDark>div:last-child>div:last-child>.confirmBtnNotify>.confirmed {
    display: flex;
height: 33px;
padding: 8px 14px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: 12px;
background: #FFF;

}

.notifyContDark>div:last-child>div:last-child>.confirmBtnNotify>.confirmed > p{
    color: #22C55E;
text-align: center;
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.notifyContDark>div:last-child>div:last-child>.confirmBtnNotify>.noReadBtn {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: rgba(25, 59, 103, 0.05);

}

.notifyContDark>div:last-child>div:last-child>.confirmBtnNotify>.noReadBtn > p{
    color: var(--Primary-Primary-500, #3B79F6);
text-align: center;
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.notifyDrowerDark >  .chakra-modal__body{
   padding: 0;
}


.notifyDrowerDark >  .chakra-modal__body .dropdown-menuNotifyCont >div:first-child{
    border-top: none;
   
}


@media only screen and (max-width: 600px) {
    .notifyContDark>div:first-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
    }

    .notifyDrowerDark .chakra-modal__header>.Headertitle {
        display: flex;
        padding: 24px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        background: #1B1E25;
    }


    .notifyContDark>div:first-child>div:nth-child(2) {
        width: 100% !important;
    }

    .notifyContDark>div:first-child>div:nth-child(3) {
        width: 100%;
    }

    .notifyContDark>div:last-child>div:last-child>.confirmBtnNotify {
        display: flex;
        flex-wrap: wrap;
    }
}