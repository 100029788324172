.bannErcontLight {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    height: 250px;
    width: 100%;
    border-radius: 12px;
    background: #EEF5FF;
}

.bannErcontLight .imgCont {
    width: 243px;
    height: 144px;
    border-radius: 12px;
    background: url("./leftMenuBanner.png") 50% / cover no-repeat, #FFF;
}

.bannErcontLight>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}


.bannErcontLight>div:nth-child(2)>p:nth-child(1) {
    color: var(--Base-Colors-Black, #000);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}


.bannErcontLight>div:nth-child(2)>p:nth-child(2) {
    width: 243px;
    color: #353535;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 141.667% */
}

.bannErcontLight>div:nth-child(3) {

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

}

.bannErcontLight>div:nth-child(3)>div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.bannErcontLight>div:nth-child(3)>div:nth-child(1) > svg{
    display: flex;
width: 24px;
height: 24px;
justify-content: center;
align-items: center;
}

.bannErcontLight>div:nth-child(3) > div:nth-child(1) >p{
    color: var(--Primary-Primary-500, #3B79F6);
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */

}

.bannErcontLight>div:nth-child(3)>div:nth-child(2) > p{
    color: #BDBDBD;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    cursor: pointer;
    
}