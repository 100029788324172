.WhoCreatedTheConversationDarkCont {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 20px;
}


.WhoCreatedTheConversationDarkCont>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: var(--Field-sizes-Field-border-width, 0px);
    opacity: var(--Overlay-border-width, 1);
}

.WhoCreatedTheConversationDarkCont .chatDateIsCreat {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 24px;
    opacity: var(--Overlay-border-width, 1);
    background: #21262E;
}

.WhoCreatedTheConversationDarkCont  .chatDateIsCreat p {

    margin: 0 !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
    opacity: var(--Overlay-border-width, 1);
}
 
.WhoCreatedTheConversationDarkCont  .whoCreatThisChat {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 4px;
    border-radius: 24px;
    opacity: var(--Overlay-border-width, 1);
}

.WhoCreatedTheConversationDarkCont  .whoCreatThisChat p {
    margin: 0 !important;
}

.WhoCreatedTheConversationDarkCont  .whoCreatThisChatName{
    color: var(--Gray-500, #6A7180);
font-feature-settings: 'clig' off, 'liga' off;

/* Extra Bold 800/12 */
font-family: "Inter";
font-size: 12px;
font-style: normal;
font-weight: 800;
line-height: 20px; /* 166.667% */
opacity: var(--Overlay-border-width, 1);
}


.WhoCreatedTheConversationDarkCont  .whoCreatThisChatText{
    color: var(--Gray-400, #858C99);
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Inter";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
opacity: var(--Overlay-border-width, 1);
}