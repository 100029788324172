.filterBntDark {
    display: flex;
    padding: 0px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 12px;
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;


}

@media only screen and (max-width: 842px) {
    .filterBntDark {
        height: 48px;
    }
}

.filterBntDark>div:first-child {
    display: flex;
    align-items: center;
    gap: 4px;
}

.filterBntDark>div:first-child>p {
    overflow: hidden;
    color: #FFF;

    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}


.filterBntDark>div:last-child {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 2.25px 3.25px;
    justify-content: center;
    align-items: center;
}

.filterBntDark>div:last-child>svg path {
    stroke: white;
    fill: white;
}

.filetContDark .chakra-modal__body {
    padding: 0;
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    border: 1px solid var(--shades-contrast-10, rgba(26, 56, 96, 0.10));
    background: #171A20;
}


/* width */

.filetContDark .chakra-modal__header {
    background: #171A20;
    color: white;
}

.filetContDark .chakra-modal__close-btn {
    color: white;
}

.filetContDark .chakra-modal__body::-webkit-scrollbar {
    width: 5px;

}

/* Track */

.filetContDark .chakra-modal__body::-webkit-scrollbar-track {
    background: #171A20;
}

/* Handle */

.filetContDark .chakra-modal__body::-webkit-scrollbar-thumb {
    background: #AAAAAA;
}

/* Handle on hover */

.filetContDark .chakra-modal__body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.filetContDark .chakra-modal__body>.statisFilterAndPrioritet {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.filetContDark .chakra-modal__body>.statisFilterAndPrioritet>div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.filetContDark .chakra-modal__body>.statisFilterAndPrioritet>div:first-child>p:first-child {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #767676;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

.filetContDark .chakra-modal__body>.statisFilterAndPrioritet>div:first-child>p:last-child {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #3B79F6;
    text-overflow: ellipsis;

    /* Field label */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
    cursor: pointer;
}


.filetContDark .chakra-modal__body>.statisFilterAndPrioritet>label {
    width: 100%;
    display: flex;
    height: 44px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
}

.filetContDark .chakra-modal__body>.statisFilterAndPrioritet>label>select {
    width: 100%;
    outline: none;
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
    overflow: hidden;
    color: #929292;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
}

.filetContDark .chakra-modal__body>.statisFilterAndPrioritet>label>select>option {
    background-color: #171A20;
    color: #929292;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

/* performer ====================================================== */
.filetContDark .chakra-modal__body>.filterPerformer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    align-self: stretch;
}

.filetContDark .chakra-modal__body>.filterPerformer>div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

}

.filetContDark .chakra-modal__body>.filterPerformer>div>p:first-child {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #767676;
    text-overflow: ellipsis;
    /* Field label */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}


.filetContDark .chakra-modal__body>.filterPerformer>div>p:last-child {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #3B79F6;
    text-overflow: ellipsis;
    /* Field label */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
    cursor: pointer;
}

.filetContDark .chakra-modal__body>.filterPerformer>section {
    display: flex;
    padding: 16px 16px 0px 16px;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 12px;
    background: #21262E;
    max-height: 256px;
}

.filetContDark .chakra-modal__body>.filterPerformer>section>label:first-child {
    display: flex;
    height: 48px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #181B22;

}

.filetContDark .chakra-modal__body>.filterPerformer>section>label>input {
    outline: none;
    width: 100%;
    background: #181B22;
    color: #A8A8A8;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    /* 171.429% */
}


.filetContDark .chakra-modal__body>.filterPerformer>section>label>input::placeholder {
    outline: none;
    width: 100%;
    color: #A8A8A8;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */

}

.filetContDark .chakra-modal__body>.filterPerformer>section>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

}

.filetContDark .chakra-modal__body>.filterPerformer>section>div>div {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}

.filetContDark .chakra-modal__body>.filterPerformer>section>div>div>div {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
    align-self: stretch;
    overflow-y: auto;
    /* Вертикальная прокрутка */
    min-height: 157px;
    /* Ограничение высоты */
    max-height: 157px;
    /* Ограничение высоты */
    padding-bottom: 11px;
    padding-top: 5px;

}

/* width */
.filetContDark .chakra-modal__body>.filterPerformer>section>div>div>div::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.filetContDark .chakra-modal__body>.filterPerformer>section>div>div>div::-webkit-scrollbar-track {
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #F3F8FF;

}

/* Handle */
.filetContDark .chakra-modal__body>.filterPerformer>section>div>div>div::-webkit-scrollbar-thumb {
    background: #AAA;

}

/* Handle on hover */
.filetContDark .chakra-modal__body>.filterPerformer>section>div>div>div::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.filetContDark .chakra-modal__body>.filterPerformer>section>div>div>div>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;
    align-self: stretch;


}

.filetContDark .chakra-modal__body>.filterPerformer>section>div>div>div>div>div {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 8px;
    background: #292F39;
}

.filetContDark .chakra-modal__body>.filterPerformer>section>div>div>div>div>div:hover {
    cursor: pointer;
    opacity: 0.6;
    transition: 0.5s;
}

.filetContDark .chakra-modal__body>.filterPerformer>section>div>div>div>div>div>.isChecked {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #3B79F6;
}

.filetContDark .chakra-modal__body>.filterPerformer>section>div>div>div>div>div>div {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #323A49;

}

.filetContDark .chakra-modal__body>.filterPerformer>section>div>div>div>div>div>.namePerfFilter {
    color: #6A7180;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 166.667% */
}


.filetContDark .chakra-modal__body>.filterPerformer>section>div>div>div>div>div>.emailPerfFilter {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    overflow: hidden;
    color: #6A7180;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 166.667% */
}

/* performer ====================================================== */

/* filterDateCont ====================================================== */
.filetContDark .chakra-modal__body>.filterDateCont {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}



.filetContDark .chakra-modal__body>.filterDateCont>div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.filetContDark .chakra-modal__body>.filterDateCont>div:first-child>p:first-child {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #767676;
    text-overflow: ellipsis;
    /* Field label */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}


.filetContDark .chakra-modal__body>.filterDateCont>div:first-child>p:last-child {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #3B79F6;
    text-overflow: ellipsis;
    /* Field label */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    cursor: pointer;
    /* 121.429% */
}

.filetContDark .chakra-modal__body>.filterDateCont>div:last-child {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 100%;

}

.filetContDark .chakra-modal__body>.filterDateCont>div:last-child>label {
    display: flex;
    width: 100%;
    height: 44px;
    padding: 0px 16px;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 12px;
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
    gap: 6px;
}

.filetContDark .chakra-modal__body>.filterDateCont>div:last-child>label>input {
    width: 100%;
    overflow: hidden;
    color: #FFF;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
    /* 157.143% */
    outline: none;
    color-scheme: dark;
}


.filetContDark .chakra-modal__body>.filterDateCont>div:last-child>label>p {
    color: #767676;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

/* filterDateCont ====================================================== */
.filetContDark .chakra-modal__body>.marketPlaceFilterCont {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.filetContDark .chakra-modal__body>.marketPlaceFilterCont>div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.filetContDark .chakra-modal__body>.marketPlaceFilterCont>div:first-child>p:first-child {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #767676;
    text-overflow: ellipsis;
    /* Field label */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}


.filetContDark .chakra-modal__body>.marketPlaceFilterCont>div:first-child>p:last-child {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #3B79F6;
    text-overflow: ellipsis;
    /* Field label */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    cursor: pointer;
    /* 121.429% */
}

.filetContDark .chakra-modal__body>.marketPlaceFilterCont>div:last-child {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px;
    align-self: stretch;
    flex-wrap: wrap;
}

.filetContDark .chakra-modal__body>.marketPlaceFilterCont>div:last-child>.cheked {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    background: #3B79F6;

}

.filetContDark .chakra-modal__body>.marketPlaceFilterCont>div:last-child>.nocheked {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    background: #21262E;

}

.filetContDark .chakra-modal__body>.marketPlaceFilterCont>div:last-child>div:hover {
    cursor: pointer;
    border-radius: 12px;
    background: #3B79F6;
}

.filetContDark .chakra-modal__body>.marketPlaceFilterCont>div:last-child>div:hover>div>p {
    color: white;
    /* Изменяем цвет текста на белый */
}

.filetContDark .chakra-modal__body>.marketPlaceFilterCont>div:last-child>div>div {
    display: flex;
    align-items: center;
    gap: 6px;
}

.filetContDark .chakra-modal__body>.marketPlaceFilterCont>div:last-child>div>svg {
    width: 35px;
    height: 25px;
}

.filetContDark .chakra-modal__body>.marketPlaceFilterCont>div:last-child>.cheked>div>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: white;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}

.filetContDark .chakra-modal__body>.marketPlaceFilterCont>div:last-child>.nocheked>div>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: white;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}


/* priceFilterCont ============================================== */

.filetContDark .chakra-modal__body>.priceFilterCont {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.filetContDark .chakra-modal__body>.priceFilterCont>div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.filetContDark .chakra-modal__body>.priceFilterCont>div:first-child>p:first-child {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #767676;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

.filetContDark .chakra-modal__body>.priceFilterCont>div:first-child>p:last-child {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #3B79F6;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    cursor: pointer;
    /* 121.429% */
}

.filetContDark .chakra-modal__body>.priceFilterCont>div:last-child {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.filetContDark .chakra-modal__body>.priceFilterCont>div:last-child>label:first-child {
    display: flex;
    width: 100%;
    height: 44px;
    padding: 0px 16px;
    justify-content: start;
    align-items: center;
    align-content: center;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
    gap: 6px;

}

.filetContDark .chakra-modal__body>.priceFilterCont>div:last-child>label:first-child>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #767676;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

.filetContDark .chakra-modal__body>.priceFilterCont>div:last-child>label:first-child>input {
    overflow: hidden;
    color: white;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
    outline: none;
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
}

.filetContDark .chakra-modal__body>.priceFilterCont>div:last-child>label:last-child {
    display: flex;
    width: 100%;
    height: 44px;
    padding: 0px 16px;
    justify-content: start;
    align-items: center;
    align-content: center;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
    gap: 6px;
}

.filetContDark .chakra-modal__body>.priceFilterCont>div:last-child>label:last-child>input {
    overflow: hidden;
    color: white;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
    outline: none;
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;

}

.filetContDark .chakra-modal__body>.priceFilterCont>div:last-child>label:last-child>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #767676;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

/* priceFilterCont ============================================== */
.filetContDark .chakra-modal__body>.complitedTaskFilterCont {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.filetContDark .chakra-modal__body>.complitedTaskFilterCont>div {
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    background: #21262E;
}

.filetContDark .chakra-modal__body>.complitedTaskFilterCont>div>div {
    display: flex;
    align-items: center;
    gap: 8px;
}

.filetContDark .chakra-modal__body>.complitedTaskFilterCont>div>div>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.filetContDark .chakra-modal__body>.complitedTaskFilterCont>div>div>div>p {
    color: white;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

/* FOOTER=========================================================================================== */
.filetContDark .chakra-modal__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: 0;
}

.filetContDark .chakra-modal__footer>div {
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-top: 1px solid rgba(0, 0, 0, 0.10);
    background: #171A20;


}

.filetContDark .chakra-modal__footer>div>button:first-child {
    display: flex;
    height: 48px;
    padding: 16px;
    align-items: center;
    gap: 4.067px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    color: #858C99;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    /* 142.857% */
}

.filetContDark .chakra-modal__footer>div>button:first-child:hover {
    opacity: 0.6;
    transition: 0.5s;
}


.filetContDark .chakra-modal__footer>div>button:last-child {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 12px;
    background: #3B79F6;
}

.filetContDark .chakra-modal__footer>div>button:last-child:hover {
    transition: 0.5s;
    opacity: 0.8;
}

.filetContDark .chakra-modal__footer>div>button:last-child>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.filetContDark .chakra-modal__footer>div>button:last-child>div>p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

@media only screen and (max-width: 589px) {
    .filetContDark .chakra-modal__body>.filterDateCont>div:last-child {
        flex-direction: column;
    }

    .filetContDark .chakra-modal__body>.priceFilterCont>div:last-child {
        flex-direction: column;
    }
}