.tradeLightCont {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 326px !important;
    width: 100%;
    background-color: white;
}

.tradeLightCont .tradeHeader {
    display: flex;
    padding: 9px 16px;
    width: 326px;
    max-height: 50px;
    flex-direction: column;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: var(--Field-sizes-Field-border-width, 0px);
    border-top: var(--Field-sizes-Field-border-width, 0px) solid rgba(0, 0, 0, 0.10);
    border-right: var(--Field-sizes-Field-border-width, 0px) solid rgba(0, 0, 0, 0.10);
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    border-left: var(--Field-sizes-Field-border-width, 0px) solid rgba(0, 0, 0, 0.10);
}

.tradeLightCont .tradeOtherChild {
 
    padding: 25px 12px;
    margin-top: auto;
    margin-bottom: 35px;
    max-height: 936px;
    overflow: auto;
    background-color: white;
}



.tradeLightCont .tradeHeader>div {
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: var(--Field-sizes-Field-border-width, 0px);
}

.tradeLightCont .tradeHeader>div>div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: var(--Field-sizes-Field-border-width, 0px);
}


.tradeLightCont .tradeHeader>div>div p {
    margin: 0 !important;
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 133.333% */
}

.tradeLightCont .teadeCloseIconCont {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.tradeLightCont .teadeCloseIconCont svg {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
}


.tradeLightCont .suppliedEmoticonsLightCont {
    display: flex !important;
    align-items: flex-start !important;
    align-content: flex-start !important;
    gap: 12px !important;
    align-self: stretch !important;
    flex-wrap: wrap !important;
}

.tradeLightCont .creatCommentBTNLight {
    display: none;
}


.tradeLightCont  .subCommentBtn {
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 24px;
    background: #F6F6F6;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
    margin: auto;
    margin-top: 16px;
    margin-bottom: 30px;
   
}


.tardeMessageItem {
    display: flex;
    padding: 16px 16px 0px 16px;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    margin-bottom: 10px;
}

.tardeMessageItem img {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 32px;

}

.tardeMessageItem>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
}


.tardeMessageItem>div>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.tardeMessageItem>div>div>div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.tardeMessageItem>div>div>div:first-child p:first-child {
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}

.tardeMessageItem>div>div>div:first-child p:last-child {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 200% */
}

.tardeMessageItem>div>div>div:last-child {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
}

.tardeMessageItem>div>div>div:last-child p {
    flex: 1 0 0;
    color: #6A7180;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}

.tradeLightCont .messageItemLightCont img{
    width: 241.87px;

}


@media only screen and (max-width: 1025px) {
    .tradeLightCont .messageItemLightCont img{
        width: 376.87px;
    
    }
    
    .tradeLightCont .messageItemLightCont .fileFlexCont{
        left: 50%;
    }
  }
  