/* The Modal (background) */
.addNewUserModalLigthCont {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 14;
    /* Sit on top */
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    justify-content: center;
    align-items: center;
}

/* Modal Content */
.addNewUserModalLigthCont .addNewUserModalLigth-content {
    position: relative;
    display: flex;
    max-width: 520px;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    border-radius: 12px;
    background: #FFF;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
}

.addNewUserModalLigthCont .addNewUserModalLigth-content .link-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    padding: 12px;
    background-color: #f8f9fa;
    border-radius: 5px;
    gap: 10px;
    cursor: pointer;
    transition: background 0.2s;
    position: relative;
}

.addNewUserModalLigthCont .addNewUserModalLigth-content .link-box > p{
    color: #353535;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    white-space: nowrap;
}

.addNewUserModalLigthCont .addNewUserModalLigth-content .link-box > input{
    color: #585858;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    border: none;
    background: none;
    outline: none;
    text-align: center;
    cursor: pointer;
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

.addNewUserModalLigthCont .addNewUserModal-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.addNewUserModalLigthCont .addNewUserModal-header>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.addNewUserModalLigthCont .addNewUserModal-header>div>p {
    color: #353535;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
}

.addNewUserModalLigthCont .addNewUserModal-header>p {
    color: #585858;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.addNewUserModalLigthCont .addNewUserModal-header>div>div {
    display: flex;
    padding: 6px 9px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.addNewUserModalLigthCont .addNewUserModal-header>div>div svg {
    width: 24px;
    height: 24px;
}

.addNewUserModalLigthCont .addNewUserModal-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.addNewUserModalLigthCont .addNewUserModal-body>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.addNewUserModalLigthCont .addNewUserModal-body>div>div {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
    
}


.addNewUserModalLigthCont .addNewUserModal-body>div>div label{
    display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1;
align-self: stretch;
overflow: hidden;
color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
text-overflow: ellipsis;

/* Field label */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 17px; /* 121.429% */
}

.addNewUserModalLigthCont .addNewUserModal-body>div>div select {
    height: 44px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    overflow: hidden;
    color: #353535;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    padding: 10px;
    /* 157.143% */
}

.addNewUserModalLigthCont .addNewUserModal-body>div>div select option {
    display: flex;
    height: 22px;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    overflow: hidden;
    color: #353535;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}



.addNewUserModalLigthCont .addNewUserModal-body>div>div .PhoneInputInput {
    height: 44px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    width: 440px !important;
}


.addNewUserModalLigthCont .addNewUserModal-footer {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.addNewUserModalLigthCont .closeaddNewUserModal {
    display: flex;
    height: 48px;
    padding: 16px;
    align-items: center;
    gap: 4.067px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    color: #858C99;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.addNewUserModalLigthCont .addDddNewUserModal {
    display: flex;
    height: 48px;
    padding: 16px;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: var(--Primary-Primary-500, #3B79F6);
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    cursor: pointer;
}

.addNewUserModalLigthCont .addDddNewUserModal>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px;
    justify-content: center;
    align-items: center;
}

.addNewUserModalLigthCont .addDddNewUserModal>div svg {
    width: 21.5px;
    height: 21.5px;
    flex-shrink: 0;
}


.addNewUserModalLigthCont .addNewUserModal-footer > .addEmloyLeftBtnCont {
    display: flex;
height: 48px;
padding: 16px;
width: auto !important;
align-items: center;
gap: 4px;
border-radius: 16px;
background: var(--Primary-Primary-500, #3B79F6);
}


.addNewUserModalLigthCont .addEmloyLeftBtnCont>div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.addNewUserModalLigthCont .addEmloyLeftBtnCont>div:first-child>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px;
    justify-content: center;
    align-items: center;
}

.addNewUserModalLigthCont .addEmloyLeftBtnCont>div:first-child p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

@media only screen and (max-width: 600px) {
    .addNewUserModalLigthCont .addNewUserModalLigth-content{
        width: 100%;
        margin: 4%;
    }
    .PhoneInput{
        width: 100%;
    }
    .addNewUserModalLigthCont .addNewUserModal-body>div>div .PhoneInputInput{
        width: 100% !important;
    }
  }