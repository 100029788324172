@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

.chatNavFilterContDark {
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    border-top: 1px solid rgba(0, 0, 0, 0.10);
    border-right: 1px solid rgba(0, 0, 0, 0.10);
    background-color: #12151A;
    height: 100dvh;
    overflow: hidden;
}




.chatNavFilterContDark .filterCont{
    display: flex;
    height: 72px;
    padding: 16px 16px 0px 16px;
    align-items: flex-end;
    gap: 28px;
    flex-shrink: 0;
    align-self: stretch;
    border-top: 1px solid #12151A;
    background: #12151A;
    border-right: 1px solid #303540;


   
}

.chatNavFilterContDark .ChatOulatCont{
    width: 100%;
    height: 100dvh;
    border-left:1px solid #303540;
}




.chatNavFilterContDark .filterCont a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    position: relative;
}


.chatNavFilterContDark .filterCont .active {
    color: var(--Primary-500, #3B79F6);
    border-bottom: 3px solid #3B79F6;
    height: 60px;
    transition: 0.3s;
    position: relative;
}

.chatNavFilterContDark .filterCont .active>span {
    position: absolute;
    left: 0;
    top: -3px;
    display: flex;
    height: 20px;
    padding: 5px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--Border-radius-L, 8px);
    background: #5E7DE8;
    color: var(--White-White, #FFF);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Extra Bold 800/8 */
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 800;
    line-height: 12px;
    /* 150% */
}


.chatNavFilterContDark .filterCont a {
    color: var(--Gray-400, #858C99);
    border-bottom: 3px solid #12151A;
    height: 60px;
    transition: 0.3s;
    text-decoration: none;

}


.chatNavFilterContDark .filterCont a>span {
    position: absolute;
    left: 0;
    top: -3px;
    display: flex;
    height: 20px;
    padding: 5px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--Border-radius-L, 8px);
    background: #5E7DE8;
    color: var(--White-White, #FFF);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Extra Bold 800/8 */
    font-family: Inter;
    font-size: 8px;
    font-style: normal;
    font-weight: 800;
    line-height: 12px;
    /* 150% */
}

.chatNavFilterContDark .filterCont a:hover{
    color: var(--Primary-500, #3B79F6);
    border-bottom: 3px solid #3B79F6;
   
}

.chatNavFilterContDark a:hover .svgCont svg {
    stroke: #3B79F6 !important;
}


.chatNavFilterContDark .svgCont {
    display: flex;
    width: 14px;
    height: 14px;
    padding: 0.625px 1.125px;
    justify-content: center;
    align-items: center;
}

.chatNavFilterContDark .svgCont svg {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
}

.chatNavFilterContDark .filterCont .active .svgCont svg path {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    fill: #3B79F6;
}

@media only screen and (max-width: 1024) {
    .leftSideNanCont {
        background-color: #F7F8FA;
        width: 241px;

    }
    .TardeCont{
        display: none;
    }
    .ChatOulatCont{
        width: 100%;
        height: calc(100dvh - 86px);
    }
}


@media only screen and (max-width: 768px) {
    .leftSideNanCont {

        width: 0;
        overflow: hidden;

    }
}


.chatNavFilterContDark .otherChatOptions {
    display: flex;
    width: 0;
    height: 100dvh;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: var(--Field-sizes-Field-border-width, 0px);
    border-top: var(--Field-sizes-Field-border-width, 0px) solid rgba(0, 0, 0, 0.10);
    border-right: var(--Field-sizes-Field-border-width, 0px) solid rgba(0, 0, 0, 0.10);
    border-bottom: var(--Field-sizes-Field-border-width, 0px) solid rgba(0, 0, 0, 0.10);
    border-left: 1px solid rgba(0, 0, 0, 0.10);
    background: #12151A;
}