
  
  .dropdownEmojiLight{
    position: relative;
    display: flex;
   
   
  }
  
  .dropdownEmojiLight .dropdownEmojiCont-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
  
  
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 9 !important;
    top: -450px;
    left: 0;
  }
  
  .dropdownEmojiLight .dropdownEmojiCont-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdownEmojiLight .dropdownEmojiCont-content:hover {background-color: #f1f1f1}
  

