@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

.chatItemPrivateDark {
    display: flex;
    padding: 16px 16px 16px 32px;
    justify-content: center;
    align-items: flex-end;
    gap: 12px;
    align-self: stretch;
    border-top: 1px solid #303540;
    border-right: 1px solid #303540;
    background: #12151A;
    position: relative;
    cursor: pointer;
    width: 100%;
}

.chatItemPrivateDark:hover {
    text-decoration: none;
    background: #21262E;
}

.chatItemPrivateDark .imgCon {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 32px;
    background: #86C995;
}

.chatItemPrivateDark .frame19 {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
}

.chatItemPrivateDark .frame19 p {
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: #A0A0A0;
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    word-break: break-word;
    /* 166.667% */
}

.chatItemPrivateDark .frame205 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.chatItemPrivateDark .frame205 p {
    margin: 0;
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}

.chatItemPrivateDark .frame205 .pinChat {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.chatItemPrivateDark .frame205 .pinChat>div {
    display: flex;
    width: 12px;
    height: 12px;
    padding: 0.625px 1.508px 0.763px 1.161px;
    justify-content: center;
    align-items: center;
}

.chatItemPrivateDark .frame205 .pinChat>div svg {
    width: 9.331px;
    height: 10.612px;
    flex-shrink: 0;
}

.chatItemPrivateDark .hicon {
    display: flex;
    width: 12px;
    height: 12px;
    padding: 0.625px 1.125px;
    justify-content: center;
    align-items: center;
}

.chatItemPrivateDark .hicon svg path {
    fill: #FFFFFF;
}

.chatItemPrivateDark .messageTime {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    position: absolute;
    right: 16px;
    top: 12px;
    overflow: hidden;
    color: var(--Gray-400, #858C99);

    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}


.activedark {
    background-color: #3a3d41 !important;
}