/* body,html{
  background-color: #F7F8FA;
} */




@media (prefers-color-scheme: dark) {
  body {
      background-color: #12151A; /* Цвет фона в темной теме */
    
  }
}