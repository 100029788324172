.dropbtnNotify{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
 
}
.dropdownNotifySendMsgLigth {
  display: flex;
  position: relative;
  display: inline-block;

}

.dropdownNotifySendMsgLigth .dropdown-contentNotify {
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: absolute;
  background-color: #F7F8FA;
  max-width: 25rem !important;
  max-height: 20vh;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 80%;
  bottom: 10%;
  z-index: 150 !important;
  border-radius: 10px;

}


/* width */
.dropdownNotifySendMsgLigth .dropdown-contentNotify::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.dropdownNotifySendMsgLigth .dropdown-contentNotify::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.dropdownNotifySendMsgLigth .dropdown-contentNotify::-webkit-scrollbar-thumb {
  background: #3B79F6;
}

/* Handle on hover */
.dropdownNotifySendMsgLigth .dropdown-contentNotify::-webkit-scrollbar-thumb:hover {
  background: #3B79F6;
}


.dropdownNotifySendMsgLigth .dropdown-contentNotify > div{
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: start;
  padding: 5px;
  width: 100%;
  cursor: pointer;
}

.dropdownNotifySendMsgLigth .dropdown-contentNotify > div p{
  margin: 0 !important;
}

.dropdownNotifySendMsgLigth .dropdown-contentNotify > div:hover{
  background-color: #F4F4F5;
}


.dropdownNotifySendMsgLigth .dropdown-contentNotify p {
  color: #6A717F !important;
  padding: 12px 16px;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  text-wrap: wrap !important;
 
}

.dropdownNotifySendMsgLigth .dropdown a:hover {
  background-color: #ddd;
}


@media only screen and (max-width: 427px) {
  .dropdownNotifySendMsgLigth .dropdown-contentNotify p{
    font-size: 10px;
  }
}


@media only screen and (max-width: 341px) {
  .dropdownNotifySendMsgLigth .dropdown-contentNotify p{
    font-size: 10px;
  }
  .dropdownNotifySendMsgLigth .dropdown-contentNotify{
    right: 50%; /* Сместите элемент на 50% */
    transform: translateX(7%); /* Перемещает элемент на половину его ширины */
   
  }
}

@media only screen and (max-width: 451px) {
  .dropdownNotifySendMsgLigth .dropdown-contentNotify{
    right: -93% !important;
  }
}