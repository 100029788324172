.discussionSidebarContLight {
    display: inline-flex;
    padding: 8px 0px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    width: 100% !important;
    position: relative;
    height: 92dvh;

}


.discussionSidebarContLight>div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    width: 100% !important;
   max-height:87dvh;
   flex: 1;


}


.sideIfnoCont {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100% !important;
}

.sideIfnoCont>.frame117 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100% !important;

}

.sideIfnoCont>div>p {
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}

.sideIfnoCont>div>.frame107 {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 16px;
}

.sideIfnoCont>div>div>.frame108 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
}



.sideIfnoCont>div>div>div>input {
    display: flex;
    height: 40px;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: 8px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #F7F8FA;
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}

.sideIfnoCont>div>div>div>div p {
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}


.discussionSidebarContLight .sideFuncCont {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.discussionSidebarContLight .sideFuncCont>.frame116 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}


.discussionSidebarContLight .sideFuncCont>.frame116>.frame117,
.discussionSidebarContLight .sideFuncCont>.frame116>.frame118,
.discussionSidebarContLight .sideFuncCont>.frame116>.frame119 {
    display: flex;
    padding: 4px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    cursor: pointer;
}


.discussionSidebarContLight .sideFuncCont>.frame116>.frame117>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.discussionSidebarContLight .sideFuncCont>.frame116>.frame117>div>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.discussionSidebarContLight .sideFuncCont>.frame116>.frame117>div>div>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px 3.017px 1.526px 2.321px;
    justify-content: center;
    align-items: center;
}

.discussionSidebarContLight .sideFuncCont>.frame116>.frame117>div>div p {
    color: var(--Primary-Primary-500, #3B79F6);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}

.addUserBTN {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.addUserBTN>div {
    display: flex;
    height: 50px;
    padding: 13.943px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: var(--Primary-500, #3B79F6);
    box-shadow: 0px 0.508px 1.525px 0px rgba(59, 130, 246, 0.40);
}

.addUserBTN>div>p {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.addUserBTN>div>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px;
    justify-content: center;
    align-items: center;
}


.addUserBTN>div>div svg {
    width: 21.5px;
    height: 21.5px;
    flex-shrink: 0;
}

.ParticipantsCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
    height: 100dvh;
}

.ParticipantsCont .ParticipantsUsersForForeach {
    height: 336px;
    overflow: auto;
    width: 100%;
    
    
}

@media only screen and (max-width: 768px) {
    .ParticipantsCont .ParticipantsUsersForForeach {
        height: 245px;
        overflow: auto;
        width: 100%;
    }

    .discussionSidebarContLight>div:first-child {
        height: 71dvh;
    }
}

.ParticipantsCont>div:first-child {
    display: flex;
    padding: 0px 16px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.ParticipantsCont>div:first-child>p {
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}

.ParticipantsCont .ParticipantsUsers {
    display: flex;
    width: 100% !important;
    padding: 0px 16px;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;
    transition: 0.3s;
}

.ParticipantsCont .ParticipantsUsers:hover{
    background-color: #F7F8FA;
    cursor: pointer;
}


.ParticipantsCont .ParticipantsUsers>p {
    color: var(--Primary-Primary-500, #3B79F6);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 166.667% */
}



.ParticipantsCont .ParticipantsUsers>div {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.ParticipantsCont .ParticipantsUsers>div>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}


.ParticipantsCont .ParticipantsUsers>div>div>p:first-child {
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}


.ParticipantsCont .ParticipantsUsers>div>div>p:last-child {
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}

.discussionSidebarContLight .discussionSidebarFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    z-index: 0;
}

.discussionSidebarContLight .discussionSidebarFooter>.LeaveTheConversation {
    display: flex;
    padding: 4px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    cursor: pointer;
}

.discussionSidebarContLight .discussionSidebarFooter>.LeaveTheConversation>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.discussionSidebarContLight .discussionSidebarFooter>.LeaveTheConversation>div>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 0.923px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.discussionSidebarContLight .discussionSidebarFooter>.LeaveTheConversation>div>div svg {
    width: 24.615px;
    height: 22.154px;
    flex-shrink: 0;
}

.discussionSidebarContLight .discussionSidebarFooter>.LeaveTheConversation>div>p {
    color: #EE6B6B;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}




.discussionSidebarContLight .discussionSidebarFooter>.Archive {
    display: flex;
    padding: 4px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    cursor: pointer;
}

.discussionSidebarContLight .discussionSidebarFooter>.Archive>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}


.discussionSidebarContLight .discussionSidebarFooter>.Archive>div>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 2px 2.5px 2px 1.5px;
    justify-content: center;
    align-items: center;
}

.discussionSidebarContLight .discussionSidebarFooter>.Archive>div>div svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.discussionSidebarContLight .discussionSidebarFooter>.Archive>div p {
    color: #EE6B6B;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}


/* loading spiner ======================================================================================================================================*/
.spinner {
    width: 56px;
    height: 56px;
    margin: auto;
    border-radius: 50%;
    border: 9px solid #3b79f6;
    animation: spinner-bulqg1 0.8s infinite linear alternate,
        spinner-oaa3wk 1.6s infinite linear;
}

@keyframes spinner-bulqg1 {
    0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }

    12.5% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    62.5% {
        clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    75% {
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }

    100% {
        clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
}

@keyframes spinner-oaa3wk {
    0% {
        transform: scaleY(1) rotate(0deg);
    }

    49.99% {
        transform: scaleY(1) rotate(135deg);
    }

    50% {
        transform: scaleY(-1) rotate(0deg);
    }

    100% {
        transform: scaleY(-1) rotate(-135deg);
    }
}