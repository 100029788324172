/* modal metka ======================== */

.metkaContLight .modalMetka {
    

    display: flex;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 8;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    display: flex;
    justify-content: center;
}

/* Modal Content */
.metkaContLight .modal-contentMetka {
    display: flex;
    width: 520px;
    overflow: auto;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    position: absolute;
    margin: auto;
    border-radius: 12px;
    background: #FFF;
}

.metkaContLight .modal-headerMetka {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.metkaContLight .modal-headerMetka>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.metkaContLight .modal-headerMetka>div>div {
    cursor: pointer;
}

.metkaContLight .metkaContLight .modal-headerMetka>div>div {
    display: flex;
    padding: 6px 9px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.metkaContLight .modal-headerMetka>div>div>svg {
    width: 24px;
    height: 24px;
}

.metkaContLight .modal-headerMetka>div>h2 {
    color: #353535;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 100% */
}

.metkaContLight .metka {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 17px;
    align-self: stretch;
    padding: 16px 0px;
}

.metkaContLight .metka>div {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 16px var(--Field-sizes-Field-padding-bottom, 0px) 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
}

.metkaContLight .metka>div>div {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    flex-wrap: wrap;
}

.metkaContLight .metka>div>div>div {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
}

.metkaContLight .metka>div>div>div>div {

    display: flex;
    padding: 16px;
    align-items: center;
    gap: 3px;
    align-self: stretch;
    border-radius: 12px;
    background: rgba(28, 55, 90, 0.05);
    max-width: 100px;
   
}

.metkaContLight .metka>div>div>div>div>div {
    display: flex;
    height: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.metkaContLight .metka>div>div>div>div >p{
    display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2;
flex: 1 0 0;
overflow: hidden;
text-overflow: ellipsis;
color: var(--Body-Text, rgba(25, 39, 57, 0.94));
text-align: center;
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 114.286% */
width: 200px;
}

.metkaContLight .metka>div>div>div>div>div>svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.metkaContLight .metka>div>p {
    color: #353535;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 100% */
}


.metkaContLight .metka>div>div>button {
    display: flex;
    height: 48px;
    padding: 16px;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: #3B79F6;
}

.metkaContLight .metka>div>div>button>div:first-child {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px;
    justify-content: center;
    align-items: center;
}

.metkaContLight .metka>div>div>button>div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.metkaContLight .metka>div>div>button>div:last-child>p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.metkaContLight .searchTagCont {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.metkaContLight .searchTagCont>div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    align-self: stretch;
}

.metkaContLight .searchTagCont>div>div:first-child {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    flex: 1 0 0;
}

.metkaContLight .searchTagCont>div>div:first-child>input {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;
}

.metkaContLight .searchTagCont>div>div:last-child {
    display: flex;
    height: 44px;
    padding: 16px;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: #3B79F6;
}

.metkaContLight .searchTagCont>div>div:last-child>p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}


.metkaContLight .modal-bodyMetka {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 12px;
    align-self: stretch;
    max-height: 465px;
    overflow: auto;
    box-sizing: border-box; /* Ensure padding is included in the height calculation */
    justify-content: space-between;
   
}

/* width */
.metkaContLight .modal-bodyMetka::-webkit-scrollbar {
    width: 2px;
}

/* Track */
.metkaContLight .modal-bodyMetka::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.metkaContLight .modal-bodyMetka::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.metkaContLight .modal-bodyMetka::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.metkaContLight .modal-bodyMetka>div {
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(28, 55, 90, 0.05) 0%, rgba(28, 55, 90, 0.05) 100%), #FFF;
    cursor: pointer;
}

.metkaContLight .modal-bodyMetka>div>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    flex: 1 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    color: #6A7180;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 166.667% */
}

.metkaContLight .modal-bodyMetka>div>div {
    display: flex;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: var(--Border-radius-L, 8px);
    background: #FFF;
}

.metkaContLight .modal-bodyMetka>div>div>svg {
    width: 24px;
    height: 24px;
}

@media only screen and (max-width: 600px) {
    .metkaContLight .modal-contentMetka{
        width: 392px !important;
    }
    .metkaContLight .searchTagCont>div>div:first-child>input{
        width: 100%;
    }
 
  }