/* .ContBodyNotify{
    padding: 20px;
    display: flex;
}

.ContBodyNotifyChild{
    width: 100%;

}

.ContBodyNotifyChild h1{
   font-size: 1rem;
}

.deletNotifyConfirm{
    width: 10%;
}

.isConfirm{
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 1.5rem;
}
.deletNotifyConfirm span{
    color: #DB5F95;
    text-align: center;
    font-size: 40px;
   border: 1px solid #DB5F95;
   border-radius: 5px;
   margin-left: 5px;
   cursor: pointer;
}

.AllButtonTask .btnNotify{
 
    margin-bottom: 10px;
    width: 60px;
    background-color: #3384CB;
    padding: 8px;
    border-radius: 10px;
    
    text-align: center;
    cursor: pointer;
 }

 .AllButtonTask .btnNotify:hover{
    opacity: 0.6;
 }
 .notifySpan{
    color: white;
    text-align: center;
    font-size: 1.5rem;
 }
.nofifyContTimes{
   
    padding: 5px;
    border: 1px solid #3384CB;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
}

 .notifyBTn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;
   
 }

 .notifyBTn > .Ncanchel{
    color:white;
    margin-right: 5%;
    padding: 3px;
    width: 45%;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #DB5F95;
    background-color: #DB5F95;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
cursor: pointer;
 }

 .notifyBTn > .Ncanchel:hover{
    opacity: 0.6;
 }
 
 .notifyBTn > .Napply{
    color:white;
    background-color: #3384CB;
    width: 45%;
    padding: 3px;
    border-radius: 5px;
    margin-left: 5%;
    text-align: center;
    border: 1px solid #3384CB;
    margin-right: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
cursor: pointer;
 }
 .notifyBTn > .Napply:hover{
    opacity: 0.6;
 }


.chakra-modal__header{
        font-size: 0.98rem !important;
}

.offcanvas-body{
   overflow: auto;
   height: 90vh;
}


.custom-loader-notify {
   width:35px;
   height:35px;
   border-radius:50%;
   background:conic-gradient(#0000 10%,#DA6094);
   -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
   animation:s3 1s infinite linear;
 }
 @keyframes s3 {to{transform: rotate(1turn)}} */

 /* new desig ==============================================================================================================*/


.notifyCintDark{
   width: 98%;
   height: auto;
   border-radius: 35px;
   padding: 20px;
   margin: 10px 0;
  border: 0.7px solid #55585D;
    background: #21262E;
 }
.notifyCintDark .notifyCintChild {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
 }
.notifyCintDark .notifyCintChild img{
    margin-left: 50% ;
 }
.notifyCintDark .notifyCintChild .css-3a5bz2{
    width: 100%;
    height: 100%;
    }
 
.notifyCintDark .notifyCintChild p {
    margin: 0;
 }
 
.notifyCintDark .notifyCintChild div p {
    color: #6B7A99;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 125% */
 }
 
.notifyCintDark .notifyCintChild div h1 {
    color: #6B7A99;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px;
    border-bottom: 2px solid #6B7A99;
    /* 100% */
 }
 
.notifyCintDark .notifyCintChild div input {
    border-radius: 10px;
    border: 0.7px solid #55585D;
    background: #353D49;
    padding:5px 10px;
    color: #6B7A99;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
 }
 
 .offcanvas {
    width: 541px !important;
 
 }
 
.notifyCintDark .infoTaskAndBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-top: 32px;
 }

 .notifyCintDark .notifyBTnConfirm svg circle {
      fill: #353D49 ;
 }
 
.notifyCintDark .infoTaskAndBtn .infoNotify {
    display: flex;
    flex-direction: column;
   
 }
 
.notifyCintDark .infoTaskAndBtn .infoNotify>div {
    display: flex;
    justify-content: space-between;
   
   
 }
.notifyCintDark .infoTaskAndBtn .infoNotify>div p:first-child{
    color: #3B79F6;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px; /* 100% */
 }
 
.notifyCintDark .infoTaskAndBtn .infoNotify>div p:last-child{
    color: #3B79F6;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
 }
 
 
.notifyCintDark .infoTaskAndBtn .infoNotify p {
    margin: 0;
    align-content: end;
 
 }
 
  .btnNotifyConfirm {
    display: flex;
    justify-content: space-between;
    align-items: end;
 }
 
 .infoNotifyText {
    width: 335px;
    border-top: 2px solid #3B79F6;
    color: #6B7A99;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 10px;
    padding-top: 5px;
    /* 125% */
 }
 
 .notifyBTnConfirm{
    display: flex;
    flex-direction: column;
 
 }

 .notifyCintDark .notifyBTnConfirm .modalCreatTask svg ellipse{
   fill: #353D49;
}
 
.notifyCintDark .notifyBTnConfirm > button{
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
    background: #353D49;
 margin: 5px 0;
 border-radius: 50px;
 display: flex;
 justify-content: center;
 align-items: center;
 align-content: center;
 
 }
 
 
.notifyCintDark .notifyBTnConfirm .modalCreatTask > button{
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
    background: #353D49;
 padding: 10px;
 margin: 5px 0;
 border-radius: 50px;
 display: flex;
 justify-content: center;
 align-items: center;
 align-content: center;
 
 }
  
.notifyCintDark .notifyBTnConfirm .modalCreatTask > button span{
    color:#3B79F6;
 }
 
  .btnNotifyConfirmMobile{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
 }
 
 .notifyCintDark .btnNotifyConfirmMobile div:nth-child(1) svg circle{
   fill: #353D49 !important;
 }

.notifyCintDark .btnNotifyConfirmMobile div{
    display: flex;
 }
 
.notifyCintDark .btnNotifyConfirmMobile div button{
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
     background-color: #F5F5F5;
     padding: 10px;
     margin: 5px 5px;
     border-radius: 100px;
     display: flex;
     justify-content: center;
     align-items: center;
     align-content: center;
     color:#3B79F6;
     width: 40px;
     height: 40px;
 }
 
 
 
 
 
 
 
 
 
 
 
 .custom-loader-deleteNotify{
    width:50px;
    height:24px;
    background: 
      radial-gradient(circle closest-side,#E62E7B 90%,#0000) 0%   50%,
      radial-gradient(circle closest-side,#E62E7B 90%,#0000) 50%  50%,
      radial-gradient(circle closest-side,#E62E7B 90%,#0000) 100% 50%;
    background-size:calc(100%/3) 12px;
    background-repeat: no-repeat;
    animation:d3 1s infinite linear;
  }
  @keyframes d3 {
      20%{background-position:0%   0%, 50%  50%,100%  50%}
      40%{background-position:0% 100%, 50%   0%,100%  50%}
      60%{background-position:0%  50%, 50% 100%,100%   0%}
      80%{background-position:0%  50%, 50%  50%,100% 100%}
  }
 .btnNotifyConfirmMobile{
    display: none;
 }
 
  @media only screen and (max-width: 600px) {
   .notifyCintDark .notifyCintChild .css-3a5bz2{
       width: 80%;
       height: 80%;
       }
    .btnNotifyConfirmMobile{
       display: flex;
    }
   .notifyCintDark .notifyBTnConfirm,.btnNotifyConfirm{
    display: none;
  }
 .notifyCintDark .infoTaskAndBtn .infoNotify{
    width: 100%;
  }
 .notifyCintDark .infoNotifyText{
    width: 100%;
  }
 .notifyCintDark .notifyCintChild {
    justify-content: space-evenly !important;
  }
 .notifyCintDark .notifyCintChild img{
    margin: 0;
  }
 .notifyCintDark .notifyCintChild  div{
    width: 181px;
  }
 .notifyCintDark .notifyCintChild  div input{
   width: 100% !important;
  }
  }
 
  @media only screen and (max-width: 340px) {
   .notifyCintDark .notifyCintChild .css-3a5bz2{
     width: 70%;
     height: 70%;
     }
    .notifyCintDark   .infoTaskAndBtn .infoNotify .infoNotifyText{
       font-size: 14px;
     }
    .notifyCintDark  .infoTaskAndBtn p{
       font-size: 16px !important;
     }
  }