.dropdownForUser {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    align-content: center !important;
}

.dropdownForUser>.dropdown-content {

    display: flex;
    width: 243px;
    height: 100px;
    padding: 16px 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    top: 300px;
    left: 50px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;

    /* shadow */
    box-shadow: 0px 1px 7px 0px rgba(106, 113, 128, 0.04);


}

.dropdownForUser .openBTN {
    display: flex;
    width: 30px;
    height: 30px;
    transform: rotate(90deg);
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
}

.dropdownForUser>.dropdown-content ul li {
    display: flex;
    padding: 4px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    margin-bottom: 8px;

}

.dropdownForUser>.dropdown-content ul li button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: #767676;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}