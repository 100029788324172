.SubCommentItemMessageContLight {
    display: flex;
    padding: 16px 16px 0px 16px;
    align-items: flex-start;
   flex-direction: column;
    gap: 12px;
    align-self: stretch;
    border-radius: var(--Field-sizes-Field-border-width, 0px);
    min-width: 326px !important;

}


.SubCommentItemMessageContLight img{
    display: flex;
width: 40px;
height: 40px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 32px;

}



.SubCommentItemMessageContLight>div:first-child {
    display: flex;
  
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    border-radius: var(--Field-sizes-Field-border-width, 0px);
}


.SubCommentItemMessageContLight .frame68 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: var(--Field-sizes-Field-border-width, 0px);
}

.SubCommentItemMessageContLight .infoContSubMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--Field-sizes-Field-border-width, 0px);
}

.SubCommentItemMessageContLight .infoContSubMessage p {
    margin: 0 !important;
}
 
.SubCommentItemMessageContLight .infoContSubMessageSenderName {
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}

.SubCommentItemMessageContLight .infoContSubMessageTime {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 200% */
}


.SubCommentItemMessageContLight .infoContSubMessagees {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
}

.SubCommentItemMessageContLight .infoContSubMessagees p{
    flex: 1 0 0;
    color: #6A7180;
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Inter";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 166.667% */
}

