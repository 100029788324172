.dropdownStatusCreatDark {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
}

.dropdownStatusCreatDark>div:first-child {
    display: flex;
    padding: 0px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #21262E;

}

.dropdownStatusCreatDark>div:first-child>div {
    display: flex;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
}

.dropdownStatusCreatDark>div:first-child>div>div {
    display: flex;
    align-items: center;
    gap: 4px;
}

.dropdownStatusCreatDark>div:first-child>div>div p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #929292;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
}

.dropdownStatusCreatDark>div:first-child>div>div>div {
    display: flex;
    height: 36px;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
}


.dropdownStatusCreatDark>div:first-child>div>div>div>button {
    display: flex;
    padding: 0px 10px;
    align-items: center;
    justify-content: space-between;
    flex: 1 0 0;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFEC8B;
    color: #353535;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
    max-width: 180px;
    min-width: 160px;

}


.popoverForCreatStatusDark {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: 0 !important;
    border: none !important;
}

.popoverForCreatStatusDark>div {
    display: flex;
    height: 39px;
    padding: 0px 10px;
    align-items: center;
    align-self: stretch;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background:#171B22;
    cursor: pointer;

}

.popoverForCreatStatusDark>div>div {
    display: flex;
    align-items: center;
    gap: 4px;
}

.popoverForCreatStatusDark>div>div >div{
    display: flex;
    align-items: center;
    gap: 4px;
}

.popoverForCreatStatusDark>div>div >div >p{

font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
}


.popoverForCreatStatusDark>div:nth-child(1) >div >div >p{
   color: white;
}

.popoverForCreatStatusDark>div:nth-child(2)> div >div >p{
    color: #56D4FC;
 }

 .popoverForCreatStatusDark>div:nth-child(3)> div >div >p{
    color: #4DFF30;
 }
 .popoverForCreatStatusDark>div:nth-child(4)> div >div >p{
    color: #FB50FF;
 }

 .popoverForCreatStatusDark>div:nth-child(5)> div >div >p{
    color: #5364FF;
 }

 .popoverForCreatStatusDark>div:nth-child(6)> div >div >p{
    color: #FF50A0;
 }


 @media only screen and (max-width: 1254px) {
    .dropdownStatusCreatDark>div:first-child>div>div p{
        font-size: 11px !important;
    }
    .dropdownStatusCreatDark>div:first-child>div>div>div>button{
        font-size: 11px !important;
    }
    .dropdownStatusCreatDark>div:first-child>div>div>div>button{
        min-width: 132px !important; 
    }
  }

  @media only screen and (max-width: 1075px) {
    .dropdownStatusCreatDark>div:first-child>div>div p{
        font-size: 9px !important;
    }
    .dropdownStatusCreatDark>div:first-child>div>div>div>button{
        font-size: 9px !important;
    }
    .dropdownStatusCreatDark>div:first-child>div>div>div>button{
        min-width: 117px  !important; 
    }
  }