.pinZakrepLightCont {
    position: relative;
    top: 50px;
    display: flex;
    height: 50px;
    padding: 8px 32px 8px 16px;
    justify-content: space-between;
    align-items: center;
    background: var(--Base-Colors-White, #FFF);
    box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
    z-index: 1;
}

.pinZakrepLightCont .pinZakrepInfo {
    display: flex;
    align-items: center;
    gap: 9px;
    flex: 1 0 0;
    cursor: pointer;
}


.pinZakrepLightCont .pinZakrepInfo p {
    margin: 0 !important;
}

.pinZakrepLightCont .pinZakrepInfoIconCont {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 1.041px 2.516px 1.273px 1.934px;
    justify-content: center;
    align-items: center;
}

.pinZakrepLightCont .pinZakrepInfoIconCont svg {
    width: 15.551px;
    height: 17.686px;
    flex-shrink: 0;
}

.pinZakrepLightCont .pinZakrepInfo .pinZakrepInfoMessageName {
    align-self: stretch;
    color: var(--Gray-500, #6A7180);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 133.333% */
}

.pinZakrepLightCont .pinZakrepInfo .pinZakrepInfoMessage {
    align-self: stretch;
    color: var(--Gray-400, #858C99);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
}


.pinZakrepLightCont .iconContPin {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
}

.pinZakrepLightCont .iconContPin svg {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
}


@media only screen and (max-width: 600px) {
    .pinZakrepLightCont .pinZakrepInfo .pinZakrepInfoMessage {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        align-self: stretch;
        overflow: hidden;
        color: var(--Gray-400, #858C99);
        font-feature-settings: 'clig' off, 'liga' off;
        text-overflow: ellipsis;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        /* 133.333% */
    }
}


