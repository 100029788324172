.generateLinkLight {
    width: 100%;
    margin: auto;
    padding: 10px !important;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .generateLinkLight > .accordion-toggle {
    width: 100% !important;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
  }
  
  .generateLinkLight > .accordion-toggle:hover {
    background-color: #0056b3;
  }
  
  .generateLinkLight > .accordion-content {
    margin-top: 10px;
    width: 100%;
  }
  
  .generateLinkLight > .accordion-content > label {
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .generateLinkLight > .accordion-content > select,
  .generateLinkLight > .accordion-content > input,
  .generateLinkLight > .accordion-content > button {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .generateLinkLight > .accordion-content > button {
    margin-top: 15px;
    background-color: #28a745;
    color: white;
    cursor: pointer;
    border: none;
  }
  
  .generateLinkLight > .accordion-content > button:hover {
    background-color: #218838;
  }
  
  .generateLinkLight > .accordion-content > .link-box {
    margin-top: 15px;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
  }
  