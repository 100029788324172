.modalShablone {
   display: none;
   /* Hidden by default */
   position: fixed;
   /* Stay in place */
   z-index: 9;
   /* Sit on top */
   padding-top: 100px;
   /* Location of the box */
   left: 0;
   top: 0;
   width: 100%;
   /* Full width */
   height: 100%;
   /* Full height */
   overflow: auto;
   /* Enable scroll if needed */
   background-color: rgb(0, 0, 0);
   /* Fallback color */
   background-color: rgba(0, 0, 0, 0.4);
   /* Black w/ opacity */
}

/* Modal Content */
.shablonDark .modal-contentShablone {
   border-radius: 12px;
   position: relative;
   background: #21262E;
   margin: 10px auto;

   border: 1px solid #888;
   width: 325px;
   height: 50vh;
   overflow: auto;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

   -webkit-animation-name: animatetop;
   -webkit-animation-duration: 0.4s;
   animation-name: animatetop;
   animation-duration: 0.4s
}

/* The Close Button */
.closeShablone {
   color: white;
   float: right;
   font-size: 20px;
   font-weight: bold;
   display: flex;
   justify-content: end;
   align-items: center;
   align-content: center;
   width: 100%;

}

.closeShablone:hover,
.closeShablone:focus {
   opacity: 0.6;
   text-decoration: none;
   cursor: pointer;
}

/* Add Animation */
@-webkit-keyframes animatetop {
   from {
      top: -300px;
      opacity: 0
   }

   to {
      top: 0;
      opacity: 1
   }
}

@keyframes animatetop {
   from {
      top: -300px;
      opacity: 0
   }

   to {
      top: 0;
      opacity: 1
   }
}




.shablonDark .ShablonContINpt {
   display: flex;
   justify-content: space-between;
   border-radius: 12px;
   padding-left: 10px;
   background: #12151A;
   box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);

   margin: 16px 4%;

}

.shablonDark .ShablonContINpt input {


   background: #12151A;


}

.modal-HeadrShablon {
   display: flex;
   border-radius: 10px;
   background: #3B79F6;
   padding: 5px 16px;
   margin-bottom: 12px;
   align-items: center;
   align-content: center;

}

.modal-HeadrShablon h2 {
   color: #FFF;
   text-align: center;
   font-feature-settings: 'clig' off, 'liga' off;
   font-family: "Roboto";
   font-size: 16px;
   font-style: normal;
   font-weight: 600;
   line-height: 20px;
   /* 125% */
   margin: 0 !important;
}

.ShablonContINpt button {
   width: 64px;
   height: 34px;
   flex-shrink: 0;
   border-radius: 10px;
   background: #3B79F6;
   display: flex;
   justify-content: center;
   align-items: center;
   align-content: center;

}

.ShablonContINpt input {
   border: none;
   outline: none;
   color: #6B7A99;
   font-feature-settings: 'clig' off, 'liga' off;
   font-family: Inter;
   font-size: 10px;
   font-style: normal;
   font-weight: 800;
   line-height: 20px;
   /* 200% */
}

.shablonDark .searchShablonCont {
   display: flex;
   align-items: center;
   align-content: center;
   border-radius: 10px;
   background: #12151A;
   box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
   height: 32px;
   padding: 10px;
   margin: 16px 4%;

}


.shablonDark .searchShablonCont input {
   background: #12151A;
   color: #636E85 !important;
   font-feature-settings: 'clig' off, 'liga' off;
   font-family: "Roboto";
   font-size: 10px;
   font-style: normal;
   font-weight: 800;
   line-height: 20px;
   /* 200% */

}

.searchShablonCont svg {
   width: 17px;
   height: 17px;
   flex-shrink: 0;
   margin-right: 10px;
   fill: #C3CAD9;
}


.searchShablonCont input {
   color: #D2D7E2;
   font-feature-settings: 'clig' off, 'liga' off;
   font-family: Inter;
   font-size: 10px;
   font-style: normal;
   font-weight: 800;
   line-height: 20px;
   /* 200% */
   border: none;
   outline: none;
}

.searchShablonCont input::placeholder {
   /* Chrome, Firefox, Opera, Safari 10.1+ */
   color: #D2D7E2;
   font-feature-settings: 'clig' off, 'liga' off;
   font-family: Inter;
   font-size: 10px;
   font-style: normal;
   font-weight: 800;
   line-height: 20px;
   /* 200% */
}

.shablonDark .shablonCont {
   display: flex;
   justify-content: space-between;
   align-items: center;
   align-content: center;
   margin: 16px 4%;
   border-radius: 10px;
   background: #12151A;
   padding: 5px;
}

.shablonDark .shablonBtn {
   border-radius: 12px;
   border: var(--Field-sizes-Field-border-width, 1px) solid rgba(59, 121, 246, 0.10);
   background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 1;
   overflow: hidden;
   color: var(--Primary-Primary-500, #3B79F6);
   text-overflow: ellipsis;
   font-family: "Inter";
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 22px;
   /* 157.143% */
}

.shablonDark .shablonCont svg ellipse {
   fill: #12151A;
}

.shablonContChild {
   display: flex;
}

.shablonCont p {
   margin: 0 !important;
}


.shablonarray {

   height: 65%;
}

.shablonNameSpan {
   color: var(--gray-blue-grey-blue-60, #6B7A99);
   text-align: center;
   font-feature-settings: 'clig' off, 'liga' off;

   /* Bold/Bold 12 */
   font-family: Roboto;
   font-size: 12px;
   font-style: normal;
   font-weight: 700;
   line-height: 20px;
   /* 166.667% */
}

@media only screen and (max-width: 977px) {
   .shablonDark .shablonBtn {
      width: 100% !important;
      font-size: 14px !important;
   }

   .shablonDark .addTaskShablonDiv {
      margin: auto !important;
      display: flex !important;
      justify-content: center !important;
      width: 100% !important;
   }

   .shablonDark {
      width: 100% !important;
      margin: auto !important;
      display: flex !important;
      justify-content: center !important;

   }
}