@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

.ChatBoxContLight {
    display: flex;
    flex-direction: column;
    width: 943px;
    padding: 16px 32px 16px 16px;
    justify-content: space-between;
    align-items: flex-start;
    background: #F7F8FA;
    width: 100%;
    position: relative;
    bottom: 73px;
    z-index: 10;
    min-height: 93px;
    max-height: 400px !important;
    
   
    

}


.ChatBoxContLight> div:first-child{
    height: auto;
    overflow: auto;
    width: 100%;
}

.ChatBoxContLight textarea {
    background-color: #F7F8FA;
    width: 100%;
    resize: none;
    color: #353535;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    border: none;
    outline: none;
   
    /* 166.667% */

}

.ChatBoxContLight textarea::placeholder {
    color: #A1A7B3;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 166.667% */
}


.ChatBoxContLight .otherOptionContForChtatBox {
    display: flex;
    justify-content: center;
    width: 100%;
}


.ChatBoxContLight .otherOptionContForChtatBox:last-child {
    justify-content: space-between;
   
}


 
.ChatBoxContLight .iconSvgCont {
    display: flex;
    width: 33px;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #3B79F6;
    transition: 0.5s;
}

.ChatBoxContLight .iconSvgCont > svg{
    transition: 'transform 0.3s ease',
}

.ChatBoxContLight .iconSvgCont:hover {
    cursor: pointer;
    background: #7aa6ff;

}
 
.ChatBoxContLight .iconSvgCont>svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}



.ChatBoxContLight .otherButtons {

    display: flex;
    align-items: center;
    gap: 12px;
}

.ChatBoxContLight .otherButtons>div {
    display: flex;
    width: 24px !important;
    height: 24px !important;
    padding: 5px 6px 4.001px 7px;
    justify-content: center;
    align-items: center;

}
 
.ChatBoxContLight .otherButtons>div:hover {
    border-radius: 4px;
    background: #FFF;
    cursor: pointer;
}


.ChatBoxContLight .otherButtons>div>svg {
    width: 16px !important;
    height: 16px !important;
    flex-shrink: 0;
}

.imagesCont{
    display: flex;
    flex-wrap: wrap;
    max-height: 150px;
    width: 100%;
}




@media only screen and (max-width: 1024px) {
    .ChatBoxContLight{
        bottom: 60px;
    }
}