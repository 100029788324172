.openModalForSubComANDSmileDarktCont {
    position: absolute;
    display: none;
    width: 327px;
    height: 40px;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border-radius: 8px;
    border: 1px solid #313439;
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
    box-shadow: 0px 1px 7px 0px rgba(106, 113, 128, 0.04);
    z-index: 1;
    

}

.openModalForSubComANDSmileDarktCont>div {
    display: flex;
    align-items: center;
    gap: 9px;
}

.openModalForSubComANDSmileDarktCont>div>div {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 6px;
    justify-content: center;
    align-items: center;

}


.openModalForSubComANDSmileDarktCont>div>div:hover {
    background:#2D323A;
    border-radius: 24px;

}




