.SubCommentItemMessageContDark {
    display: flex;
    padding: 16px 16px 0px 16px;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
    align-self: stretch;
    border-radius: var(--Field-sizes-Field-border-width, 0px);
    min-width: 326px !important;
    background-color: #12151A;

}


.SubCommentItemMessageContDark img {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 32px;

}



.SubCommentItemMessageContDark>div:first-child {
    display: flex;

    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    border-radius: var(--Field-sizes-Field-border-width, 0px);
}


.SubCommentItemMessageContDark .frame68 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: var(--Field-sizes-Field-border-width, 0px);
}

.SubCommentItemMessageContDark .infoContSubMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--Field-sizes-Field-border-width, 0px);
}

.SubCommentItemMessageContDark .infoContSubMessage p {
    margin: 0 !important;
}

.SubCommentItemMessageContDark .infoContSubMessageSenderName {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}

.SubCommentItemMessageContDark .infoContSubMessageTime {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--Gray-400, #858C99) !important;

    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 200% */
}


.SubCommentItemMessageContDark .infoContSubMessagees {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
}

.SubCommentItemMessageContDark .infoContSubMessagees p {
    flex: 1 0 0;
    color: #A0A0A0;


    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}