

 /* new desig ==============================================================================================================*/


 .notifyCintLight{
   width: 98%;
   height: auto;
   border-radius: 35px;
   border: 0.7px solid #A7A7A7;
   background: var(--base-colors-white, #FFF);
   padding: 20px;
   margin: 10px 0;

}
.notifyCintLight .notifyCintChild {
   display: flex;
   justify-content: space-between;
   align-content: center;
   align-items: center;
}
.notifyCintLight .notifyCintChild img{
   margin-left: 50% ;
}
.notifyCintLight .notifyCintChild .css-3a5bz2{
   width: 100%;
   height: 100%;
   }

.notifyCintLight .notifyCintChild p {
   margin: 0;
}

.notifyCintLight .notifyCintChild div p {
   color: #6B7A99;
   font-feature-settings: 'clig' off, 'liga' off;
   font-family: Inter;
   font-size: 16px;
   font-style: normal;
   font-weight: 600;
   line-height: 20px;
   /* 125% */
}

.notifyCintLight .notifyCintChild div h1 {
   color: #6B7A99;
   font-feature-settings: 'clig' off, 'liga' off;
   font-family: Inter;
   font-size: 20px;
   font-style: normal;
   font-weight: 900;
   line-height: 20px;
   border-bottom: 2px solid #6B7A99;
   /* 100% */
}

.notifyCintLight .notifyCintChild div input {
   border-radius: 10px;
   border: 0.7px solid #A7A7A7;
   background: #F5F5F5;
   padding:5px 10px;
   color: #6B7A99;
   font-feature-settings: 'clig' off, 'liga' off;
   font-family: Inter;
   font-size: 12px;
   font-style: normal;
   font-weight: 600;
   line-height: 20px;
   /* 166.667% */
}

.offcanvas {
   width: 541px !important;

}

.notifyCintLight .infoTaskAndBtn {
   display: flex;
   justify-content: space-between;
   align-items: center;
   align-content: center;
   margin-top: 32px;
}

.notifyBTnConfirm svg{
   margin: 10px 0;
}

.notifyCintLight .notifyBTnConfirm > button{
   box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
   background: white;
margin: 5px 0;
border-radius: 50px;
display: flex;
justify-content: center;
align-items: center;
align-content: center;

}


.notifyCintLight .notifyBTnConfirm .modalCreatTask > button{
   box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
   background: white;
padding: 15px;
margin: 5px 0;
border-radius: 50px;
display: flex;
justify-content: center;
align-items: center;
align-content: center;

}

.notifyCintLight .infoTaskAndBtn .infoNotify {
   display: flex;
   flex-direction: column;
 
}

.notifyCintLight .infoTaskAndBtn .infoNotify>div {
   display: flex;
   justify-content: space-between;
  
  
}
.notifyCintLight .infoTaskAndBtn .infoNotify>div p:first-child{
   color: #3B79F6;
   font-feature-settings: 'clig' off, 'liga' off;
   font-family: "Inter";
   font-size: 20px;
   font-style: normal;
   font-weight: 800;
   line-height: 20px; /* 100% */
   width: 200px;
}

.notifyCintLight .infoTaskAndBtn .infoNotify>div p:last-child{
   color: #3B79F6;
   font-feature-settings: 'clig' off, 'liga' off;
   font-family: Inter;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 20px; /* 142.857% */
}


.notifyCintLight .infoTaskAndBtn .infoNotify p {
   margin: 0;
   align-content: end;

}

 .btnNotifyConfirm {
   display: flex;
   justify-content: space-between;
   align-items: end;
}

.infoNotifyText {
   width: 335px;
   border-top: 2px solid #3B79F6;
   color: #6B7A99;
   font-feature-settings: 'clig' off, 'liga' off;
   font-family: Inter;
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   margin-bottom: 10px;
   padding-top: 5px;
   /* 125% */
}
.btnNotifyConfirm svg{
   cursor: pointer;
}

.notifyBTnConfirm{
   display: flex;
   flex-direction: column;

}






 .btnNotifyConfirmMobile{
   display: flex;
   justify-content: space-between;
   margin-top: 20px;
}

.btnNotifyConfirmMobile svg{
   margin: 0 5px;
}

.notifyCintLight .btnNotifyConfirmMobile div{
   display: flex;
}

.notifyCintLight .btnNotifyConfirmMobile div button{
   box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
    background-color: #F5F5F5;
    padding: 10px;
    margin: 5px 5px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color:#3B79F6;
    width: 40px;
    height: 40px;

}











.custom-loader-deleteNotify{
   width:50px;
   height:24px;
   background: 
     radial-gradient(circle closest-side,#E62E7B 90%,#0000) 0%   50%,
     radial-gradient(circle closest-side,#E62E7B 90%,#0000) 50%  50%,
     radial-gradient(circle closest-side,#E62E7B 90%,#0000) 100% 50%;
   background-size:calc(100%/3) 12px;
   background-repeat: no-repeat;
   animation:d3 1s infinite linear;
 }
 @keyframes d3 {
     20%{background-position:0%   0%, 50%  50%,100%  50%}
     40%{background-position:0% 100%, 50%   0%,100%  50%}
     60%{background-position:0%  50%, 50% 100%,100%   0%}
     80%{background-position:0%  50%, 50%  50%,100% 100%}
 }
.btnNotifyConfirmMobile{
   display: none;
}

 @media only screen and (max-width: 784px) {
   .notifyCintLight .notifyCintChild .css-3a5bz2{
      width: 80%;
      height: 80%;
      }
   .btnNotifyConfirmMobile{
      display: flex;
   }
   .notifyCintLight .notifyBTnConfirm,.btnNotifyConfirm{
   display: none;
 }
 .notifyCintLight .infoTaskAndBtn .infoNotify{
   width: 100%;
 }
 .notifyCintLight .infoNotifyText{
   width: 100%;
 }
 .notifyCintLight .notifyCintChild {
   justify-content: space-evenly !important;
 }
 .notifyCintLight .notifyCintChild img{
   margin: 0;
 }
 .notifyCintLight .notifyCintChild  div{
   width: 181px;
 }
 .notifyCintLight .notifyCintChild  div input{
  width: 100% !important;
 }
 }

 @media only screen and (max-width: 340px) {
   .notifyCintLight .notifyCintChild .css-3a5bz2{
    width: 70%;
    height: 70%;
    }
    .notifyCintLight   .infoTaskAndBtn .infoNotify .infoNotifyText{
      font-size: 14px;
    }
    .notifyCintLight  .infoTaskAndBtn p{
      font-size: 16px !important;
    }
 }