.modal-overlay {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modal-overlay label {
  display: flex;
  padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Field-sizes-Field---Label-gap, 6px);
  align-self: stretch;
  color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));

  text-overflow: ellipsis;
  /* Field label */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  /* 121.429% */
}

.modal-overlay>div>p {
  align-self: stretch;
  color: #585858;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.modal-overlay label>input {
  display: flex;
  height: 44px;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  outline: none;
}


.modal-overlay>div:last-child {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.modal-overlay>div:last-child>div {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.modal-overlay>div:last-child>div>div:first-child {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  background: #F8FBFF;
  width: 100%;
}

.modal-overlay>div:last-child>div>div:first-child>div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}


.modal-overlay>div:last-child>div>div:first-child>div:first-child>div:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 102px;
  align-self: stretch;

}


.modal-overlay>div:last-child>div>div:first-child>div:first-child>div:nth-child(1)>div {
  display: flex;
  width: 199px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.modal-overlay>div:last-child>div>div:first-child>div:first-child>div:nth-child(1)>div>p:first-child {
  align-self: stretch;
  color: #353535;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}


.modal-overlay>div:last-child>div>div:first-child>div:first-child>div:nth-child(1)>div>p:last-child {
  align-self: stretch;
  color: #585858;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.modal-overlay>div:last-child>div>div:first-child>div:first-child>button {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
}

.modal-overlay>div:last-child>div>div:first-child>div:first-child>button>p {
  color: #3B79F6;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.modal-overlay>div:last-child>div>div:first-child>div:first-child>button>svg {
  width: 24px;
  height: 24px;
}


.modal-overlay>div:last-child>div>div:first-child>div:first-child>div:nth-child(4) {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.modal-overlay>div:last-child>div>div:first-child>div:first-child>div:nth-child(4)>input {
  display: flex;
  height: 48px;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #FFF;
  width: 100%;
  outline: none;
}

.modal-overlay>div:last-child>div>div:first-child>div:first-child>div:nth-child(4)>button {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  background: #3B79F6;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
}


.modal-overlay>div:last-child>div>div:first-child>div:first-child>.priceCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.modal-overlay>div:last-child>div>div:first-child>div:first-child>.priceCont>div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.modal-overlay>div:last-child>div>div:first-child>div:first-child>.priceCont>div>p:first-child {
  align-self: stretch;
  color: #353535;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}


.modal-overlay>div:last-child>div>div:first-child>div:first-child>.priceCont>div>p:last-child {
  color: #585858;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.modal-overlay>div:last-child>div>div:first-child>div:first-child>.priceCont>p {
  color: #353535;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 120% */
}

.modal-overlay>div:last-child>div>div:first-child>div:first-child>.priceCont>.skidkaUnderLine{
  color: #353535;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 24px;
text-decoration: line-through;
}

.modal-overlay>div:last-child>div>div:first-child>div>p {
  align-self: stretch;
  color: #585858;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}


.modal-overlay>div:last-child>div>div:first-child>div > svg{
  width: 100%;
}

.modal-overlay>div:last-child>div>.footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}

.modal-overlay>div:last-child>div>.footer>button:first-child {
  display: flex;
  height: 48px;
  padding: 16px;
  align-items: center;
  gap: 4.067px;
  color: #858C99;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.10);
}


.modal-overlay>div:last-child>div>.footer>button:last-child {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  background: #3B79F6;
}

.modal-overlay>div:last-child>div>.footer>button:last-child>div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.modal-overlay>div:last-child>div>.footer>button:last-child>div>div {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 2px;
  justify-content: center;
  align-items: center;
}

.modal-overlay>div:last-child>div>.footer>button:last-child>div>div>svg {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.modal-overlay>div:last-child>div>.footer>button:last-child>div>p {
  color: #FFF;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}