.selectPrioritetDark {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
}

.selectPrioritetDark>button {
    display: flex;
    padding: 0px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background:#21262E;
    max-width: 270px;
    min-width: 240px;
   
}

.selectPrioritetDark>button>div {
    display: flex;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    width: 100%;
    
}

.selectPrioritetDark>button>div>div {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: space-between;
    width: 100%;
}


.selectPrioritetDark>button>div>div>:first-child {
    display: flex;
    align-items: center;
    gap: 4px;
}

.selectPrioritetDark>button>div>div>:first-child>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #929292 !important;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
}

.selectPrioritetDark>button>div>div>:first-child>div>p {
    color: white !important;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.selectPrioritetDark>button>div>div>:last-child {
    width: 24px;
    height: 24px;
}


.selectPrioritetDark>button>div>div>:last-child >svg path{
    fill: white;
    stroke: white;
}


.popoverForCreatPrioritetDark {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: 0 !important;
    border: none !important;
}

.popoverForCreatPrioritetDark>div {
    display: flex;
    height: 39px;
    padding: 0px 10px;
    align-items: center;
    align-self: stretch;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #171B22;
    cursor: pointer;
}

.popoverForCreatPrioritetDark>div>div {
    display: flex;
    align-items: center;
    gap: 4px;
}

.popoverForCreatPrioritetDark>div>div >div{
    display: flex;
    align-items: center;
    gap: 4px;
}

.popoverForCreatPrioritetDark>div>div >div >p{

font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
}


.popoverForCreatPrioritetDark>div:nth-child(1) >div >div >p{
   color: red;
}

.popoverForCreatPrioritetDark>div:nth-child(2)> div >div >p{
    color: #56D4FC;
 }

 .popoverForCreatPrioritetDark>div:nth-child(3)> div >div >p{
    color: #4DFF30;
 }
 .popoverForCreatPrioritetDark>div:nth-child(4)> div >div >p{
    color: #FB50FF;
 }

 .popoverForCreatPrioritetDark>div:nth-child(5)> div >div >p{
    color: #5364FF;
 }

 .popoverForCreatPrioritetDark>div:nth-child(6)> div >div >p{
    color: #FF50A0;
 }


 @media only screen and (max-width: 1254px) {
    .selectPrioritetDark>button>div>div>:first-child>p{
        font-size: 11px !important;
    }
    .selectPrioritetDark>button>div>div>:first-child>div>p{
        font-size: 11px !important;
    }
    .selectPrioritetDark>button{
        min-width: 165px !important;
    }
  }

  @media only screen and (max-width: 1075px) {
    .selectPrioritetDark>button>div>div>:first-child>p{
        font-size: 9px !important;
    }
    .selectPrioritetDark>button>div>div>:first-child>div>p{
        font-size: 9px !important;
    }
    .selectPrioritetDark>button{
        min-width: 156px  !important;
    }
  }