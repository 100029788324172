.addCompanyCont {
   display: flex;
   flex-direction: column;
   width: 50%;
   justify-content: center;
   align-items: center;
   align-content: center;
   margin: auto;
    height:50vh;
  
}

.addCompanyCont form{
   
    padding: 20px;
}
.addCompanyCont form h1{
    margin-bottom:50px;
}

.addCompanyCont form input{
    width: 100%;
    margin: 5px 5px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    outline: 1px solid #BABABA;
}



.addCompanyCont form button{
    margin: 5px 5px;
    width: 20%;
}