.chatBodyLightCont {

  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: white;
  
}


.chatBodyLightCont>div {
  display: flex;
  flex-direction: column;
  flex-direction: column-reverse;
  padding: 25px 12px;
  padding-top: 100px;
  margin-top: auto;
  margin-bottom: 36px;
  overflow: auto;
  flex: 1;
  background-color: white;

}




/* width */
.chatBodyLightCont>div::-webkit-scrollbar {
  width: 5px;

}

/* Track */
.chatBodyLightCont>div::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.chatBodyLightCont>div::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background: #AAA;
}

/* Handle on hover */
.chatBodyLightCont>div::-webkit-scrollbar-thumb:hover {
  background: #555;
}


@media only screen and (max-width: 768px) {
  .chatBodyLightCont {
    height: 78dvh;
  }
  .chatBodyLightCont > div {
    padding-top: 70px;  /* Меньше отступа сверху для мобильных */
  }
}

.chatBodyLightCont .datemessageCont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatBodyLightCont .datemessageCont div {
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 24px;
  background: #F6F6F6;
  opacity: 1;
}

.chatBodyLightCont .datemessageCont p {
  margin: 0 !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Gray-400, #858C99);
  font-feature-settings: 'clig' off, 'liga' off;
  text-overflow: ellipsis;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  opacity: 1;
}