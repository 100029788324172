.taskTableDekstopContLight {
    overflow: auto;
    height: fit-content;
    /* height: calc(100dvh - 170px); */
    margin-top: 22px;
    margin: 15px;
    padding-bottom: 20px;
    width: 100%;
    border-radius: 10px;



}
.taskTableDekstopContLight .allProcjetCompanyNameCont{
    flex: 1 0 !important;
    color: #353535;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
}

/* width */
.taskTableDekstopContLight::-webkit-scrollbar {
    width: 1px;
}

/* Track */
.taskTableDekstopContLight::-webkit-scrollbar-track {
    background: white;
    border: none;
    outline: none;
}

/* Handle */
.taskTableDekstopContLight::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.taskTableDekstopContLight::-webkit-scrollbar-thumb:hover {
    background: white;
}


.taskTableDekstopContLight table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px;

}

.taskTableDekstopContLight table thead th>div {
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    width: 100%;
}

.taskTableDekstopContLight table thead th>div p {
    color: #929292;
    font-family: "Inter";

    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 121.429% */
}

.taskTableDekstopContLight table thead th>div svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.taskTableDekstopContLight table thead th:first-child {
    border-radius: 10px 0 0 0;
}

.taskTableDekstopContLight table thead th:last-child {
    border-radius: 0 10px 0 0;
}

.taskTableDekstopContLight table tbody tr:last-child td:first-child {
    border-radius: 0 0 0 10px;
}



.taskTableDekstopContLight table th {
    position: sticky;
    top: 0px;
    z-index: 5;
    background-color: white;
    color: #929292;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 121.429% */
    text-align: center;
    font-weight: normal;
    font-size: 18px;
    outline: 0.7px solid rgba(136, 136, 136, 0.10);
    border: 1px solid rgba(136, 136, 136, 0.10);


}



.taskTableDekstopContLight table th,
table td {

    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px;

}

.taskTableDekstopContLight table thead th {
    height: 54px;
    padding: 0px 16px;
    color: #929292;
    font-family: "Inter";
    font-size: 0.7vw;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    text-align: justify;
    text-wrap: wrap;
    /* 121.429% */
}

.taskTableDekstopContLight table thead .active >div p{
    color: #3B79F6;
}

.taskTableDekstopContLight table thead .active >div > svg path{
    stroke: #3B79F6;
}

.taskTableDekstopContLight table thead td {
    text-align: left;
    font-size: 15px;
    border: 1px solid rgba(136, 136, 136, 0.10);
    padding-left: 20px;
    background-color: rgba(136, 136, 136, 0.10);


}

.taskTableDekstopContLight table tbody td {
    font-size: 15px;
    border: 1px solid rgba(136, 136, 136, 0.10);
    background-color: white;
    text-align: center;




}

.taskTableDekstopContLight table tbody .preformerAllporjectTD > div:first-child{
    margin-bottom: 10px;
}


.taskTableDekstopContLight .taskNameAndIcons {
    display: flex !important;
    width: 100%;
    align-items: center !important;
    gap: var(--Field-sizes-Field---Label-gap, 6px) !important;
    height: 100% !important;
    cursor: pointer !important;
    padding: 0px 16px;
}


.taskTableDekstopContLight .taskNameAndIcons>div {
    padding: 0px 16px;
}


.taskTableDekstopContLight .taskNameAndIcons p {
    color: #353535;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
    flex: 1 0 0;
    text-align: start;
    word-break: break-word;
}







.PopoverTaskBTN>.taskBtn {
    display: flex;
    width: 100%;
    padding: 16px 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    background: #FFF;

}


.PopoverTaskBTN>.taskBtn>div>div {
    display: flex;
    padding: 4px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    width: 100%;
}

.PopoverTaskBTN>.taskBtn>div>div>div>div {
    display: flex;
    padding: 4px 12px;
    align-items: flex-start;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
}

.PopoverTaskBTN>.taskBtn>div>div>div>div > p{
    color: #767676;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}

.PopoverTaskBTN>.taskBtn>div>div>div>div:hover {
    border-radius: var(--Border-radius-L, 8px);
    background: #F7F8FA;
    cursor: pointer;
}

.marketplace {
    display: flex;
    justify-content: center;
    align-items: center;
}

.marketplace>div {
    display: flex;
    align-items: flex-start;
    gap: 3px;
}

.marketplace>div svg {
    width: 33px;
    height: 25px;
}

.taskTableDekstopContLight .PRIORITY {
    padding: 0px 16px;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
    color: #353535;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

.dateChange {
    display: flex;
    padding: 0px 16px;
    align-items: center;
    align-self: stretch;

}

.taskTableDekstopContLight .taskNameAndIcons p {
    flex: 1 0 0 !important;
    color: #353535;

    /* Field label */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

.taskTableDekstopContLight .priceTaskTable input {
    display: flex;
    width: 97px;
    padding: 0px 16px;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
    border: none;
    outline: none;
    color: #353535;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 114.286% */
}


.taskTableDekstopContLight table tbody td > div {
    display: flex;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    flex: 1 0 0;
}

.taskTableDekstopContLight table tbody td > div >div > p{
    color: #353535;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
}

.taskTableDekstopContLight table tbody td  >.perfomerInfo div:first-child{

flex: 0;

}


.taskTableDekstopContLight table tbody td > div .perfAllInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
}

.taskTableDekstopContLight table tbody td > div .perfAllInfo>p:first-child {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: #353535;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-align: start;
    /* 114.286% */
}


.taskTableDekstopContLight table tbody td > div .perfAllInfo>p:last-child {

    color: #929292;
    font-family: "Inter";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 160% */
}

@media only screen and (max-width: 1752px) {
    .editStatusBySelectContLight button>.Frame625852>.Frame625851>p {
        font-size: 8px !important;
    }

    .editPriorityBySelectContLight button>.Frame625852>.Frame625851>p {
        font-size: 8px !important;
    }

    .DateEditForTable>div input {
        font-size: 8px !important;


    }





    .taskTableDekstopContLight .taskNameAndIcons p {
        font-size: 10px;
    }

    .PopoverTaskBTN>.taskBtn>div>div {
        flex-direction: column !important;
    }
}

@media only screen and(max-width: 1464px) {
    .taskTableDekstopContLight table thead th>div {
        padding: 0 !important;
    }

    .editPriorityBySelectContLight {
        padding: 0;
    }
}

@media only screen and (max-width: 1606px) {

    .taskTableDekstopContLight table tbody td div {
        padding: 0 !important;
    }

    .taskTableDekstopContLight table thead th div {
        padding: 0 !important;
    }
}

@media only screen and (max-width: 1440px) {
    .taskTableDekstopContLight {
        display: none !important;
    }
}