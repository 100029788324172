.nothingFoundSearchTaskLight {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    background: #ECF0FF;
    height: 100%;
}

.nothingFoundSearchTaskLight>div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 16px;
    margin: auto;
}

.nothingFoundSearchTaskLight>div:last-child>p:first-child {
    align-self: stretch;
    color: #353535;
    text-align: center;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 160% */
}


.nothingFoundSearchTaskLight>div:last-child>p:last-child {
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 121.429% */
}