 .qr-scanner-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   border-radius: 10px;
   max-width: 400px;
   height: 100%;
 }

 .qr-scanner-header {
   margin-bottom: 10px;
 }

 .qr-scanner-header h2 {
   font-size: 24px;
   color: #333;
   margin: 0;
   text-align: center;
 }

 .qr-scanner-wrapper {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 16px;
   border-radius: 12px;
   background: #FFF;
 }

 .qr-video-wrapper {
   position: relative;
   width: 100%;
 }

 .qr-video-wrapper video {
   border-radius: 17.132px;
   width: 402px;


 }

 .qr-video-wrapper canvas {
   border-radius: 8px;
 }

 .qr-overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   pointer-events: none;
 }

 .qr-overlay::before {
   content: '';
   position: absolute;
   top: 50%;
   left: 50%;
   width: 150px;
   height: 150px;
   transform: translate(-50%, -50%);
   border: 3px solid white;
   border-radius: 8px;
   z-index: 2;

 }

 .qr-overlay::after {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;

   z-index: 1;
   clip-path: polygon(50% 50%, 50% calc(50% - 75px), 50% calc(50% + 75px),
       calc(50% - 75px) 50%, calc(50% + 75px) 50%,
       50% calc(50% - 75px), 50% calc(50% + 75px));
 }

 .camera-toggle-button {
   display: flex;
   padding: 12px 24px;
   justify-content: center;
   align-items: center;
   gap: 12px;
   align-self: stretch;
   border-radius: 12px;
   background: #3B79F6;
   color: #FFF;
   font-family: "Inter";
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 24px;
   /* 171.429% */
 }

 .camera-toggle-button:hover {
   background-color: #0056b3;
 }

 .qr-scanner-result {
   margin-top: 20px;
   font-size: 16px;
   color: #555;
   text-align: center;
 }

 @media only screen and (max-width: 600px) {
   .qr-video-wrapper video {
     height: auto !important;
     align-self: stretch !important;
   }

   .lendingQrScannModalBTNCont .chakra-modal__body {
     display: flex;
     padding: 16px !important;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     gap: 16px !important;
   }
 }

 @media only screen and (max-width: 356px) {
   .qr-scanner-container {
     width: 100% !important;
   }

   .scannerModalContLight {
     width: 97% !important;
   }

   .scannerModalContLight .chakra-modal__body {
     padding: 0 !important;
   }

   .qr-scanner-header h2 {
     font-size: 12px;
   }

   .camera-toggle-button {
     display: flex;
     justify-content: center;
     align-items: center;
     align-content: center;
     font-size: 10px;

   }
 }



 @media only screen and (max-height: 665px) {
   .lendingQrScannModalBTNCont .chakra-modal__body .modalheader {
     display: none !important;
   }

   .lendingQrScannModalBTNCont .chakra-modal__body {
     gap: 12px !important;
   }

   .qr-video-wrapper video {
     width: 310px;
     height: 295px !important;

   }
 }

 @media only screen and (max-height: 609px) {
   .qr-video-wrapper video {
     width: 310px;
   }
 }