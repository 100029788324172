.nothingFoundSearchTaskDark {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    background: #181B22;
    height: 100%;
}

.nothingFoundSearchTaskDark>div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 16px;
    margin: auto;
}

.nothingFoundSearchTaskDark>div:last-child>p:first-child {
    align-self: stretch;
    color: white;
    text-align: center;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 160% */
}


.nothingFoundSearchTaskDark>div:last-child>p:last-child {
    color: #767676;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 121.429% */
}