@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.navBarLeftDeskopContLight {
    display: flex;
    width: 305px;
    height: 100dvh;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.10);
    background: #F8FBFF;
    transition: 0.5s;
    overflow: hidden;
    overflow-y: auto;

}


/* width */
.navBarLeftDeskopContLight::-webkit-scrollbar,
.navBarLeftDeskopContLight scrollbar {
    width: 2px;
}

/* Track */
.navBarLeftDeskopContLight::-webkit-scrollbar-track,
.navBarLeftDeskopContLight scrollbar {
    background: #f1f1f1;
}

/* Handle */
.navBarLeftDeskopContLight::-webkit-scrollbar-thumb,
.navBarLeftDeskopContLight scrollbar {
    background: #888;
}

/* Handle on hover */
.navBarLeftDeskopContLight::-webkit-scrollbar-thumb:hover,
.navBarLeftDeskopContLight scrollbar {
    background: #555;
}




  
.navBarLeftDeskopContLight .navBarLeftDeskopFirstChild {
    width: 100%;
}

.navBarLeftDeskopContLight .navBarLeftDeskopFirstChild {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.navBarLeftDeskopContLight .toggleAndLogoCont {
    display: flex;
    height: 72px;
    padding: 20px 16px 20px 32px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
}


.navBarLeftDeskopContLight .logoAndSaitNameCont {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.navBarLeftDeskopContLight .logoAndSaitNameCont>div {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 1px 2px 0.143px 3px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}


.navBarLeftDeskopContLight .toggleAndLogoCont>div:last-child {
    display: flex;
    padding: 7.2px;
    justify-content: center;
    align-items: center;
    gap: 7.2px;
    border-radius: 72px;
    background: var(--White-White, #FFF);
    box-shadow: 0px 1.44px 3.6px 0px rgba(38, 51, 77, 0.03);
    cursor: pointer;
}

.navBarLeftDeskopContLight .toggleAndLogoCont>div:last-child svg {
    width: 21.6px;
    height: 21.6px;
}

.navBarLeftDeskopContLight .logoAndSaitNameCont>p {
    color: #353535;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}


.navBarLeftDeskopContLight .nameAndNavLinkItems {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.navBarLeftDeskopContLight .myInfoAndOptionIcon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
   
}

.navBarLeftDeskopContLight .Component38 {
    display: flex;
    padding: 12px 16px 12px 32px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: #ECF0FF;
    overflow: hidden;
}

.navBarLeftDeskopContLight .Component38>div:first-child {
    display: flex;
    align-items: center;
    gap: 12px;
}



.navBarLeftDeskopContLight .Component38>div:first-child p {
    color: #353535;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    /* 171.429% */
}


.navBarLeftDeskopContLight .Component38>div:last-child {
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: var(--Border-radius-L, 8px);
    background: #F8FBFF;
    cursor: pointer;
}

.navBarLeftDeskopContLight .Component38>div:last-child div {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 1px;
    justify-content: center;
    align-items: center;
}

.navBarLeftDeskopContLight .Component38>div:last-child div svg {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
}


.navBarLeftDeskopContLight .Component38>div:last-child div svg:hover{
    animation: rotate 2s linear infinite; /* Adjust duration and timing function as needed */

}



@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  

.navBarLeftDeskopContLight .leftMenuLinkItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}

.navBarLeftDeskopContLight .leftMenuLinkItem>div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}


.navBarLeftDeskopContLight .leftMenuLinkItem .Frame54 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}


.navBarLeftDeskopContLight .leftMenuLinkItem .Frame54:hover{
    background: #5E7DE8;
    
}


.navBarLeftDeskopContLight .leftMenuLinkItem .Frame54:hover .frame4 p {
    color: white;
}


.navBarLeftDeskopContLight .leftMenuLinkItem .Frame54:hover .frame4 svg path{
    stroke: white;
}


.navBarLeftDeskopContLight .leftMenuLinkItem .active{
    background: #5E7DE8;
}

.navBarLeftDeskopContLight .leftMenuLinkItem .active .badgeChatMessage{
    background-color: white !important;
    
    
}

.navBarLeftDeskopContLight .leftMenuLinkItem .active .badgeChatMessage > p{
    color: #5E7DE8 !important;
}

.navBarLeftDeskopContLight .leftMenuLinkItem .active   .frame4 p{
    color: white;
}


.navBarLeftDeskopContLight .leftMenuLinkItem .active   .frame4 svg path{
    stroke: white;
}

.navBarLeftDeskopContLight .leftMenuLinkItem .Frame15 {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}


.navBarLeftDeskopContLight .leftMenuLinkItem .Frame14 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}


.navBarLeftDeskopContLight .leftMenuLinkItem .Frame14 > div {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

}



.navBarLeftDeskopContLight .leftMenuLinkItem .comp32 {
    display: flex;
    padding: 12px 24px 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 9px;
}


.navBarLeftDeskopContLight .leftMenuLinkItem .frame4 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}


.navBarLeftDeskopContLight .leftMenuLinkItem .frame4 div {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 3px 1.801px 3px 2px;
    justify-content: center;
    align-items: center;
}

.navBarLeftDeskopContLight .leftMenuLinkItem .frame4 div>svg {
    width: 16.199px;
    height: 14px;
    flex-shrink: 0;
}


.navBarLeftDeskopContLight .leftMenuLinkItem .frame4 p {
    
    color: #353535;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    /* 171.429% */
}


.navBarLeftDeskopContLight .navBarLeftDeskopLasttChild {
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}




.navBarLeftDeskopContLight .addEmploysAndToggleThemeAndReclamCont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
}

.forSlide{

 display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
height: 250px;
width: 100%;
border-radius: 12px;
background: #EEF5FF;
}

.navBarLeftDeskopContLight .addEmploysAndToggleThemeAndReclamCont > div:first-child{
    display: flex;
padding: 0px 16px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
align-self: stretch;

}

 
.navBarLeftDeskopContLight .addEmloyLeftBtnCont{
    display: flex;
width: 273px;
padding: 12px 16px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 10px;
border-radius: 12px;
background: #3B79F6;
}


.navBarLeftDeskopContLight .addEmloyLeftBtnCont > div:first-child{
    display: flex;
justify-content: center;
align-items: center;
gap: 8px;
}

.navBarLeftDeskopContLight .addEmloyLeftBtnCont > div:first-child > div{
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px;
    justify-content: center;
    align-items: center;
}

.navBarLeftDeskopContLight .addEmloyLeftBtnCont > div:first-child p{
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
}


.navBarLeftDeskopContLight .addEmploysAndToggleThemeAndReclamCont .toggleBTNForTheme{
    display: flex;
padding: 12px 16px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
}

.navBarLeftDeskopContLight .addEmploysAndToggleThemeAndReclamCont .component38{
    display: flex;
width: 273px;
padding: 12px 16px;
justify-content: space-between;
align-items: center;
border-radius: 12px;
background: #ECF0FF;
}


.navBarLeftDeskopContLight .addEmploysAndToggleThemeAndReclamCont .component38 .frame4{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.navBarLeftDeskopContLight .addEmploysAndToggleThemeAndReclamCont .component38 .frame4 p{
    color: #353535;
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
}


.navBarLeftDeskopContLight .addEmploysAndToggleThemeAndReclamCont .component38 .frame4 svg{
    width: 20px;
height: 20px;
}

.navBarLeftDeskopContLight .navBarLeftDeskopLasttChild>div:last-child {
    display: flex;
    padding: 0px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.badgeChatMessage{
    display: flex !important;
height: 20px !important;
padding: 5px 8px !important;
justify-content: center !important;
align-items: center !important;
gap: 10px !important;
border-radius: 8px !important;
background: #5E7DE8 !important;
/* margin-left: 10px !important; */
}


.frame4{
   display: flex;
}
.frame4 .badgeChatMessage p{
    display: flex !important;
    color: var(--White-White, #FFF) !important;
text-align: center !important;
font-feature-settings: 'clig' off, 'liga' off !important;

/* Extra Bold 800/8 */
font-family: "Inter" !important;
font-size: 8px !important;
font-style: normal !important;
font-weight: 800 !important;
line-height: 12px !important; /* 150% */
margin: 0 !important;
}