.lendingQrScannModalBTNCont .chakra-modal__body {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 12px;
    background: #FFF;
}

.lendingQrScannModalBTNCont .chakra-modal__body .modalheader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}


.lendingQrScannModalBTNCont .chakra-modal__body .modalheader>h1 {
    align-self: stretch;
    color: #1E1E1E;
    text-align: center;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.lendingQrScannModalBTNCont .chakra-modal__body .modalheader>p {
    align-self: stretch;
    color: #565656;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.lendingQrScannModalBTNCont .chakra-modal__body .modalToggelBTNCont {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.lendingQrScannModalBTNCont .chakra-modal__body .modalToggelBTNCont>button:first-child {
    display: flex;
    height: 48px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 12px;
    background: #F8FBFF;
    color: #353535;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
}
.lendingQrScannModalBTNCont .chakra-modal__body .modalToggelBTNCont .active{
    background: linear-gradient(0deg, #5E7DE8 0%, #5E7DE8 100%), #FFF !important;
    color: white !important;

}

.lendingQrScannModalBTNCont .chakra-modal__body .modalToggelBTNCont>button:last-child {
    display: flex;
    height: 48px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 12px;
    background: #F8FBFF;
    color: #353535;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

@media only screen and (max-width: 600px) {
    .lendingQrScannModalBTNCont .chakra-modal__body .modalToggelBTNCont{
        flex-direction: column !important;
        width: 100%;
    }
    .lendingQrScannModalBTNCont .chakra-modal__body .modalToggelBTNCont > button{
        width: 100%;
    }
  }
  