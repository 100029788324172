.loopingCreatTaskLight {
    display: flex;
    width: 247.5px;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background: #ECF0FF;

}

.loopingCreatTaskLight>div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.loopingCreatTaskLight>div:nth-child(1)>div {
    width: 20px;
    height: 20px;
}

.loopingCreatTaskLight>div:nth-child(1)>p {
    color: #353535;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.loopingCreatTaskLight>div:nth-child(2) {
    display: flex;
    width: 32px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}




.loopingTaskLight {
    border-radius: 12px !important;
    background: #FFF !important;
}

.loopingTaskLight .chakra-modal__body {
    display: flex;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
    border-radius: 12px;
    background: #FFF;
}

.loopingTaskLight .chakra-modal__body>div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.loopingTaskLight .chakra-modal__body>div:nth-child(1)>p {
    align-self: stretch;
    color: #585858;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.loopingTaskLight .chakra-modal__body>div:nth-child(1)>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.loopingTaskLight .chakra-modal__body>div:nth-child(1)>div>p {
    color: #353535;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 100% */
}

.loopingTaskLight .chakra-modal__body>div:nth-child(1)>div>div {
    display: flex;
    padding: 6px 9px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.loopingTaskLight .chakra-modal__body>div:nth-child(1)>div>div>svg {
    width: 24px;
    height: 24px;
}


.loopingTaskLight .chakra-modal__body>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.loopingTaskLight .chakra-modal__body>div:nth-child(2)>label {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.loopingTaskLight .chakra-modal__body>div:nth-child(2)>label>input {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    outline: none !important;
}

.loopingTaskLight .chakra-modal__body>div:nth-child(2)>label>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;

    /* 121.429% */
}


/*  */
.loopingTaskLight .chakra-modal__body>div:nth-child(3) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.loopingTaskLight .chakra-modal__body>div:nth-child(3)>label {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.loopingTaskLight .chakra-modal__body>div:nth-child(3)>label>input {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    outline: none !important;
    overflow: hidden;
    color: #929292;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
}

.loopingTaskLight .chakra-modal__body>div:nth-child(3)>label>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;

    /* 121.429% */
}

/*  */

.loopingTaskLight .chakra-modal__body>div:nth-child(4) {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
}

.loopingTaskLight .chakra-modal__body>div:nth-child(4)>label {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.loopingTaskLight .chakra-modal__body>div:nth-child(4)>label>div {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    outline: none !important;
    overflow: hidden;

    color: #929292;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
}

.loopingTaskLight .chakra-modal__body>div:nth-child(4)>label>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;

    /* 121.429% */
}

.loopingTaskLight .chakra-modal__body>div:nth-child(5) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}


.loopingTaskLight .chakra-modal__body>div:nth-child(5)>div:first-child {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);

}

.loopingTaskLight .chakra-modal__body>div:nth-child(5)>div:first-child>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-overflow: ellipsis;

    /* Field label */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

.loopingTaskLight .chakra-modal__body>div:nth-child(5)>div:first-child>div>input {
    color: #353535;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.loopingTaskLight .chakra-modal__body>div:nth-child(5)>div:last-child {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    flex: 1 0 0;
}

.loopingTaskLight .chakra-modal__body>div:nth-child(5)>div:last-child>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-overflow: ellipsis;

    /* Field label */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}



.loopingTaskLight .chakra-modal__body>div:nth-child(5)>div:last-child>select {
    display: flex;
    padding: 10px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;
}

.loopingTaskLight .chakra-modal__body>div:nth-child(6) {
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    background: #ECF0FF;
}



.loopingTaskLight .chakra-modal__body>div:nth-child(6)>div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.loopingTaskLight .chakra-modal__body>div:nth-child(6)>div:first-child>p {
    color: #353535;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}


.loopingTaskLight .chakra-modal__body>div:nth-child(7) {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.loopingTaskLight .chakra-modal__body>div:nth-child(7)>button {
    display: flex;
    height: 48px;
    padding: 16px;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    background: #3B79F6;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

@media only screen and (max-width: 1632px) {
    .loopingCreatTaskLight {
        width: 100% !important;
    }
}
