.uploadFileDark {
    display: flex;
    padding: 0px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.uploadFileDark>div {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.uploadFileDark>div>div:nth-child(1) {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 16px;
}

.uploadFileDark>div>div:nth-child(1)>label {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 10px;
    flex-wrap: wrap;
}

.uploadFileDark>div>div:nth-child(1)>label>p {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: var(--shades-contrast-5, rgba(25, 59, 103, 0.05));
    color: var(--semantic-colors-primary-text, #005FDB);
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.uploadFileDark>div>div:nth-child(2) {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    width: 100%;
    flex-wrap: wrap;
    overflow: auto;
    height: 200px;
}

/* width */
.uploadFileDark>div>div:nth-child(2)::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  .uploadFileDark>div>div:nth-child(2)::-webkit-scrollbar-track {
    background: #21262E;
  }
  
  /* Handle */
  .uploadFileDark>div>div:nth-child(2)::-webkit-scrollbar-thumb {
    background: #181B22;
  }
  
  /* Handle on hover */
  .uploadFileDark>div>div:nth-child(2)::-webkit-scrollbar-thumb:hover {
    background:#C9C9C9;
    cursor: pointer;
  }




  .uploadFileDark>div>div:nth-child(2)>div {
    display: flex;
    width: 169.375px;
    height: 140px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }
  
  
  .uploadFileDark>div>div:nth-child(2)>div>img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: var(--Border-radius-L, 8px);
    filter: brightness(80%)
  
  }
  
  .uploadFileDark>div>div:nth-child(2)>div>svg {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 250px;
    height: 162px;
    border-radius: var(--Border-radius-L, 8px);




  
  }
  
  
  .uploadFileDark>div>div:nth-child(2)>div>video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: var(--Border-radius-L, 8px);
    filter: brightness(80%)
  }
  
  .uploadFileDark>div>div:nth-child(2)>div>div {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    padding: 12px 13.375px 12px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 44px;
    flex: 1 0 0;
    align-self: stretch;
    width: 100%;
    height: 100%;
    flex-direction: column-reverse;
    border-radius: 12px !important;
  
  }
  
  .uploadFileDark>div>div:nth-child(2)>div>div:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  
  .uploadFileDark>div>div:nth-child(2)>div>div>div:first-child {
    display: flex;
    width: 144px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
  }
  
  .uploadFileDark>div>div:nth-child(2)>div>div>div:first-child>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: #FFF;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 161.104%;
    /* 22.554px */
  }
  
  .uploadFileDark>div>div:nth-child(2)>div>div>div:first-child>div {
    display: flex;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
  }
  
  .uploadFileDark>div>div:nth-child(2)>div>div>div:first-child>div>p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 121.429% */
  }
  
  .uploadFileDark>div>div:nth-child(2)>div>div>div:last-child {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  
  }
  
  .uploadFileDark>div>div:nth-child(2)>div>div>div:last-child>div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  
  .uploadFileDark>div>div:nth-child(2)>div>div>div:last-child>div>div:first-child {
    display: flex;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: var(--Border-radius-L, 8px);
    background: #333846;
    cursor: pointer;
  }
  
  .uploadFileDark>div>div:nth-child(2)>div>div>div:last-child>div>div:first-child>svg {
    width: 24px;
    height: 24px;
  }
  
  
  .uploadFileDark>div>div:nth-child(2)>div>div>div:last-child>div>div:last-child {
    display: flex;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: var(--Border-radius-L, 8px);
    background: #333846;
    cursor: pointer;
  }

  .uploadFileDark>div>div:nth-child(2)>div>div>div:last-child>div>div:last-child  > .canchelDownload path{
    stroke: white;
  }
  
  .uploadFileDark>div>div:nth-child(2)>div>div>div:last-child>div>div:last-child>svg {
    width: 24px;
    height: 24px;
  
  }
  
  