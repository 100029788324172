@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

.messageItemDarkCont {
    display: flex;
    padding: 16px 32px 16px 16px;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    background: #12151A;
    margin: 16px 0;
    
}

.messageItemDarkCont:hover{
    background: #21262E;
    cursor: pointer;
}

.messageItemDarkCont p {
    margin: 0 !important;
    color: #A0A0A0 !important; 
}

.messageItemDarkCont .messageInfoCont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}

.messageItemDarkCont .frame19 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.messageItemDarkCont .frame19 .name {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}

.messageItemDarkCont .frame19 .clock {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: var(--Gray-400, #858C99);

    font-feature-settings: 'clig' off, 'liga' off;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 200% */
}

.messageItemDarkCont .frame41 {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.messageItemDarkCont .messageText {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    flex: 1 0 0;

}

.openModalForAddSubCommentAndSmileContRelative{
    position: relative;
}