.navBottomDarkCont {
    display: none;
    width: 100%;
    height: 72px;
    padding: 12px 16px 20px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.10);
    background: #0D0F12;
    position: absolute;
    bottom: 0px;

}

.navBottomDarkCont>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}


.navBottomDarkCont>div a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}


.navBottomDarkCont>div a>div {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 3px 1.801px 3px 2px;
    justify-content: center;
    align-items: center;
}

.navBottomDarkCont>div a>div svg {
    width: 16.199px;
    height: 14px;
    flex-shrink: 0;
}

.navBottomDarkCont>div a>p {
    color: #919AA1;
    font-family: "Inter";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 140% */
}

.otherMenuP {
    color: #585858 !important;
    font-family: "Inter";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    /* 140% */

}


/*  if active  */

.navBottomDarkCont>div .active svg path {

    stroke: #3B79F6;
}

.navBottomDarkCont>div .active p {
    color: #3B79F6;
}

@media only screen and (max-width: 1024px) {
    .navBottomDarkCont {
        display: flex;
    }

    .navBottomDarkCont {
        padding: 12px 60px 20px 60px;
    }
}

@media only screen and (max-width: 768px) {
    .navBottomDarkCont {
        padding: 12px 16px 20px 16px;
        z-index: 200;

    }
}

.navBottomDarkCont > .taskCneter {
    display: flex;
    width: 51px;
    height: 51px;
    padding: 5.186px;
    justify-content: center !important;
    align-items: center !important;
    flex-shrink: 0;
    border-radius: 114.75px;
    background:#373e4a;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-46%, -119%);
    cursor: pointer;
}

.navBottomDarkCont > .taskCneter>div {
    display: flex;
    width: 40.627px;
    height: 40.627px;
    padding: 8.813px 8.815px 8.814px 8.813px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 32.796px;
    background: #4680F7;
}

.navBottomDarkCont > .taskCneter>div>svg {
    width: 23px;
    height: 23px;
    flex-shrink: 0;
}

.navBottomDarkCont>div a> .otherMenuP{
    color: #919AA1 !important;
}

.navBottomDarkCont>div a>div svg{
    width: 16.199px !important;
    height: 14px !important;
    flex-shrink: 0 !important;
    stroke: #919AA1 !important;
}