.marketPlaceContDark {
    display: flex;
    padding: 12px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    background: #272C34;
}

.marketPlaceContDark>div {
    display: flex;
    align-items: flex-start;
    gap: 17px;
    align-self: stretch;
}

.marketPlaceContDark>div>div {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
}

.marketPlaceContDark>div>div>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
}


.marketPlaceContDark>div>div>div {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    flex-wrap: wrap;
}

.marketPlaceContDark>div>div>div>div {
    display: flex;
    height: 44px;
    padding: 0px 16px;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    background: #21262E;
}

.marketPlaceContDark>div>div>div>.selected {
    background-color: #3B79F6;
}


.marketPlaceContDark>div>div>div>.selected>div>p {
    color: white;
}

.marketPlaceContDark>div>div>div>div:hover {
    cursor: pointer;
    background-color: #3B79F6;
    transition: 0.5s;

}

.marketPlaceContDark>div>div>div>div>svg {
    width: 26px;
    height: 25px;
}

.marketPlaceContDark>div>div>div>div>div {
    display: flex;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
}

.marketPlaceContDark>div>div>div>div>div>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: white;
    /* 157.143% */
}

.marketPlaceContDark>div>div>div>div:hover>div>p {
    color: #FFF;
}

@media only screen and (max-width: 600px) {
    .marketPlaceContDark>div>div>div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
    }

    .marketPlaceContDark>div>div>div>div {
        width: 100%;
        justify-content: space-between;
    }
}