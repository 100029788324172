.modalDeletCompanyDark {
    background: #21262E !important;
}

.modalDeletCompanyDark>.chakra-modal__header {
    color: white;
}

.modalDeletCompanyDark>.chakra-modal__body .messeageBody {
    align-self: stretch;
    color: #FFF;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.modalDeletCompanyDark>.chakra-modal__footer>button:first-child {
    border-radius: 16px;
    border: var(--Overlay-border-width, 1px) solid rgba(255, 255, 255, 0.10);
    color: #858C99;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}