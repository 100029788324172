@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200&family=Roboto:wght@100;400&display=swap');

.devPageCont {
    display: flex;
    width: 100%;
    height: 93vh;
    min-width: 360px;
    align-items: flex-start;
    flex-wrap: wrap;
    border-radius: var(--16, 16px);
    background: var(--background-colors-gray-20, #FAFAFA);

    /* Shadows/Base/1 Level */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.devPageCont p {
    margin: 0;
    padding: 0;
    color: black;
}

.devPageCont .devPageCont1 {
    display: flex;
    min-width: 360px;
   
    padding: var(--96, 96px) 0px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--base-colors-white, #FFF);
    transition: 0.5s;
}

.devPageCont  .devPageContCenter{
    display: flex;
flex-direction: column;
align-items: center;
gap: var(--32, 32px);
}

.devPageCont .devPageCont1 img {
    width: 50px;
    height: 50px;
}

.devPageCont1 .settingImgCont{
    width: 100px; /* Set your desired image dimensions */
    height: 100px;
    background: url('./settingsICon.png') center/cover; /* Replace 'your-image.png' with your image URL */
    animation: rotate 4s linear infinite; /* Adjust the duration and animation name as needed */
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.devPageCont .devPageCont2 {
    display: flex;
    min-width: 360px;
    padding: var(--24, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--40, 40px);
    flex: 1 0 0;
    align-self: stretch;
    background: var(--base-colors-gray-40, #F5F5F5);
    
}

.devPageCont .devPageCont2 .myimg {
    display: flex;
    padding: var(--40, 40px);
    align-items: flex-end;
    gap: var(--24, 24px);
    flex: 1 0 0;
    align-self: stretch;
    border-radius: var(--12, 12px);
    background: var(--image-backgorund-7, url("./backImage.jpg"), lightgray 50% / cover no-repeat);
}


.devPageCont .Heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--12, 12px);
}

.devPageCont .Heading .errorP {
    color: var(--primary-primary-500, #3B79F6);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/12 */
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}

.devPageCont .Heading .noPageP {
    color: var(--base-colors-gray-900, #1A1A1A);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/36 */
    font-family: "Inter";
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    /* 166.667% */
}

.devPageCont .Heading .infoP {
    color: var(--base-colors-gray-400, #999);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/12 */
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}


.devPageCont .Content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--16, 16px);
    align-self: stretch;
}

.devPageCont .Content .Links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--8, 8px);
}

.devPageCont .Content .Links p {
    color: var(--primary-primary-500, #3B79F6);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/16 */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}


.devPageCont .Content .Links svg {
    width: var(--20, 20px);
    height: var(--20, 20px);
}

.devPageCont .Links2 {
    display: flex;
    min-width: 420px;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: var(--24, 24px);
    align-self: stretch;
    flex-wrap: wrap;
}

.devPageCont .Links2 p{
    color: var(--primary-primary-500, #3B79F6);
font-feature-settings: 'clig' off, 'liga' off;

/* Semibold 600/16 */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
}


@media only screen and (max-width: 1183px) {
    .devPageCont .noPageP{
            font-size: 30px !important;
            text-wrap: wrap !important;
    }
    .devPageCont .Heading .infoP{
        font-size: 10px;
    }
  }


  @media only screen and (max-width: 1005px) {
    .devPageCont .Heading .infoP{
        font-size: 10px;
       text-wrap: wrap !important;
    }
    .devPageCont .Links2{
        justify-content: center !important;
        align-self: center !important;
    }
    .devPageCont .devPageCont1 {
        height: 50vh !important;
    }
   .devPageCont .devPageCont2{
   height: 40vh !important;
   }
   .devPageCont .devPageCont1{
    padding: 0;
    padding: 20px 0;
   }
   
  }