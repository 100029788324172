.navbarTopDarkCont .navbarContForDekstop {
    display: flex;
    width: 100%;
    padding: 12px 32px 12px 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);

    background: #12151A;
    height: 72px;
}

.navbarTopDarkCont .marketplaceTopMenuCont {
    display: flex;
    height: 48px;
    align-items: center;
    gap: 16px;
}

.navbarTopDarkCont .marketplaceTopMenuCont .Frame226 {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}


.navbarTopDarkCont .marketplaceTopMenuCont .Frame226 .menu {
    display: flex;
    align-items: center;
    gap: 24px;
}

.navbarTopDarkCont .marketplaceTopMenuCont .Frame226 .menu a {
    color: white;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/12 */
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}

.navbarTopDarkCont .searchTopMenu {
    display: flex;
    width: 346px;
    height: 48px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 12px;
    border-radius: 12px;
    background: #21262E;
}

.navbarTopDarkCont .searchTopMenu>div>svg path {
    stroke: white;
    fill: white;
}

.navbarTopDarkCont .searchTopMenu input {
    color: #A8A8A8;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    outline: none;
    border: none;
    background: #21262E;
}


.navbarTopDarkCont .rightOtherComponent {
    display: flex;
    align-items: center;
    gap: 16px;
}

.navbarTopDarkCont .rightOtherComponent .Frame229 {
    display: flex;
    align-items: center;
    gap: 24px;
}


.navbarTopDarkCont .rightOtherComponent .Frame229 .Frame49 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}


.navbarTopDarkCont .rightOtherComponent .Frame229 .Frame49 .dropContTop {
    display: flex;
    align-items: flex-start;
    gap: 4px;
}


.navbarTopDarkCont .rightOtherComponent .Frame229 .Frame49 .addCompanyBTNTopNav {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    background: var(--Primary-Primary-500, #3B79F6);
}

.navbarTopDarkCont .rightOtherComponent .Frame229 .Frame49 .addCompanyBTNTopNav>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.navbarTopDarkCont .rightOtherComponent .Frame229 .Frame49 .minimizeSearch {
    display: none;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #21262E;
}

.navbarTopDarkCont .rightOtherComponent .Frame229 .Frame49 .minimizeSearch svg {
    stroke: white;
}

.navbarTopDarkCont .minimizeSearchCont {
    display: flex;
    align-items: center;
    gap: 12px;
}

.navbarTopDarkCont .logoForMobile {
    display: none;
}

.navbarTopDarkCont .rightOtherComponent .Frame229 .Frame49 .minimizeSearch>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 0.25px 0.25px 1.25px 1.25px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.navbarTopDarkCont .rightOtherComponent .Frame229 .Frame49 .minimizeSearch>div>svg {
    width: 22.5px;
    height: 22.5px;
    flex-shrink: 0;
}


.navbarTopDarkCont .rightOtherComponent .Frame229 .Frame49 .addCompanyBTNTopNav>div .svgContBTN {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px;
    justify-content: center;
    align-items: center;
}

.navbarTopDarkCont .rightOtherComponent .Frame229 .Frame49 .addCompanyBTNTopNav>div .svgContBTN svg {
    width: 21.5px;
    height: 21.5px;
    flex-shrink: 0;
}

.navbarTopDarkCont .rightOtherComponent .Frame229 .Frame49>.addCompanyBTNTopNav p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}


.navbarTopDarkCont .rightOtherComponent .Frame229 .Frame49 .questionMark {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #21262E;
}

.navbarTopDarkCont .rightOtherComponent .Frame229 .Frame49 .questionMark svg path {
    stroke: white;
}

.navbarTopDarkCont .dropContTop {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 4px;
}

.navbarTopDarkCont .rightLogoForPLanshet {
    display: none;
}


@media only screen and (max-width: 1320px) {
    .navbarTopDarkCont .searchTopMenu {
        display: none !important;
    }

    .navbarTopDarkCont .minimizeSearch {
        display: flex !important;
    }
}

@media only screen and (max-width: 1340px) {
    .rightOtherComponent .Frame229 .Frame49>.addCompanyBTNTopNav p {
        font-size: 12px !important;
    }
}

.navbarTopDarkCont .logoAndSaitNameCont {
    display: flex;
    align-items: center;
    gap: 8px;
}

.navbarTopDarkCont .PlanshetCont {
    display: none;
}

/* planshet ====================================================================================== */
@media only screen and (max-width: 1024px) {
    .navbarTopDarkCont .navbarContForDekstop {
        display: none !important;
    }

    .navbarTopDarkCont .PlanshetCont {
        display: flex;
    }

    .navbarTopDarkCont .PlanshetCont {
        display: flex;
        height: 72px;
        padding: 0px 16px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-bottom: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
        background: linear-gradient(0deg, #12151A 0%, #12151A 100%), #F8FBFF;
    }

    .navbarTopDarkCont .PlanshetContLogoCont {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .navbarTopDarkCont .PlanshetContLogoCont>div {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .navbarTopDarkCont .iconContLogo {
        display: flex;
        width: 32px;
        height: 32px;
        padding: 1px 2px 0.143px 3px;
        justify-content: center;
        align-items: center;
        background: url("logo.png") -7.714px -2.571px / 171.429% 150% no-repeat;
    }

    .navbarTopDarkCont .PlanshetContLogoCont>div>p {
        color: white;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        /* 150% */
    }


    /* right item planshet  */
    .navbarTopDarkCont .rightItemPlanshet {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .navbarTopDarkCont .minmizeSearchPlanshet {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: #21262E;
    }

    .navbarTopDarkCont .minmizeSearchPlanshet svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        stroke: white;
    }


    .navbarTopDarkCont .dropContTopPlanshet {
        display: flex;
        align-items: flex-start;
        gap: 4px;
    }

    .navbarTopDarkCont .addCompanyBTNTopNavPlanshet {
        display: flex;
        padding: 12px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        border-radius: 12px;
        background: var(--Primary-Primary-500, #3B79F6);
    }

    .navbarTopDarkCont .addCompanyBTNTopNavPlanshet>div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .navbarTopDarkCont .addCompanyBTNTopNavPlanshet>div p {
        color: #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 171.429% */
    }

    .navbarTopDarkCont .questionMarkPlanshet {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: #21262E;
    }

    .navbarTopDarkCont .questionMarkPlanshet svg path {
        stroke: white;
    }
}

/* mobile============================================================ */
.navbarTopDarkCont .NavBarTopMobileCont {
    display: none;
}

@media only screen and (max-width: 768px) {

    .PlanshetCont,
    .PlanshetContLogoCont {
        display: none !important;
    }

    .navbarTopDarkCont .NavBarTopMobileCont {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;


    }

    .navbarTopDarkCont .NavBarTopMobileCont>div {
        display: flex;
        width: 100%;

        height: 72px;
        padding: 0px 16px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        background: linear-gradient(0deg, #12151A 0%, #12151A 100%), #F8FBFF;
    }

    .navbarTopDarkCont .logoAndSearch {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .navbarTopDarkCont .logoAndSearch .imgContContMobile {
        width: 27px;
        height: 30.857px;
        flex-shrink: 0;
        background: url("logo.png") -7.714px -2.571px / 171.429% 150% no-repeat;
    }

    .navbarTopDarkCont .searchContMinimizeMobile {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: #21262E;
    }

    .navbarTopDarkCont .searchContMinimizeMobile>svg {
        stroke: white;

    }

    .navbarTopDarkCont .searchContMinimizeMobile>svg path {
        fill: white;
    }

    .navbarTopDarkCont .searchContMinimizeMobile svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;

    }

    .navbarTopDarkCont .drowerCompanyContMobile {
        display: flex;
        align-items: flex-start;
        gap: 4px;
    }

    .navbarTopDarkCont .notifyAndQueryCont {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    .navbarTopDarkCont .questionMark {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: #21262E;
    }

    .navbarTopDarkCont .questionMark svg {
        width: 24px;
        height: 24px;
        flex-shrink: 0;


    }

    .navbarTopDarkCont .questionMark svg path {
        stroke: white;

    }
}


@media only screen and (max-width: 368px) {

    .navbarTopDarkCont .NavBarTopMobileCont {
        display: flex !important;
        height: 72px !important;
        justify-content: space-between !important;
        align-items: center !important;
        align-self: stretch !important;
        width: 100%;
    }

    .navbarTopDarkCont .NavBarTopMobileCont>div {
        display: flex !important;
        height: 72px !important;
        padding: 12px 16px !important;
        justify-content: space-between !important;
        align-items: center !important;
        align-self: stretch !important;
        width: 100% !important;
        background: #181B22 !important;
    }

    .navbarTopDarkCont .logoAndSearch {
        display: flex;
        align-items: center;
        gap: 9px;
    }

    .navbarTopDarkCont .logoAndSearch .imgContContMobile {
        width: 27px;
        height: 30.857px;
        flex-shrink: 0;
    }

    .navbarTopDarkCont .searchContMinimizeMobile {
        display: flex;
        width: 36px;
        height: 36px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 11.25px;
        border-radius: 112.5px;
        background: linear-gradient(0deg, #21262e, #21262e), linear-gradient(0deg, #eef5ff, #eef5ff), #e8ecfe;
    }

    .navbarTopDarkCont .searchContMinimizeMobile>svg {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
    }

    .navbarTopDarkCont .questionMark {
        display: flex;
        width: 36px;
        height: 36px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 11.25px;
        border-radius: 112.5px;
        background: linear-gradient(0deg, #21262e, #21262e), linear-gradient(0deg, #eef5ff, #eef5ff), #e8ecfe;
    }

    .navbarTopDarkCont .questionMark>svg {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
    }

    .navbarTopDarkCont .notifyAndQueryCont {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }

    .dropdownAllNotifyContDark .notifyBtnNav {
        display: flex;
        width: 36px !important;
        height: 36px !important;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 11.25px;
        border-radius: 112.5px;
        background: #EEF5FF;
    }

    .dropdownAllNotifyCont .notifyBtnNav>svg {
        display: flex;
        width: 18px !important;
        height: 18px !important;
        padding: 1.8px 1.662px 0.9px 1.8px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }
}

/* search minimize  */

.navbarTopDarkCont .toggleSreachCont {
    display: flex;
    padding: 12px 32px 12px 16px;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    border-left: 1px solid rgba(255, 255, 255, 0.10);
    background: #12151A;
}

.navbarTopDarkCont .toggleSreachCont>label {
    display: flex;
    height: 48px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #21262E;
}

.navbarTopDarkCont .toggleSreachCont>label input {

    width: 100%;
    border: none;
    outline: none;
    color: #A8A8A8;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    background: #21262E;
    /* 171.429% */
}

.navbarTopDarkCont .toggleSreachCont>button {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #21262E;
}

.navbarTopDarkCont .toggleSreachCont>button>div {
    display: flex;
    padding: 6px 9px;
    justify-content: center;
    align-items: center;
    gap: 4px;
}


.navbarTopDarkCont .toggleSreachCont>button>svg {
    width: 24px;
    height: 24px;


}

.navbarTopDarkCont .toggleSreachCont>button>div svg path {
    stroke: white;
    fill: white;
}

.navbarTopDarkCont .toggleSreachCont>label svg path {
    stroke: white;
    fill: white;
}