.UploadFileItemsDark {
    display: flex;
    width: 280px;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: #21262E;
    margin: 3px;
    height: 60px;
}


.UploadFileItemsDark>div:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
}

.UploadFileItemsDark>div:nth-child(1)>div:nth-child(1) {
    display: flex;
    width: 40px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: var(--Border-radius-M, 4px);
    background: #12151A;
}


.UploadFileItemsDark>div:nth-child(1)>img{
    width: 40px;
align-self: stretch;
border-radius: var(--Border-radius-M, 4px);

}

.UploadFileItemsDark>div:nth-child(1)>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100px;
}

.UploadFileItemsDark>div:nth-child(1)>div:nth-child(2) > p:first-child{
    color: white;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    text-overflow: ellipsis;
    overflow: hidden;
}


.UploadFileItemsDark>div:nth-child(1)>div:nth-child(2) > p:last-child{
    color: #A1A7B3;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}

.UploadFileItemsDark>div:nth-child(2) {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    cursor: pointer;
}

.UploadFileItemsDark>div:nth-child(2)>svg {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
   
}

.UploadFileItemsDark>div:nth-child(2)>svg path{
    stroke: white;
}