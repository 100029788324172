.DateEditForTableDark {
  display: flex;
  padding: 0px 4px;
  align-items: center;
  gap: 12px;
}

.DateEditForTableDark>div {
  display: flex;
  padding: 0px 4px;
  align-items: center;
  gap: 12px;
}

@media only screen and (max-width: 1655px) {
  .DateEditForTableDark>div {
    display: flex;
    padding: 0px 4px;
    align-items: center;
    gap: 12px;
    width: 100%;
  }
}

.DateEditForTableDark>div input {
  color:white;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  min-width: 100px;
  width: 100%;
  background-color: #21262E;
  outline: none;
  
}

.DateEditForTableDark .dateChange input {
  color: #353535;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  border: none;
  outline: none;
  cursor: none;
  color-scheme: dark;
}

.DateEditForTableDark>div>div {
  display: flex;
  width: var(--Field-sizes-Field-height, 36px);
  height: var(--Field-sizes-Field-height, 36px);
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background: #333846;
}

.DateEditForTableDark>div>div svg path{
  color: white;
  fill: white;
}


.DateEditForTableDark .changeDateInput {
  width: 100%;
  
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.chageDateContentDark{
  background-color:#21262E !important;
}


.chageDateContentDark>.chakra-modal__body .changeDateInput {

  width: 100%;
}

.chageDateContentDark>.chakra-modal__header{
  color: white !important;
}

.chageDateContentDark>.chakra-modal__footer button{
  color: white !important;
}

.chageDateContentDark > button  svg path{
  fill: white !important;
  color: white !important;
  stroke: white;
}

.chageDateContentDark > .chakra-modal__body input{
  background-color: #21262E !important;
  color-scheme: dark;
  color:white;

}