@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap');

.settIngsNav {
    overflow: auto !important;
    height: calc(100% - 73px);
    /* 100% of viewport height minus 50px */
    transition: 0.5s;
    background-color: #F7F8FA !important;

}

.settIngsNav ul {
    display: flex;
    padding-top: 12px;
    padding-bottom: 15px;
}

.settIngsNav ul li {
    list-style: none;
    margin-left: 20px;
}


.settIngsNav ul li a {
    color: #8E8E93;
    text-decoration: none;
    padding-bottom: 5px;
    padding: 10px;
    transition: 0.2s;
    border-radius: 12px;
    background: #FFF;
    color: #353535;
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
}

.settIngsNav ul li a:hover {
    border-radius: 5px;
    background-color: #3B79F6 !important;
    border: none;
    opacity: 0.5;
    color: white;
}

.settIngsNav ul li .active {
    border-radius: 5px;
    background-color: #3B79F6;
    border: none;
    border-radius:12px;
    color: #FFF;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
    
}

.settIngsNavOutlet {

    width: 100%;

}

@media (max-width: 600px) {
    .settIngsNav {

        padding: 10px;

    }

    .settIngsNav ul {
        display: flex;
        justify-content: center;
        padding: 0 !important;
    }


    .settIngsNav ul li {
        margin: 10px 0;
        font-size: 10px;
        font-weight: 900;
        color: #6A7180;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
        /* 166.667% */


    }

}

.settIngsNav {
    background-color: white;
}




/* dark */
.settIngsNavDark {
    background-color: #12151A;
    transform: 0.5s;
}

.settIngsNavDark {
    overflow: auto !important;
    width: 100%;
}

.settIngsNavDark ul {
    display: flex;
    padding-top: 12px;
    padding-bottom: 15px;
}

.settIngsNavDark ul li {
    list-style: none;
    margin-left: 20px;
}


.settIngsNavDark ul li a {
    color: #8E8E93;
    text-decoration: none;
    padding-bottom: 5px;
    padding: 10px;


    transition: 0.2s;
}

.settIngsNavDark ul li a:hover {
    border-radius: 5px;
    background: #3B79F6;
    border: none;
    opacity: 0.5;
    color: white;
}

.settIngsNavDark ul li .active {
    border-radius: 5px;
    background: #3B79F6;
    border: none;

    color: white;
}

.settIngsNavOutlet {

    width: 100%;

}

@media only screen and (max-width: 617px) {
    .settIngsNavOutlet {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
}

@media (max-width: 600px) {
    .settIngsNavDark {

        padding: 10px;

    }

    .settIngsNavDark ul {
        display: none !important;
    }

    .settIngsNavDark ul {
        display: flex;
        justify-content: center;
        padding: 0 !important;
    }


    .settIngsNavDark ul li {
        margin: 10px 0;
        font-size: 10px;
        font-weight: 900;
        color: #6A7180;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-style: normal;
        font-weight: 900;
        line-height: 20px;
        /* 166.667% */


    }

}