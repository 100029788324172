.fileFlexContDark {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}


.fileFlexContDark .otherFileCont {
    display: flex;
    width: 280px;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--Border-radius-L, 8px);
    border: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #21262E;
}

.fileFlexContDark .otherFileCont>div:first-child {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
}

.fileFlexContDark .otherFileCont>div:last-child {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #3B79F6;
}

.fileFlexContDark .otherFileCont>div:first-child>div {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
}

.fileFlexContDark .otherFileCont>div:first-child>div>div:first-child {
    display: flex;
    width: 40px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: var(--Border-radius-M, 4px);
    background: #21262E;
}

.fileFlexContDark .otherFileCont>div:first-child>div>div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.fileFlexContDark .otherFileCont>div:first-child>div>div:last-child>p:first-child {
    color: white !important;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-wrap: wrap;
    width: 172px;
    display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1;
flex: 1 0 0;
overflow: hidden;
text-overflow: ellipsis;
    /* 166.667% */
}

.fileFlexContDark .otherFileCont>div:first-child>div>div:last-child>p:last-child {
    color: #A1A7B3;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}