.suppliedEmoticonsDarkCont {
    margin-top: 100px;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    margin: 0;
    background-color: #12151A;
}

.suppliedEmoticonsDarkCont .emojyContSubComment {
    display: flex;
    height: 34px;
    padding: 12px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #858C99;
}

.suppliedEmoticonsDarkCont .emojyContSubComment:hover{
    background: var(--Primary-Primary-500, #3B79F6);
    cursor: pointer;
    transition: 1s;
}


.suppliedEmoticonsDarkCont .emojyContSubComment:hover  p{
    color: white;
}

.suppliedEmoticonsDarkCont .emojyContSubComment div {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 6.563px 6.674px 6.788px 6.676px;
    justify-content: center;
    align-items: center;
}

.suppliedEmoticonsDarkCont .emojyContSubComment p {
    margin: 0 !important;
    color: #858C99;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    /* 166.667% */
}

.suppliedEmoticonsDarkCont .emojyContSubComment > .isMyIDEmojy{
    color: #FFF !important;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
}

@media only screen and (max-width: 1024px) {
    .suppliedEmoticonsDarkCont {
        display: flex ;
        align-items: flex-start ;
        align-content: flex-start ;
        gap: 12px  ;
        align-self: stretch ;
        flex-wrap: wrap ;
    }


}


