.CreatTaskDark {
    display: inline-flex;
    padding: 24px 31px 31px 16px;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    background: #181B22;
    height: 100dvh;
}

.CreatTaskDark .headerTask {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}


.CreatTaskDark .headerTask {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.CreatTaskDark .headerTask>div:first-child {
    display: flex;
    height: 48px;
    align-items: center;
    gap: 16px;
}

.CreatTaskDark .headerTask>div:last-child {
    display: flex;
    height: 48px;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);

}



@media only screen and (max-width: 501px) {
    .CreatTaskDark .headerTask {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;

    }
}

.CreatTaskDark .choosePerformerDiv {
    display: flex;
    width: 100%;
}


.CreatTaskDark .sectionOtherTask {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    overflow: auto;

}


@media only screen and (max-width: 768px) {
    .CreatTaskDark .sectionOtherTask {
        flex-direction: column !important;
    }

    .CreatTaskDark .sectionOtherTask>div:first-child {
        overflow: visible !important;
        height: auto !important;
        /* height: 1000px !important; */
        /* или overflow: hidden !important; */
    }
}

/* width */
.CreatTaskDark .sectionOtherTask::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.CreatTaskDark .sectionOtherTask::-webkit-scrollbar-track {
    background: #21262E;
}

/* Handle */
.CreatTaskDark .sectionOtherTask::-webkit-scrollbar-thumb {
    background: #21262E;
}

/* Handle on hover */
.CreatTaskDark .sectionOtherTask::-webkit-scrollbar-thumb:hover {
    background: #21262E;
    cursor: pointer;
}



.CreatTaskDark .sectionOtherTask>div:first-child {
    display: flex;
    width: 100%;
    padding: 16px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 12px;
    background: #21262E;
    height: 774px;
    overflow: hidden;
}

.CreatTaskDark .sectionOtherTask>div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 28px;

}


.CreatTaskDark .sectionOtherTask>div:first-child>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.CreatTaskDark .sectionOtherTask>div:first-child>div>label {
    display: flex;
    padding: 0px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;

}

.CreatTaskDark .sectionOtherTask>div:first-child>div>label>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: #767676;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

.CreatTaskDark .sectionOtherTask>div:first-child>div>label>input {
    align-items: flex-start;
    align-self: stretch;
    background: linear-gradient(0deg, #21262e, #21262e), #fff;
    border: 1px solid #ffffff1a;
    border: var(--Overlay-border-width, 1px) solid #ffffff1a;
    border-radius: 12px;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 44px;
    justify-content: center;
    line-height: 22px;
    outline: none;
    overflow: hidden;
    padding: 0 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}



.CreatTaskDark .sectionOtherTask>div:first-child>div>label>textarea {
    display: flex;
    min-height: 100px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    background: linear-gradient(0deg, #21262e, #21262e), #fff;
    border: 1px solid #ffffff1a;
    width: 100%;
    overflow: hidden;
    color:white;
    text-overflow: ellipsis;
    white-space: normal; /* Изменено с nowrap на normal */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    outline: none;
    overflow-y: scroll;
    height: 150px;
    resize: none;
}

.CreatTaskDark .sectionOtherTask>div:first-child>div>label>textarea::placeholder{
    color: white;
}


/* width */
.CreatTaskDark .sectionOtherTask>div:first-child>div>label>textarea::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.CreatTaskDark .sectionOtherTask>div:first-child>div>label>textarea::-webkit-scrollbar-track {
    background: #21262E;
}

/* Handle */
.CreatTaskDark .sectionOtherTask>div:first-child>div>label>textarea::-webkit-scrollbar-thumb {
    background: #C9C9C9;
}

/* Handle on hover */
.CreatTaskDark .sectionOtherTask>div:first-child>div>label>textarea::-webkit-scrollbar-thumb:hover {
    background: #C9C9C9;
}


.CreatTaskDark .sectionOtherTask>div:first-child>div>label>input::placeholder {
    overflow: hidden;
    color: white;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}

.CreatTaskDark .dateCreatDiv {
    display: flex;
    width: 100%;
    padding: 12px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background: #272C34;
}

.CreatTaskDark .dateCreatDiv>div {
    display: flex;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
}


@media only screen and (max-width: 1632px) {
    .CreatTaskDark .dateCreatDiv>div {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }


}

.CreatTaskDark .dateCreatDiv>div>div:first-child {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    border-radius: 12px;

}

@media only screen and (max-width: 1189px) {
    .CreatTaskDark .dateCreatDiv>div>div:first-child {
        flex-direction: column;
    }
}

.CreatTaskDark .dateCreatDiv>div>div:first-child>div {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
}


.CreatTaskDark .dateCreatDiv>div>div:first-child>div>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color:#76766B;
    text-overflow: ellipsis;

    /* Field label */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

.CreatTaskDark .sectionOtherTask>div:last-child {
    display: flex;
    width: 100%;
    height: 774px;
    padding: 16px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 12px;
    background:#21262E;

}



.creatTaskButton>button>p {
    color: white;
}


.backTask {
    display: flex;
    height: 24px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.backTask>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;
   
}

.backTask>div:hover{
    border-bottom: 1px solid #474747;
}

.backTask>div svg {
    width: 24px;
    height: 24px;

}


.backTask>div>p {
    color: #474747;
    text-align: center;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}