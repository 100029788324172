@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

.joinTheGroupDarkCont {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.10);
    background: #21262E;
    position: absolute;
    bottom: 60px;
    left: 0;
}


.joinTheGroupDarkCont div:last-child {
    display: flex;
    height: 60px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: #21262E;
    cursor: pointer;
}


.joinTheGroupDarkCont div:last-child>div {
    display: flex;
    align-items: center;
    gap: 12px;
}

.joinTheGroupDarkCont div:last-child>p {
    margin: 0 !important;
    color: #3B79F6;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter" !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 166.667% */
}

.joinTheGroupDarkCont  .svgCont {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 2.25px;
    justify-content: center;
    align-items: center;
}

.joinTheGroupDarkCont  .svgCont svg {
    width: 19.5px;
    height: 19.5px;
    flex-shrink: 0;
}