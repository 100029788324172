.forMobileAndPlanshetContLight {
    display: none;

}


@media only screen and (max-width: 1440px) {
    .forMobileAndPlanshetContLight {
        overflow: auto !important;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 12px;
        background: #ECF0FF;
        height: 57dvh;
        ;
    }

    .forMobileAndPlanshetContLight>div {
        width: 100%;
    }

    .forMobileAndPlanshetContLight .Component77 {
        display: flex;
        padding: 16px 16px 16px 0px;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        background-color: #FFF;
    }

    .forMobileAndPlanshetContLight .Component77 .Frame27 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex: 1 0 0;
        align-self: stretch;
    }

    .forMobileAndPlanshetContLight .Component77 .Frame626026 {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .forMobileAndPlanshetContLight .Component77 .Frame27>div:nth-child(1) {
        display: flex;
        width: 54px;
        padding: 0px 16px;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);
        align-self: stretch;
        border-right: var(--Overlay-border-width, 2px) solid rgba(136, 136, 136, 0.10);
    }

    .forMobileAndPlanshetContLight .Component77 .Frame27>div:nth-child(2) {
        display: flex;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);
        flex: 1 0 0;
    }

    .forMobileAndPlanshetContLight .Component77 .Frame27>div:nth-child(2) >p {
        flex: 1 0 0;
        color: #353535;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        word-wrap: break-word;
        word-break: break-word;
        /* 121.429% */
    }

    .forMobileAndPlanshetContLight .Component77 .Frame626026>div {
        display: flex;
        padding: 4px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        border-radius: var(--Border-radius-L, 8px);
        background: #F7F8FA;
    }

    .forMobileAndPlanshetContLight .Component77 .Frame626026>div>div>p {
        display: none;
    }

    .forMobileAndPlanshetContLight .hiddenContenet {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        background: #F8FBFF;
        overflow: hidden;
        transition: 1s;
        height: 0;


    }

    .forMobileAndPlanshetContLight .hiddenContenet>div {
        display: flex;
        padding: 0px 16px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        background: #FFF;
    }

    .forMobileAndPlanshetContLight .hiddenContenet>div>div:nth-child(2)>div button {
        width: 152px;

    }

    .forMobileAndPlanshetContLight .hiddenContenet>div>div:nth-child(2)>div button>div p {
        font-size: 14px !important;
    }

    .forMobileAndPlanshetContLight .hiddenContenet>div>div:nth-child(1) {
        display: flex;
        width: 128px;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);
        align-self: stretch;
    }

    .forMobileAndPlanshetContLight .hiddenContenet>div>div:nth-child(1) p {
        flex: 1 0 0;
        color: #929292;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        /* 121.429% */
    }

    .forMobileAndPlanshetContLight .hiddenContenet>div>div:nth-child(2)>div {
        display: flex;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);
    


    }

    .forMobileAndPlanshetContLight .hiddenContenet>div>div:nth-child(2) > .perfAllInfo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1 0 0;
    }

    .forMobileAndPlanshetContLight .hiddenContenet>div>div:nth-child(2) > .perfAllInfo > p{
        flex: 1 0;
        color: #353535;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        word-wrap: break-word;
        word-break: break-word;
    }

    .forMobileAndPlanshetContLight .hiddenContenet>div>div:nth-child(2)>div input {
        color: #353535;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 142.857% */
    }

    .forMobileAndPlanshetContLight .hiddenContenet>div>div:nth-child(2)>div>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1 0 0;
        padding: 0 !important;
    }

    .forMobileAndPlanshetContLight .hiddenContenet>div>div:nth-child(2)>div>div .DateEditForTable {
        padding: 0 !important;
    }

    .forMobileAndPlanshetContLight .hiddenContenet>div>div:nth-child(2)>div>div .DateEditForTable>div {
        padding: 0;
    }

    .forMobileAndPlanshetContLight .hiddenContenet>div>div:nth-child(2)>div>div>p:nth-child(1) {
        align-self: stretch;
        color: #353535;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 114.286% */
    }

    .forMobileAndPlanshetContLight .hiddenContenet>div>div:nth-child(2)>div>div>p:nth-child(2) {
        align-self: stretch;
        color: #929292;
        font-family: "Inter";
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 160% */
    }

    .forMobileAndPlanshetContLight .hiddenContenet>div>div:nth-child(2) {
        display: flex;
        padding: 12px 0px;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);
        flex: 1 0 0;
        min-width: 138px;
    }

    .forMobileAndPlanshetContLight .hiddenContenet>div>div:nth-child(2) > p {
        flex: 1 0 0;
        color: #353535;
        /* Field label */
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        word-wrap: break-word;
        word-break: break-word;
        /* 121.429% */
    }

    .forMobileAndPlanshetContLight .forSubTask {
        width: 100% !important;
        padding: 0 !important;
    }

    .forMobileAndPlanshetContLight .forSubTask>div {
        display: flex;
        flex-direction: column;
        width: 100% !important;
        gap: 0 !important;
    }

    .forMobileAndPlanshetContLight .forSubTask>div>div {
        width: 100% !important;
    }

    .forMobileAndPlanshetContLight .forSubTask>div>div .Component77 {
        background-color: #F8FBFF !important;
    }

    .forMobileAndPlanshetContLight .forSubTask>div>div .Component77 .Frame27>div:nth-child(1) {
        display: flex;
        width: 144px;
        height: 54px;
        align-items: center;

    }

    .forMobileAndPlanshetContLight .hiddenContenet .DateEditForTable input {
        font-size: 13px !important;
    }
@media (min-height: 722px) {
    .forMobileAndPlanshetContLight {
        padding-bottom: 8vh !important;
    }
}
@media (min-height: 664px) {
    .forMobileAndPlanshetContLight {
        padding-bottom: 12vh !important;
    }
}
@media (min-height: 444px) {
    .forMobileAndPlanshetContLight {
        padding-bottom: 18vh !important;
    }
}

}