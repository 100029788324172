.forMobileAndPlanshetContDark {
    display: none;

}


@media only screen and (max-width: 1440px) {
    .forMobileAndPlanshetContDark {
        overflow: auto !important;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 12px;
        height: 57dvh;
        
    }

    .forMobileAndPlanshetContDark>div {
        width: 100%;
    }

    .forMobileAndPlanshetContDark .Component77 {
        display: flex;
        padding: 16px 16px 16px 0px;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        background-color: #21262e;
    }

    .forMobileAndPlanshetContDark .Component77 .Frame27 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex: 1 0 0;
        align-self: stretch;
    }

    .forMobileAndPlanshetContDark .Component77 .Frame626026 {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .forMobileAndPlanshetContDark .Component77 .Frame27>div:nth-child(1) {
        display: flex;
        width: 54px;
        padding: 0px 16px;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);
        align-self: stretch;
        border-right: var(--Overlay-border-width, 2px) solid rgba(136, 136, 136, 0.10);
    }

    .forMobileAndPlanshetContDark .Component77 .Frame27>div:nth-child(1) > svg >path {
        stroke: white;
    }
    .forMobileAndPlanshetContDark .Component77 .Frame27>div:nth-child(2) {
        display: flex;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);
        flex: 1 0 0;
    }

    .forMobileAndPlanshetContDark .Component77 .Frame27>div:nth-child(2) >  p {
        flex: 1 0 0;
        color: white;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        word-wrap: break-word;
        word-break: break-word;
        /* 121.429% */
    }

    .forMobileAndPlanshetContDark .Component77 .Frame626026>div {
        align-items: flex-start;
        background: #333846;
        border-radius: 8px;
        border-radius: var(--Border-radius-L, 8px);
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 4px;
        display: flex;
        
    }

    .forMobileAndPlanshetContDark .Component77 .Frame626026>div>div>p {
        display: none;
    }

    .forMobileAndPlanshetContDark .hiddenContenet {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        background:#252a32;
        overflow: hidden;
        transition: 1s;
        height: 0;


    }

    .forMobileAndPlanshetContDark .hiddenContenet>div {
        display: flex;
        padding: 0px 16px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        background:#252a32;
    }

    .forMobileAndPlanshetContDark .hiddenContenet>div>div:nth-child(2)>div button {
        width: 152px;

    }

    .forMobileAndPlanshetContDark .hiddenContenet>div>div:nth-child(2)>div button>div p {
        font-size: 14px !important;
    }

    .forMobileAndPlanshetContDark .hiddenContenet>div>div:nth-child(1) {
        display: flex;
        width: 128px;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);
        align-self: stretch;
    }

    .forMobileAndPlanshetContDark .hiddenContenet>div>div:nth-child(1) p {
        flex: 1 0 0;
        color: #929292;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        /* 121.429% */
    }

    .forMobileAndPlanshetContDark .hiddenContenet>div>div:nth-child(2)>div {
        display: flex;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);

    }
    .forMobileAndPlanshetContDark .hiddenContenet>div>div:nth-child(2) > .perfAllInfo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1 0 0;
    }

    .forMobileAndPlanshetContDark .hiddenContenet>div>div:nth-child(2) > .perfAllInfo  > p{
        flex: 1 0;
        color: white;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        word-wrap: break-word;
        word-break: break-word;
    }



    .forMobileAndPlanshetContDark .hiddenContenet>div>div:nth-child(2)>div input {
        font-feature-settings: "clig" off, "liga" off;
        background-color: #252a32;
        border: none;
        color: #fff;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        outline: none;
    }

    .forMobileAndPlanshetContDark .hiddenContenet>div>div:nth-child(2)>div>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1 0 0;
        padding: 0 !important;
    }

    .forMobileAndPlanshetContDark .hiddenContenet>div>div:nth-child(2)>div>div .DateEditForTable {
        padding: 0 !important;
    }

    .forMobileAndPlanshetContDark .hiddenContenet>div>div:nth-child(2)>div>div .DateEditForTable>div {
        padding: 0;
    }

    .forMobileAndPlanshetContDark .hiddenContenet>div>div:nth-child(2)>div>div>p:nth-child(1) {
        align-self: stretch;
        color: #353535;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 114.286% */
    }

    .forMobileAndPlanshetContDark .hiddenContenet>div>div:nth-child(2)>div>div>p:nth-child(2) {
        align-self: stretch;
        color: #929292;
        font-family: "Inter";
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 160% */
    }

    .forMobileAndPlanshetContDark .hiddenContenet>div>div:nth-child(2) {
        display: flex;
        padding: 12px 0px;
        align-items: center;
        gap: var(--Field-sizes-Field---Label-gap, 6px);
        flex: 1 0 0;
        min-width: 138px;
    }
    .forMobileAndPlanshetContDark .hiddenContenet>div>div:nth-child(2) > svg > path{
        stroke: white;
    }

    .forMobileAndPlanshetContDark .hiddenContenet>div>div:nth-child(2) > p {
        flex: 1 0 0;
        color:white;
        /* Field label */
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        word-wrap: break-word;
        word-break: break-word;
        /* 121.429% */
    }

    .forMobileAndPlanshetContDark .forSubTask {
        width: 100% !important;
        padding: 0 !important;
    }

    .forMobileAndPlanshetContDark .forSubTask>div {
        display: flex;
        flex-direction: column;
        width: 100% !important;
        gap: 0 !important;
    }

    .forMobileAndPlanshetContDark .forSubTask>div>div {
        width: 100% !important;
    }

    .forMobileAndPlanshetContDark .forSubTask>div>div .Component77 {
        background-color: #2b3038!important;
    }

    .forMobileAndPlanshetContDark .forSubTask>div>div .Component77 .Frame27>div:nth-child(1) {
        display: flex;
        width: 144px;
        height: 54px;
        align-items: center;

    }

    .forMobileAndPlanshetContDark .hiddenContenet .DateEditForTable input {
        font-size: 13px !important;
    }

    .forMobileAndPlanshetContDark .Component77 .Frame626026>div>div svg{
        width: 24px;
        height: 24px;
    }
    @media (min-height: 722px) {
        .forMobileAndPlanshetContDark {
        padding-bottom: 59px !important;
        }
      }
      @media (min-height: 664px) {
        .forMobileAndPlanshetContDark {
            padding-bottom: 103px !important;
        }
      }

      @media (min-height: 444px) {
        .forMobileAndPlanshetContDark {
            padding-bottom: 134px !important;
        }
      }
}