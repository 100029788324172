.mydataContDark {
  display: flex;
  width: 100%;
  padding: 24px 32px 24px 16px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  height: 100dvh;
  background: linear-gradient(0deg, #181B22 0%, #181B22 100%), #ECF0FF;
}

.mydataContDark>div {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.mydataContDark>div>div:first-child {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  border-radius: 12px;
  background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
}


.mydataContDark>div>div:first-child>div {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.mydataContDark>div>div:first-child>div>label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  overflow: hidden;
  color: #767676;
  text-overflow: ellipsis;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  /* 121.429% */
}

.mydataContDark>div>div:first-child>div>label>input {
  align-items: flex-start;
  align-self: stretch;
  background: linear-gradient(0deg, #21262e, #21262e), #fff;
  border: 1px solid #ffffff1a;
  border: var(--Overlay-border-width, 1px) solid #ffffff1a;
  border-radius: 12px;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 44px;
  justify-content: center;
  line-height: 22px;
  outline: none;
  overflow: hidden;
  padding: 0 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.mydataContDark>div>div:first-child>div>label>input::placeholder {
  overflow: hidden;
  color: #FFF;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.mydataContDark>div>div:first-child>div>label>p {
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  background: #171C24;
  color: var(--semantic-colors-primary-text, #005FDB);
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.mydataContDark>div>div:first-child>div>label>div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.mydataContDark>div>div:first-child>div>label>div>svg {
  width: 24px;
  height: 24px;
}

.mydataContDark>div>div:first-child>div>label>div>svg path {
  fill: white;
}

.mydataContDark>div>div:first-child>div:nth-child(2) {
  display: flex;
  align-items: flex-start;
  gap: 17px;
  align-self: stretch;
}


.mydataContDark>div>div:first-child>div:nth-child(2)>label {
  display: flex;
  padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Field-sizes-Field---Label-gap, 6px);
  flex: 1 0 0;
  overflow: hidden;
  color: #767676;
  text-overflow: ellipsis;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  /* 121.429% */

}

.mydataContDark>div>div:first-child>div:nth-child(2)>label>div {
  height: 44px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 12px;
  border: var(--Overlay-border-width, 1px) solid rgba(255, 255, 255, 0.10);
  background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
  padding: 0px 16px;
}


.mydataContDark>div>div:first-child>div:nth-child(2)>label>div>input {
  width: 100%;
  outline: none;
  border: none;
  background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
  overflow: hidden;
  color: #929292;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.mydataContDark>div>div:first-child>div:nth-child(2)>label>div>div {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 2.25px 2.271px 2.271px 2.25px;
  justify-content: center;
  align-items: center;
}

.mydataContDark>div>div:first-child>div:nth-child(2)>label>div>div>svg path {
  fill: white;
}

.mydataContDark>div>div:first-child>div:nth-child(2)>label>input {
  display: flex;
  height: 44px;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: var(--Overlay-border-width, 1px) solid rgba(255, 255, 255, 0.10);
  background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
  overflow: hidden;
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.mydataContDark>div>div:first-child>div>label>div>p {
  color: rgba(255, 255, 255, 0.69);
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 161.104%;
  /* 25.777px */
}

.mydataContDark>div>div:first-child>button {
  display: flex;
  height: 48px;
  padding: 16px;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  background:#3B79F6;
  color:white;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */

}

.mydataContDark>div>div:first-child>button>svg path {

  stroke-width: white;

}

.mydataContDark>div>div:last-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.mydataContDark>div>div:last-child>div>div>div:last-child {

  cursor: pointer;
}

.mydataContDark>div>div:last-child>div:last-child>div {
  background-color: white !important;
}

.mydataContDark>div>div:last-child>div {
  display: flex;
  width: 440px;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 12px;
  background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
}

.mydataContDark>div>div:last-child>div>div {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: linear-gradient(0deg, #171C24 0%, #171C24 100%), #ECF0FF;
}

.mydataContDark>div>div:last-child>div>div>div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.mydataContDark>div>div:last-child>div>div>div:first-child>p {
  color: white;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
}

.mydataContDark>div>div:last-child>div>div>div:first-child>svg {
  width: 20px;
  height: 20px;

}

.mydataContDark>div>div:last-child>div>div>div:first-child>svg path {
  stroke: white;

}

.outhP {
  color: #DD5E98 !important;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
}

@media only screen and (max-width: 1139px) {
  .mydataContDark>div>div:last-child>div {
    width: 310px;
  }
}

@media only screen and (max-width: 768px) {
  .mydataContDark>div {
    flex-direction: column;
    width: 100%;
    overflow: auto;
    padding-bottom: 150px;
  }

  /* width */
  .mydataContDark>div::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  .mydataContDark>div::-webkit-scrollbar-track {
    background: #181B22;
  }

  /* Handle */
  .mydataContDark>div::-webkit-scrollbar-thumb {
    background: rgb(228, 222, 222);
  }

  /* Handle on hover */
  .mydataContDark>div::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .mydataContDark>div>div:first-child {
    width: 100%;
  }

  .mydataContDark>div>div:last-child {
    width: 100%;
  }

  .mydataContDark>div>div:last-child>div {
    width: 100%;
  }
}

/* modal */
.logoutConfirmConteDark .chakra-modal__body>p {
  align-self: stretch;
  color: white;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.logoutConfirmConteDark .chakra-modal__header {
  background: #21262E;
  color: white;
}

.logoutConfirmConteDark .chakra-modal__body {
  background: #21262E;
}

.logoutConfirmConteDark .chakra-modal__footer {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  background: #21262E;
}

.logoutConfirmConteDark>button {
  color: white;
}

.logoutConfirmConteDark .chakra-modal__footer>button:first-child {
  display: flex;
  height: 48px;
  padding: 16px;
  align-items: center;
  gap: 4.067px;
  border-radius: 16px;
  border: var(--Overlay-border-width, 1px) solid rgba(255, 255, 255, 0.10);
  color: #858C99;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background: #21262E;
  /* 142.857% */
}

.logoutConfirmConteDark .chakra-modal__footer>button:last-child {
  display: flex;
  height: 48px;
  padding: 16px;
  align-items: center;
  gap: 4.067px;
  border-radius: 16px;
  background: #EE6B6B;
  color: #FFF;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

