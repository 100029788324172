.taskTableDekstopContDark {
    overflow: auto;
    height: fit-content;
    /* height:calc(100dvh - 170px); */
    margin-top: 22px;
    margin: 15px;
    padding-bottom: 20px;
    width: 100%;
    border-radius: 10px;



}

.taskTableDekstopContDark .allProcjetCompanyNameCont{
    flex: 1 0 !important;
    color: white;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
}

/* width */
.taskTableDekstopContDark::-webkit-scrollbar {
    width: 1px;
}

/* Track */
.taskTableDekstopContDark::-webkit-scrollbar-track {
    background: white;
    border: none;
    outline: none;
}

/* Handle */
.taskTableDekstopContDark::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.taskTableDekstopContDark::-webkit-scrollbar-thumb:hover {
    background: white;
}


.taskTableDekstopContDark table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px;

}
.taskTableDekstopContDark table thead th{
    background-color: #21262E;
}
.taskTableDekstopContDark table thead th>div {
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    width: 100%;
 
}

.taskTableDekstopContDark table thead th>div p {
    color: #929292;
    font-family: "Inter";

    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 121.429% */
}

.taskTableDekstopContDark table thead .active >div p{
    color: #3B79F6;
}

.taskTableDekstopContDark table thead .active >div > svg path{
    stroke: #3B79F6;
}

.taskTableDekstopContDark table thead th>div svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.taskTableDekstopContDark table thead th:first-child {
    border-radius: 10px 0 0 0;
}

.taskTableDekstopContDark table thead th:last-child {
    border-radius: 0 10px 0 0;
}

.taskTableDekstopContDark table tbody tr:last-child td:first-child {
    border-radius: 0 0 0 10px;
}



.taskTableDekstopContDark table th {
    position: sticky;
    top: 0px;
    z-index: 5;
    background-color: white;
    color: #929292;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 121.429% */
    text-align: center;
    font-weight: normal;
    font-size: 18px;
    outline: 0.7px solid rgba(136, 136, 136, 0.10);
    border: 1px solid rgba(136, 136, 136, 0.10);


}



.taskTableDekstopContDark table th,
table td {

    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px;

}

.taskTableDekstopContDark table thead th {
    height: 54px;
    padding: 0px 16px;
    color: #929292;
    font-family: "Inter";
    font-size: 0.7vw;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    text-align: justify;
    text-wrap: wrap;
    /* 121.429% */
}

.taskTableDekstopContDark table thead td {
    text-align: left;
    font-size: 15px;
    border: 1px solid rgba(136, 136, 136, 0.10);
    padding-left: 20px;
    background-color: rgba(136, 136, 136, 0.10);


}

.taskTableDekstopContDark table tbody .preformerAllporjectTD > div:first-child{
    margin-bottom: 10px;
}

.taskTableDekstopContDark table tbody td {
    font-size: 15px;
    border: 1px solid rgba(136, 136, 136, 0.10);
    background-color: #21262E;
    text-align: center;
}

.taskTableDekstopContDark table tbody .subTaskTr td{
    background-color: #2B3038;
}

.taskTableDekstopContDark table tbody td button >svg path{
    stroke: white;
}


.taskTableDekstopContDark .taskNameAndIcons {
    display: flex !important;
    width: 100%;
    align-items: center !important;
    gap: var(--Field-sizes-Field---Label-gap, 6px) !important;
    height: 100% !important;
    cursor: pointer !important;
    padding: 0px 16px;
}

.taskTableDekstopContDark .taskNameAndIcons > svg path{
    stroke: white;
    
}

.taskTableDekstopContDark .taskNameAndIcons>div {
    padding: 0px 16px;
}


.taskTableDekstopContDark .taskNameAndIcons p {
    color: #353535;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
    flex: 1 0 0;
    text-align: start;
    word-break: break-word;
}



.PopoverTaskBTNDark{
    border: 1px solid #36393F !important;
}
 .PopoverTaskBTNDark>.taskBtn {
    display: flex;
    width: 100%;
    padding: 16px 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    background:#21262E;

}




.PopoverTaskBTNDark>.taskBtn>div>div {
    display: flex;
    padding: 4px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    width: 100%;
}

.PopoverTaskBTNDark>.taskBtn>div>div > div:nth-child(2) svg path{
   stroke: white;
}

.PopoverTaskBTNDark>.taskBtn>div>div>div>div {
    display: flex;
    padding: 4px 12px;
    align-items: flex-start;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
}

.PopoverTaskBTNDark>.taskBtn>div>div>div>div >p{
   color: white;
font-feature-settings: 'liga' off, 'clig' off;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
}

.PopoverTaskBTNDark>.taskBtn>div>div>div>div:hover {
    border-radius: var(--Border-radius-L, 8px);
    background: #181B22;
    cursor: pointer;
}

.marketplace {
    display: flex;
    justify-content: center;
    align-items: center;
}

.marketplace>div {
    display: flex;
    align-items: flex-start;
    gap: 3px;
}

.marketplace>div svg {
    width: 33px;
    height: 25px;
}

.taskTableDekstopContDark .PRIORITY {
    padding: 0px 16px;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
    color: #353535;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

.dateChange {
    display: flex;
    padding: 0px 16px;
    align-items: center;
    align-self: stretch;

}

.taskTableDekstopContDark .taskNameAndIcons p {
    flex: 1 0 0 !important;
    color: white;

    /* Field label */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

.taskTableDekstopContDark .priceTaskTable input {
    display: flex;
    width: 97px;
    padding: 0px 16px;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
    border: none;
    outline: none;
    background-color: #21262E;
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
}


.taskTableDekstopContDark table tbody td > div {
    display: flex;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    flex: 1 0 0;
}

.taskTableDekstopContDark table tbody td > div >div > p{
    color: white;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
}



.taskTableDekstopContDark table tbody td > div .perfAllInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
}

.taskTableDekstopContDark table tbody td >.perfomerInfo > div:first-child{
    flex: 0 !important;
    
}

.taskTableDekstopContDark table tbody td >  div .perfAllInfo>p:first-child {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: white;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-align: start;
    /* 114.286% */
}


.taskTableDekstopContDark table tbody td > div .perfAllInfo>p:last-child {
    color: #929292;
    font-family: "Inter";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 160% */
}
@media only screen and (max-width: 1752px) {
    .editStatusBySelectContLight button>.Frame625852>.Frame625851>p {
        font-size: 8px !important;
    }

    .editPriorityBySelectContLight button>.Frame625852>.Frame625851>p {
        font-size: 8px !important;
    }

    .DateEditForTable>div input {
        font-size: 8px !important;


    }

   



    .taskTableDekstopContDark .taskNameAndIcons p {
        font-size: 10px;
    }

    .PopoverTaskBTNDark>.taskBtn>div>div {
        flex-direction: column !important;
    }
}

@media only screen and(max-width: 1464px) {
    .taskTableDekstopContDark table thead th>div {
        padding: 0 !important;
    }

    .editPriorityBySelectContLight {
        padding: 0;
    }
}
@media only screen and (max-width: 1606px) {

    .taskTableDekstopContDark table tbody td > div{
        padding: 0 !important;
    }
    .taskTableDekstopContDark table thead th div{
        padding: 0 !important;
    }
}
@media only screen and (max-width: 1440px) {
    .taskTableDekstopContDark {
        display: none !important;
    }
}

