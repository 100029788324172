@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap');

.chatHeaderDarktCont {
    position: absolute;
    width: 100%;
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 32px 16px 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #303540;
    background: #12151A;
    top: 0;
    left: 0;
    z-index: 1;

}

.chatHeaderDarktCont .searchContForMobile {
    display: none;
}

.chatHeaderDarktCont .chatHeaderLightContChild1 {
    display: flex;
    align-items: center;

}


.chatHeaderDarktCont .chatHeaderLightContChild2 {
    display: flex;
    align-items: center;
    gap: 16px;

}

.chatHeaderDarktCont .chatHeaderLightContChild1 .chatNameConts {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.chatHeaderDarktCont div:first-child div p {
    margin: 0 !important;


}


  .chatHeaderDarktCont .chatName {
    margin: 0 !important;
    color: white !important;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.chatHeaderDarktCont .chatTypeNameAndParticipants {
    margin: 0 !important;
    color: #A0A0A0;


    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

 
.chatHeaderDarktCont .pinCont {
    display: flex;
    align-items: flex-start;
}


.chatHeaderDarktCont .pinCont div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px 3.017px 1.526px 2.321px;
    justify-content: center;
    align-items: center;
}


.chatHeaderDarktCont .pinCont div svg {
    width: 18.661px;
    height: 21.224px;
    flex-shrink: 0;
    cursor: pointer;
}

.chatHeaderDarktCont .videoChatContIcon {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 3.25px 0.25px;
    justify-content: center;
    align-items: center;
}

 .videoChatContIcon svg {
    width: 23.5px;
    height: 17.5px;
    flex-shrink: 0;
}

.infoCont {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 2.25px;
    justify-content: center;
    align-items: center;
}

.infoCont svg {
    width: 19.5px;
    height: 19.5px;
    flex-shrink: 0;
}

.chatHeaderDarktCont .chatHeaderLightContChild2 .searchByChat {
    display: flex;
    width: 240px;
    height: 40px;
    padding: 0px 16px;
    align-items: center;

    gap: 4px;
    border-radius: 8px;
    border: 1px solid #303540;
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), var(--Base-Colors-White, #FFF);
}


.searchSvgCont {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.chatHeaderDarktCont  .searchByChat input {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #21262E;
    font-feature-settings: 'clig' off, 'liga' off;
    border: none;
    outline: none;
    color: var(--Gray-300, #A1A7B3);

    /* Semibold 600/12 */
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
}

.backBTNforMobile {
    display: none;
}

.searchByChatForMobile {
    display: none;
}

/* for tablet */
@media only screen and (max-width: 1024px) {
    .chatHeaderDarktCont   .chatHeaderLightContChild2  .searchByChat {
        display: flex !important;
        width: 168px;
        height: 40px;
        padding: 0px 16px;
        justify-content: space-between;
        align-items: center;
    }

    .chatHeaderDarktCont   .chatHeaderLightContChild2  .searchByChat input {
        width: 100px;
    }

    .chatHeaderDarktCont    .infoCont {
        display: none;
    }
}

/* for mobile */
@media only screen and (max-width: 767px) {
    .chatHeaderDarktCont   .chatHeaderLightContChild2  .searchByChat {
        display: flex !important;
        width: 168px;
        height: 40px;
        padding: 0px 16px;
        justify-content: space-between;
        align-items: center;
    }

    .chatHeaderDarktCont    .backBTNforMobile {
        display: flex;
        width: 24px;
        height: 24px;
        padding: 6.25px 3.25px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-right: 16px;
    }

    .chatHeaderDarktCont   .backBTNforMobile svg {
        width: 17.5px;
        height: 11.5px;
        flex-shrink: 0;
    }

    .chatHeaderDarktCont   .searchByChat input {
        width: 100px !important;

    }

    .chatHeaderDarktCont   .infoCont {
        display: none;
    }

    .chatHeaderDarktCont div:first-child div p {
        margin: 0 !important;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        align-self: stretch;
        overflow: hidden;
        color: var(--Gray-500, #6A7180);
        font-feature-settings: 'clig' off, 'liga' off;
        text-overflow: ellipsis;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 166.667% */

    }

    .chatHeaderDarktCont   .chatHeaderLightContChild2  .searchByChat {
        display: none !important;
    }

    .chatHeaderDarktCont    .searchByChatForMobile {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
    }

    .chatHeaderDarktCont   .searchByChatForMobile svg {
        width: 24px;
        height: 24px;
    }

    .chatHeaderDarktCont  .searchContForMobileShow {
        display: flex;
        width: 100%;
        height: 40px;
        padding: 0px 16px;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        border-bottom: 1px solid #21262E;
        background: #12151A;
        animation: widthAnimation 0.4s ease-in-out  alternate;
    }

    .chatHeaderDarktCont   .searchContForMobileShow input {
        display: flex;
        width: 100%;
        height: 20px;
        padding: 0px 16px;
        justify-content: space-between;
        align-items: center;
        border: none;
        outline: none;
        background: #12151A;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Semibold 600/12 */
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 166.667% */

    }
    .chatHeaderDarktCont  .searchContForMobileShow input::placeholder {
        display: flex;
        width: 100%;
        height: 20px;
        padding: 0px 16px;
        justify-content: space-between;
        align-items: center;
        border: none;
        outline: none;
        color: var(--Gray-300, #A1A7B3);
        font-feature-settings: 'clig' off, 'liga' off;

        /* Semibold 600/12 */
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 166.667% */

    }

}

@keyframes widthAnimation {
    from {
      width: 20%;
    }
    to {
      width: 100%;
    }
  }