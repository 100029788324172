.paymentContLight {
    display: flex;
    width: 100%;
    padding: 24px 32px 24px 16px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
}

.paymentContLight>div {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    background: #FFF;
}

.paymentContLight>div>div:first-child {
    display: flex;
    height: 66px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.paymentContLight>div>div:first-child>h2 {
    align-self: stretch;
    color: #353535;
    text-align: center;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */
}


.paymentContLight>div>div:first-child>p {
    align-self: stretch;
    color: #585858;
    text-align: center;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.content .toggleMountCont {
    display: flex;
    padding: 4px;
    align-items: flex-start;
    gap: 4px;
    border-radius: 12px;
    background: #F8FBFF;
}



.content .toggleMountCont>.active {
    display: flex;
    width: 78px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #F8FBFF;
    color: #353535;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.content .toggleMountCont>button:last-child {
    display: flex;
    width: 78px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #5E7DE8;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}


.content .paymentContInfo {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.content .paymentContInfo .monthlySelectedPlan {
    display: flex;
    width: 50%;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 8px;
    background: #FFF;
}

.content .paymentContInfo .monthlySelectedPlan>div {
    display: flex;
    padding: 24px 24px 12px 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 12px;
    background: #F8FBFF;
    gap: 50px;
}



.content .paymentContInfo .monthlySelectedPlan>div>div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.content .paymentContInfo .monthlySelectedPlan>div>div:first-child>div:first-child {
    display: flex;
    height: 165px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}



.content .paymentContInfo .monthlySelectedPlan>div>div:first-child>div:first-child>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.content .paymentContInfo .monthlySelectedPlan>div>div:first-child>div:first-child>div>p {
    color: var(--Neutral-Title, #262626);
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    opacity: 0.5;
}

.content .paymentContInfo .monthlySelectedPlan>div>div:first-child>div:first-child>div>div {
    display: flex;
    align-items: flex-end;
    gap: 8px;
}

.content .paymentContInfo .monthlySelectedPlan>div>div:first-child>div:first-child>div>div>p:first-child {
    color: var(--Neutral-Title, #262626);
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    /* 112.5% */
}

.content .paymentContInfo .monthlySelectedPlan>div>div:first-child>div:first-child>div>div>p:last-child {
    color: var(--Neutral-Title, #262626);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    /* 156.25% */
    text-decoration-line: strikethrough;
    opacity: 0.5;
}

.content .paymentContInfo .monthlySelectedPlan>div>div:first-child>div:first-child>p {
    width: 310px;
    color: var(--Neutral-Body-text, #808080);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}


.content .paymentContInfo .monthlySelectedPlan>div>div:first-child>div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.content .paymentContInfo .monthlySelectedPlan>div>div:first-child>div:last-child>div {
    display: flex;
    align-items: center;
    gap: 12px;
}

.content .paymentContInfo .monthlySelectedPlan>div>div:first-child>div:last-child>div>p {
    color: #808080;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    /* 143.75% */
}

.content .paymentContInfo .monthlySelectedPlan>div>div:first-child>div:last-child>div>svg {
    width: 24px;
    height: 24px;
}

.content .paymentContInfo .monthlySelectedPlan>div>button {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #3B79F6;
   
}

.content .paymentContInfo .monthlySelectedPlan>div>button:disabled{
    opacity: 0.5 !important;
    cursor: no-drop !important;
}

.content .paymentContInfo .monthlySelectedPlan>div>button>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.content .paymentContInfo .monthlySelectedPlan>div>button>div>p {
    color: #3B79F6;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.content .paymentContInfo .monthlySelectedPlan>div>button>div>svg {
    width: 24px;
    height: 24px;
}


/* ========================================================================== */




.selectThisPlan {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 12px;
    background: #FFF;
}

.selectThisPlan>div {
    display: flex;
    padding-bottom: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.selectThisPlan>div>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}


.selectThisPlan>div>div>div:nth-child(1) {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #3B79F6;
    position: relative;
}



.selectThisPlan>div>div>div:nth-child(1)>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.selectThisPlan>div>div>div:nth-child(1)>div>svg {
    width: 142px;
    height: 142px;
    transform: rotate(0deg);
    position: absolute;
    right: 0;
    top: -1.23px;
    fill: #FFF;
}


.selectThisPlan>div>div>div:nth-child(1)>div>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.selectThisPlan>div>div>div:nth-child(1)>div>div>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.selectThisPlan>div>div>div:nth-child(1)>div>div>div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.selectThisPlan>div>div>div:nth-child(1)>div>div>div>p {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    opacity: 0.7;
}



.selectThisPlan>div>div>div:nth-child(1)>div>div>div>div {
    display: flex;
    align-items: flex-end;
    gap: 8px;
}



.selectThisPlan>div>div>div:nth-child(1)>div>div>div>div>p:first-child {
    color: #FFF;
    font-family: Manrope;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 35.2px */
}



.selectThisPlan>div>div>div:nth-child(1)>div>div>div>div>p:last-child {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    /* 156.25% */
    text-decoration-line: strikethrough;
    opacity: 0.5;
}

.selectThisPlan>div>div>div:nth-child(1)>div>div>p {
    width: 318px;
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    opacity: 0.78;
}


.selectThisPlan>div>div>div:nth-child(2),
.selectThisPlan>div>div>div:nth-child(3) {
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.selectThisPlan>div>div>div:nth-child(2)>div {
    display: flex;
    align-items: center;
    gap: 12px;
}

.selectThisPlan>div>div>div:nth-child(2)>div>p {
    color: var(--Neutral-Body-text, #808080);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    /* 143.75% */
}

.selectThisPlan>div>div>div:nth-child(2)>div>svg {
    width: 24px;
    height: 24px;
}


.selectThisPlan>div>button {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    background: #3B79F6;

}

.selectThisPlan>div>button>div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.selectThisPlan>div>button>div>p {
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.selectThisPlan>div>button>div>svg {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 2px;
    justify-content: center;
    align-items: center;
}


@media only screen and (max-width: 763px) {
    .content {
        width: 100%;
    }

    .content .paymentContInfo {
        flex-direction: column !important;
        width: 100%;
        overflow: auto;
        height: calc(100dvh - 430px);
    }

    .content .paymentContInfo .monthlySelectedPlan {
        width: 100%;
    }

    .selectThisPlan {
        width: 100%;
    }

    .paymentContLight>div {
        gap: 61px;
    }
}