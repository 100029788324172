@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200&family=Roboto:wght@100;400&display=swap');
.errorCont {
    display: flex;
    width: 100%;
    height: 100vh !important;
    min-width: 360px;
    justify-content: center;
    align-items: flex-start;
}

 .errorCont img {
    width: 112.25px;
    height: 112.25px;
}

.errorCont p {
    margin: 0;
    padding: 0;
    color: black;

}

.errorCont .errorContChild {
    display: flex;
    padding: 96px 0px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    
}


.errorCont .Center {
    display: flex;
    padding: 0px 15px;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    align-self: stretch;
}

.errorCont .Heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.errorCont .Heading .errorP{
    color: var(--primary-primary-500, #3B79F6);
font-feature-settings: 'clig' off, 'liga' off;

/* Semibold 600/12 */
font-family: "Inter";
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 166.667% */
}

.errorCont .Heading .defaundP{
    color: var(--base-colors-gray-900, #1A1A1A);
    font-feature-settings: 'clig' off, 'liga' off;
    
    /* Semibold 600/36 */
    font-family: "Inter";
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px; /* 166.667% */
}


.errorCont .Heading .InfoP{
    align-self: stretch;
    color: var(--base-colors-gray-400, #999);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;

/* Semibold 600/12 */
font-family: "Inter";
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 166.667% */

}

.errorCont .Items {
    display: flex;
    width: 330px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.errorCont .itemsChild1 {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-bottom: 1px solid var(--base-colors-gray-100, #E6E6E6);
}

.errorCont .iconBaseCont {
    display: flex;
    width: 40px;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid var(--base-colors-gray-100, #E6E6E6);
    background: var(--base-colors-white, #FFF);

    /* Shadows/Base/1 Level */
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
}

.errorCont .itemsChild1 svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.errorCont .itemsChild1Cont {
    display: flex;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.errorCont .itemsChild1Cont p {
    color: var(--base-colors-gray-700, #4D4D4D);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/14 */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}

.errorCont .Links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.errorCont .Links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.errorCont .linksCont {
    display: flex;
    align-items: flex-start;
    gap: 24px;
}

.errorCont .linksCont p {
    color: var(--primary-primary-500, #3B79F6);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/16 */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    cursor: pointer;
}

.errorCont .linksCont2 {
    display: flex;
    width: 190px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
 
}

.errorCont .linksCont2 svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.errorCont .linksCont2 p {
    color: var(--primary-primary-500, #3B79F6);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Semibold 600/16 */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    cursor: pointer;
}


@media only screen and (max-width: 600px) {
    .errorCont .defaundP{
        font-size: 30px !important;
   }
   .errorCont  .Heading .InfoP{
    font-size: 10px !important;
   }
   .errorCont  .Center{
    margin-bottom: 20px;
   }
  }