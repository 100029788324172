.navBarLeftDeskopContLightMinimize {
    display: flex;
    width: 100px;
    height: 100dvh;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    border-right: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #F8FBFF;
    transition: 0.5s;
    overflow: hidden;
    overflow-y: auto;
    
}


/* width */
.navBarLeftDeskopContLightMinimize::-webkit-scrollbar {
    width: 2px;
  }
  
  /* Track */
  .navBarLeftDeskopContLightMinimize::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .navBarLeftDeskopContLightMinimize::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .navBarLeftDeskopContLightMinimize::-webkit-scrollbar-thumb:hover {
    background: #555;
  }


.navBarLeftDeskopContLightMinimize .navBarLeftDeskopFirstChild {
    width: 100%;
}

.navBarLeftDeskopContLightMinimize .logoAndSaitNameCont {
    display: none;

}




.navBarLeftDeskopContLightMinimize .toggleAndLogoCont {
    display: flex;
    height: 72px;
    padding: 20px 16px 20px 32px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-bottom: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);

}


.navBarLeftDeskopContLightMinimize .toggleAndLogoCont>div:last-child {
    display: flex;
    padding: 7.2px;
    justify-content: center;
    align-items: center;
    gap: 7.2px;
    border-radius: 72px;
    background: var(--White-White, #FFF);

    /* Shadows/Gray Blue 30/3%/5b */
    box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
    cursor: pointer;
}

.navBarLeftDeskopContLightMinimize .Component38>div:last-child {
    display: none;
}

.navBarLeftDeskopContLightMinimize .Component38>div:first-child {
    display: flex;
    padding: 12px 16px 12px 32px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: #ECF0FF;
    margin-top: 12px;
}


.navBarLeftDeskopContLightMinimize .Component38>div:first-child p {
    display: none;
   
}


.navBarLeftDeskopContLightMinimize .leftMenuLinkItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}


.navBarLeftDeskopContLightMinimize .leftMenuLinkItem .Frame54 {
    display: flex;
    padding: 2px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    margin-bottom: 8px;
    
   
}

.navBarLeftDeskopContLightMinimize .leftMenuLinkItem  .frame4 > div{
    display: flex;
    width: 20px;
    height: 20px;
    padding: 3px 1.801px 3px 2px;
    justify-content: center;
    align-items: center;
    margin-left: 23%;
}
.navBarLeftDeskopContLightMinimize .leftMenuLinkItem .active {
    background: #5E7DE8;
}



.navBarLeftDeskopContLightMinimize .leftMenuLinkItem .active .badgeChatMessage{
    background-color: white !important;
    margin-left: 23%;
    
}

.navBarLeftDeskopContLightMinimize .leftMenuLinkItem .active .badgeChatMessage > p{
    color: #5E7DE8 !important;
}


.navBarLeftDeskopContLightMinimize .leftMenuLinkItem .active .Frame14 .comp32 .frame4 svg path{
    stroke: white;
}

.navBarLeftDeskopContLightMinimize .leftMenuLinkItem .Frame54 .Frame15 {
    display: flex;
    padding: 12px 24px 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 9px;
}


.navBarLeftDeskopContLightMinimize .leftMenuLinkItem .Frame54:hover{
    background: #5E7DE8;
}

/* .navBarLeftDeskopContLightMinimize .leftMenuLinkItem .Frame54:hover .Frame14 .comp32  {
    display: flex;
    width: 20px;
    height: 20px;
    padding: 3px 1.501px 3.001px 1px;
    justify-content: center;
    align-items: center;
} */

/* .navBarLeftDeskopContLightMinimize .leftMenuLinkItem .Frame54:hover .Frame14 .comp32 .frame4{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
} */

.navBarLeftDeskopContLightMinimize .leftMenuLinkItem .Frame54:hover .Frame14 .comp32 .frame4 svg path{
    stroke: white;
}

.navBarLeftDeskopContLightMinimize .leftMenuLinkItem .frame4 p {
    display: none;
}


.navBarLeftDeskopContLightMinimize .navBarLeftDeskopLasttChild {
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}



.navBarLeftDeskopContLightMinimize .addEmploysAndToggleThemeAndReclamCont{
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}


.navBarLeftDeskopContLightMinimize .addEmploysAndToggleThemeAndReclamCont .forSlide{
    display: none;
}

.navBarLeftDeskopContLightMinimize .addEmploysAndToggleThemeAndReclamCont > div:first-child{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 10px;
align-self: stretch;
}


.navBarLeftDeskopContLightMinimize .addEmploysAndToggleThemeAndReclamCont .toggleBTNForTheme{
    width: 100%;
}


.navBarLeftDeskopContLightMinimize .addEmploysAndToggleThemeAndReclamCont > div:first-child > div{
    display: none;
}



.navBarLeftDeskopContLightMinimize  .addEmloyLeftBtnCont{
    display: flex;
padding: 12px 16px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 12px;
background: #3B79F6;
    width: 64px;
    transition: 0.5s;
}

.navBarLeftDeskopContLightMinimize .addEmloyLeftBtnCont p{
    display: none;
}


.navBarLeftDeskopContLightMinimize .navBarLeftDeskopLasttChild>div:last-child{
    display: none;
}


.navBarLeftDeskopContLightMinimize .toggleBTNForTheme .frame4{
    display: none;
}

.navBarLeftDeskopContLightMinimize .toggleBTNForTheme  .component38{
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
background: #ECF0FF;
}


