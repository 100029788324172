.conteinerToggelBTNContLight {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    background: #FFF;
}

.modalToggleCont {
    width: 100%;

}

.conteinerToggelBTNContLight>.modalheader {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.conteinerToggelBTNContLight>.modalheader>h1 {
    color: #353535;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
}


.conteinerToggelBTNContLight>.modalheader>p {
    align-self: stretch;
    color: #585858;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.conteinerToggelBTNContLight>.modalToggelBTNCont {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 16px;
}

.conteinerToggelBTNContLight>.modalToggelBTNCont>button {
    display: flex;
    height: 48px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 12px;
    background: #F8FBFF;
    color: #353535;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */

}

.conteinerToggelBTNContLight>.modalToggelBTNCont>.active {
    background: linear-gradient(0deg, #5E7DE8 0%, #5E7DE8 100%), #FFF;
    color: #FFF;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
}

.conteinerToggelBTNContLight>.modalForInstructions {
    display: flex;
    height: 48px;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.conteinerToggelBTNContLight>.modalForInstructions>div {
    display: flex;
    align-items: center;
    gap: 8px;
}

.conteinerToggelBTNContLight>.modalForInstructions>div>svg {
    width: 24px;
    height: 24px;
}

.conteinerToggelBTNContLight>.modalForInstructions>div>p {
    color: #3B79F6;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

@media only screen and (max-width: 600px) {
    .conteinerToggelBTNContLight>.modalToggelBTNCont {
        flex-direction: column;
    }

    .conteinerToggelBTNContLight>.modalToggelBTNCont>button {
        width: 100%;
    }

}