.firstVisitLight>.chakra-modal__body>p {
    align-self: stretch;
    color: #585858;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.firstVisitLight>.chakra-modal__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.firstVisitLight>.chakra-modal__header >div{
    display: flex;
    padding: var(--Field-sizes-Field---Label-gap, 6px) 9px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: 0.5s;
}


.firstVisitLight>.chakra-modal__header >div:hover{
    cursor: pointer;
    background-color: #e7e7e7;
    border-radius: 12px;
}
.firstVisitLight>.chakra-modal__header >div >svg{
    width: 24px;
    height: 24px;
}

.firstVisitLight>.chakra-modal__header >div >svg path{
    stroke: #353535;
    fill: #353535;
}


.firstVisitLight>.chakra-modal__header>p {
    color: #353535;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
}

.firstVisitLight>.chakra-modal__footer {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}



.firstVisitLight>.chakra-modal__footer>button:last-child {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    background: var(--Primary-Primary-500, #3B79F6);
}

.firstVisitLight>.chakra-modal__footer>button:last-child>p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.firstVisitLight>.chakra-modal__footer>button:last-child>div {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.firstVisitLight>.chakra-modal__footer>button:first-child {
    display: flex;
    height: 48px;
    padding: 16px;
    align-items: center;
    gap: 4.067px;
    border-radius: 16px;
    border: var(--Overlay-border-width, 1px) solid rgba(0, 0, 0, 0.10);
}

.firstVisitLight>.chakra-modal__footer>button:first-child>p {
    color: #858C99;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}