.navBarLeftDeskopContDarkMinimize {
    display: flex;
    width: 100px;
    height: 100dvh;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    border-right: var(--Overlay-border-width, 1px) solid   rgba(255, 255, 255, 0.10);
    background: #12151A;
    transition: 0.5s;
    overflow: hidden;
    overflow-y: auto;
}


/* width */
.navBarLeftDeskopContDarkMinimize::-webkit-scrollbar {
    width: 2px;
  }
  
  /* Track */
  .navBarLeftDeskopContDarkMinimize::-webkit-scrollbar-track {
    background: #12151A;
  }
  
  /* Handle */
  .navBarLeftDeskopContDarkMinimize::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .navBarLeftDeskopContDarkMinimize::-webkit-scrollbar-thumb:hover {
    background: #555;
  }


.navBarLeftDeskopContDarkMinimize .navBarLeftDeskopFirstChild {
    width: 100%;
}

.navBarLeftDeskopContDarkMinimize .logoAndSaitNameCont {
    display: none;

}




.navBarLeftDeskopContDarkMinimize .toggleAndLogoCont {
    display: flex;
    height: 72px;
    padding: 20px 16px 20px 32px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-bottom:1px solid rgba(255, 255, 255, 0.10) ;

}


.navBarLeftDeskopContDarkMinimize .toggleAndLogoCont>div:last-child {
    display: flex;
    padding: 7.2px;
    justify-content: center;
    align-items: center;
    gap: 7.2px;
    border-radius: 72px;
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #FFF;
    box-shadow: 0px 1.44px 3.6px 0px rgba(38, 51, 77, 0.03);
    cursor: pointer;
}

.navBarLeftDeskopContDarkMinimize .toggleAndLogoCont>div:last-child svg path{
    stroke: white;
}

.navBarLeftDeskopContDarkMinimize .Component38>div:last-child {
    display: none;
}

.navBarLeftDeskopContDarkMinimize .Component38>div:first-child {
    display: flex;
    padding: 12px 16px 12px 32px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #ECF0FF;
    margin-top: 12px;
}


.navBarLeftDeskopContDarkMinimize .Component38>div:first-child p {
    display: none;
   
}


.navBarLeftDeskopContDarkMinimize .leftMenuLinkItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
}


.navBarLeftDeskopContDarkMinimize .leftMenuLinkItem .Frame54 {
    display: flex;
    padding: 2px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    margin-bottom: 8px;

   
}
.navBarLeftDeskopContDarkMinimize .leftMenuLinkItem  .frame4 > div{
    display: flex;
    width: 20px;
    height: 20px;
    padding: 3px 1.801px 3px 2px;
    justify-content: center;
    align-items: center;
    margin-left: 23%;
}

.navBarLeftDeskopContDarkMinimize .leftMenuLinkItem .Frame54 .comp32 svg path{
    stroke: white;
}

.navBarLeftDeskopContDarkMinimize .leftMenuLinkItem .active {
    background: #5E7DE8;
    
}


.navBarLeftDeskopContDarkMinimize .leftMenuLinkItem .active .badgeChatMessage{
    background-color: white !important;
    
    
}

.navBarLeftDeskopContDarkMinimize .leftMenuLinkItem .active .badgeChatMessage > p{
    color: #5E7DE8 !important;
}


.navBarLeftDeskopContDarkMinimize .leftMenuLinkItem .active .Frame14 .comp32 .frame4 svg path{
    stroke: white;
}

.navBarLeftDeskopContDarkMinimize .leftMenuLinkItem .Frame54 .Frame15 {
    display: flex;
    padding: 12px 24px 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 9px;
}


.navBarLeftDeskopContDarkMinimize .leftMenuLinkItem .Frame54:hover{
    background: #5E7DE8;
}


.navBarLeftDeskopContDarkMinimize .leftMenuLinkItem .Frame54:hover .Frame14 .comp32 .frame4 svg path{
    stroke: white;
}

.navBarLeftDeskopContDarkMinimize .leftMenuLinkItem .frame4 p {
    display: none;
}


.navBarLeftDeskopContDarkMinimize .navBarLeftDeskopLasttChild {
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}



.navBarLeftDeskopContDarkMinimize .addEmploysAndToggleThemeAndReclamCont{
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}


.navBarLeftDeskopContDarkMinimize .addEmploysAndToggleThemeAndReclamCont .forSlide{
    display: none;
}

.navBarLeftDeskopContDarkMinimize .addEmploysAndToggleThemeAndReclamCont > div:first-child{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 10px;
align-self: stretch;
}


.navBarLeftDeskopContDarkMinimize .addEmploysAndToggleThemeAndReclamCont .toggleBTNForTheme{
    width: 100%;
}


.navBarLeftDeskopContDarkMinimize .addEmploysAndToggleThemeAndReclamCont > div:first-child > div{
    display: none;
}



.navBarLeftDeskopContDarkMinimize  .addEmloyLeftBtnCont{
    display: flex;
padding: 12px 16px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 12px;
background: #3B79F6;
}

.navBarLeftDeskopContDarkMinimize .addEmloyLeftBtnCont p{
    display: none;
}


.navBarLeftDeskopContDarkMinimize .navBarLeftDeskopLasttChild>div:last-child{
    display: none;
}


.navBarLeftDeskopContDarkMinimize .toggleBTNForTheme .frame4{
    display: none;
}

.navBarLeftDeskopContDarkMinimize .toggleBTNForTheme  .component38{
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    border-radius: 12px;
    background: linear-gradient(0deg, #21262E 0%, #21262E 100%), #ECF0FF;
}