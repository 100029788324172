.noTaskContLight {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    background: #ECF0FF;
    height: 100%;
}


.noTaskContLight>div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 16px;
    margin: auto;

}

.noTaskContLight>div:last-child>div:first-child {
    width: 342.258px;
    height: 255px;
    background-color: red;
    background: url("../../../../../../client/public/notTaskImg.png") 50% / cover no-repeat;
}

.noTaskContLight>div:last-child>div:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.noTaskContLight>div:last-child>div:last-child>div:first-child {
    display: flex;
    width: 356.079px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}


.noTaskContLight>div:last-child>div:last-child>div:first-child>p:first-child {
    color: #353535;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 100% */
}

.noTaskContLight>div:last-child>div:last-child>div:first-child>p:last-child {
    align-self: stretch;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 121.429% */
}



.noTaskContLight>div:last-child>div:last-child>div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.noTaskContLight>div:last-child>div:last-child>button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.noTaskContLight>div:last-child>div:last-child>button>p {
    color: var(--Primary-Primary-500, #3B79F6);

    /* Field label */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

.noTaskContLight>div:last-child>div:last-child>button>div {
    display: flex;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: var(--Border-radius-L, 8px);
    background: #F7F8FA;
}

.noTaskContLight>div:last-child>div:last-child>button>div>svg path {
    fill: #3B79F6;
}