.firstVisitDark>.chakra-modal__body>p {
    align-self: stretch;
    color: #A0A0A0;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.firstVisitDark{
background-color: #21262E !important;
}
.firstVisitDark>.chakra-modal__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.firstVisitDark>.chakra-modal__header >div{
    display: flex;
    padding: var(--Field-sizes-Field---Label-gap, 6px) 9px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: 0.5s;

}
.firstVisitDark>.chakra-modal__header >div:hover{
    background-color: #323A49;
    cursor: pointer;
    border-radius: 12px;

}

.firstVisitDark>.chakra-modal__header >div > svg{
    width: 24px;
height: 24px;
}
.firstVisitDark>.chakra-modal__header>p {
    color:white;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 120% */
}


.firstVisitDark>.chakra-modal__footer {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}



.firstVisitDark>.chakra-modal__footer>button:last-child {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    background: var(--Primary-Primary-500, #3B79F6);
}

.firstVisitDark>.chakra-modal__footer>button:last-child>p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.firstVisitDark>.chakra-modal__footer>button:last-child>div {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.firstVisitDark>.chakra-modal__footer>button:first-child {
    display: flex;
    height: 48px;
    padding: 16px;
    align-items: center;
    gap: 4.067px;
    border-radius: 16px;
    border: var(--Overlay-border-width, 1px) solid rgba(255, 255, 255, 0.10);
}

.firstVisitDark>.chakra-modal__footer>button:first-child>p {
    color: #858C99;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}