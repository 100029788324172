.taskMenegerHeaderDark {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}


.taskMenegerHeaderDark>div:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 16px;
}

.taskMenegerHeaderDark>div:nth-child(1) .Component50 {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 12px;
    background: #3B79F6;
}

.taskMenegerHeaderDark>div:nth-child(1) .Component50 .Frame4 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.taskMenegerHeaderDark>div:nth-child(1) .Component50 .Frame4 p {
    color: #FFF;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.taskMenegerHeaderDark>div:nth-child(1) .Component50 .Frame4 .Component46 {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px;
    justify-content: center;
    align-items: center;
}

.taskMenegerHeaderDark>div:nth-child(1) .Component50 .Frame4 .Component46 svg {
    width: 21.5px;
    height: 21.5px;
    flex-shrink: 0;
}

.taskMenegerHeaderDark>div:nth-child(1) .Component41 {
    position: relative;
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
}

.taskMenegerHeaderDark>div:nth-child(1) .Component41 .Field {
    display: flex;
    padding: 0px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #21262E;
    cursor: pointer;
}

.taskMenegerHeaderDark>div:nth-child(1) .Component41 .Field .value {
    display: flex;
    align-items: center;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
}

.taskMenegerHeaderDark>div:nth-child(1) .Component41 .Field .value .Frame625852 {
    display: flex;
    align-items: center;
    gap: 4px;
}

.taskMenegerHeaderDark>div:nth-child(1) .Component41 .Field .value .Frame625852 p {
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 1 !important;
    overflow: hidden;
    color: #929292 !important;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
}

.taskMenegerHeaderDark>div:nth-child(1) .Component41 .Field .value .Frame625852 .Frame625851 {
    padding: 0 5px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: white;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.taskMenegerHeaderDark>div:nth-child(1) .Component41 .Field .value .Frame625852 .Component46 {
    width: 24px;
    height: 24px;
}

.taskMenegerHeaderDark>div:nth-child(1) .Component41 .Field .value .Frame625852 .Component46 svg path{
    stroke: white;
}

.taskMenegerHeaderDark>div:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 16px;
}

.taskMenegerHeaderDark>div:nth-child(2)>.Component43 {
    display: flex;
    width: 330px;
    height: 48px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #21262E;
}

.taskMenegerHeaderDark>div:nth-child(2)>.Component43>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 0.25px 0.25px 1.25px 1.25px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.taskMenegerHeaderDark>div:nth-child(2)>.Component43>input {
    color: #A8A8A8;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    /* 171.429% */
    outline: none;
    border: none;
    width: 100%;
    background: #21262E;
}

.taskMenegerHeaderDark>div:nth-child(2)>.Component43>input::placeholder{
    color: #A8A8A8;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    /* 171.429% */
}

@media only screen and (max-width: 1551px) {

    .taskMenegerHeaderDark>div:nth-child(2)>.Component43 {
        width: 211px;
    }

    .taskMenegerHeaderDark>div:nth-child(1) .Component41 .Field .value .Frame625852 p {
        display: none !important;
    }
}


@media only screen and (max-width: 1024px) {

    .taskMenegerHeaderDark>div:nth-child(2)>.Component43 {
        width: 331px;
    }
}

@media only screen and (max-width: 842px) {
    .taskMenegerHeaderDark>div:nth-child(1) .Component41 {
        display: flex;
        height: 48px;
        width: 100%;

    }

    .taskMenegerHeaderDark {
        flex-direction: column;
    }

    .taskMenegerHeaderDark>div:nth-child(1) {
        flex-direction: column;
        margin-bottom: 12px;
        width: 100%;
    }

    .taskMenegerHeaderDark>div:nth-child(1) .Component50 {
        width: 100%;
    }

    .taskMenegerHeaderDark>div:nth-child(2) {
        width: 100%;
    }

    .taskMenegerHeaderDark>div:nth-child(2)>.Component43 {
        width: 100%;
    }
}

/* dropDown */

.taskMenegerHeaderDark .dropdown-contentSelecet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    background-color: #171B22;
    min-width: 160px;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
    top: 41px;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
    


}

.taskMenegerHeaderDark .dropdown-contentSelecet li {
    display: flex;
    height: 39px;
    padding: 0px 10px;
    align-items: center;
    align-self: stretch;
    border: 1px solid #171B22;
    background: linear-gradient(0deg, #171B22 0%, #171B22 100%);
    color: white;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    cursor: pointer;
    /* 171.429% */


}