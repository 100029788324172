.file-upload {
    display: flex;
    align-items: start;
    justify-content: start;
    margin: 20px;
}

.file-upload-label {
    display: inline-block;
    background-color: #3182CE; /* Цвет фона кнопки */
    color: white; /* Цвет текста */
    padding: 15px 30px; /* Отступы кнопки */
    border-radius: 5px; /* Закругленные углы */
    cursor: pointer; /* Указатель при наведении */
    transition: background-color 0.3s; /* Плавный переход цвета */
}

.file-upload-label:hover {
    background-color: #45a049; /* Цвет фона кнопки при наведении */
}

.file-upload-input {
    display: none; /* Скрываем стандартное поле загрузки */
}
