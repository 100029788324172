.subTaskContLight {
    display: flex;
    width: 100%;
    padding: 12px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background: #F8FBFF;
}

.subTaskContLight>div {
    display: flex;
    padding: var(--Field-sizes-Field-padding-top, 0px) 0px var(--Field-sizes-Field-padding-bottom, 0px) 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;

}

.subTaskContLight>div>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    overflow: hidden;
    color: var(--Secondary-Text, rgba(27, 43, 65, 0.69));
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* 121.429% */
}

.subTaskContLight>div .addSubTaskBtnInput {
    display: flex;
    align-items: flex-start;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
    align-self: stretch;
}

.subTaskContLight>div .addSubTaskBtnInput>input {
    display: flex;
    height: 44px;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    border-radius: 12px;
    border: var(--Field-sizes-Field-border-width, 1px) solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    flex: 1 0 0;
    overflow: hidden;
    color: #353535;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}

.subTaskContLight>div .addSubTaskBtnInput>input::placeholder {
    color: #353535;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}

.subTaskContLight>div .addSubTaskBtnInput>button {
    display: flex;
    width: 44px;
    height: 44px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background: #3B79F6;
}

.subTaskContLight>div .addSubTaskBtnInput>button>div {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1.25px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.subTaskContLight .listSubTask {
    width: 100%;

    min-height: 120px;
    max-height: 175px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: var(--Field-sizes-Field---Label-gap, 6px);
}

/* width */
.subTaskContLight .listSubTask::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.subTaskContLight .listSubTask::-webkit-scrollbar-track {
    background: white;
}

/* Handle */
.subTaskContLight .listSubTask::-webkit-scrollbar-thumb {
    background: #C9C9C9;
}

/* Handle on hover */
.subTaskContLight .listSubTask::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.subTaskContLight .modalCreatTaskChild>div:first-child {
    display: flex;
    width: 100%;
    min-height: 44px;   
    padding: 3px 16px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 12px;
    background: rgba(28, 55, 90, 0.05);
    cursor: pointer;
}

.subTaskContLight .modalCreatTaskChild>div:first-child>p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    flex: 1 0 0;
    overflow: hidden;
    color: #353535;
    text-overflow: ellipsis;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
}

.subTaskContLight .modalCreatTaskChild>div:first-child>div {
    display: flex;
    height: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.subTaskContLight .modalCreatTaskChild>div:first-child>div>svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}

.subTaskContLight .modalCreatTaskChild .modalChildModal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 10;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.subTaskContLight .modalCreatTaskChild .modal-contentChildModal {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 100%;
    /* Full width */
    height: 100dvh;
    /* Full height */
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
    
}